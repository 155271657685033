<div class="container">
  <section class="splash-area">
    <div class="info">
      <h2>Welcome to FIP Oasis</h2>
      <p>
        {{ OUR_MISSION }}
      </p>
      <p>
        Create an account today for a free trial to get immediate help with
        treatment.
      </p>
      <button
        mat-flat-button
        color="primary"
        (click)="signUp()">
        Sign Up
      </button>
      <div style="margin-top: 2.5rem">
        <h3>Other Resources</h3>
        <div class="ctas">
          <a
            href="https://assets.website-files.com/57b6460c0c7bdb62381c074b/62b1f339bdb99715147e75b1_FIP%20Warriors%20Treatment%20Guide.pdf"
            mat-stroked-button
            target="_blank">
            FIP Warriors Treatment Guide
          </a>
          <button mat-stroked-button routerLink="/cost-estimator">Cost Estimator</button>
          <button mat-stroked-button routerLink="/faqs">FAQs</button>
          <!--<button mat-stroked-button routerLink="/registration">
          Pre-Register Your Cat
        </button>-->
        </div>
      </div>
    </div>
  </section>
  <section class="highlights-area">
    <div class="highlights">
      <h2>Features</h2>
      <div class="cards-container">
        <mat-card class="card">
          <mat-card-header>
            <mat-card-title style="font-size: 1.25rem"
              >Track Your Treatment and Observation
            </mat-card-title>
            <app-chips>PRO</app-chips>
          </mat-card-header>
          <mat-card-content style="margin-top: 1rem">
            <p>
              Input treatment logs using data from your cats recommended medical
              care plan; calculate dose for each medicinal shot or pill. The
              treatment log will give your assigned administrators a clear view
              into your cats health.
            </p>
          </mat-card-content>
        </mat-card>
        <mat-card class="card">
          <mat-card-header>
            <mat-card-title style="font-size: 1.25rem"
              >Get Assistance
            </mat-card-title>
            <app-chips>PRO</app-chips>
          </mat-card-header>
          <mat-card-content style="margin-top: 1rem">
            <p>
              Notify administrators with a request for help and leave comments
              for them to review later on. They will respond and give guidance
              on how to proceed next.
            </p>
          </mat-card-content>
        </mat-card>
        <!-- TODO: Get help on making this
        <mat-card class="card">
          <mat-card-header>
            <mat-card-title style="font-size: 1.25rem"
            >Store Files and Tasks
            </mat-card-title>
            <app-chips>PRO</app-chips>
          </mat-card-header>
          <mat-card-content style="margin-top: 1rem">
            <p>
              Input Blood Work values to get help understanding your pets health.
            </p>
          </mat-card-content>
        </mat-card> -->
        <mat-card class="card">
          <mat-card-header>
            <mat-card-title style="font-size: 1.25rem"
              >Easy Dosing designed to work with the popular GS Brands
            </mat-card-title>
            <app-chips>PRO</app-chips>
          </mat-card-header>
          <mat-card-content style="margin-top: 1rem">
            <p>
              Data on the popular treatment medication brands are stored within
              FIP Oasis. We keep track of the important brand data such as shots
              or pills, concentration and dosage, so you don't have to. Simply
              input your cats weight, verify the correct medication brand, and
              click "Calculate Dose" for your correct dose.
            </p>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </section>
</div>
