<div class="page-container">
  <h1>Treatment Products</h1>
  <div class="page-description">
    <p>
      To determine what products your cat needs, consult your cats
      <strong>Medical Care Plan.</strong>
    </p>
    <p *ngIf="applicationData?.activeMedicationBrands?.length === 0">
      You need a cat assigned to a treatment product to see recommendations.
    </p>
    <p
      *ngIf="
        applicationData?.activeMedicationBrands?.length ?? 0 > 0
      ">
      We've found the assigned Medication Brands for your cats below.
    </p>
  </div>

  <div class="treatment-products">
    <div *ngIf="showRequisitions">
      <h4 class="category-header">Karma, Cappella, Rainbow</h4>
      <!-- <ng-container
        *ngTemplateOutlet="
          discounts;
          context: { brand: 'Karma' }
        "></ng-container> -->
      <button
        style="margin-top: 1rem"
        mat-stroked-button
        (click)="goToRequisitions()">
        <mat-icon
          aria-hidden="false"
          aria-label="New Capella Requisition"
          fontIcon="vaccines"></mat-icon
        >Requisitions
      </button>
    </div>
    <div *ngIf="showLucky">
      <h4 class="category-header">Lucky</h4>
      <ng-container *ngTemplateOutlet="discounts; context: { brand: 'Lucky' }">
      </ng-container>
      <div style="margin-top: 1rem" class="title-product">
        <a
          (click)="
            goToLink(
              'https://www.fip-treatment.com',
              AnalyticsEventEnum.LUCKY_PRODUCT
            )
          "
          >https://www.fip-treatment.com</a
        >
      </div>
    </div>
    <div *ngIf="showSparkAuraValor">
      <h4 class="category-header">Spark/Aura, Valor, Aala</h4>
      <a
        (click)="
          goToLink(
            'https://fipcare.us/shop',
            AnalyticsEventEnum.SPARK_AURA_VALOR_PRODUCT
          )
        "
        >https://fipcare.us/shop</a
      >
    </div>
    <div *ngIf="showHarmony">
      <h4 class="category-header">Harmony</h4>
      <a
        (click)="
          goToLink(
            'https://www.harmonyfipcats.com',
            AnalyticsEventEnum.HARMONY_PRODUCT
          )
        "
        >https://www.harmonyfipcats.com</a
      >
    </div>
    <div *ngIf="showAzul">
      <h4 class="category-header">Azul</h4>
      <a
        (click)="
          goToLink('http://azulfip.com', AnalyticsEventEnum.AZUL_PRODUCT)
        "
        >http://azulfip.com</a
      >
    </div>
  </div>
</div>

<ng-template #discounts let-brand="brand">
  <mat-accordion>
    <mat-expansion-panel hideToggle [(expanded)]="panelOpenState">
      <mat-expansion-panel-header>
        <mat-panel-title
          ><mat-icon style="margin-right: 0.5rem">discount</mat-icon> Coupons &
          Discounts
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div [ngSwitch]="brand">
        <div *ngSwitchCase="'Lucky'">
          <strong
            >Use discount code <span class="primary-color">OASIS20W</span> for
            20% off</strong
          >
        </div>
        <div *ngSwitchCase="'Karma'">
          <strong
            >Your Cat's first order using
            <span class="primary-color">Requisitions</span> will include a
            <span class="primary-color">Free Vial</span> or a
            <span class="primary-color">Free Bag of Pills!</span></strong
          >
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</ng-template>
