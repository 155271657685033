<div class="glance-card-container" (click)="clicked()">
  <div class="glance-card-header">
    <div class="title">{{ title }}</div>
    <div class="sub-title">{{ subTitle }}</div>
  </div>
  <div class="glance-card-body">
    <div class="glance-card-amount">
      {{ number }}
    </div>
    <span class="spacer"></span>
    <div class="glance-card-description">{{ description }}</div>
  </div>
</div>
