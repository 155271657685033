import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnDestroy } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { LoadingService } from '../../service/loading/loading.service';
import { OUR_MISSION } from '../../model/faq-constants';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss']
})
export class FaqsComponent implements OnDestroy {
  destroyed = new Subject<void>();
  isMobile!: boolean;

  constructor(
    breakpointObserver: BreakpointObserver,
    private loadingService: LoadingService
  ) {
    this.loadingService.setLoading(false);
    breakpointObserver
      .observe([Breakpoints.XSmall])
      .pipe(takeUntil(this.destroyed))
      .subscribe((result) => {
        this.isMobile = result.matches;
      });
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  protected readonly OUR_MISSION = OUR_MISSION;
}
