<form [formGroup]="datesForm" class="container">
  <mat-form-field hintLabel="MM/DD/YYYY" class="startDate">
    <mat-label>Start Date</mat-label>
    <input
      id="startDate"
      appRequiredRoles="Admin"
      matInput
      [matDatepicker]="startDate"
      formControlName="startDate" />
    <mat-datepicker-toggle
      matIconSuffix
      [for]="startDate"></mat-datepicker-toggle>
    <mat-datepicker #startDate></mat-datepicker>
  </mat-form-field>
  <button
    class="button"
    mat-stroked-button
    [disabled]="startDate.disabled"
    (click)="setTreatmentEndDate()"
    style="">
    Fill End Date
  </button>

  <mat-form-field hintLabel="MM/DD/YYYY" class="endDate">
    <mat-label>End Date</mat-label>
    <input
      id="endDate"
      matInput
      [matDatepicker]="endDate"
      formControlName="endDate" />
    <mat-datepicker-toggle
      matIconSuffix
      [for]="endDate"></mat-datepicker-toggle>
    <mat-datepicker #endDate></mat-datepicker>
  </mat-form-field>
</form>
<div class="action-footer">
  <button mat-stroked-button (click)="close()">Close</button>
  <button
    mat-flat-button
    color="primary"
    (click)="save()"
    [disabled]="!datesForm.valid">
    Save
  </button>
</div>
