import { Component } from '@angular/core';
import { LoadingService } from '../../service/loading/loading.service';
import { MatDialog } from '@angular/material/dialog';
import { ApplicationData, CatTableRow, Requisition } from '../../model/models';
import { RequisitionModalComponent } from '../../components/modals/requisition-modal/requisition-modal.component';
import { FipAdminApiService } from '../../service/api/fip-admin-api.service';
import { AnalyticsEventEnum, RequisitionStatus } from '../../model/enums';
import { AnalyticsService } from '../../service/analytics/analytics.service';
import { ApplicationDataService } from '../../service/data/application-data.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-requisitions',
  templateUrl: './requisitions.component.html',
  styleUrls: ['./requisitions.component.scss']
})
export class RequisitionsComponent {
  requisitions: Requisition[] = [];
  cats: CatTableRow[] | undefined;
  requirePayment = false;
  applicationData: ApplicationData | undefined;
  loading = true;

  constructor(
    private loadingService: LoadingService,
    private dialog: MatDialog,
    private fipAdminApiService: FipAdminApiService,
    private analyticsService: AnalyticsService,
    private applicationDataService: ApplicationDataService
  ) {
    this.fipAdminApiService.getRequisitions().subscribe((requisitions) => {
      this.requisitions = requisitions;
      this.requirePayment =
        this.requisitions.find(
          (requisition) =>
            requisition.status === RequisitionStatus.AWAITING_PAYMENT
        ) != undefined;
    });

    this.fipAdminApiService.getCats().subscribe((cats) => {
      this.cats = cats;
      this.loading = false;
    });

    this.applicationDataService
      .getApplicationData()
      .pipe(takeUntilDestroyed())
      .subscribe((applicationData) => (this.applicationData = applicationData));

    this.loadingService.setLoading(false);
  }

  openRequisition(requisition: Requisition | undefined) {
    const eventEnum = requisition
      ? AnalyticsEventEnum.VIEW_REQUISITION
      : AnalyticsEventEnum.NEW_REQUISITION;
    this.analyticsService.sendAnalyticsEvent(
      this.applicationData,
      undefined,
      eventEnum
    );
    const requisitionFormDialog = this.dialog.open(RequisitionModalComponent, {
      minWidth: 350,
      width: '75vw',
      maxWidth: 750,
      ariaModal: true,
      autoFocus: false,
      data: {
        requisition: requisition,
        cats: this.cats
      }
    });
    requisitionFormDialog
      .afterClosed()
      .subscribe((requisition: Requisition) => {
        if (requisition) {
          const index = this.requisitions.findIndex(
            (r) => r.id === requisition.id
          );
          if (index != -1) {
            this.requisitions[index] = requisition;
          } else {
            this.requisitions.push(requisition);
          }
          this.requisitions.sort((a, b) => {
            if (a.id && b.id) {
              return Number(b.id) - Number(a.id);
            }
            return 0;
          });
          // To trigger change detection in the table... so stupid i know
          this.requisitions = [...this.requisitions];
        }
      });
  }
}
