import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { Comment, CreateNotification } from '../../model/models';
import { FipAdminApiService } from '../../service/api/fip-admin-api.service';
import { FormControl, Validators } from '@angular/forms';
import { NotificationService } from '../../service/notification/notification.service';
import {
  distinctUntilChanged,
  Subject,
  Subscription,
  take,
  takeUntil,
  timer
} from 'rxjs';
import { notificationStatus } from '../../model/constants';
import { handleAvatarResponse } from '../../service/utils/ui-utils';

@Component({
  selector: 'app-app-comments',
  templateUrl: './cat-comments.component.html',
  styleUrls: ['./cat-comments.component.scss']
})
export class CatCommentsComponent implements OnInit, OnDestroy {
  @Input()
  comments: Comment[] = [];

  @Input()
  catId: string | undefined;

  @Output()
  updatedComments = new EventEmitter();

  maxLength = 9999;
  notificationSubscription$: Subscription | undefined;

  textArea = new FormControl('', Validators.max(this.maxLength));

  toggleRequestAssistance = new FormControl(true);

  remaining = this.maxLength;

  timeZone = 'UTC';

  enableSubmit = true;
  loading = false;
  requestAdminAssistance = false;
  adminAssistanceNotificationIds: string[] = [];

  profileIdImages = new Map();

  destroyed = new Subject<void>();

  constructor(
    private fipAdminApiService: FipAdminApiService,
    private notificationService: NotificationService
  ) {
    this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    this.textArea.valueChanges.subscribe((value) => {
      this.remaining = this.maxLength - (value ? value.length : 0);
    });
  }

  ngOnInit() {
    if (this.catId) {
      this.handleNotifications();
      timer(10000, 10000)
        .pipe(takeUntil(this.destroyed))
        .subscribe(() => {
          this.fipAdminApiService
            .getComments(this.catId)
            .subscribe((response) => {
              this.comments = response;
            });
        });
    }

    this.setProfileImages();

    // Decided not to put inside parent because it's a lot of code
    // So this component re-renders everytime you select the comment tab, but it's not a huge deal
    // Just not super fluid. The fix is to make cat subscribe to notifications and store / pass in
    // if the user requested help
    this.toggleRequestAssistance.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((value) => {
        this.requestAssistanceChange(value);
      });
  }

  private setProfileImages() {
    const profileImageIds = new Set<string>();
    this.comments.forEach((comment) => {
      if (comment.createdBy && comment.createdBy.id) {
        profileImageIds.add(comment.createdBy.id);
      }
    });

    profileImageIds.forEach((profileId) => {
      this.fipAdminApiService
        .getProfileAvatar(profileId)
        .subscribe((avatar) => {
          this.profileIdImages.set(profileId, handleAvatarResponse(avatar));
        });
    });
  }

  saveComment() {
    if (this.catId) {
      const comment: Comment = {
        id: crypto.randomUUID(),
        text: this.textArea.value || '',
        catId: this.catId
      };
      this.enableSubmit = false;
      this.loading = true;
      this.toggleRequestAssistance.disable({
        emitEvent: false
      });

      this.fipAdminApiService.saveComment(comment).subscribe((comment) => {
        this.comments.unshift(comment);
        this.notificationService.retrieveNotifications();
        this.setProfileImages();
      });

      timer(1000)
        .pipe(take(1))
        .subscribe(() => {
          this.enableSubmit = true;
          this.loading = false;
          this.toggleRequestAssistance.enable({
            emitEvent: false
          });
        });

      this.textArea.setValue('');
      this.updatedComments.emit(this.comments);
      this.setProfileImages();
    }
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
    // Trying to prevent memory leaks, do we need this? Won't this break browser caching?
    /* this.profileIdImages.forEach((value) => {
      URL.revokeObjectURL(value);
    }); */
  }

  isAdminComment(comment: Comment) {
    return comment.createdBy?.role?.includes('Admin');
  }

  requestAssistanceChange(checked: boolean | null) {
    if (checked && this.catId) {
      const createNotification: CreateNotification = {
        type: notificationStatus.REQUEST_HELP,
        catId: this.catId
      };
      this.notificationService
        .saveNotification(createNotification)
        .subscribe(() => {
          this.notificationService.retrieveNotifications(); // Super ineffective but if its not being called that much meh
        });
    } else {
      this.adminAssistanceNotificationIds.forEach((id) => {
        if (id) {
          this.notificationService.clearSingleNotification(id).subscribe(() => {
            this.notificationService.retrieveNotifications();
          });
        }
      });
    }
  }

  private handleNotifications() {
    this.notificationSubscription$ =
      this.notificationService.notificationSubject
        .pipe(takeUntil(this.destroyed))
        .subscribe((notifications) => {
          const catHelpNotifications = notifications.filter(
            (notification) =>
              notification.catId === this.catId &&
              notification.type == notificationStatus.REQUEST_HELP
          );

          this.requestAdminAssistance = catHelpNotifications.length > 0;
          this.adminAssistanceNotificationIds = catHelpNotifications.map(
            (notification) => notification.id
          );
          if (
            this.toggleRequestAssistance.value != this.requestAdminAssistance
          ) {
            this.toggleRequestAssistance.setValue(this.requestAdminAssistance, {
              emitEvent: false
            });
          }
        });
  }

  showProfileImage(comment: Comment) {
    if (comment.createdBy && comment.createdBy.id) {
      return !!this.profileIdImages.get(comment.createdBy.id);
    }
    return false;
  }
}
