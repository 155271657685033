<h2 mat-dialog-title>Support us</h2>
<div mat-dialog-content>
  Choose a cat below.
  <div style="margin-top: 0.5rem">
    When purchasing pro you will be:
    <ul>
      <li class="pro-item">
        Supporting FIP Oasis maintenance and upkeep.
      </li>
      <li class="pro-item">
        Supporting development of new FIP Oasis features.
      </li>
      <li class="pro-item">
        Supporting betterment of FIP Treatment and Research.
      </li>
      <li class="pro-item">Helping keep the site free to support FIP treatment for cats around the country.</li>
    </ul>
    <mat-form-field>
      <mat-label>Cat</mat-label>
      <mat-select [formControl]="selectedCatControl">
        <mat-option *ngFor="let cat of cats" [value]="cat">
          {{ cat.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-chip-listbox style="margin-bottom: 1rem;" [formControl]="selectedMembership">
      <mat-chip-option *ngFor="let membership of proMemberships" [value]="membership">{{membership.name}}</mat-chip-option>
    </mat-chip-listbox>
    <div>
      Thanks for being a FIP Oasis user!
    <div><mat-icon>favorite</mat-icon> FIP Oasis team</div>
    </div>
  </div>
</div>
<div mat-dialog-actions align="end">
  <button mat-stroked-button (click)="cancel()">Go Back</button>
  <button mat-flat-button color="primary" [disabled]="!selectedCatControl.valid || !selectedMembership.valid" (click)="confirm()">Confirm</button>
</div>
