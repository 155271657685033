import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpStatusCode
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { SnackbarService } from '../../service/snackbar/snackbar.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private snackBarService: SnackbarService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.error instanceof Blob) {
          this.handleBlobError(err);
        } else if (this.isValidSnackbarError(err)) {
          this.snackBarService.openFailureSnackBar(
            err.status + ': ' + err.error.message
          );
        }
        // If you throw this it will get caught in subscribes later on
        return throwError(() => err);
      })
    );
  }

  private isValidSnackbarError(err: HttpErrorResponse) {
    return (
      err.status &&
      err.status != HttpStatusCode.Unauthorized &&
      err.error &&
      err.error.message
    );
  }

  private handleBlobError(err: HttpErrorResponse) {
    if (err.headers.get('content-type') === 'application/json') {
      const reader = new FileReader();
      reader.onload = () => {
        const json = JSON.parse(reader.result as string);
        this.snackBarService.openFailureSnackBar(
          err.status + ': ' + json.message
        );
        // If you throw this it will get caught in subscribes later on
        return throwError(() => err);
      };
      reader.readAsText(err.error);
    }
  }
}
