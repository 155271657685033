import { ResolveFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import {
  catchError,
  forkJoin,
  map,
  Observable,
  of,
  switchMap,
  throwError
} from 'rxjs';
import { FipAdminApiService } from '../../service/api/fip-admin-api.service';
import {
  CatRegistration,
  CatTableRow,
  DashboardData,
  DashboardResolverData
} from '../../model/models';
import { AuthService } from '@auth0/auth0-angular';
import { isUserAdmin } from '../../service/utils/ui-utils';

export const dashboardResolver: ResolveFn<DashboardResolverData | undefined> = (
  route,
  state
) => {
  const router = inject(Router);
  const fipAdminApiService = inject(FipAdminApiService);
  let dashboardData$: Observable<DashboardData | undefined> =
    fipAdminApiService.getDashboardData();
  let cats$: Observable<CatTableRow[]>;
  let catRegistrations$: Observable<CatRegistration[] | undefined>;

  // Lot going on in here
  return inject(AuthService).user$.pipe(
    switchMap((user) => {
      if (!user) {
        return throwError(() => 'Invalid User');
      }

      const isAdmin = isUserAdmin(user);

      if (isAdmin) {
        cats$ = fipAdminApiService.getAdminAssignedCats();
        catRegistrations$ = of(undefined);
      } else {
        cats$ = fipAdminApiService.getCats();
        dashboardData$ = of(undefined); // Data for them
        catRegistrations$ = fipAdminApiService.getCatRegistrationsByProfile();
      }

      return forkJoin([dashboardData$, cats$, catRegistrations$]).pipe(
        catchError(() => {
          void router.navigate(['error/forbidden']);
          return throwError(() => 'Forbidden');
        }),
        map(([dashboardData, cats, catRegistrations]) => {
          const mappedCatRegistrations = catRegistrations?.map(
            (registration) => ({
              ...registration,
              data: JSON.parse(registration.data)
            })
          );
          return {
            dashboardData,
            cats,
            catRegistrations: mappedCatRegistrations
          };
        })
      );
    })
  );
};
