<h2 mat-dialog-title>{{ data?.title }}</h2>
<div mat-dialog-content>
  <mat-list *ngIf="data.listData" role="list">
    <mat-list-item
      *ngFor="let item of data?.listData"
      role="listitem"
      (click)="close(item)"
      class="list-item">
      <!-- Notification check lol -->
      <div style="margin-bottom: 0.5rem" *ngIf="item.type">
        {{ item?.catName }} - {{ item.dateCreated | timeAgo }}
      </div>

      <!-- Cats -->
      <div *ngIf="item.assignedAdmins">{{ item?.name }} - {{ item.city }}</div>

      <!-- Stupid check to verify it's a enrollment type ROFL -->
      <div
        *ngIf="item.open === false || item.open === true"
        class="icon-text-grid">
        <mat-icon
          fontIcon="visibility"
          style="font-size: 1.25rem; color: black"
          matTooltip="Continue Enrollment"
          matListItemIcon></mat-icon>
        {{ item?.profile?.firstName }} &nbsp;
        {{ item?.profile?.lastName }} &nbsp;
        {{ item?.dateCreated | date : "short" : timeZone }}
      </div>
    </mat-list-item>
  </mat-list>

  <!-- Upcoming tasks are grouped in a map by cat -->
  <div *ngIf="data.mapData">
    <mat-list *ngFor="let entry of data.mapData | keyvalue">
      <h4>{{entry.key}}</h4>
      <mat-list-item
        role="listitem"
        *ngFor="let catTask of $any(entry.value)"
        (click)="close(catTask)">
          <app-task-card
            [date]="(catTask.dueDate | date : 'dd' : timeZone) ?? ''"
            [month]="(catTask.dueDate | date : 'MMM' : timeZone) ?? ''"
            [description]="catTask.description"
            [notified]="catTask.notified"
            [notifiedOn]="catTask.notifiedOn"
            [isAdmin]="true"
            [type]="getTaskTitle(catTask.taskType)"></app-task-card>
      </mat-list-item>
    </mat-list>
  </div>


</div>
<div mat-dialog-actions align="end">
  <button mat-stroked-button (click)="close()">Go Back</button>
</div>
