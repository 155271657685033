<div class="task-card-container" (click)="clicked()">
  <div class="task-date">
    <div class="month">{{ month }}</div>
    <div class="date">{{ date }}</div>
  </div>
  <div class="task-info">
    <div class="category">{{ type }}</div>
    <div class="description">{{ description }}</div>
    <div class="notified" *ngIf="isAdmin">
      <mat-icon *ngIf="notified" color="primary" style="font-size: 1rem">
        campaign
      </mat-icon>
      <div>
        {{ notifiedOn | date : "shortDate" }}
      </div>
    </div>
  </div>
</div>
