import { Component, Input } from '@angular/core';
import { FipAdminApiService } from '../../service/api/fip-admin-api.service';
import { BloodWork } from '../../model/blood-work-models';
import { MatDialog } from '@angular/material/dialog';
import { BloodWorkTestModalComponent } from '../modals/blood-work-test-modal/blood-work-test-modal.component';
import { ConfirmationModalComponent } from '../modals/confirmation-modal/confirmation-modal.component';
import { SnackbarService } from '../../service/snackbar/snackbar.service';
import { BloodWorkModalComponent } from '../modals/blood-work-modal/blood-work-modal.component';

@Component({
  selector: 'app-blood-work',
  templateUrl: './blood-work.component.html',
  styleUrls: ['./blood-work.component.scss']
})
export class BloodWorkComponent {
  catId: string | undefined;
  @Input()
  set id(catId: string | undefined) {
    if (catId) {
      this.catId = catId;
      this.fipAdminApiService
        .getBloodWork(this.catId)
        .subscribe((bloodWorks) => (this.bloodWorks = bloodWorks));
    }
  }
  bloodWorks: BloodWork[] = [];

  constructor(
    private fipAdminApiService: FipAdminApiService,
    public dialog: MatDialog,
    private snackbarService: SnackbarService
  ) {}

  openBloodWork(bloodWork: BloodWork | undefined) {
    const bloodWorkModalDialog = this.dialog.open(BloodWorkModalComponent, {
      minWidth: 350,
      width: '75vw',
      maxWidth: 750,
      ariaModal: true,
      autoFocus: false,
      data: {
        bloodWork,
        catId: this.catId
      }
    });
    bloodWorkModalDialog.afterClosed().subscribe((bloodWork: BloodWork) => {
      if (bloodWork) {
        this.updateBloodWorks(bloodWork);
      }
    });
  }

  updateBloodWorks(bloodWork: BloodWork) {
    const bloodWorkIndex = this.bloodWorks.findIndex(
      (bw) => bw.id === bloodWork.id
    );
    if (bloodWorkIndex != -1) {
      this.bloodWorks[bloodWorkIndex] = bloodWork;
    } else {
      this.bloodWorks.push(bloodWork);
      this.bloodWorks.sort((a, b) => {
        return new Date(a.date).getTime() - new Date(b.date).getTime();
      });
    }
    this.bloodWorks = [...this.bloodWorks];
  }

  saveBloodWork(bloodWork: BloodWork) {
    if (this.catId) {
      this.fipAdminApiService
        .saveBloodWork(bloodWork)
        .subscribe((bloodWork) => {
          this.updateBloodWorks(bloodWork);
          this.snackbarService.openSuccessSnackBar('Blood Work saved.');
        });
    }
  }

  openBloodWorkTests(bloodWork: BloodWork | undefined) {
    const bloodWorkTestModalDialog = this.dialog.open(
      BloodWorkTestModalComponent,
      {
        minWidth: 350,
        width: '75vw',
        maxWidth: 1200,
        ariaModal: true,
        autoFocus: false,
        data: {
          bloodWork,
          bloodWorks: this.bloodWorks
        }
      }
    );
    bloodWorkTestModalDialog.afterClosed().subscribe((bloodWork: BloodWork) => {
      if (bloodWork) {
        this.saveBloodWork(bloodWork);
      }
    });
  }

  deleteBloodWorkConfirm(bloodWork: BloodWork) {
    const confirmationDialog = this.dialog.open(ConfirmationModalComponent, {
      minWidth: 350,
      width: '75vw',
      maxWidth: 750,
      ariaModal: true,
      autoFocus: false,
      data: {
        action: `delete ${bloodWork.description} Blood Work`
      }
    });
    confirmationDialog.afterClosed().subscribe((decision: boolean) => {
      if (decision) {
        this.deleteBloodWork(bloodWork.id);
      }
    });
  }

  private deleteBloodWork(id: string | undefined) {
    this.fipAdminApiService.deleteBloodWork(id).subscribe(() => {
      this.bloodWorks = this.bloodWorks.filter(
        (bloodWork) => bloodWork.id !== id
      );
      this.snackbarService.openSuccessSnackBar('Blood Work deleted.');
    });
  }
}
