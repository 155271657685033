import { Directive, Input, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { ApplicationDataService } from '../data/application-data.service';
import { AuthService } from '@auth0/auth0-angular';
import { environment } from '../../../environments/environment';

@Directive({
  selector: '[appRequiredRoles]'
})
export class RequiredRolesDirective implements OnInit {
  @Input() appRequiredRoles: string | undefined;

  constructor(
    private applicationDataService: ApplicationDataService,
    private authService: AuthService,
    private ngControl: NgControl
  ) {}

  ngOnInit(): void {
    this.authService.user$.subscribe((user) => {
      if (user) {
        const roles = user[environment.namespace + '/roles'];
        const isDeveloper = roles.includes('Developer');
        const isRole = roles.includes(this.appRequiredRoles);

        // Don't disable for developers at all
        if (isDeveloper) {
          return;
        }
        // Default disable it, if they have the role, don't disable it
        let disabled = true;
        if (isRole) {
          disabled = false;
        }

        if (disabled) {
          this.ngControl.control?.disable();
        }
      }
    });
  }
}
