import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CatDate } from '../../../model/models';
import { FipAdminApiService } from '../../../service/api/fip-admin-api.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { SnackbarService } from '../../../service/snackbar/snackbar.service';
import { getDateWithoutTimezoneOffset } from '../../../service/utils/ui-utils';

@Component({
  selector: 'app-cat-dates-form',
  templateUrl: './cat-dates-form.component.html',
  styleUrls: ['./cat-dates-form.component.scss']
})
export class CatDatesFormComponent implements OnInit {
  @Input()
  catDate: CatDate | undefined;

  @Input()
  catId: string | undefined;

  @Input()
  dateType: string | undefined;

  @Output()
  catDatesSave = new EventEmitter<CatDate>();

  @Output()
  catDatesClose = new EventEmitter<void>();

  fipTreatmentDays = 84;

  datesForm = this.formBuilder.group({
    startDate: [new Date(), Validators.required],
    endDate: new FormControl<Date | undefined>(undefined, Validators.required)
  });

  constructor(
    private fipAdminApiService: FipAdminApiService,
    private formBuilder: FormBuilder,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit() {
    if (this.catDate) {
      this.setFormValues(this.catDate);
    }
  }

  setTreatmentEndDate() {
    const startDateValue = this.startDate.value;
    if (startDateValue) {
      const startDate = new Date(startDateValue);
      const endDate = startDate.getDate() + this.fipTreatmentDays;
      const newDate = new Date(startDate.setDate(endDate));
      this.endDate.setValue(newDate);
    }
  }

  get startDate() {
    return this.datesForm.controls.startDate;
  }

  get endDate() {
    return this.datesForm.controls.endDate;
  }

  private setFormValues(catDate: CatDate) {
    this.startDate.setValue(getDateWithoutTimezoneOffset(catDate.startDate));
    this.endDate.setValue(getDateWithoutTimezoneOffset(catDate.endDate));
  }

  close() {
    this.catDatesClose.emit();
  }

  save() {
    const catDate: any = {
      ...this.catDate,
      ...this.datesForm.getRawValue(),
      catId: this.catId,
      dateType: this.dateType
    };

    this.fipAdminApiService
      .saveCatDate(catDate)
      .subscribe((catDate: CatDate) => {
        this.snackbarService.openSuccessSnackBar('Dates Saved!');
        this.catDatesSave.emit(catDate);
      });
  }
}
