import { Component, Input } from '@angular/core';
import { CAT_FIP_OASIS_PRO_ID } from 'src/app/model/constants';
import { FipAdminApiService } from 'src/app/service/api/fip-admin-api.service';
import { PaymentBreakdownModalComponent } from '../modals/payment-breakdown-modal/payment-breakdown-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-purchase-pro-button',
  templateUrl: './purchase-pro-button.component.html',
  styleUrls: ['./purchase-pro-button.component.scss']
})
export class PurchaseProButtonComponent {
  @Input() catId: string | undefined;
  @Input() productId: string | undefined;
  @Input() profileId: string | undefined;
  @Input() catName = '';

  constructor(
    private fipAdminApiService: FipAdminApiService,
    private dialog: MatDialog
  ) {}

  purchaseProduct() {
    if (this.catId && this.profileId) {
      const paymentBreakdownModal = this.dialog.open(
        PaymentBreakdownModalComponent,
        {
          minWidth: 350,
          width: '75vw',
          maxWidth: 600,
          ariaModal: true,
          autoFocus: false,
          data: {
            title: 'Payment Breakdown',
            catName: this.catName,
            productId: this.productId
          }
        }
      );
      paymentBreakdownModal.afterClosed().subscribe((decision: any) => {
        if (decision.confirm) {
          this.redirectToCheckout(decision.promotionCode);
        }
      });
    }
  }

  redirectToCheckout(promotionCode: string | undefined) {
    const checkoutProduct: any = {
      // Don't pass price into here, it will be calculated on backend again
      description: `${this.catName} will become a Pro Member. You will be supporting the FIP Oasis team and upcoming new features for new users. Thanks again!`,
      currency: 'usd',
      imageUrl: window.location.origin + '/assets/cat_island_oasis.png',
      successUrl: window.location.origin + '/dashboard',
      cancelUrl: window.location.origin + '/dashboard',
      quantity: 1,
      catId: this.catId,
      productId: this.productId,
      purchaser: this.profileId,
      promotionCode: promotionCode
    };

    this.fipAdminApiService.checkout(checkoutProduct).subscribe((result) => {
      if (result && result.url) {
        window.location.href = result.url;
      }
    });
  }
}
