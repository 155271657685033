import {
  Cat,
  ApplicationData,
  MedicalCarePlan,
  MedicationBrand
} from '../../../model/models';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MedicalRecord } from 'src/app/model/models';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { FipAdminApiService } from 'src/app/service/api/fip-admin-api.service';
import { SnackbarService } from 'src/app/service/snackbar/snackbar.service';
import { AnalyticsService } from '../../../service/analytics/analytics.service';
import { AnalyticsEventEnum } from '../../../model/enums';
import { dosePills, doseShot } from '../../../service/utils/dose-utils';
import { treatmentTypes } from '../../../model/constants';
import { isEidd } from '../../../service/utils/ui-utils';

@Component({
  selector: 'app-medical-record-form',
  templateUrl: './medical-record-form.component.html',
  styleUrls: ['./medical-record-form.component.scss']
})
export class MedicalRecordFormComponent implements OnInit {
  @Input()
  medicalRecord: MedicalRecord | undefined;

  @Input()
  catId: string | undefined;

  @Input()
  medicalCarePlan: MedicalCarePlan | undefined;

  @Input()
  medicationBrands: MedicationBrand[] | undefined;

  @Input()
  recentMedicalRecord: MedicalRecord | undefined;

  @Input()
  applicationData: ApplicationData | undefined;

  @Output()
  medicalRecordSave = new EventEmitter<MedicalRecord>();

  @Output()
  medicalRecordClose = new EventEmitter<void>();

  filteredSelectionBrands: MedicationBrand[] | undefined;
  viewType = '';
  maxCommentLength = 299;

  medicalRecordForm = this.formBuilder.group({
    weight: [0, [Validators.required, Validators.max(999), Validators.min(0)]],
    weightUnit: ['lb', Validators.required],
    dosage: [0, Validators.required],
    dose: [0, [Validators.required, Validators.maxLength(7)]],
    doseUnit: ['ML', Validators.required],
    treatmentTime: ['', Validators.required],
    treatmentType: ['', Validators.required],
    treatmentDate: new FormControl<Date | undefined>(
      undefined,
      Validators.required
    ),
    medicationBrand: new FormControl<MedicationBrand | undefined>(undefined),
    concentration: [0, Validators.required],
    frequency: ['1', Validators.required],
    comment: ['', Validators.maxLength(this.maxCommentLength)]
  });

  // Value to store
  pillCount = 0;

  secondaryDosage: boolean = false;

  constructor(
    private fipAdminApiService: FipAdminApiService,
    private formBuilder: FormBuilder,
    private snackbarService: SnackbarService,
    private analyticsService: AnalyticsService
  ) {}

  ngOnInit() {
    // This makes a medical care plan required
    if (!this.medicalRecord) {
      this.setupFormForCreate();
    } else {
      this.setupFormFromMedialRecord();
    }
  }

  save() {
    if (this.catId) {
      const medicalRecord: any = {
        ...this.medicalRecord,
        catId: this.catId,
        ...this.medicalRecordForm.getRawValue(),
        treatmentDate: this.generateTreatmentDateTimestamp(),
        treatmentTime: undefined // clear this from the object
      };

      this.fipAdminApiService
        .saveMedicalRecord(medicalRecord)
        .subscribe((medicalRecord) => {
          this.snackbarService.openSuccessSnackBar('Medical Record Saved!');
          this.medicalRecordSave.emit(medicalRecord);
        });
    } else {
      console.error('Cant save medical record without cat id');
    }
  }

  generateTreatmentDateTimestamp() {
    if (this.treatmentTime.value && this.treatmentDate.value) {
      const dateValue: Date = this.treatmentDate.value;
      const timeValue: string = this.treatmentTime.value;

      // Extract hours and minutes from the time input value
      const [hours, minutes] = timeValue.split(':').map(Number);

      // Create a new Date object with the date and time values
      return new Date(
        dateValue.getFullYear(),
        dateValue.getMonth(),
        dateValue.getDate(),
        hours,
        minutes
      );
    }
    return new Date();
  }

  setupFormForCreate() {
    // Should this be required?
    if (this.medicalCarePlan) {
      this.defaultFormMedicalCarePlan(this.medicalCarePlan);
    }
    if (this.recentMedicalRecord) {
      this.weight.setValue(this.recentMedicalRecord.weight);
      this.weightUnit.setValue(this.recentMedicalRecord.weightUnit);
    }

    this.setTreatmentTime(new Date());
    this.treatmentDate.setValue(new Date());
    this.viewType = 'Create';
  }

  setupFormFromMedialRecord() {
    if (this.medicalRecord) {
      this.setFormDetailsFromMedicationBrand(
        this.medicalRecord.medicationBrand
      );
      this.dosage.setValue(this.medicalRecord.dosage || 0);
      this.weight.setValue(this.medicalRecord.weight || 0);
      this.weightUnit.setValue(this.medicalRecord.weightUnit);

      this.treatmentDate.setValue(new Date(this.medicalRecord.treatmentDate));

      this.setTreatmentTime(new Date(this.medicalRecord.treatmentDate));

      this.comment.setValue(this.medicalRecord.comment || '');
      this.dose.setValue(this.medicalRecord.dose);
      this.doseUnit.setValue(this.medicalRecord.doseUnit);
      this.frequency.setValue(this.medicalRecord.frequency.toString());
      this.viewType = '';
    }
  }

  private setTreatmentTime(date: Date) {
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    this.treatmentTime.setValue(`${hours}:${minutes}`);
  }

  defaultFormMedicalCarePlan(medicalCarePlan: MedicalCarePlan) {
    if (medicalCarePlan?.medicationBrand) {
      this.setFormDetailsFromMedicationBrand(medicalCarePlan.medicationBrand);
    }
    this.dosage.setValue(medicalCarePlan.dosage);
  }

  setFormDetailsFromMedicationBrand(medicationBrand: MedicationBrand) {
    this.filterBrands(medicationBrand.type);
    const selectedMedicationBrand = this.filteredSelectionBrands?.find(
      (brand) => brand.name == medicationBrand.name
    );

    this.medicationBrand.setValue(selectedMedicationBrand);
    this.treatmentType.setValue(medicationBrand.type);
    this.concentration.setValue(medicationBrand.concentration);
  }

  close() {
    this.medicalRecordClose.emit();
  }

  treatmentTypeChange(treatmentType: string) {
    this.filterBrands(treatmentType);
    this.medicationBrand.reset();
    this.concentration.reset();
    this.dose.reset();
  }

  medicationBrandChange() {
    const concentration = this.medicationBrand.value?.concentration;
    if (concentration) {
      this.concentration.setValue(concentration);
    }
    // Check second eidd brand
    if (
      isEidd(this.medicationBrand.value) &&
      isEidd(this.medicalCarePlan?.secondMedicationBrand) &&
      this.medicalCarePlan?.secondDosage
    ) {
      this.dosage.setValue(this.medicalCarePlan.secondDosage);
      this.secondaryDosage = true;
    } else if (this.medicalCarePlan?.dosage) {
      this.dosage.setValue(this.medicalCarePlan.dosage);
      this.secondaryDosage = false;
    }
    this.dose.reset();
  }

  filterBrands(treatmentType: string) {
    this.filteredSelectionBrands = this.medicationBrands?.filter(
      (brand) => brand.type === treatmentType
    );
  }

  calculateDose(): string {
    this.analyticsService.sendAnalyticsEvent(
      this.applicationData,
      { id: this.catId } as Cat,
      AnalyticsEventEnum.CALCULATE_DOSE
    );

    const weight = this.weight.value;
    const dosage = this.dosage.value;
    const concentration = this.concentration.value;
    if (weight && dosage && concentration) {
      if (this.treatmentType.value === 'Shot') {
        this.calculateDoseShot(concentration, weight, dosage);
      } else if (this.treatmentType.value?.includes('Pill')) {
        this.calculateDosePills(concentration, weight, dosage);
      }
    }
    return 'ERROR';
  }

  // private getKgWeight(weight: number) {
  //  return this.weightUnit.value === 'lb' ? weight / 2.2 : weight;
  // }

  calculateDoseShot(concentration: number, weight: number, dosage: number) {
    const dose = doseShot(this.weightUnit.value, weight, dosage, concentration);
    this.dose.setValue(dose);
    this.doseUnit.setValue('ML');
  }

  calculateDosePills(concentration: number, weight: number, dosage: number) {
    let mgPerKg = dosePills(
      this.weightUnit.value,
      weight,
      dosage,
      this.medicationBrand.value
    );

    if (this.frequency.value) {
      mgPerKg = mgPerKg / Number(this.frequency.value);
    }

    const pills = Math.ceil(mgPerKg / concentration);

    if (this.medicationBrand.value?.name.includes('mg')) {
      this.dose.setValue(Math.ceil(mgPerKg));
      this.doseUnit.setValue('MG');
    } else {
      const kgPills = Math.ceil(mgPerKg / 6);
      this.dose.setValue(kgPills);
      this.doseUnit.setValue('KG');
    }

    this.pillCount = pills;
  }

  get weight() {
    return this.medicalRecordForm.controls.weight;
  }

  get dosage() {
    return this.medicalRecordForm.controls.dosage;
  }

  get concentration() {
    return this.medicalRecordForm.controls.concentration;
  }

  get comment() {
    return this.medicalRecordForm.controls.comment;
  }

  get treatmentDate() {
    return this.medicalRecordForm.controls.treatmentDate;
  }

  get medicationBrand() {
    return this.medicalRecordForm.controls.medicationBrand;
  }

  get treatmentType() {
    return this.medicalRecordForm.controls.treatmentType;
  }

  get frequency() {
    return this.medicalRecordForm.controls.frequency;
  }

  get dose() {
    return this.medicalRecordForm.controls.dose;
  }

  get treatmentTime() {
    return this.medicalRecordForm.controls.treatmentTime;
  }

  get doseUnit() {
    return this.medicalRecordForm.controls.doseUnit;
  }

  get weightUnit() {
    return this.medicalRecordForm.controls.weightUnit;
  }

  onUnitChange() {
    if (this.weightUnit.value === 'lb' && this.weight.value) {
      this.weight.setValue(Number((this.weight.value * 2.2).toFixed(1)));
    }

    if (this.weightUnit.value === 'kg' && this.weight.value) {
      this.weight.setValue(Number((this.weight.value / 2.2).toFixed(1)));
    }

    if (this.dose.value) {
      this.calculateDose();
    }
  }

  protected readonly treatmentTypes = treatmentTypes;

  isActiveBrand(medicationBrand: MedicationBrand) {
    return (
      this.medicalCarePlan?.medicationBrand?.name === medicationBrand.name ||
      this.medicalCarePlan?.secondMedicationBrand?.name === medicationBrand.name
    );
  }
}
