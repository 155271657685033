import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { ApplicationData, Requisition } from '../../../model/models';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RequisitionStatus } from '../../../model/enums';

@Component({
  selector: 'app-requisition-table',
  templateUrl: './requisition-table.component.html',
  styleUrls: ['./requisition-table.component.scss']
})
export class RequisitionTableComponent implements AfterViewInit {
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  dataSource!: MatTableDataSource<Requisition>;

  mobileColumns: string[] = ['id', 'status', 'name', 'dateCreated'];
  desktopColumns: string[] = [
    'id',
    'status',
    'trackingNumber',
    'name',
    'cat',
    'dateCreated'
  ];
  isMobileSizeScreen = false;

  displayedColumns = this.desktopColumns;

  isAdmin: boolean | undefined;

  @Input()
  set requisitions(requisitionData: Requisition[]) {
    this.dataSource = new MatTableDataSource<Requisition>(requisitionData);
    this.dataSource.paginator = this.paginator;
  }

  @Input()
  applicationData: ApplicationData | undefined;

  @Output()
  openRequisitionModal = new EventEmitter<Requisition>();

  timeZone;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private changeDetector: ChangeDetectorRef
  ) {
    this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.breakpointObserver
      .observe([Breakpoints.XSmall])
      .pipe(takeUntilDestroyed())
      .subscribe((result) => {
        this.isMobileSizeScreen = result.matches;
        if (this.isMobileSizeScreen) {
          this.displayedColumns = this.mobileColumns;
        } else {
          this.displayedColumns = this.desktopColumns;
        }
      });
  }

  ngAfterViewInit() {
    this.paginator.pageSize = 10;
    this.changeDetector.detectChanges();
  }

  convertArrayOfObjectsToString(data: Requisition[]) {
    let message = '';
    for (const requisition of data) {
      if (requisition.status === RequisitionStatus.PAID) {
        const id = 'Order ID: ' + requisition.id + '\n';
        const shippingSpeed = requisition.shippingSpeed + '\n';
        const quantityBrand =
          requisition.quantity + ' ' + requisition.medicationBrand.name + '\n';
        const email = requisition.email + '\n';
        const fullName =
          requisition.firstName + ' ' + requisition.lastName + '\n';
        const address =
          requisition.addressLine1 +
          ' ' +
          requisition.addressLine2 +
          '\n' +
          requisition.city +
          ', ' +
          requisition.state +
          ', ' +
          requisition.zipCode +
          '\n';
        const total = 'Total: ' + requisition.total;
        const divider = '\n' + '\n';
        message +=
          id +
          shippingSpeed +
          quantityBrand +
          email +
          fullName +
          address +
          total +
          divider;
      }
    }
    return message;
  }

  downloadText(content: any, fileName: string) {
    const blob = new Blob([content], { type: 'text' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.click();
    URL.revokeObjectURL(url);
  }

  downloadTable() {
    const content = this.convertArrayOfObjectsToString(this.dataSource.data);
    this.downloadText(content, 'data.text');
  }

  openRequisition(requisition: Requisition) {
    this.openRequisitionModal.emit(requisition);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
