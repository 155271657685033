import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Task } from '../../model/models';
import { TaskModalComponent } from '../modals/task-modal/task-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from '../modals/confirmation-modal/confirmation-modal.component';
import { FipAdminApiService } from '../../service/api/fip-admin-api.service';
import { catStatus, taskType } from '../../model/constants';
import { map } from 'rxjs';
import {
  dueDateSort,
  getStatusTitle,
  getTaskTitle
} from '../../service/utils/ui-utils';
import { ConfettiService } from '../../service/confetti/confetti.service';

@Component({
  selector: 'app-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.scss']
})
export class TaskListComponent {
  @Input()
  set tasks(tasks: Task[]) {
    this.setupTasks(tasks);
  }

  @Input()
  taskTitle: string | undefined;

  @Input()
  catId: string | undefined;

  @Input()
  catStatus: string | undefined;

  @Input()
  isAdmin: boolean | undefined;

  @Output()
  treatmentTaskUpdate = new EventEmitter<void>();

  timeZone = 'UTC';

  treatmentTasks: Task[] = [];
  observationTasks: Task[] = [];
  generalTasks: Task[] = [];

  constructor(
    public dialog: MatDialog,
    private fipAdminApiService: FipAdminApiService,
    private confettiService: ConfettiService
  ) {
    this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  openTasksModal(task: Task | undefined) {
    const tasksDialog = this.dialog.open(TaskModalComponent, {
      minWidth: 350,
      width: '75vw',
      maxWidth: 750,
      ariaModal: true,
      autoFocus: false,
      data: {
        task,
        catId: this.catId,
        isAdmin: this.isAdmin
      }
    });
    tasksDialog.afterClosed().subscribe((task: Task) => {
      if (task) {
        this.updateTask(task);
      }
    });
  }

  deleteTask($event: MouseEvent, task: Task) {
    $event.stopPropagation();
    const confirmationDialog = this.dialog.open(ConfirmationModalComponent, {
      minWidth: 350,
      width: '75vw',
      maxWidth: 750,
      ariaModal: true,
      autoFocus: false,
      data: {
        action:
          'Delete ' +
          task.description +
          ' ' +
          getTaskTitle(task.taskType) +
          ' Task'
      }
    });
    confirmationDialog.afterClosed().subscribe((decision: boolean) => {
      if (decision) {
        this.fipAdminApiService.deleteTask(task.id).subscribe(() => {
          this.updateTask({ ...task, delete: true } as Task);
        });
      }
    });
  }

  autoGenerateTasks($event: MouseEvent) {
    $event.stopPropagation();
    const confirmationDialog = this.dialog.open(ConfirmationModalComponent, {
      minWidth: 350,
      width: '75vw',
      maxWidth: 750,
      ariaModal: true,
      autoFocus: false,
      data: {
        action:
          'Generate new Blood Work ' + getStatusTitle(this.catStatus) + ' tasks'
      }
    });
    confirmationDialog.afterClosed().subscribe((decision: boolean) => {
      if (decision) {
        this.generateTasks(this.catId);
      }
    });
  }

  updateTask(task: Task) {
    // Not sure what I'm doing with this but my brain is mush so lets go
    if (task.taskType === taskType.BLOOD_WORK_TREATMENT) {
      this.treatmentTasks = this.updateTaskData(task, this.treatmentTasks);
      this.treatmentTaskUpdate.emit();
    }
    if (task.taskType === taskType.BLOOD_WORK_OBSERVATION) {
      this.observationTasks = this.updateTaskData(task, this.observationTasks);
    }

    if (task.taskType === taskType.GENERAL) {
      this.generalTasks = this.updateTaskData(task, this.generalTasks);
    }
  }

  private updateTaskData(updatedTask: Task, tasks: Task[]) {
    let changedTasks = [...tasks];
    if (updatedTask.delete) {
      changedTasks = changedTasks.filter((t) => t.id !== updatedTask.id);
    } else {
      const index = changedTasks.findIndex((t) => t.id == updatedTask.id);
      if (index != -1) {
        changedTasks[index] = updatedTask;
      } else {
        changedTasks = [...changedTasks, updatedTask];
      }
    }

    this.shouldShowConfetti(updatedTask);

    return changedTasks.sort(dueDateSort);
  }

  private shouldShowConfetti(task: Task) {
    if (
      task.resolved &&
      (task.taskType === taskType.BLOOD_WORK_OBSERVATION ||
        task.taskType == taskType.BLOOD_WORK_TREATMENT)
    ) {
      this.confettiService.celebrateWithConfetti(true);
    }
  }

  protected readonly map = map;
  protected readonly taskType = taskType;
  protected readonly getTaskTitle = getTaskTitle;

  generateTasks(catId: string | undefined) {
    let type = undefined;
    if (this.catStatus) {
      if (this.catStatus == catStatus.TREATMENT) {
        type = taskType.BLOOD_WORK_TREATMENT;
      }

      if (this.catStatus == catStatus.OBSERVATION) {
        type = taskType.BLOOD_WORK_OBSERVATION;
      }

      if (catId && type) {
        this.fipAdminApiService
          .generateTasks(catId, type)
          .subscribe((tasks: Task[]) => {
            this.setupTasks(tasks);
          });
      }
    }
  }

  private setupTasks(tasks: Task[]) {
    this.treatmentTasks = tasks
      .filter((task) => task.taskType === taskType.BLOOD_WORK_TREATMENT)
      .sort(dueDateSort);
    this.observationTasks = tasks
      .filter((task) => task.taskType === taskType.BLOOD_WORK_OBSERVATION)
      .sort(dueDateSort);
    this.generalTasks = tasks
      .filter((task) => task.taskType === taskType.GENERAL)
      .sort(dueDateSort);
  }

  protected readonly getStatusTitle = getStatusTitle;

  showGenerate() {
    return (
      this.catStatus === catStatus.OBSERVATION ||
      this.catStatus === catStatus.TREATMENT
    );
  }
}
