import { Component } from '@angular/core';
import { ApplicationDataService } from '../../service/data/application-data.service';
import { Profile } from '../../model/models';
import { Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent {
  profile: Profile | undefined;
  isAdmin = false;
  isDeveloper = false;
  constructor(
    private applicationDataService: ApplicationDataService,
    private router: Router
  ) {
    this.applicationDataService
      .getApplicationData()
      .pipe(takeUntilDestroyed())
      .subscribe((applicationData) => {
        if (applicationData) {
          this.profile = applicationData.profile;
          this.isAdmin = applicationData.isAdmin || false;
          this.isDeveloper = applicationData.isDeveloper || false;

          if (!this.profile) {
            void this.router.navigate(['profile', 'new']);
          }
        }
      });
  }

  onProfileSave(profile: Profile) {
    this.profile = profile;
    this.applicationDataService.setData({ profile });
  }
}
