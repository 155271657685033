import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { Cat, ApplicationData, CatTableRow } from '../../../model/models';
import { Router } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { getStatusTitle } from '../../../service/utils/ui-utils';
import { AnalyticsService } from '../../../service/analytics/analytics.service';
import { AnalyticsEventEnum } from '../../../model/enums';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'app-cat-table',
  templateUrl: './cat-table.component.html',
  styleUrls: ['./cat-table.component.scss']
})
export class CatTableComponent implements AfterViewInit {
  dataSource!: MatTableDataSource<CatTableRow>;

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatMenuTrigger)
  contextMenu!: MatMenuTrigger;

  contextMenuPosition = { x: '0px', y: '0px' };

  @Input()
  set cats(value: CatTableRow[] | undefined) {
    this.dataSource = new MatTableDataSource<CatTableRow>(value);
  }

  @Input()
  applicationData: ApplicationData | undefined;

  @Output()
  catTableClick = new EventEmitter<void>();

  @Input()
  isSearch: boolean | undefined;

  desktopColumns = [
    'help',
    'name',
    'status',
    'brand',
    'owner',
    'treatmentStartDate',
    'admins',
    'location',
    'lastOwnerActivity',
    'complete'
    //'lastAdminActivity'
  ];

  mobileColumns = ['help', 'name', 'lastOwnerActivity'];

  displayedColumns = this.desktopColumns;

  isMobileSizeScreen = false;
  timeZone = 'UTC';

  constructor(
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private analyticsService: AnalyticsService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.breakpointObserver
      .observe([Breakpoints.XSmall])
      .pipe(takeUntilDestroyed())
      .subscribe((result) => {
        this.isMobileSizeScreen = result.matches;
        if (this.isMobileSizeScreen) {
          this.displayedColumns = this.mobileColumns;
        } else {
          this.displayedColumns = this.desktopColumns;
        }
      });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  goToCat(cat: Cat) {
    let eventEnum = AnalyticsEventEnum.CAT_TABLE_VIEW;
    if (this.isSearch) {
      eventEnum = AnalyticsEventEnum.SEARCH_CAT_TABLE_VIEW;
    }

    this.analyticsService.sendAnalyticsEvent(
      this.applicationData,
      cat,
      eventEnum
    );
    this.catTableClick.emit();
    void this.router.navigate(['cat', cat.id]);
  }

  ngAfterViewInit() {
    this.paginator.pageSize = 25;
    this.dataSource.paginator = this.paginator;
    this.changeDetectorRef.detectChanges();

    // This is just to fix location sorting and I'm not sure it even works
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'location':
          this.getLocation(item);
          break;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        default: // @ts-ignore
          return item[property];
      }
    };
    this.dataSource.sort = this.sort;
  }

  getLocation(catTableRow: CatTableRow) {
    return catTableRow.city + ', ' + catTableRow.state;
  }

  protected readonly getStatusTitle = getStatusTitle;

  onContextMenu(event: MouseEvent, item: Cat) {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = { item: item };
    this.contextMenu.menu?.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }

  openNewTab(cat: any) {
    window.open(window.location.origin + '/cat/' + cat.id);
  }
}
