import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import {
  Cat,
  CatModalData,
  ApplicationData,
  Profile
} from 'src/app/model/models';
import { FipAdminApiService } from 'src/app/service/api/fip-admin-api.service';
import { ApplicationDataService } from '../../../service/data/application-data.service';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  of,
  startWith,
  Subject,
  switchMap,
  takeUntil
} from 'rxjs';
import {
  catStatus,
  catStatusData,
  diseasesSelection,
  conditionsSelections,
  catBreeds,
  catGenders,
  sideEffectSelections
} from '../../../model/constants';
import { SnackbarService } from 'src/app/service/snackbar/snackbar.service';
import {
  getDateWithoutTimezoneOffset,
  handleAvatarResponse
} from '../../../service/utils/ui-utils';
import { ConfirmationModalComponent } from '../../modals/confirmation-modal/confirmation-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CatRegistrationStatus } from '../../../model/enums';

@Component({
  selector: 'app-cat-form',
  templateUrl: './cat-form.component.html',
  styleUrls: ['./cat-form.component.scss']
})
export class CatFormComponent implements OnInit, OnDestroy {
  destroyed = new Subject<void>();

  eligibleProfiles: Profile[] | undefined;
  applicationData: ApplicationData | undefined;

  @Input()
  set profiles(profiles: Profile[] | undefined) {
    this.eligibleProfiles = profiles;
    this.setEnrollmentCatProfile();
  }

  @Input()
  cat: Cat | undefined;

  @Input()
  catAvatarUrl: string | undefined;

  @Input()
  process = '';

  @Output()
  catSave = new EventEmitter<CatModalData>();

  @Output()
  catFormClose = new EventEmitter<CatModalData>();

  assignableAdmins: Profile[] = [];
  viewType = 'Create';
  today = new Date();

  eligibleStatuses = catStatusData;

  catForm = this.formBuilder.group({
    name: [
      '',
      [Validators.required, Validators.maxLength(40), Validators.minLength(2)]
    ],
    status: new FormControl<{ status: string; title: string } | undefined>(
      undefined,
      Validators.required
    ),
    birthDate: new FormControl<Date | undefined>(
      undefined,
      Validators.required
    ),
    /*gender: new FormControl<string | undefined>(undefined, [
      Validators.required
    ]),*/
    color: new FormControl<string | undefined>(undefined, [
      Validators.maxLength(30),
      Validators.minLength(2)
    ]),
    breed: new FormControl<string | undefined>(undefined, Validators.required),
    assignedAdmins: new FormControl<Profile[]>(
      [],
      [Validators.required, Validators.minLength(1)]
    ),
    diseases: new FormControl<string[]>(
      [],
      [Validators.required, Validators.minLength(1)]
    ),
    sideEffects: new FormControl<string[]>([]),
    gender: new FormControl<string | undefined>(undefined, [
      Validators.required
    ]),
    complete: new FormControl<boolean>(false),
    conditions: new FormControl<string[]>([]),
    profile: new FormControl<Profile | undefined>(
      undefined,
      Validators.required
    ),
    bio: ['', Validators.maxLength(200)]
  });

  selectedAvatar: File | undefined;
  avatarUrl: string | undefined;

  constructor(
    private formBuilder: FormBuilder,
    private fipAdminApiService: FipAdminApiService,
    private applicationDataService: ApplicationDataService,
    private snackbarService: SnackbarService,
    private dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit() {
    this.applicationDataService
      .getApplicationData()
      .pipe(takeUntil(this.destroyed))
      .subscribe((applicationData) => {
        if (
          applicationData &&
          applicationData.assignableAdmins &&
          applicationData.assignableAdmins.length > 0
        ) {
          this.assignableAdmins = applicationData.assignableAdmins;
          this.applicationData = applicationData;
          // This will be set in the input setter before hitting this
          // during enrollment
          if (this.profile.value) {
            this.setDefaultStateAssignedAdmins(
              this.profile.value?.state,
              applicationData.assignableAdmins
            );
          }
        } else if (this.cat) {
          this.assignableAdmins = this.cat.assignedAdmins;
        }
      });

    if (this.cat) {
      this.viewType = 'Edit';
      this.populateCatForm(this.cat);
      this.disableFormForEnrollment();
      if (this.catAvatarUrl) {
        this.avatarUrl = this.catAvatarUrl;
      }
    } else {
      this.status.setValue(this.getStatusData(catStatus.PENDING_TREATMENT));
      this.status.disable();
    }
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  save() {
    // This is dangerous but the raw value prints the json of it
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const cat: any = {
      ...this.cat,
      ...this.catForm.getRawValue(),
      status: this.status.value?.status
    };

    this.fipAdminApiService.saveCat(cat).subscribe((cat) => {
      this.snackbarService.openSuccessSnackBar(
        'Cat Information has been Saved!'
      );
      this.catSave.emit({ cat, avatar: this.avatarUrl });
      this.disableFormForEnrollment();
    });
  }

  setEnrollmentCatProfile() {
    if (!this.cat && this.eligibleProfiles?.length === 1) {
      this.profile.setValue(this.eligibleProfiles[0]);
    }
  }

  // Disable entire cat form in enrollment process if it's being retrieved
  disableFormForEnrollment() {
    if (this.process == 'enrollment') {
      this.catForm.disable();
    }
  }

  close() {
    this.catFormClose.emit({ avatar: this.avatarUrl });
  }

  private populateCatForm(cat: Cat) {
    this.name.setValue(cat.name);
    this.status.setValue(this.getStatusData(cat.status));
    this.birthDate.setValue(getDateWithoutTimezoneOffset(cat.birthDate));
    this.color.setValue(cat.color);
    this.bio.setValue(cat.bio);
    this.defaultCatProfile(cat.profile);
    this.diseases.setValue(cat.diseases);
    this.conditions.setValue(cat.conditions);
    this.breed.setValue(cat.breed);
    this.gender.setValue(cat.gender);
    this.sideEffects.setValue(cat.sideEffects);
    this.complete.setValue(cat.complete);
    if (cat.assignedAdmins) {
      this.setAssignedAdmins(cat.assignedAdmins);
    }

    this.setupEligibleProfilesSearch();
  }

  defaultCatProfile(profile: Profile) {
    this.eligibleProfiles = [profile];
    this.profile.setValue(profile);
  }

  getStatusData(status: string) {
    return this.eligibleStatuses.find((data) => status === data.status);
  }

  private setDefaultStateAssignedAdmins(
    state: string | undefined,
    assignableAdmins: Profile[]
  ) {
    if (state) {
      const stateAssignedAdmins = assignableAdmins.filter(
        (admin) =>
          admin.assignedStates?.includes(state) ||
          admin.id === this.applicationData?.profile?.id
      );

      this.setAssignedAdmins(stateAssignedAdmins);
    }
  }

  // You need to use this function to filter the assignableAdmins. Why? Even though the data is the same
  // the memory references are different, so we have to find the ones with same id and match it
  private setAssignedAdmins(assignedAdmins: Profile[]) {
    const assignedAdminIds = assignedAdmins.map((profile) => profile.id);

    const selectedAdmins = this.assignableAdmins.filter((profile) =>
      assignedAdminIds.includes(profile.id)
    );
    this.assignedAdmins.setValue(selectedAdmins);
  }

  get assignedAdmins() {
    return this.catForm.controls.assignedAdmins;
  }

  get diseases() {
    return this.catForm.controls.diseases;
  }

  get profile() {
    return this.catForm.controls.profile;
  }

  get birthDate() {
    return this.catForm.controls.birthDate;
  }

  get name() {
    return this.catForm.controls.name;
  }

  get color() {
    return this.catForm.controls.color;
  }

  get bio() {
    return this.catForm.controls.bio;
  }

  get status() {
    return this.catForm.controls.status;
  }

  get conditions() {
    return this.catForm.controls.conditions;
  }

  get breed() {
    return this.catForm.controls.breed;
  }

  get gender() {
    return this.catForm.controls.gender;
  }

  get sideEffects() {
    return this.catForm.controls.sideEffects;
  }

  get complete() {
    return this.catForm.controls.complete;
  }

  protected readonly catStatus = catStatus;
  protected readonly conditionsSelections = conditionsSelections;
  protected readonly diseasesSelection = diseasesSelection;
  protected readonly catGenders = catGenders;

  getProfileDisplayText(option: Profile): string {
    return option.firstName + ' ' + option.lastName + ', ' + option.state;
  }

  private setupEligibleProfilesSearch() {
    this.profile.valueChanges
      .pipe(
        debounceTime(400),
        distinctUntilChanged(),
        startWith(''),
        switchMap((value) => {
          if (typeof value === 'string' && value.length > 0) {
            return this.fipAdminApiService.searchAssignProfiles(value);
          }
          return of(null);
        })
      )
      .subscribe((profiles) => {
        if (profiles) {
          this.eligibleProfiles = profiles;
        }
      });
  }

  onFileSelected(event: any) {
    this.selectedAvatar = event.target.files[0];
    if (this.selectedAvatar && this.cat?.id) {
      const uploadData = new FormData();
      uploadData.append('file', this.selectedAvatar, this.selectedAvatar.name);
      this.fipAdminApiService
        .uploadCatPicture(this.cat?.id, uploadData)
        .subscribe((avatar) => {
          this.avatarUrl = handleAvatarResponse(avatar);
          if (this.cat && avatar && avatar.body) {
            this.cat.avatar = handleAvatarResponse(avatar);
          }
        });
    }
  }

  protected readonly catBreeds = catBreeds;

  deleteCat($event: any) {
    $event.stopPropagation();
    const confirmationDialog = this.dialog.open(ConfirmationModalComponent, {
      minWidth: 350,
      width: '75vw',
      maxWidth: 750,
      ariaModal: true,
      autoFocus: false,
      data: {
        action: `delete the cat: ${this.cat?.name}`,
        subContent: `Data related to the cat: files, tasks, blood work, medical records must deleted prior.
        All notifications around the cat will be removed. Comments, Enrollments and Requisitions will also be removed.`
      }
    });
    confirmationDialog.afterClosed().subscribe((decision: boolean) => {
      if (decision && this.cat?.id) {
        this.fipAdminApiService
          .deleteCat(this.cat.id)
          .pipe(
            catchError((error) => {
              this.snackbarService.openFailureSnackBar(error.error.message);
              return of(undefined);
            })
          )
          .subscribe((result) => {
            if (result !== undefined) {
              this.close();
              this.snackbarService.openSuccessSnackBar('Cat deleted.');
              void this.router.navigate(['dashboard']);
            }
          });
      }
    });
  }

  goToCatRegistration() {
    if (this.cat) {
      this.close();
      void this.router.navigate([
        'registration',
        'cat',
        this.cat?.catRegistrationId
      ]);
    }
  }

  protected readonly sideEffectSelections = sideEffectSelections;
}
