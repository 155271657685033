import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BloodWork } from '../../../model/blood-work-models';

@Component({
  selector: 'app-blood-work-test-modal',
  templateUrl: './blood-work-test-modal.component.html',
  styleUrls: ['./blood-work-test-modal.component.scss']
})
export class BloodWorkTestModalComponent {
  viewType = 'Edit';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialogRef<BloodWorkTestModalComponent>
  ) {}

  onSave($event: BloodWork) {
    this.dialog.close($event);
  }

  onClose() {
    this.dialog.close();
  }
}
