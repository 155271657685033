import { nonExtraDosagePills } from './ui-utils';
import { MedicationBrand } from '../../model/models';

export const getKgWeight = (
  unit: string | undefined | null,
  weight: number
) => {
  return unit === 'lb' ? weight / 2.2 : weight;
};

export const doseShot = (
  unit: string | undefined | null,
  weight: number,
  dosage: number,
  concentration: number
) => {
  const kilograms = getKgWeight(unit, weight);
  const mgPerKg = kilograms * dosage;
  return Math.ceil((mgPerKg / concentration) * 10) / 10;
};

export const dosePills = (
  unit: string | undefined | null,
  weight: number,
  dosage: number,
  medicationBrand: MedicationBrand | undefined | null
) => {
  const kilograms = getKgWeight(unit, weight);
  // If dose is greater than 6, for each 2.5 additional dosage above 6, add 1 more to dosage
  // Don't add extra dosage to the pills that don't need it

  // Removing extra dosage
  /* if (
    dosage > 6 &&
    medicationBrand &&
    !nonExtraDosagePills.includes(medicationBrand.name) &&
    !medicationBrand.name.includes('EIDD')
  ) {
    const extraDosage = (dosage - 6) / 2.5;
    dosage = extraDosage + dosage;
  } */
  // mgPerKg
  // I've though of ceiling this here but sometimes we need to ceil it later in medical-record
  return kilograms * dosage;
};

export const determineDosage = (
  diseases: string[],
  felv: boolean,
  fiv: boolean
) => {
  let dosage = 0;
  if (felv || fiv) {
    dosage = dosage + 2;
  }

  // Just go through and pick the highest one in order of severity
  // E.g. wet, neuro and FELV = 12mg/kg
  let diseaseDosage = 0;
  if (diseases.includes('Wet') || diseases.includes('Wet (Abdominal)')) {
    diseaseDosage = 6;
  }

  if (diseases.includes('Dry')) {
    diseaseDosage = 8;
  }

  if (diseases.includes('Wet (Pleural)' || 'Wet (Bicompartment)')) {
    diseaseDosage = 8;
  }

  if (diseases.includes('Ocular') || diseases.includes('Neuro')) {
    diseaseDosage = 10;
  }

  return dosage + diseaseDosage;
};
