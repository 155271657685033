<div
  *ngIf="
    requisition?.status === RequisitionStatus.AWAITING_PAYMENT ||
    requisition?.status === RequisitionStatus.SHIPPED ||
    requisition?.status === RequisitionStatus.PAID
  "
  style="margin-bottom: 0.5rem">
  <button
    (click)="activeTab = 0"
    [ngClass]="activeTab === 0 ? 'active-tab' : ''"
    mat-flat-button>
    Details
  </button>
  <button
    (click)="activeTab = 1"
    [ngClass]="activeTab === 1 ? 'active-tab' : ''"
    style="margin-left: 1rem"
    mat-flat-button>
    <mat-icon>attach_money</mat-icon>
    How to submit payment
  </button>
</div>
<div
  *ngIf="activeTab === 0"
  [formGroup]="requisitionForm"
  class="requisition-form">
  <app-banner *ngIf="!requisition?.id && infoBannerText" class="banner" spacing="compact" [icon]="'information'">
    {{infoBannerText}}
  </app-banner>
  <mat-form-field *ngIf="requisition?.id" class="status">
    <mat-label>Status</mat-label>
    <mat-select
      appRequiredRoles="Developer"
      id="status"
      formControlName="status">
      <mat-option *ngFor="let status of requisitionStatuses" [value]="status">{{
        status
        }}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field *ngIf="requisition?.id" class="tracking-number">
    <mat-label>Tracking Number</mat-label>
    <input
      appRequiredRoles="Developer"
      id="tracking-number"
      matInput
      formControlName="trackingNumber" />
  </mat-form-field>

  <mat-form-field class="treatment-type">
    <mat-label>Type</mat-label>
    <mat-select
      id="treatmentType"
      formControlName="treatmentType"
      (selectionChange)="treatmentTypeChange($event.value)">
      <mat-option *ngFor="let type of treatmentTypes" value="{{type}}">{{type}}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="medication-brand">
    <mat-label>Medication Brand</mat-label>
    <mat-select
      (selectionChange)="calculateTotal()"
      id="medicationBrand"
      formControlName="medicationBrand">
      <mat-option
        *ngFor="let medicationBrand of filteredSelectionBrands"
        [value]="medicationBrand">
        {{ medicationBrand.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="cat">
    <mat-label>Cat</mat-label>
    <mat-select
      (selectionChange)="checkPromos()"
      id="cat"
      formControlName="cat">
      <mat-option *ngFor="let cat of catIdNames" [value]="cat">
        {{ cat.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="first-name">
    <mat-label>First Name</mat-label>
    <input id="first-name" matInput formControlName="firstName" />
  </mat-form-field>

  <mat-form-field class="last-name">
    <mat-label>Last Name</mat-label>
    <input id="last-name" matInput formControlName="lastName" />
  </mat-form-field>

  <mat-form-field class="email">
    <mat-label>PayPal Email</mat-label>
    <input
      id="email"
      matInput
      placeholder=""
      formControlName="email"
      type="text" />
  </mat-form-field>
  <p *ngIf="!requisition?.id" class="email-description">
    Your <strong>PayPal</strong> email. This will be used by manufactures to
    track who has paid and can receive shipments.
  </p>

  <mat-form-field class="phone-number">
    <mat-label>Phone Number</mat-label>
    <input
      id="phone-number"
      matInput
      placeholder=""
      formControlName="phoneNumber"
      type="text" />
  </mat-form-field>

  <mat-checkbox
    class="phone-number-usage"
    id="phoneNumberUsage"
    formControlName="phoneNumberUsage"
  >I consent to receiving text or phone calls to this phone number for updates
    on this requisition.
  </mat-checkbox>

  <mat-form-field class="address-line-1">
    <mat-label>Address Line 1</mat-label>
    <input id="address-line-1" matInput formControlName="addressLine1" />
  </mat-form-field>

  <mat-form-field class="address-line-2">
    <mat-label>Address Line 2</mat-label>
    <input id="address-line-2" matInput formControlName="addressLine2" />
  </mat-form-field>

  <mat-form-field class="city">
    <mat-label>City</mat-label>
    <input
      id="city"
      matInput
      placeholder=""
      formControlName="city"
      type="text" />
  </mat-form-field>

  <mat-form-field class="state">
    <mat-label>State</mat-label>
    <input
      type="text"
      id="state"
      placeholder="State"
      (change)="checkState($event)"
      matInput
      formControlName="state"
      [matAutocomplete]="stateAuto" />
    <mat-autocomplete #stateAuto="matAutocomplete">
      <mat-option
        *ngFor="let option of filteredStates | async"
        [value]="option.name">
        {{ option.name }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <mat-form-field class="zip-code">
    <mat-label>Postal Code</mat-label>
    <input
      id="zipCode"
      matInput
      placeholder=""
      formControlName="zipCode"
      type="text" />
  </mat-form-field>

  <mat-form-field class="country">
    <mat-label>Country</mat-label>
    <mat-select id="country" formControlName="country">
      <mat-option *ngFor="let country of countries" [value]="country.country">{{
        country.country
        }}</mat-option>
    </mat-select>
  </mat-form-field>

  <div
    *ngIf="medicationBrand.value && treatmentType.value === 'Pill'"
    class="pill-packet-message icon-text-grid">
    <mat-icon> warning</mat-icon>
    {{ medicationBrand.value.name }} can only be
    purchased in quantities of {{ getQuantities() }}
  </div>

  <mat-form-field class="quantity">
    <mat-label
    >Quantity
      {{ treatmentType.value === "Shot" ? "Vial" : "Pill" }}'s
    </mat-label
    >
    <input
      id="quantity"
      type="number"
      matInput
      placeholder="0"
      min="0"
      (change)="roundNumberUp()"
      formControlName="quantity" />
  </mat-form-field>

  <mat-form-field class="shipping-speed">
    <mat-label>Shipping Speed</mat-label>
    <mat-select
      (selectionChange)="calculateTotal()"
      id="shipping-speed"
      formControlName="shippingSpeed">
      <mat-option *ngFor="let speed of shippingSpeeds" [value]="speed.name">
        {{ speed.name }} {{ speed.time }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div
    *ngIf="promoCodes.value && promoCodes.value.length > 0"
    class="promo-banner">
    <app-banner
      *ngIf="!requisition?.id"
      [icon]="'local_offer'"
      spacing="compact">
      This requisition is eligible for a
      {{ isShot ? "free vial" : "free bag of pills" }}. We will add the quantity
      when you save.
    </app-banner>
    <app-banner
      *ngIf="requisition?.id"
      [icon]="'local_offer'"
      spacing="compact">
      {{ isShot ? "Free vial" : "Free Pill Bag" }} offer was applied to this
      requisition.
    </app-banner>
  </div>

  <div
    *ngIf="requisitionForm.controls.medicationBrand.value"
    class="estimated-total">
    <mat-label>Estimated Total</mat-label>
    <strong>{{ requisitionForm.controls.total.value | currency }}</strong>
  </div>

  <mat-checkbox class="disclaimer" id="disclaimer" formControlName="disclaimer"
  >I agree to <a [routerLink]="'/terms'">FIP Oasis Terms of Service</a> and
    acknowledge FIP Oasis is not responsible for handling payment, transactions,
    delivery, or fulfillment of requisitions.
  </mat-checkbox>
</div>

<div
  *ngIf="activeTab === 0"
  class="action-footer"
  style="justify-content: space-between">
  <div>
    <button type="button" mat-stroked-button (click)="back()">Back</button>
    <button
      type="submit"
      mat-flat-button
      color="primary"
      (click)="save()"
      [disabled]="!requisitionForm.valid || loading">
      <span *ngIf="!loading"> Save </span>
      <span *ngIf="loading">
        <mat-spinner
          strokeWidth="2"
          diameter="20"
          color="primary"></mat-spinner>
      </span>
    </button>
  </div>
  <button
    *ngIf="
      requisition?.id &&
      (requisition?.status === RequisitionStatus.SUBMITTED ||
        requisition?.status === RequisitionStatus.AWAITING_PAYMENT)
    "
    mat-flat-button
    color="warn"
    (click)="cancelRequisition()">
    Cancel
  </button>
</div>

<div></div>

<div class="sending-payment" *ngIf="activeTab === 1">
  <div>
    Send payment of <strong>{{ requisition?.total | currency }}</strong> to:
  </div>
  <div>
    PayPal Email: <strong>{{ getRequisitionEmail() }}</strong>
  </div>
  <div><strong>Important</strong> Payment directions per manufacturer:</div>
  <div>1. Select <strong>Family & Friends</strong></div>
  <div>
    2. Please write <strong>“P+donation {{ requisition?.id }}”</strong> on memo.
  </div>
  <div>
    <button
      *ngIf="requisition?.status === RequisitionStatus.AWAITING_PAYMENT"
      type="submit"
      mat-flat-button
      color="primary"
      (click)="markPaid()">
      Mark as Paid
    </button>
  </div>
</div>
