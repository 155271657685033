import { MedicalCarePlanModalComponent } from '../../components/modals/medical-care-plan-modal/medical-care-plan-modal.component';
import { MedicalRecordModalComponent } from '../../components/modals/medical-record-modal/medical-record-modal.component';
import { CatModalComponent } from '../../components/modals/cat-modal/cat-modal.component';
import { Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import {
  Cat,
  CatModalData,
  ApplicationData,
  CatDate,
  Comment,
  MedicalCarePlan,
  MedicalRecord,
  Profile,
  Task
} from '../../model/models';
import { FipAdminApiService } from '../../service/api/fip-admin-api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ApplicationDataService } from '../../service/data/application-data.service';
import { MatDialog } from '@angular/material/dialog';
import { Location } from '@angular/common';
import {
  calculateAge,
  calculateDayNumber,
  calculateProgress,
  getStatusTitle
} from '../../service/utils/ui-utils';
import { NotificationService } from '../../service/notification/notification.service';
import { catStatus, dateType, taskType } from '../../model/constants';
import { CatDatesModalComponent } from '../../components/modals/cat-dates-modal/cat-dates-modal.component';
import { LoadingService } from '../../service/loading/loading.service';
import { ScrollStrategy, ScrollStrategyOptions } from '@angular/cdk/overlay';
import { AnalyticsService } from '../../service/analytics/analytics.service';
import { AnalyticsEventEnum, FileType } from '../../model/enums';

@Component({
  selector: 'app-cat',
  templateUrl: './cat.component.html',
  styleUrls: ['./cat.component.scss']
})
export class CatComponent implements OnDestroy {
  protected readonly close = close;
  protected readonly calculateAge = calculateAge;
  protected readonly dateType = dateType;

  applicationData: ApplicationData | undefined;
  applicationData$: Subscription | undefined;

  cat: Cat | undefined;
  medicalRecords: MedicalRecord[] = [];
  catId: string | undefined;
  selectedMedicalRecord: MedicalRecord | undefined;
  comments: Comment[] = [];
  timeZone = 'UTC';

  recentMedicalRecord: MedicalRecord | undefined;

  medicalCarePlan: MedicalCarePlan | undefined;
  treatmentDates: CatDate | undefined;
  observationDates: CatDate | undefined;
  showChatWindow = false;
  scrollStrategy!: ScrollStrategy;

  // For Creates
  eligibleProfiles: Profile[] = [];
  assignableAdmins: Profile[] = [];

  tasks: Task[] = [];
  avatarUrl: string | undefined;

  progress = {
    treatment: {
      dayNumber: 0,
      totalDays: 0,
      percentage: 0
    },
    observation: {
      dayNumber: 0,
      totalDays: 0,
      percentage: 0
    }
  };

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  @ViewChild('medicalRecordDialog') medicalRecordDialog:
    | TemplateRef<any>
    | undefined;

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  @ViewChild('catFormDialog') catFormDialog: TemplateRef<any> | undefined;
  @ViewChild('medicalCarePlanDialog') medicalCarePlanDialog:
    | TemplateRef<any>
    | undefined;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private fipAdminApiService: FipAdminApiService,
    private formBuilder: FormBuilder,
    private applicationDataService: ApplicationDataService,
    public dialog: MatDialog,
    private location: Location,
    private notificationService: NotificationService,
    private loadingService: LoadingService,
    private analyticsService: AnalyticsService,
    private ss: ScrollStrategyOptions
  ) {
    this.scrollStrategy = ss.block();
    this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    this.applicationData$ = this.applicationDataService
      .getApplicationData()
      .subscribe((applicationData) => {
        this.applicationData = applicationData;

        // Default selectable profiles
        if (this.applicationData?.profile && !this.applicationData?.isAdmin) {
          this.eligibleProfiles = [this.applicationData.profile];
        } else if (this.applicationData?.isAdmin) {
          //this.retrieveAllEligibleProfiles();
        }
      });

    // Help children components by getting most recent notifs on parent load
    this.notificationService.retrieveNotifications();

    this.activatedRoute.data.subscribe(({ catComponentData }) => {
      this.cat = catComponentData.cat;

      if (this.cat && this.cat.id) {
        this.catId = this.cat.id;
        this.tasks = catComponentData.tasks;
        this.medicalRecords = catComponentData.medicalRecords;
        this.comments = catComponentData.comments;
        this.medicalCarePlan = catComponentData.medicalCarePlan;
        this.getDates(this.catId);
        this.setupDates(catComponentData.catDates);
        this.setupMedicalRecords(catComponentData.medicalRecords);

        if (this.cat.avatar) {
          this.avatarUrl = this.cat.avatar;
        } else {
          this.avatarUrl = undefined;
        }
      }
      this.loadingService.setLoading(false);
    });

    this.activatedRoute.queryParams.subscribe((params) => {
      if (params['goTo'] === 'comment') {
        this.showChatWindow = true;
      }
    });
  }

  ngOnDestroy() {
    if (this.applicationData$) {
      this.applicationData$.unsubscribe();
    }
  }

  private getMedicalRecords(catId: string) {
    this.fipAdminApiService
      .getMedicalRecords(catId)
      .subscribe((medicalRecords) => {
        this.setupMedicalRecords(medicalRecords);
      });
  }

  private getDates(catId: string) {
    this.fipAdminApiService.getCatDates(catId).subscribe((catDates) => {
      this.setupDates(catDates);
    });
  }

  private setupDates(catDates: CatDate[]) {
    this.treatmentDates = catDates.find(
      (catDate) => catDate.dateType === dateType.TREATMENT
    );

    this.observationDates = catDates.find(
      (catDate) => catDate.dateType === dateType.OBSERVATION
    );
    this.updateProgress(dateType.TREATMENT);
    this.updateProgress(dateType.OBSERVATION);
  }

  private getTasks(catId: string) {
    this.fipAdminApiService.getTasks(catId).subscribe((tasks) => {
      this.tasks = tasks;
    });
  }

  requireProfile() {
    if (this.applicationData) {
      if (this.applicationData.isAdmin) {
        return false;
      }
      return !this.applicationData.profile;
    }

    return true;
  }

  openMedicalRecord(medicalRecord: MedicalRecord | undefined) {
    this.analyticsService.sendAnalyticsEvent(
      this.applicationData,
      this.cat,
      AnalyticsEventEnum.NEW_MEDICAL_RECORD
    );

    this.selectedMedicalRecord = medicalRecord;
    const medicalRecordDialog = this.dialog.open(MedicalRecordModalComponent, {
      minWidth: 350,
      width: '75vw',
      maxWidth: 750,
      ariaModal: true,
      autoFocus: false,
      data: {
        catId: this.catId,
        medicalCarePlan: this.medicalCarePlan,
        medicationBrands: this.applicationData?.medicationBrands,
        medicalRecord: this.selectedMedicalRecord,
        recentMedicalRecord: this.recentMedicalRecord,
        applicationData: this.applicationData
      }
    });
    medicalRecordDialog
      .afterClosed()
      .subscribe((medicalRecord: MedicalRecord) => {
        let event = AnalyticsEventEnum.CLOSE_MEDICAL_RECORD;
        if (medicalRecord) {
          // If we inserted the first medical record, we need to refresh the data since it will start treatment and insert dates
          // I don't know how we want to do this best... we could refresh the entire page but this will just make the apis to get all the new data
          if (
            (this.medicalRecords.length === 0 ||
              this.cat?.status == catStatus.STOPPED) &&
            this.catId
          ) {
            this.getTasks(this.catId);
            this.getDates(this.catId);
            this.getCat(this.catId);
          }
          this.onMedicalRecordSave(medicalRecord);
          event = AnalyticsEventEnum.SAVE_MEDICAL_RECORD;
        }

        this.analyticsService.sendAnalyticsEvent(
          this.applicationData,
          this.cat,
          event
        );
      });
  }

  openCatFormDialog() {
    const catFormDialog = this.dialog.open(CatModalComponent, {
      minWidth: 350,
      width: '75vw',
      maxWidth: 800,
      ariaModal: true,
      autoFocus: false,
      data: {
        cat: this.cat,
        eligibleProfiles: this.eligibleProfiles,
        assignableAdmins: this.assignableAdmins,
        catAvatarUrl: this.avatarUrl
      }
    });
    catFormDialog.afterClosed().subscribe((data: CatModalData) => {
      if (data && data.cat) {
        this.cat = data.cat;
        if (this.cat) {
          this.catId = this.cat.id;
        }
        if (this.catId) {
          this.getDates(this.catId);
          this.getTasks(this.catId);
        }
      }

      if (data && data.avatar) {
        this.avatarUrl = data.avatar;
      }
    });
  }

  onMedicalRecordSave(medicalRecord: MedicalRecord) {
    if (this.cat && this.treatmentDates) {
      medicalRecord.treatmentDayNumber = calculateDayNumber(
        new Date(this.treatmentDates.startDate),
        medicalRecord.treatmentDate
      );
    }
    const index = this.medicalRecords.findIndex(
      (mr) => medicalRecord.id && mr.id === medicalRecord.id
    );

    if (index != -1) {
      this.medicalRecords[index] = medicalRecord;
    } else {
      this.medicalRecords.push(medicalRecord);
    }

    this.medicalRecords.sort((a, b) => {
      return (
        new Date(b.treatmentDate).getTime() -
        new Date(a.treatmentDate).getTime()
      );
    });

    // To trigger change detection in the table
    this.medicalRecords = [...this.medicalRecords];
    this.recentMedicalRecord = this.medicalRecords[0];
  }

  // This is just for enrollment?
  onCatSave(data: CatModalData) {
    if (data.cat) {
      this.cat = data.cat;
      this.dialog.closeAll();
    }
  }

  onCatClose() {
    if (this.catFormDialog) {
      this.dialog.closeAll();
    } else {
      this.location.back();
    }
  }

  openMedicalCarePlanDialog() {
    const medicalCarePlanDialog = this.dialog.open(
      MedicalCarePlanModalComponent,
      {
        minWidth: 350,
        width: '75vw',
        maxWidth: 750,
        ariaModal: true,
        data: {
          catId: this.catId,
          medicationBrands: this.applicationData?.medicationBrands,
          medicalCarePlan: this.medicalCarePlan
        }
      }
    );
    medicalCarePlanDialog
      .afterClosed()
      .subscribe((medicalCarePlan: MedicalCarePlan) => {
        if (medicalCarePlan) {
          this.medicalCarePlan = medicalCarePlan;
        }
      });
  }

  openCatDatesModal(dateType: string) {
    const catDatesDialog = this.dialog.open(CatDatesModalComponent, {
      minWidth: 350,
      width: '75vw',
      maxWidth: 750,
      ariaModal: true,
      autoFocus: false,
      data: {
        catId: this.catId,
        catDate: this.getCatDatesFromDateType(dateType),
        dateType
      }
    });
    catDatesDialog.afterClosed().subscribe((catDate: CatDate) => {
      if (catDate) {
        this.setCatDatesFromDateType(catDate);
        this.updateProgress(catDate.dateType);
      }
    });
  }

  setCatDatesFromDateType(catDate: CatDate) {
    switch (catDate.dateType) {
      case dateType.TREATMENT:
        this.treatmentDates = catDate;
        break;
      case dateType.OBSERVATION:
        this.observationDates = catDate;
        break;
    }
  }

  getCatDatesFromDateType(type: string) {
    switch (type) {
      case dateType.TREATMENT:
        return this.treatmentDates;
      case dateType.OBSERVATION:
        return this.observationDates;
    }
    return undefined;
  }

  getCat(catId: string | undefined) {
    if (catId) {
      this.fipAdminApiService
        .getCat(catId)
        .subscribe((cat) => (this.cat = cat));
    }
  }

  // Could do this on the backend perhaps, but it would require custom DTOs
  updateProgress(progressType: string) {
    const dates =
      progressType === 'TREATMENT'
        ? this.treatmentDates
        : this.observationDates;

    if (this.cat && dates) {
      const progress = calculateProgress(dates.startDate, dates.endDate);
      if (progressType === 'TREATMENT') {
        this.progress.treatment = { ...progress };
      } else {
        this.progress.observation = { ...progress };
      }
    }
  }

  protected readonly taskType = taskType;

  medicalRecordDeleted() {
    if (this.catId) {
      this.getMedicalRecords(this.catId);
    }
  }

  private setupMedicalRecords(medicalRecords: MedicalRecord[]) {
    if (medicalRecords) {
      this.medicalRecords = medicalRecords;
      if (this.medicalRecords.length > 0) {
        this.recentMedicalRecord = this.medicalRecords[0];
      }
    }
  }

  toggleChatWindow() {
    this.showChatWindow = !this.showChatWindow;
  }

  updateComments(e: Comment[]) {
    this.comments = e;
  }

  protected readonly getStatusTitle = getStatusTitle;
  protected readonly FileType = FileType;
}
