import { Component, OnInit } from '@angular/core';
import { ActivityService } from '../../service/activity/activity.service';
import { ActivityRequest, Comment, Profile } from '../../model/models';
import { LoadingService } from '../../service/loading/loading.service';
import { FormControl } from '@angular/forms';
import { FipAdminApiService } from '../../service/api/fip-admin-api.service';
export interface QuickRange {
  label: string;
  value: number;
  unit: string;
}

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss']
})
export class ActivityComponent implements OnInit {
  comments: Comment[] = [];
  timeZone = 'UTC';
  timeFilterOptions = [
    {
      label: 'Last 5 minutes',
      value: 5,
      unit: 'minutes'
    },
    {
      label: 'Last 15 minutes',
      value: 15,
      unit: 'minutes'
    },
    {
      label: 'Last 30 minutes',
      value: 30,
      unit: 'minutes'
    },
    {
      label: 'Last 1 hours',
      value: 1,
      unit: 'hours'
    },
    { label: 'Last 24 hours', value: 24, unit: 'hours' }
  ];

  profiles: Profile[] = [
    {
      country: 'US',
      firstName: 'Nathaniel',
      lastName: 'Webb',
      state: 'TX',
      city: 'Denton',
      organizations: [],
      assignedStates: ['TX'],
      zipCode: '78732'
    },
    {
      country: 'US',
      firstName: 'Eric',
      lastName: 'Rangil',
      state: 'TX',
      city: 'Austin',
      organizations: [],
      assignedStates: ['TX'],
      zipCode: '78732'
    },
    {
      country: 'US',
      firstName: 'Travis',
      lastName: 'Scott',
      state: 'TX',
      city: 'Houston',
      organizations: [],
      assignedStates: ['TX'],
      zipCode: '78732'
    }
  ];

  rangeType = new FormControl<string>('selection');
  profileType = new FormControl<string>('all');
  quickRange = new FormControl<QuickRange>(this.timeFilterOptions[3]);
  profileSelection = new FormControl<Profile>(this.profiles[0]);
  startDate = new FormControl<Date>(new Date());
  endDate = new FormControl<Date>(new Date());

  constructor(
    private activityService: ActivityService,
    private loadingService: LoadingService,
    private fipAdminApiService: FipAdminApiService
  ) {
    this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  ngOnInit() {
    this.loadingService.setLoading(false);
    this.findActivity();
  }

  findActivity() {
    let activityRequest;
    if (this.rangeType.value === 'selection') {
      activityRequest = this.buildActivityRequestFromSelection();
    } else {
      activityRequest = this.buildActivityRequestFromDateRange();
    }

    this.activityService
      .getComments(activityRequest)
      .subscribe((comments) => (this.comments = comments));
  }

  private buildActivityRequestFromSelection(): ActivityRequest {
    if (this.quickRange.value && this.quickRange.value.unit) {
      const endDate = new Date();
      let timeAgoNumber = this.quickRange.value.value * 60 * 1000;

      if (this.quickRange.value?.unit == 'hours') {
        timeAgoNumber = timeAgoNumber * 60;
      }

      const startDate = new Date(endDate.getTime() - timeAgoNumber); // 30 minutes in milliseconds

      return {
        startDate: startDate,
        endDate: endDate
      };
    }
    return {
      startDate: new Date(),
      endDate: new Date()
    };
  }

  private buildActivityRequestFromDateRange(): ActivityRequest {
    if (this.startDate.value && this.endDate.value) {
      const startDate = this.startDate.value;
      startDate.setHours(0, 0, 0, 0);

      const endDate = this.endDate.value;
      endDate.setHours(23, 59, 59, 999);

      return {
        startDate,
        endDate
      };
    }
    return {
      startDate: new Date(),
      endDate: new Date()
    };
  }
}
