<div class="page-container">
  <div style="margin-bottom: 1rem">
    <h1>Supplies</h1>
    <h3><a
      (click)="
        goToLink(
          'https://www.healthycatstore.com',
          AnalyticsEventEnum.HEALTHY_CAT_STORE
        )
      "
    >Healthy Cat Store</a
    ></h3>
    <p style="margin-top: 0.5rem">
      Healthy Cat Store was created to help pet parents treating their cats for
      FIP access all of the supplies needed in one easy-to-navigate online
      store.
    </p>
  </div>
</div>
