<div class="page-container">
  <h1>Contact Us</h1>
  <form [formGroup]="formData" class="form-container">
    <mat-form-field hintLabel="Emails will never be shared.">
      <mat-label>Email Address</mat-label>
      <input id="email" matInput formControlName="email" type="email" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>First Name</mat-label>
      <input id="firstName" matInput formControlName="firstName" type="text" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Last Name</mat-label>
      <input id="lastName" matInput formControlName="lastName" type="text" />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Request Type</mat-label>
      <mat-select formControlName="requestType">
        <mat-option *ngFor="let option of requestTypes" [value]="option">
          {{ option }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Subject</mat-label>
      <input id="subject" matInput formControlName="subject" type="text" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Message</mat-label>
      <textarea
        id="message"
        matInput
        formControlName="message"
        type="text"
        rows="5"></textarea>
    </mat-form-field>
    <div class="action-footer">
      <a
        disabled="{{ !formData.valid }}"
        [href]="emailString"
        target="_top"
        mat-flat-button
        color="primary"
        (click)="onSubmit(formData)"
        >Send</a
      >
    </div>
  </form>
</div>
