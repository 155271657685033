<table mat-table [dataSource]="dataSource" style="margin-bottom: 1rem">
  <ng-container matColumnDef="type">
    <th mat-header-cell *matHeaderCellDef>Type</th>
    <td
      mat-cell
      *matCellDef="let element">
      {{ element.type ?? "N/A" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="value">
    <th mat-header-cell *matHeaderCellDef>Value</th>
    <td mat-cell *matCellDef="let element; let i = index" style="padding-block: .5rem;">
      <mat-form-field
        class="value-field"
        subscriptSizing="dynamic"
        *ngIf="
          bloodWork && bloodWork.bloodWorkMetaData && bloodWork.bloodWorkMetaData[i]
        ">
        <div class="blood-work-input">
          <input
            id="value"
            type="number"
            max="999"
            min="0"
            maxlength="3"
            matInput
            (change)="onModelChange($event, i)"
            placeholder=""
            [(ngModel)]="bloodWork.bloodWorkMetaData[i].value" />
        </div>
      </mat-form-field>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
<!--<mat-paginator-->
<!--  [pageSizeOptions]="[5, 10, 25]"-->
<!--  showFirstLastButtons-->
<!--  aria-label="Select page of bloodworks"></mat-paginator>-->
