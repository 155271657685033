<mat-toolbar class="navbar">
  <div class="navbar-desktop">
    <a class="app-title" routerLinkActive="active" [routerLink]="'/dashboard'">
      <h2>
        <img
          src="assets/cat_island_oasis.png"
          width="35"
          height="30"
          style="margin-right: 0.15rem" />
        <span>FIP Oasis</span>
        <!--<app-chips *ngIf="proMember">PRO</app-chips>-->
      </h2>
    </a>
    <span class="spacer"></span>

    <ng-container *ngIf="authService.user$ | async; else noUserLoggedIn">
      <div *ngFor="let route of routes.pro">
        <button
          *ngIf="route?.adminOnly ? applicationData?.isAdmin : true"
          mat-button
          routerLinkActive="active"
          [routerLink]="route?.routeURL"
          [routerLinkActiveOptions]="{ exact: true }"
          class="navbar-text-buttons">
          {{ route?.text }}
        </button>
      </div>

      <!-- Can't put these buttons in the list because we want
      the custom notification badge -->
      <ng-template
        *ngTemplateOutlet="catRegistrationButton"></ng-template>
      <ng-template *ngTemplateOutlet="requisitionButton"></ng-template>

      <button
        mat-icon-button
        [matMenuTriggerFor]="profileMenu"
        aria-label="profile menu">
        <mat-icon>account_circle</mat-icon>
      </button>
      <mat-menu #profileMenu="matMenu">
        <div style="padding: 1rem">
         {{getMenuTitle()}}
        </div>
        <button (click)="logOut()" mat-flat-button style="width: 100%">
          <mat-icon>logout</mat-icon>
          <span>Sign Out</span>
        </button>
      </mat-menu>
      <div *ngIf="applicationData?.profile">
        <button
          mat-icon-button
          color="basic"
          [matMenuTriggerFor]="notificationMenu"
          aria-label="notifications"
          matTooltip="View Notifications">
          <mat-icon
            [matBadgeHidden]="notifications.length === 0"
            matBadge="&#8288;"
            matBadgeColor="warn"
            matBadgeSize="small"
            matBadgeOverlap="true"
            >notifications
          </mat-icon>
        </button>
        <mat-menu #notificationMenu class="notification-menu-panel">
          <app-notification-menu
            [notifications]="notifications"></app-notification-menu>
        </mat-menu>
      </div>
      <button
        [hidden]="!isAdmin"
        mat-icon-button
        aria-label="search"
        (click)="openSearchDialog()">
        <mat-icon>search</mat-icon>
      </button>
    </ng-container>
  </div>
  <div class="navbar-mobile">
    <a class="app-title" routerLinkActive="active" [routerLink]="'/dashboard'">
      <h2>
        <img
          src="assets/cat_island_oasis.png"
          width="30"
          height="25"
          style="margin-right: 0.15rem" />
        <span>FIP Oasis</span>
        <!--<app-chips *ngIf="proMember" class="pro-user">PRO</app-chips>-->
      </h2>
    </a>
    <span class="spacer"></span>
    <ng-container *ngIf="authService.user$ | async; else noUserLoggedIn">
      <button
        [hidden]="!isAdmin"
        mat-icon-button
        aria-label="search"
        (click)="openSearchDialog()">
        <mat-icon>search</mat-icon>
      </button>
      <div *ngIf="applicationData" style="margin-right: 0.5em">
        <button
          mat-icon-button
          color="basic"
          [matMenuTriggerFor]="notificationMenu"
          aria-label="notifications">
          <mat-icon
            [matBadgeHidden]="notifications.length === 0"
            matBadge="&#8288;"
            matBadgeColor="warn"
            matBadgeSize="small"
            matBadgeOverlap="true"
            >notifications
          </mat-icon>
        </button>
        <mat-menu #notificationMenu class="notification-menu-panel">
          <app-notification-menu
            [notifications]="notifications"></app-notification-menu>
        </mat-menu>
      </div>
      <button
        mat-icon-button
        color="primary"
        (click)="toggleSideNav()"
        matTooltip="Open Site Menu">
        <mat-icon
          aria-hidden="false"
          aria-label="Menu"
          fontIcon="menu"></mat-icon>
      </button>
      <div
        class="navbar-mobile-content"
        [ngClass]="{ opened: navbarMobileToggle }">
        <div class="close-toggle">
          <button
            mat-icon-button
            (click)="toggleSideNav()"
            style="color: white"
            matTooltip="Close Site Menu">
            <mat-icon
              aria-hidden="false"
              aria-label="Close Menu"
              fontIcon="close"></mat-icon>
          </button>
        </div>
        <h2 class="greeting">
          Hello, {{ applicationData?.profile?.firstName }}
          {{ applicationData?.profile?.lastName }}
        </h2>

        <div *ngFor="let route of routes.pro">
          <button
            *ngIf="route?.adminOnly ? applicationData?.isAdmin : true"
            mat-button
            routerLinkActive="active"
            [routerLink]="route?.routeURL"
            [routerLinkActiveOptions]="{ exact: false }"
            class="navbar-text-buttons"
            (click)="toggleSideNav()">
            {{ route?.text }}
          </button>
        </div>

        <ng-template *ngTemplateOutlet="catRegistrationButton"></ng-template>
        <ng-template
          *ngTemplateOutlet="profileAnalytics"></ng-template>
        <ng-template *ngTemplateOutlet="requisitionButton"></ng-template>

        <div style="margin-top: 2rem">
          <button (click)="logOut()" mat-button>
            <mat-icon>logout</mat-icon>
            <span>Sign Out</span>
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</mat-toolbar>

<ng-template #noUserLoggedIn>
  <button
    mat-flat-button
    color="primary"
    (click)="redirectToAuth0()"
    class="login-button">
    Log In/Sign Up
  </button>
</ng-template>

<ng-template #catRegistrationButton>
  <button
    *ngIf="applicationData?.isAdmin"
    mat-button
    routerLinkActive="active"
    [routerLink]="'/registration/cat/review'"
    [routerLinkActiveOptions]="{ exact: false }"
    class="registration-button navbar-text-buttons"
    (click)="toggleSideNav()"
    [matBadgeHidden]="!adminGlobalNotifications?.catRegistrations"
    matBadge="&#8288;"
    matBadgeColor="warn"
    matBadgeSize="small"
    matBadgeOverlap="true">
    Cat Registrations
  </button>
</ng-template>

<ng-template #profileAnalytics>
  <button
    *ngIf="applicationData?.isDeveloper"
    mat-button
    routerLinkActive="active"
    [routerLink]="'/registration/profile/review'"
    [routerLinkActiveOptions]="{ exact: false }"
    class="registration-button navbar-text-buttons"
    (click)="toggleSideNav()">
    Profile Analytics
  </button>
</ng-template>

<ng-template #requisitionButton>
  <button
    *ngIf="applicationData?.showRequisitions || applicationData?.isDeveloper"
    mat-button
    routerLinkActive="active"
    [routerLink]="'/requisitions'"
    [routerLinkActiveOptions]="{ exact: false }"
    class="registration-button navbar-text-buttons"
    (click)="toggleSideNav()"
    [matBadgeHidden]="!adminGlobalNotifications?.requisitions"
    matBadge="&#8288;"
    matBadgeColor="warn"
    matBadgeSize="small"
    matBadgeOverlap="true">
    Requisitions
  </button>
</ng-template>
