<form [formGroup]="profileForm" class="form-container">
  <div *ngIf="profile?.id && !enrollment" class="profile-image-upload">
    <div *ngIf="!avatarUrl" class="empty-image">
      {{ profile?.firstName?.charAt(0) || "" }}
    </div>
    <img *ngIf="avatarUrl" class="profile-image" [src]="avatarUrl" />
    <div style="margin-bottom: 1.3rem">
      <button style="margin-top: 0.5rem;" type="button" mat-button (click)="fileInput.click()">
        Upload/Edit Photo
      </button>
      <input
        hidden
        accept="image/jpeg"
        (change)="onFileSelected($event)"
        #fileInput
        type="file"
        id="file" />
    </div>
  </div>

  <div class="form-email">
    <mat-form-field hintLabel="Emails will never be shared.">
      <mat-label>Email Address</mat-label>
      <input
        appRequiredRoles="Admin"
        id="email"
        matInput
        formControlName="email"
        type="email" />
    </mat-form-field>
  </div>
  <div class="form-name">
    <mat-form-field>
      <mat-label>First Name</mat-label>
      <input
        id="firstName"
        matInput
        placeholder=""
        formControlName="firstName"
        type="text" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Last Name</mat-label>
      <input
        id="lastName"
        matInput
        placeholder=""
        formControlName="lastName"
        type="text" />
    </mat-form-field>
  </div>
  <div class="form-location">
    <mat-form-field>
      <mat-label>City</mat-label>
      <input
        id="city"
        matInput
        placeholder=""
        formControlName="city"
        type="text" />
    </mat-form-field>

    <mat-form-field>
      <mat-label>State</mat-label>
      <input
        type="text"
        id="state"
        placeholder="State"
        (change)="checkState($event)"
        matInput
        formControlName="state"
        [matAutocomplete]="stateAuto" />
      <mat-autocomplete #stateAuto="matAutocomplete">
        <mat-option
          *ngFor="let option of filteredStates | async"
          [value]="option.name">
          {{ option.name }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Postal Code</mat-label>
      <input
        id="zipCode"
        matInput
        placeholder=""
        formControlName="zipCode"
        type="text" />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Country</mat-label>
      <mat-select
        appRequiredRoles="Admin"
        id="country"
        formControlName="country"
        (selectionChange)="countryChange($event.value, true)">
        <mat-option
          *ngFor="let country of countries"
          [value]="country.country"
        >{{ country.country }}</mat-option
        >
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Organization</mat-label>
      <mat-select
        appRequiredRoles="Admin"
        id="organizations"
        multiple
        formControlName="organizations">
        <mat-option
          *ngFor="let organization of assignableOrganizations"
          [value]="organization"
          >{{ organization.name }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </div>

  <!-- Only show this if they don't have any data saved -->
  <div *ngIf="profile && (!profile.heardAboutUs || profile.heardAboutUs?.length === 0)">
    <Label>How did you hear about us?</Label>
    <div class="hear-about-us">
      <mat-checkbox
        *ngFor="let value of heardAboutUsValues"
        id="heard-{{value}}"
        (change)="updateValue($event.checked, value)"
      >{{ value }}
      </mat-checkbox>
    </div>
  </div>
</form>

<div class="action-footer">
  <button type="button" mat-stroked-button (click)="goBack()">Back</button>
  <button
    type="submit"
    mat-flat-button
    color="primary"
    (click)="save()"
    [disabled]="!profileForm.valid">
    Save
  </button>
</div>
