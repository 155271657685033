import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  Renderer2,
  ViewChild
} from '@angular/core';
import { LoadingService } from '../../service/loading/loading.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements AfterViewInit, OnDestroy {
  scriptSrc =
    'https://app.enzuzo.com/__enzuzo-privacy-app.js?mode=tos&apiHost=https://app.enzuzo.com&qt=1688308960362&referral=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJDdXN0b21lcklEIjoyMjk3MiwiQ3VzdG9tZXJOYW1lIjoiY3VzdC13NllaSFJMbCIsIkN1c3RvbWVyTG9nb1VSTCI6IiIsIlJvbGVzIjpbInJlZmVycmFsIl0sIlByb2R1Y3QiOiJlbnRlcnByaXNlIiwiaXNzIjoiRW56dXpvIEluYy4iLCJuYmYiOjE2ODgzMDg5MzF9.xvV_j81JUwP-yMpcWxgsQZ6XNWHDjiSD1GmB_Tr1U-4';

  @ViewChild('terms') termsElement: ElementRef | undefined;

  constructor(
    private loadingService: LoadingService,
    private renderer: Renderer2,
    private sanitizer: DomSanitizer,
    private parentElement: ElementRef
  ) {
    this.loadingService.setLoading(false);
  }

  ngAfterViewInit() {
    const script = this.renderer.createElement('script');
    script.src = this.scriptSrc; // Replace 'something' with the actual script URL or source code
    this.renderer.appendChild(this.termsElement?.nativeElement, script);
  }

  ngOnDestroy() {
    this.renderer.removeChild(
      this.parentElement.nativeElement,
      this.termsElement?.nativeElement
    );
  }
}
