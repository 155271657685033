<div class="container">
  <div class="close">
    <span class="spacer"></span>
    <button mat-icon-button aria-label="close modal" (click)="onClose()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="search">
    <form>
      <mat-form-field appearance="outline" class="search-field">
        <input
          name="searchTerm"
          matInput
          placeholder="Search for Cats or Profiles"
          [(ngModel)]="searchTerm" />
        <button
          mat-icon-button
          matSuffix
          (click)="search()"
          [attr.aria-label]="'Search'"
          type="submit">
          <mat-icon>search</mat-icon>
        </button>
      </mat-form-field>
    </form>
  </div>

  <div
    *ngIf="
      searchResult &&
      searchResult.profiles?.length === 0 &&
      searchResult.cats?.length === 0
    ">
    No results found.
  </div>

  <div class="results">
    <div
      *ngIf="searchResult && searchResult.cats && searchResult.cats.length > 0">
      <h3>Cats - click to view</h3>
      <app-cat-table
        [cats]="searchResult.cats"
        [isSearch]="true"
        (catTableClick)="onClose()"
        [applicationData]="applicationData">
      </app-cat-table>
    </div>

    <div
      *ngIf="
        searchResult &&
        searchResult.profiles &&
        searchResult.profiles.length > 0
      ">
      <h3>Profiles</h3>
      <app-profile-table
        [applicationData]="applicationData"
        [profiles]="searchResult.profiles"
        (profileTableClick)="onClose()">
      </app-profile-table>
    </div>
  </div>
</div>
