import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { CatComponent } from './pages/cat/cat.component';
import { AuthGuard } from '@auth0/auth0-angular';
import { ErrorComponent } from './pages/error/error.component';
import { EnrollmentComponent } from './pages/enrollment/enrollment.component';
import { enrollmentGuard } from './guard/enrollment/enrollment.guard';
import { catResolver } from './resolver/cat/cat.resolver';
import { enrollmentResolver } from './resolver/enrollment/enrollment.resolver';
import { HomeComponent } from './pages/home/home.component';
import { catProPurchaseGuard } from './guard/purchase/catProPurchaseGuard';
import { FaqsComponent } from './pages/faqs/faqs.component';
import { ContactComponent } from './pages/contact/contact.component';
import { dashboardResolver } from './resolver/dashboard/dashboard.resolver';
import { profileGuard } from './guard/profile/profile.guard';
import { profileResolver } from './resolver/profile/profile.resolver';
import { dashboardGuard } from './guard/dashboard/dashboard.guard';
import { CatRegistrationComponent } from './pages/cat-registration/cat-registration.component';
import { ActivityComponent } from './pages/activity/activity.component';
import { TermsComponent } from './components/terms/terms.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { CatRegistrationReviewComponent } from './pages/cat-registration-review/cat-registration-review.component';
import { catRegistrationResolver } from './resolver/cat-registration/cat-registration.resolver';
import { ProfileRegistrationComponent } from './pages/profile-registration/profile-registration.component';
import { ProfileRegistrationReviewComponent } from './pages/profile-registration-review/profile-registration-review.component';
import { profileRegistrationResolver } from './resolver/profile-registration/profile-registration.resolver';
import { ProfileRegistrationStatusComponent } from './pages/profile-registration-status/profile-registration-status.component';
import { SuppliesComponent } from './pages/supplies/supplies.component';
import { RequisitionsComponent } from './pages/requisitions/requisitions.component';
import { ProductsComponent } from './pages/products/products.component';
import { UserComponent } from './pages/user/user.component';
import { ProfileNewComponent } from './pages/profile-new/profile-new.component';
import { CostEstimatorComponent } from './pages/cost-estimator/cost-estimator.component';
import { AnalyticsComponent } from './pages/analytics/analytics.component';

// Need to limit what users who are not admins can actually hit endpoint wise
const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [dashboardGuard],
    resolve: {
      dashboardResolverData: dashboardResolver
    },
    title: 'Dashboard'
  },
  {
    path: 'home',
    component: HomeComponent,
    title: 'Home'
  },
  {
    path: 'faqs',
    component: FaqsComponent,
    title: 'FAQs'
  },
  {
    path: 'contact-us',
    component: ContactComponent,
    title: 'Contact Us'
  },
  {
    path: 'user',
    component: UserComponent,
    canActivate: [AuthGuard],
    title: 'My Profile'
  },
  {
    path: 'profile/new',
    component: ProfileNewComponent,
    canActivate: [AuthGuard],
    title: 'New Profile'
  },
  {
    path: 'profile/:id',
    component: ProfileComponent,
    canActivate: [AuthGuard, profileGuard],
    resolve: {
      profile: profileResolver
    },
    title: 'View Profile'
  },
  /*{ Cats only created through enrollment, leaving this out
    path: 'cat',
    component: CatComponent,
    canActivate: [AuthGuard, purchaseGuard],
    title: 'Cat',
    children: [
      {
        path: '',
        // component: CatComponent,
        // canActivate: [AuthGuard],
        redirectTo: 'create',
        pathMatch: 'full'
      },
      {
        path: 'create',
        component: CatComponent,
        // canActivate: [AuthGuard],
        // pathMatch: 'prefix',
        title: 'Create Cat',
        pathMatch: 'full'
      }
    ]
  }, */
  {
    path: 'cat/:id',
    component: CatComponent,
    canActivate: [AuthGuard, catProPurchaseGuard],
    resolve: {
      catComponentData: catResolver
    },
    title: 'Edit Cat'
  },
  {
    path: 'enrollment',
    component: EnrollmentComponent,
    canActivate: [AuthGuard, enrollmentGuard],
    title: 'Enrollment',
    children: [
      {
        path: '',
        redirectTo: 'new',
        pathMatch: 'full'
      },
      {
        path: 'new',
        component: EnrollmentComponent,
        pathMatch: 'full'
      }
    ]
  },
  {
    path: 'enrollment/:id',
    canActivate: [AuthGuard, enrollmentGuard],
    resolve: {
      enrollment: enrollmentResolver
    },
    component: EnrollmentComponent,
    title: 'Enrollment'
  },
  {
    path: 'registration/profile/new',
    component: ProfileRegistrationComponent,
    title: 'New Profile'
  },
  {
    path: 'registration/profile/status',
    component: ProfileRegistrationStatusComponent,
    title: 'Profile Registration Status'
  },
  {
    path: 'registration/cat/new',
    canActivate: [AuthGuard],
    component: CatRegistrationComponent,
    title: 'New Cat Registration'
  },
  {
    path: 'registration/cat/review',
    canActivate: [AuthGuard],
    component: CatRegistrationReviewComponent,
    title: 'Review Cat Registrations'
  },
  {
    path: 'registration/profile/review',
    canActivate: [AuthGuard],
    component: ProfileRegistrationReviewComponent,
    title: 'Review Profile Registrations'
  },
  {
    path: 'registration/cat/:id',
    canActivate: [AuthGuard],
    component: CatRegistrationComponent,
    resolve: {
      registration: catRegistrationResolver
    },
    title: 'View Cat Registration'
  },
  {
    path: 'registration/profile/:id',
    canActivate: [AuthGuard],
    component: ProfileRegistrationComponent,
    resolve: {
      registration: profileRegistrationResolver
    },
    title: 'View Profile Registration'
  },
  {
    path: 'activity',
    canActivate: [AuthGuard],
    component: ActivityComponent,
    title: 'Activity'
  },
  {
    path: 'analytics',
    canActivate: [AuthGuard],
    component: AnalyticsComponent,
    title: 'Analytics'
  },
  {
    path: 'requisitions',
    canActivate: [AuthGuard],
    component: RequisitionsComponent,
    title: 'Requisitions'
  },
  {
    path: 'treatment/supplies',
    canActivate: [AuthGuard],
    component: SuppliesComponent,
    title: 'Supplies'
  },
  {
    path: 'treatment/products',
    canActivate: [AuthGuard],
    component: ProductsComponent,
    title: 'Supplies'
  },
  {
    path: 'terms',
    component: TermsComponent,
    title: 'Terms of Service'
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
    title: 'Privacy Policy'
  },
  {
    path: 'error/:error',
    component: ErrorComponent,
    title: 'Error'
  },
  {
    path: 'cost-estimator',
    component: CostEstimatorComponent,
    title: 'Cost Estimator'
  },
  {
    path: '**',
    redirectTo: 'error/notFound',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
