import { ResolveFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { FipAdminApiService } from '../../service/api/fip-admin-api.service';
import { catchError, throwError } from 'rxjs';
import { CatRegistration } from '../../model/models';

export const catRegistrationResolver: ResolveFn<CatRegistration | null> = (
  route,
  state
) => {
  const fipAdminApiService = inject(FipAdminApiService);
  const router = inject(Router);
  const catRegistrationId = route.paramMap.get('id');
  if (catRegistrationId) {
    return fipAdminApiService.getCatRegistration(catRegistrationId).pipe(
      catchError(() => {
        void router.navigate(['error/notFound']);
        return throwError(() => 'Not found');
      })
    );
  } else {
    void router.navigate(['dashboard']);
    return null;
  }
};
