import { Component, OnInit } from '@angular/core';
import { LoadingService } from '../../service/loading/loading.service';
import { FipAdminApiService } from '../../service/api/fip-admin-api.service';
import { Profile, ProfileRegistration } from '../../model/models';
import { Router } from '@angular/router';
import { NotificationService } from '../../service/notification/notification.service';
import { ApplicationDataService } from '../../service/data/application-data.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-profile-registration-review',
  templateUrl: './profile-registration-review.component.html',
  styleUrls: ['./profile-registration-review.component.scss']
})
export class ProfileRegistrationReviewComponent implements OnInit {
  timeZone = 'UTC';
  profiles: Profile[] = [];
  isDeveloper: boolean | undefined;
  constructor(
    private loadingService: LoadingService,
    private fipAdminApiService: FipAdminApiService,
    private router: Router,
    private notificationService: NotificationService,
    private applicationDataService: ApplicationDataService
  ) {
    this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.loadingService.setLoading(false);
    this.applicationDataService
      .getApplicationData()
      .pipe(takeUntilDestroyed())
      .subscribe((applicationData) => {
        this.isDeveloper = applicationData?.isDeveloper;
      });
  }

  ngOnInit() {
    this.notificationService.retrieveNotifications();
    this.fipAdminApiService.getProfileAnalytics().subscribe((profiles) => {
      this.profiles = profiles;
    });
  }

  goToProfileRegistration(id: string | undefined) {
    void this.router.navigate(['registration', 'profile', id]);
  }
}
