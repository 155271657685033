import { Component, Input } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

export type Spacing = 'comfort' | 'cozy' | 'compact';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent {
  @Input() spacing: Spacing = 'comfort';
  @Input() color: ThemePalette = 'primary';
  @Input() icon: string | undefined;
}
