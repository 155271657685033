import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BloodWork } from '../../../model/blood-work-models';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-blood-work-test-form',
  templateUrl: './blood-work-test-form.component.html',
  styleUrls: ['./blood-work-test-form.component.scss']
})
export class BloodWorkTestFormComponent implements OnInit {
  @Input()
  bloodWork: BloodWork | undefined;

  // List of all blood works to show comparisons
  @Input()
  bloodWorks: BloodWork[] = [];

  previousBloodWorks: BloodWork[] = [];

  @Output()
  bloodWorkSave = new EventEmitter<BloodWork>();

  @Output()
  bloodWorkClose = new EventEmitter<BloodWork>();

  constructor(public dialog: MatDialog) {}

  ngOnInit() {
    this.previousBloodWorks = this.bloodWorks.filter((bloodWork) => {
      if (this.bloodWork) {
        return (
          bloodWork.date < this.bloodWork?.date &&
          this.bloodWork.id != bloodWork.id
        );
      }
      return false;
    });

    console.log(this.previousBloodWorks);
  }

  close() {
    this.bloodWorkClose.emit();
  }

  save() {
    this.bloodWorkSave.emit(this.bloodWork);
  }
}
