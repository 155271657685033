import { Component, Input } from '@angular/core';
import { ApplicationData, Profile } from '../../model/models';
import { Router } from '@angular/router';
import { ApplicationDataService } from '../../service/data/application-data.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-profile-new',
  templateUrl: './profile-new.component.html',
  styleUrls: ['./profile-new.component.scss']
})
export class ProfileNewComponent {
  @Input()
  enrollment = false;

  profile: Profile | undefined;
  message: string | undefined;

  constructor(
    private router: Router,
    private applicationDataService: ApplicationDataService
  ) {
    this.applicationDataService
      .getApplicationData()
      .pipe(takeUntilDestroyed())
      .subscribe((applicationData) => {
        if (applicationData) {
          if (applicationData?.profile) {
            void this.router.navigate(['user']);
          }
          this.setupProfileForCreate(applicationData);
        }
      });
  }

  onProfileSave(profile: Profile) {
    const navigate = !this.profile?.id && !this.enrollment;
    this.profile = profile;
    this.applicationDataService.setData({ profile });
    // Only navigate when we never had a profile to begin with
    if (navigate) {
      void this.router.navigate(['dashboard']);
    }
  }

  private setupProfileForCreate(applicationData: ApplicationData | undefined) {
    if (applicationData?.email) {
      this.profile = {
        email: applicationData.email,
        organizations: applicationData.assignableOrganizations
      };
      this.message = 'Please save a profile to continue.';
    } else {
      this.message =
        'Error, could not find an email. Please log out and log back in.';
    }
  }
}
