import { Component, OnDestroy, OnInit, signal } from '@angular/core';
import { ApplicationDataService } from '../../service/data/application-data.service';
import {
  Enrollment,
  ApplicationData,
  CatTableRow,
  DashboardData,
  Notification,
  Task,
  CatRegistration,
  Cat
} from '../../model/models';
import { ActivatedRoute, Router } from '@angular/router';
import { count, Subscription } from 'rxjs';
import { FipAdminApiService } from '../../service/api/fip-admin-api.service';
import { MatDialog } from '@angular/material/dialog';
import { GlanceModalComponent } from 'src/app/components/modals/glance-modal/glance-modal.component';
import { LoadingService } from '../../service/loading/loading.service';
import { handleAvatarResponse } from '../../service/utils/ui-utils';
import { ConfirmationModalComponent } from '../../components/modals/confirmation-modal/confirmation-modal.component';
import { AnalyticsService } from '../../service/analytics/analytics.service';
import { AnalyticsEventEnum } from '../../model/enums';
import { PaymentBreakdownModalComponent } from '../../components/modals/payment-breakdown-modal/payment-breakdown-modal.component';
import { SupportUsModalComponent } from '../../components/modals/support-us-modal/support-us-modal.component';
import { CAT_FIP_OASIS_PRO_ID } from '../../model/constants';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  applicationData: ApplicationData | undefined;
  applicationData$: Subscription | undefined;
  cats: CatTableRow[] | undefined;
  dashboardData: DashboardData | undefined;
  timeZone = 'UTC';

  catImageUrls: Map<string, string> = new Map<string, string>();
  catRegistrations: CatRegistration[] = [];
  openCatRegistrations: CatRegistration[] = [];

  constructor(
    private applicationDataService: ApplicationDataService,
    private router: Router,
    private fipAdminApiService: FipAdminApiService,
    private dialog: MatDialog,
    private loadingService: LoadingService,
    private activatedRoute: ActivatedRoute,
    private analyticsService: AnalyticsService
  ) {
    this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.loadingService.setLoading(true);
  }

  ngOnInit() {
    this.activatedRoute.data.subscribe(({ dashboardResolverData }) => {
      if (dashboardResolverData) {
        this.dashboardData = dashboardResolverData.dashboardData;
        if (dashboardResolverData.cats) {
          this.cats = dashboardResolverData.cats;
          this.loadApplicationDataAndImages();
        }

        if (dashboardResolverData.catRegistrations) {
          this.catRegistrations = dashboardResolverData.catRegistrations;
          this.openCatRegistrations = this.catRegistrations.filter(
            (registration) => registration.status === 'STARTED'
          );
        }

        this.loadingService.setLoading(false);
      }
    });
  }

  loadApplicationDataAndImages() {
    this.applicationData$ = this.applicationDataService
      .getApplicationData()
      .subscribe((applicationData) => {
        this.applicationData = applicationData;
        if (!this.applicationData?.isAdmin) {
          this.loadCatImages();
        }
      });
  }

  private loadCatImages() {
    this.cats?.forEach((cat) => {
      this.fipAdminApiService.getCatAvatar(cat.id).subscribe((avatar) => {
        const avatarUrl = handleAvatarResponse(avatar);
        if (avatarUrl) {
          this.catImageUrls.set(cat.id, avatarUrl);
        }
      });
    });
  }

  ngOnDestroy() {
    if (this.applicationData$) {
      this.applicationData$.unsubscribe();
    }
  }

  continueEnrollment(enrollment: Enrollment) {
    void this.router.navigate(['enrollment', enrollment.id]);
  }

  openCat(catId: string) {
    void this.router.navigate(['cat', catId]);
  }

  openEnrollmentsGlanceCard() {
    if (
      this.dashboardData?.openEnrollments &&
      this.dashboardData.openEnrollments.length > 0
    ) {
      const enrollmentGlanceModal = this.dialog.open(GlanceModalComponent, {
        minWidth: 350,
        width: '75vw',
        maxWidth: 750,
        ariaModal: true,
        autoFocus: false,
        data: {
          title: 'Enrollments',
          listData: this.dashboardData?.openEnrollments
        }
      });
      enrollmentGlanceModal
        .afterClosed()
        .subscribe((redirectData: Enrollment | null) => {
          if (redirectData) {
            this.continueEnrollment(redirectData);
          }
        });
    }
  }

  openCatCard(cats: CatTableRow[] | undefined, title: string) {
    if (cats && cats?.length > 0) {
      const catCardModal = this.dialog.open(GlanceModalComponent, {
        minWidth: 350,
        width: '75vw',
        maxWidth: 750,
        ariaModal: true,
        autoFocus: false,
        data: {
          title,
          listData: cats
        }
      });
      catCardModal
        .afterClosed()
        .subscribe((redirectData: CatTableRow | null) => {
          if (redirectData) {
            this.openCat(redirectData.id);
          }
        });
    }
  }

  openRequestHelpGlanceCard(notifications: Notification[] | undefined) {
    if (notifications && notifications?.length > 0) {
      const catRequiredAssistanceModal = this.dialog.open(
        GlanceModalComponent,
        {
          minWidth: 350,
          width: '75vw',
          maxWidth: 750,
          ariaModal: true,
          autoFocus: false,
          data: {
            title: 'Cat Requested Assistance',
            listData: notifications
          }
        }
      );
      catRequiredAssistanceModal
        .afterClosed()
        .subscribe((redirectData: Notification | null) => {
          if (redirectData) {
            this.openCat(redirectData.catId);
          }
        });
    }
  }

  protected readonly count = count;

  openTaskCard(upcomingTasks: Map<string, Task> | undefined, title: string) {
    const catRequiredAssistanceModal = this.dialog.open(GlanceModalComponent, {
      minWidth: 350,
      width: '75vw',
      maxWidth: 750,
      ariaModal: true,
      autoFocus: false,
      data: {
        title,
        mapData: upcomingTasks
      }
    });
    catRequiredAssistanceModal.afterClosed().subscribe((task: Task | null) => {
      if (task) {
        this.openCat(task.catId);
      }
    });
  }

  startNewEnrollment() {
    void this.router.navigate(['enrollment']);
  }

  confirmNewCatRegistration($event: MouseEvent) {
    $event.stopPropagation();
    const confirmationDialog = this.dialog.open(ConfirmationModalComponent, {
      minWidth: 350,
      width: '75vw',
      maxWidth: 750,
      ariaModal: true,
      autoFocus: false,
      data: {
        action: `enroll a new cat into FIP Oasis`,
        subContent: `In order to provide assistance, we will need you to answer questions about your cat. Once finished,
        your cat will be enrolled in FIP Oasis and you can start a chat with your admins for immediate help.`
      }
    });
    confirmationDialog.afterClosed().subscribe((decision: boolean) => {
      if (decision) {
        this.newCatRegistration();
      }
    });
  }

  newCatRegistration() {
    void this.router.navigate(['registration', 'cat', 'new']);
  }

  continueCatRegistration(id: string) {
    void this.router.navigate(['registration', 'cat', id]);
  }

  goToSupplies() {
    this.analyticsService.sendAnalyticsEvent(
      this.applicationData,
      undefined,
      AnalyticsEventEnum.HEALTHY_CAT_STORE
    );
    window.location.href = 'https://www.healthycatstore.com';
    //void this.router.navigate(['treatment', 'supplies']);
  }

  goToProducts() {
    this.analyticsService.sendAnalyticsEvent(
      this.applicationData,
      undefined,
      AnalyticsEventEnum.PRODUCTS_BUTTON
    );
    void this.router.navigate(['treatment', 'products']);
  }

  goToHandbook() {
    this.analyticsService.sendAnalyticsEvent(
      this.applicationData,
      undefined,
      AnalyticsEventEnum.HANDBOOK_BUTTON
    );
    window.location.href =
      'https://drive.google.com/file/d/11VrAL2obwNc6W6T8AfFHhMtQH4ykwVrr/view?usp=share_link';
  }

  goToFacebook() {
    this.analyticsService.sendAnalyticsEvent(
      this.applicationData,
      undefined,
      AnalyticsEventEnum.FACEBOOK_BUTTON
    );
    window.location.href =
      'https://www.facebook.com/profile.php?id=100094351179009';
  }

  supportUs() {
    this.dialog.open(SupportUsModalComponent, {
      minWidth: 350,
      width: '75vw',
      maxWidth: 600,
      ariaModal: true,
      autoFocus: false,
      data: {
        title: 'Support Us',
        cats: this.cats,
        profileId: this.applicationData?.profile?.id
      }
    });
  }
}
