<div class="page-container">
  <h1>Activity</h1>
  <div class="filter-form">
    <div class="date-range">
      <h4>Range</h4>
      <mat-radio-group aria-label="rangeType" [formControl]="rangeType">
        <mat-radio-button value="selection">Selection</mat-radio-button>
        <mat-radio-button value="dateRange">Date Range</mat-radio-button>
      </mat-radio-group>

      <mat-form-field *ngIf="rangeType.value === 'selection'">
        <mat-label>Quick range</mat-label>
        <mat-select [formControl]="quickRange">
          <mat-option *ngFor="let option of timeFilterOptions" [value]="option">
            {{ option.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="rangeType.value === 'dateRange'">
        <mat-label>Date Range</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input
            [formControl]="startDate"
            matStartDate
            placeholder="Start date" />
          <input [formControl]="endDate" matEndDate placeholder="End date" />
        </mat-date-range-input>
        <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle
          matIconSuffix
          [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
    </div>
    <div class="profile-range">
      <h4>Profiles</h4>
      <mat-radio-group aria-label="profileRange" [formControl]="profileType">
        <mat-radio-button value="all">All</mat-radio-button>
        <mat-radio-button value="admin">Admin Only</mat-radio-button>
        <mat-radio-button value="specific">Specific</mat-radio-button>
      </mat-radio-group>

      <mat-form-field *ngIf="profileType.value === 'specific'">
        <mat-label>Quick range</mat-label>
        <mat-select [formControl]="profileSelection">
          <mat-option *ngFor="let option of profiles" [value]="option">
            {{ option.firstName }} {{ option.lastName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <button
    mat-flat-button
    color="primary"
    (click)="findActivity()"
    style="margin-bottom: 1rem">
    Search
  </button>
  <div class="activity-feed">
    <div class="discussions">
      <h4 class="category-header">Discussions</h4>
      <div *ngIf="comments.length > 0">
        <div style="margin-bottom: 1rem; line-height: 1.125rem;" *ngFor="let comment of comments">
          {{ comment.dateCreated | date : "short" : timeZone }}
          <span class="initiator">
            {{ comment.createdBy?.firstName }}
          </span>
           commented: {{ comment.text }} on
          cat:
          {{ comment.catName }}
        </div>
      </div>
      <div *ngIf="comments.length === 0">No Recent Activity</div>
    </div>
    <div class="file-uploads">
      <h4 class="category-header">File Uploads</h4>
      <div>No Recent Activity</div>
    </div>
    <div class="profiles">
      <h4 class="category-header">New Profiles</h4>
      <div>No Recent Activity</div>
    </div>
    <div class="cats">
      <h4 class="category-header">New Cats</h4>
      <div>No Recent Activity</div>
    </div>
  </div>
</div>
