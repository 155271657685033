import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-task-card',
  templateUrl: './task-card.component.html',
  styleUrls: ['./task-card.component.scss']
})
export class TaskCardComponent {
  @Input({ required: true }) date!: string;
  @Input({ required: true }) month!: string;
  @Input({ required: true }) description!: string;
  @Input({ required: true }) type!: string;

  @Input() isAdmin: boolean | undefined;
  @Input() notified!: string;
  @Input() notifiedOn!: string;

  @Output()
  cardClicked = new EventEmitter();

  clicked() {
    this.cardClicked.emit();
  }
}
