import { Component, Input } from '@angular/core';
import { Cat, ApplicationData, CatProMemberDTO } from '../../model/models';
import { CAT_FIP_OASIS_PRO_ID } from '../../model/constants';
import { AnalyticsService } from '../../service/analytics/analytics.service';
import { Router } from '@angular/router';
import { AnalyticsEventEnum } from '../../model/enums';

@Component({
  selector: 'app-cat-card',
  templateUrl: './cat-card.component.html',
  styleUrls: ['./cat-card.component.scss']
})
export class CatCardComponent {
  @Input() name = '';
  @Input() avatarUrl: string | undefined;
  @Input() subName = '';
  @Input() treatmentProgress = 0;
  @Input() catProMemberDTO: CatProMemberDTO | undefined;
  @Input() applicationData: ApplicationData | undefined;
  @Input() id: string | undefined;
  @Input() profileId: string | undefined;
  @Input() weightTrend = false;
  @Input() lastDailyDose = 0;

  protected readonly CAT_FIP_OASIS_PRO_ID = CAT_FIP_OASIS_PRO_ID;

  constructor(
    private analyticsService: AnalyticsService,
    private router: Router
  ) {}

  viewCatInfo() {
    this.analyticsService.sendAnalyticsEvent(
      this.applicationData,
      { id: this.id } as Cat,
      AnalyticsEventEnum.VIEW_INFO
    );

    void this.router.navigate(['cat', this.id]);
  }
}
