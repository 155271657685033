<h2 mat-dialog-title>Payment Breakdown</h2>
<div mat-dialog-content>
  Clicking 'Confirm' will redirect you to an external site to handle payment
  processing.
  <div style="margin-top: 0.5rem">
    What is included in your One time FIP Oasis Pro Purchase:
    <ul>
      <li class="pro-item">
        Premium support for your cat through <strong>treatment</strong> and
        <strong>observation</strong>
      </li>
      <li class="pro-item">Fast, direct communication to your admin(s) through <strong>chat</strong></li>
      <li class="pro-item">Daily treatment log</li>
      <li class="pro-item">Easy to use <strong>dose calculator</strong> for all the popular brands</li>
      <li class="pro-item"><strong>Reminders</strong> for important dates like blood work</li>
      <li class="pro-item"><strong>File uploads</strong> (blood work, important photos, treatment handbook)</li>
    </ul>
  </div>
  <div class="container" *ngIf="proPrice">
    <div class="discount">
      <mat-form-field style="width: 100%">
        <mat-label>Promo Code</mat-label>
        <input
          matInput
          [disabled]="appliedCode.length > 0"
          [(ngModel)]="discountCode" />
        <button
          mat-button
          [disabled]="appliedCode.length > 0"
          (click)="applyPromoCode()"
          matSuffix>
          Apply
        </button>
        <mat-hint *ngIf="appliedCode">
          Code
          <span class="discount-code"> {{ appliedCode }} </span>
          applied!</mat-hint
        >
        <mat-hint *ngIf="!isCodeValid"
          >Code <span class="invalid-code">{{ invalidCode }} </span> is not
          valid.</mat-hint
        >
      </mat-form-field>
    </div>
    <div class="mathbox">
      <div class="pro-membership">
        <span class="description">Pro Membership</span>
        <span class="price"> {{ proPrice | currency }} </span>
      </div>
      <div class="discounts" *ngIf="discount > 0">
        <span class="description"> Discounts </span>
        <span class="price"> - {{ discount | currency }} </span>
      </div>
      <mat-divider style="width: 30%"></mat-divider>
      <div class="subtotal">
        <span class="description"> Subtotal </span>
        <span class="price"> {{ subTotal | currency }}* </span>
      </div>
      <small class="disclaimer">
        <sup> * </sup>
        does not include applicable taxes
      </small>
    </div>
  </div>
</div>
<div mat-dialog-actions align="end">
  <button mat-stroked-button (click)="cancel()">Go Back</button>
  <button mat-flat-button color="primary" (click)="confirm()">Confirm</button>
</div>
