import { Injectable } from '@angular/core';
import { AnalyticsEvent, Cat, ApplicationData } from '../../model/models';
import { AnalyticsEventEnum } from '../../model/enums';
import { FipAdminApiService } from '../api/fip-admin-api.service';
import { take } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  constructor(private fipAdminApiService: FipAdminApiService) {}

  sendAnalyticsEvent(
    applicationData: ApplicationData | undefined,
    cat: Cat | undefined,
    event: AnalyticsEventEnum,
    details = ''
  ) {
    const analyticsEvent: AnalyticsEvent = {
      level: 'info',
      event,
      email: applicationData?.email,
      firstName: applicationData?.profile?.firstName,
      lastName: applicationData?.profile?.lastName,
      role: applicationData?.profile?.role,
      catId: cat?.id,
      catName: cat?.name,
      details
    };
    this.fipAdminApiService
      .sendAnalyticsEvent(analyticsEvent)
      .pipe(take(1))
      .subscribe();
  }
}
