import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpStatusCode
} from '@angular/common/http';
import { catchError, map, Observable, switchMap, throwError } from 'rxjs';
import { AuthService } from '@auth0/auth0-angular';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  // const noAuthRoutes = ['/api/registration/profile/save', '/api/registration/profile/status']

  // eslint-disable-next-line
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    // No Auth Routes
    if (request.url.includes('/api/brands/all')) {
      return next.handle(request);
    }

    // Not super safe to store in localStorage... but it is what it is. These tokens kind of last too long,
    // need to lower it to like 1 hour maybe
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      return next
        .handle(
          request.clone({
            setHeaders: {
              Authorization: `Bearer ${accessToken}`,
              Accept: 'application/json'
            }
          })
        )
        .pipe(
          catchError((error) => {
            // Clear token and get a new one
            if (error.status === HttpStatusCode.Unauthorized) {
              localStorage.removeItem('accessToken');
              return this.intercept(request, next);
            }
            return throwError(() => error);
          })
        );
    }

    return this.getAccessToken().pipe(
      catchError((error) => {
        // Logout on errors when getting the access token, so they can log back in.
        // If this keeps happening, maybe we just remove the access token then maybe i just try the loginWithRedirect
        this.authService.logout();
        return throwError(() => error);
      }),
      map((accessToken) => {
        if (accessToken) {
          localStorage.setItem('accessToken', accessToken);
        }
        return request.clone({
          setHeaders: { Authorization: `Bearer ${accessToken}` }
        });
      }),
      switchMap((request) => next.handle(request))
    );
  }

  private getAccessToken(): Observable<string | undefined> {
    // You don't need this for refresh tokens..?? Uhh okay
    /* const getTokenOptions = {
      authorizationParams: {
        scope: 'admin:write'
      }
    };
    */

    //if (window.location.href.includes('localhost:4200')) {
    // Can't consent in localhost or dev so popup it is.
    //  return this.authService.getAccessTokenWithPopup(getTokenOptions);
    //} else {
    // Need to fix consent required when its deployed

    return this.authService.getAccessTokenSilently();
    //}
  }
}
