import { Injectable } from '@angular/core';
import { BehaviorSubject, take, timer } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfettiService {
  private showConfetti = new BehaviorSubject<boolean>(false);

  constructor() {}

  public celebrateWithConfetti(endCelebration: boolean) {
    this.showConfetti.next(true);

    if (endCelebration) {
      timer(4000)
        .pipe(take(1))
        .subscribe(() => {
          this.showConfetti.next(false);
        });
    }
  }

  public getShowConfetti() {
    return this.showConfetti;
  }

  public endConfetti() {
    this.showConfetti.next(false);
  }
}
