import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FipAdminApiService } from '../../../service/api/fip-admin-api.service';
import { ApplicationData, SearchResult } from '../../../model/models';
import { ApplicationDataService } from '../../../service/data/application-data.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-search-modal',
  templateUrl: './search-modal.component.html',
  styleUrls: ['./search-modal.component.scss']
})
export class SearchModalComponent {
  searchResult: SearchResult | undefined;
  searchTerm = '';
  applicationData: ApplicationData | undefined;
  constructor(
    public dialog: MatDialogRef<SearchModalComponent>,
    private fipAdminApiService: FipAdminApiService,
    private applicationDataService: ApplicationDataService
  ) {
    this.applicationDataService
      .getApplicationData()
      .pipe(takeUntilDestroyed())
      .subscribe((applicationData) => (this.applicationData = applicationData));
  }

  onClose() {
    this.dialog.close();
  }

  search() {
    if (this.searchTerm.length > 0) {
      this.fipAdminApiService
        .getSearchResult(this.searchTerm)
        .subscribe((searchResult) => (this.searchResult = searchResult));
    }
  }
}
