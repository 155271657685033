import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { BloodWork } from '../../../model/blood-work-models';
import { Router } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AnalyticsService } from '../../../service/analytics/analytics.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-blood-work-table',
  templateUrl: './blood-work-table.component.html',
  styleUrls: ['./blood-work-table.component.scss']
})
export class BloodWorkTableComponent implements AfterViewInit {
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  dataSource!: MatTableDataSource<BloodWork>;

  @Input()
  set bloodWorks(bloodWorks: BloodWork[]) {
    this.dataSource = new MatTableDataSource<BloodWork>(bloodWorks);
  }

  @Input()
  previousBloodWorks: BloodWork[] = [];

  @Output()
  rowSelected = new EventEmitter<BloodWork>();

  @Output()
  deleteBloodWork = new EventEmitter<BloodWork>();

  @Output()
  editBloodWork = new EventEmitter<BloodWork>();

  mobileColumns: string[] = ['date', 'description', 'view', 'delete'];
  desktopColumns: string[] = ['date', 'description', 'view', 'delete'];
  isMobileSizeScreen = false;

  displayedColumns = this.desktopColumns;

  constructor(
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private analyticsService: AnalyticsService
  ) {
    this.breakpointObserver
      .observe([Breakpoints.XSmall])
      .pipe(takeUntilDestroyed())
      .subscribe((result) => {
        this.isMobileSizeScreen = result.matches;
        if (this.isMobileSizeScreen) {
          this.displayedColumns = this.mobileColumns;
        } else {
          this.displayedColumns = this.desktopColumns;
        }
      });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  onRowSelected(bloodWork: BloodWork) {
    this.rowSelected.emit(bloodWork);
  }

  onDeleteBloodWork($event: Event, bloodWork: BloodWork) {
    $event.stopPropagation();
    this.deleteBloodWork.emit(bloodWork);
  }

  onEditBloodWork($event: MouseEvent, bloodWork: BloodWork) {
    $event.stopPropagation();
    this.editBloodWork.emit(bloodWork);
  }
}
