import { Component, OnInit } from '@angular/core';
import { FipAdminApiService } from '../../service/api/fip-admin-api.service';
import { LoadingService } from '../../service/loading/loading.service';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss']
})
export class AnalyticsComponent implements OnInit {
  statusCounts: {
    status: string;
    statusCount: number;
    statusPercentage: number;
  }[] = [];

  completeCats = 0;

  constructor(
    private fipAdminApiService: FipAdminApiService,
    private loadingService: LoadingService
  ) {
    this.loadingService.setLoading(false);
  }

  ngOnInit() {
    this.fipAdminApiService.getStatusCounts().subscribe((statusCounts) => {
      this.statusCounts = statusCounts;
    });
    this.fipAdminApiService.getCompleteCats().subscribe((completeCats) => {
      this.completeCats = completeCats.complete;
    });
  }

  getCountTotal() {
    return this.statusCounts
      .map((count) => count.statusCount)
      .reduce((partialSum, a) => partialSum + a, 0);
  }
}
