<div class="filter-area">
  <mat-form-field>
    <mat-label>Filter</mat-label>
    <input
      matInput
      (keyup)="applyFilter($event)"
      placeholder="Filter Cats..."
      #input />
  </mat-form-field>
</div>
<table mat-table [dataSource]="dataSource" matSort>
  <ng-container matColumnDef="help">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <div class="icon-grid">
        <mat-icon
          *ngIf="element.requestAssistance"
          class="assistance warning-row"
          >report</mat-icon
        >
        <app-chips
          class="pro-member"
          *ngIf="element.catProMemberDTO.status === 'Active'"
          >PRO</app-chips
        >
        <app-chips
          class="pro-member"
          *ngIf="element.catProMemberDTO.status === 'Trial'"
          >TRIAL {{ element.catProMemberDTO?.daysRemaining || 0 }}d</app-chips
        >
      </div>
    </td></ng-container
  >

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>Name</th>
    <td mat-cell *matCellDef="let element">
      <strong>{{ element.name }}</strong>
    </td></ng-container
  >

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
    <td mat-cell *matCellDef="let element">
      {{ getStatusTitle(element.status) }}
    </td>
  </ng-container>

  <ng-container matColumnDef="brand">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Brand</th>
    <td mat-cell *matCellDef="let element">{{ element.medicationBrand }}</td>
  </ng-container>

  <ng-container matColumnDef="owner">
    <th mat-header-cell *matHeaderCellDef>Owner</th>
    <td mat-cell *matCellDef="let element">{{ element.ownerName }}</td>
  </ng-container>

  <ng-container matColumnDef="treatmentStartDate">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Start Date</th>
    <td mat-cell *matCellDef="let element">{{ element.treatmentStartDate }}</td>
  </ng-container>

  <ng-container matColumnDef="treatmentEndDate">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>End Date</th>
    <td mat-cell *matCellDef="let element">{{ element.treatmentEndDate }}</td>
  </ng-container>

  <ng-container matColumnDef="location">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Location</th>
    <td mat-cell *matCellDef="let element">{{ getLocation(element) }}</td>
  </ng-container>

  <ng-container matColumnDef="admins">
    <th mat-header-cell *matHeaderCellDef>Admins</th>
    <td mat-cell *matCellDef="let element">
      {{ element.assignedAdmins.join(", ") }}
    </td>
  </ng-container>

  <ng-container matColumnDef="lastOwnerActivity">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Owner Activity</th>
    <td mat-cell *matCellDef="let element">
      {{ element.lastOwnerActivity | timeAgo }}
    </td>
  </ng-container>

  <ng-container matColumnDef="complete">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Complete</th>
    <td mat-cell *matCellDef="let element">
      <mat-icon *ngIf="element.complete">done</mat-icon>
    </td>
  </ng-container>

  <ng-container matColumnDef="lastAdminActivity">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Admin Activity</th>
    <td mat-cell *matCellDef="let element">
      {{ element.lastAdminActivity | timeAgo }}
    </td>
  </ng-container>

  <ng-container matColumnDef="open">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>New Tab</th>
    <td mat-cell *matCellDef="let element">
      <mat-icon [matTooltip]="'Open in new tab'" (click)="openNewTab(element)">open_in_new</mat-icon>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: displayedColumns"
    (click)="goToCat(row)" (contextmenu)="onContextMenu($event, row)"></tr>
  <!-- Row shown when there is no matching data. -->
  <tr class="no-data-row" *matNoDataRow>
    <td class="mat-cell" colspan="6">
      No data matching the filter "{{ input.value }}"
    </td>
  </tr>
</table>
<mat-paginator
  [pageSizeOptions]="[5, 10, 25, 50]"
  showFirstLastButtons
  aria-label="Select page of cats"></mat-paginator>

<div style="visibility: hidden; position: fixed"
     [style.left]="contextMenuPosition.x"
     [style.top]="contextMenuPosition.y"
     [matMenuTriggerFor]="contextMenu">
</div>
<mat-menu #contextMenu="matMenu">
  <ng-template matMenuContent let-item="item">
    <button mat-menu-item (click)="openNewTab(item)">Open in new tab</button>
  </ng-template>
</mat-menu>
