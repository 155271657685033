import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FipAdminApiService } from '../../service/api/fip-admin-api.service';
import { HttpResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from '../modals/confirmation-modal/confirmation-modal.component';
import { catchError, of } from 'rxjs';
import { FormControl, Validators } from '@angular/forms';
import { SnackbarService } from '../../service/snackbar/snackbar.service';
import { AnalyticsService } from '../../service/analytics/analytics.service';
import { ApplicationData, Cat } from '../../model/models';
import { AnalyticsEventEnum, FileType } from '../../model/enums';

export interface EditingFile {
  originalName: string;
  extension: string;
}

@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.scss']
})
export class FilesComponent implements OnInit {
  fileId: string | undefined;
  fileType: FileType | undefined;

  @Input()
  applicationData: ApplicationData | undefined;

  @Input()
  readOnly = false;

  @Input()
  set id(id: string | undefined) {
    if (id) {
      this.fileId = id;
      // This is to fix changing IDs on search, it doesn't duplicate file calls because
      // fileType is undefined on cat load here, but not when switching cats
      this.getCatFiles();
    }
  }

  @Input()
  set type(fileType: FileType | undefined) {
    if (fileType) {
      this.fileType = fileType;
      switch (fileType) {
        case FileType.CAT:
          this.maxSizeInMb = 5;
          break;
        case FileType.CAT_REGISTRATION:
          this.maxSizeInMb = 50;
          break;
        default:
          this.maxSizeInMb = 5;
      }
    }
  }

  selectedFile: File | undefined;

  fileNames: string[] = [];
  fileUrls = new Map();

  maxSizeInMb = 5;

  errorMessage: string | undefined;

  editingFile: EditingFile | undefined;

  editingFileNameControl = new FormControl<string | undefined>(undefined, [
    Validators.required,
    Validators.minLength(1)
  ]);

  loadingImages = new Map<string, boolean>();
  loadedImages = new Map<string, boolean>();

  @ViewChild('fileInput') fileInput!: any;
  fileUploading = false;

  constructor(
    private fipAdminApiService: FipAdminApiService,
    public dialog: MatDialog,
    private snackbarService: SnackbarService,
    private analyticsService: AnalyticsService
  ) {}

  ngOnInit() {
    this.getCatFiles();
  }

  downloadCatFile(fileName: string) {
    if (!this.fileId) {
      console.error('No cat id');
      return;
    }

    if (!this.fileType) {
      console.error('No fileType');
      return;
    }

    if (this.loadedImages.has(fileName)) {
      this.snackbarService.openFailureSnackBar(
        'File has been downloaded. Click open icon.'
      );
      return;
    }

    this.loadingImages.set(fileName, true);

    this.fipAdminApiService
      .downloadCatFile(this.fileType, fileName, this.fileId)
      .subscribe((response: HttpResponse<Blob>) => {
        const contentDisposition = response.headers.get('Content-Disposition');
        if (contentDisposition && response.body) {
          let contentType = response.headers.get('Content-Type');
          if (!contentType) {
            contentType = 'application/octet-stream';
          }
          const fileBlob = new Blob([response.body], {
            type: contentType
          });

          // Create a URL for the blob data
          const fileUrl = URL.createObjectURL(fileBlob);
          this.fileUrls.set(fileName, fileUrl);

          this.loadingImages.delete(fileName);
          this.loadedImages.set(fileName, true);

          /* const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(contentDisposition);
          const fileName =
            matches && matches.length > 1
              ? matches[1].replace(/['"]/g, '')
              : 'download'; */
          //this.openFileViewerModal(fileUrl, fileName);
          //window.open(fileUrl);
        }
      });
  }

  onFileSelected(event: any): void {
    const file = event.target.files[0];
    const fileSizeInMB = file.size / (1024 * 1024); // Convert file size to MB
    if (fileSizeInMB > this.maxSizeInMb) {
      this.snackbarService.openFailureSnackBar(
        `Input size of: ${Math.floor(fileSizeInMB)}MB is greater than allowed ${
          this.maxSizeInMb
        }MB`
      );
      this.analyticsService.sendAnalyticsEvent(
        this.applicationData,
        { id: this.fileId } as Cat,
        AnalyticsEventEnum.FILE_TOO_LARGE,
        file.name + ', ' + 'fileSize: ' + fileSizeInMB.toFixed(2) + 'MB'
      );
    } else {
      this.selectedFile = event.target.files[0];
      this.errorMessage = undefined;
      this.uploadFile();
    }
  }

  uploadFile() {
    if (this.selectedFile && this.fileId && this.fileType) {
      const uploadData = new FormData();
      uploadData.append('file', this.selectedFile, this.selectedFile.name);

      this.fileUploading = true;

      this.fipAdminApiService
        .uploadFile(this.fileType, this.fileId, uploadData)
        .pipe(
          catchError(() => {
            this.clearFile();
            return of(undefined);
          })
        )
        .subscribe(() => {
          if (this.selectedFile?.name) {
            this.fileNames.push(this.selectedFile.name);
            this.fileNames.sort();
            this.clearFile();
            this.snackbarService.openSuccessSnackBar('File uploaded.');
          }
        });
    }
  }

  getCatFiles() {
    if (this.fileId && this.fileType) {
      this.fipAdminApiService
        .getFiles(this.fileType, this.fileId)
        .subscribe((fileNames) => (this.fileNames = fileNames));
    }
  }

  deleteCatFile(fileName: string) {
    if (this.fileId && this.fileType) {
      this.fipAdminApiService
        .deleteCatFile(this.fileType, fileName, this.fileId)
        .subscribe(() => {
          this.fileNames = this.fileNames.filter(
            (existingFileName) => existingFileName !== fileName
          );
          this.snackbarService.openSuccessSnackBar('File deleted.');
        });
    }
  }

  deleteCatFileConfirm($event: MouseEvent, fileName: string) {
    $event.stopPropagation();
    const confirmationDialog = this.dialog.open(ConfirmationModalComponent, {
      minWidth: 350,
      width: '75vw',
      maxWidth: 750,
      ariaModal: true,
      autoFocus: false,
      data: {
        action: `delete ${fileName}`
      }
    });
    confirmationDialog.afterClosed().subscribe((decision: boolean) => {
      if (decision) {
        this.deleteCatFile(fileName);
      }
    });
  }

  openFile(fileUrl: string, fileName: string) {
    // This usually always downloads unless they have the browser setting to open
    // const link = document.createElement('a');
    // link.href = fileUrl;
    // link.download = fileName;
    // link.target = '_blank';
    // link.click();

    // Doesn't actually pass fileName so idk, but hey it works
    window.open(fileUrl, fileName);
  }

  setFileToEdit(fileName: string | null) {
    //const filename = "example_file.jpg";
    if (fileName) {
      const lastIndex = fileName.lastIndexOf('.');
      const extension = fileName.substring(lastIndex + 1);

      const parts = fileName.split('.');
      parts.pop();
      const nameWithoutExtension = parts.join('.');

      this.editingFile = {
        originalName: fileName,
        extension
      };

      this.editingFileNameControl.setValue(nameWithoutExtension);
    }
  }

  private clearFile() {
    this.selectedFile = undefined;
    this.fileInput.nativeElement.value = '';
    this.fileUploading = false;
  }

  saveFileRename(value: any) {
    if (
      this.editingFile &&
      this.editingFileNameControl.value &&
      this.fileType
    ) {
      const newFileName =
        this.editingFileNameControl.value + '.' + this.editingFile.extension;
      this.fipAdminApiService
        .renameFile(
          this.fileType,
          this.fileId,
          this.editingFile.originalName,
          newFileName
        )
        .subscribe((fileNames) => {
          this.fileNames = fileNames;
          this.editingFile = undefined;
          this.editingFileNameControl.setValue(undefined);
          this.snackbarService.openSuccessSnackBar('File renamed.');
        });
    }
  }
}
