import { MedicationBrand, Task } from '../../model/models';
import { environment } from '../../../environments/environment';
import { User } from '@auth0/auth0-angular';
import { catStatusData, State, taskTypeData } from '../../model/constants';
import { HttpResponse } from '@angular/common/http';

export const isUserAdmin = (user: User) => {
  const roles = user[environment.namespace + '/roles'];
  return roles.includes('Admin') || roles.includes('Developer');
};

export const isUserDeveloper = (user: User) => {
  const roles = user[environment.namespace + '/roles'];
  return roles.includes('Developer');
};

export const calculateDayNumber = (startDate: Date, treatmentDate: string) => {
  const date = new Date(treatmentDate);
  date.setHours(0, 0, 0, 0); // Clear time from the treatmentDate
  return Math.ceil(
    (date.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)
  );
};

export const handleAvatarResponse = (avatar: HttpResponse<Blob>) => {
  if (avatar && avatar.body && avatar.body.size > 0) {
    return handleAvatarBlob(avatar.body);
  }
  return undefined;
};

export const handleAvatarBlob = (avatar: Blob) => {
  const blob = new Blob([avatar], { type: 'image/jpeg' });
  return URL.createObjectURL(blob);
};

// From chatGpt, kinda long but it works
export const calculateAge = (birthDate: string) => {
  const birthdateObj = new Date(birthDate);
  const today = new Date();
  let years = today.getFullYear() - birthdateObj.getFullYear();
  let months = today.getMonth() - birthdateObj.getMonth();

  if (today.getDate() < birthdateObj.getDate()) {
    months--;
  }

  if (months < 0) {
    years--;
    months += 12;
  }

  return `${years > 0 ? years + ' years and ' : ''}` + `${months} month(s)`;
};

export const getDateWithoutTimezoneOffset = (dateString: string) => {
  const [year, month, day] = dateString.split('-').map(Number);
  return new Date(year, month - 1, day);
};

export const dueDateSort = (a: Task, b: Task) => {
  return new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime();
};

export const getTaskTitle = (key: string) => {
  // Seems kind of inefficient but it's a small array
  return taskTypeData.find((type) => type.type === key)?.title || 'Unknown';
};

export const getStatusTitle = (status: string | undefined) => {
  if (status) {
    return catStatusData.filter((data) => data.status === status)[0].title;
  }
  return 'Empty';
};

export const calculateProgress = (firstDate: string, secondDate: string) => {
  const startDate = new Date(firstDate);
  const endDate = new Date(secondDate);

  const daysDifference = Math.floor(
    (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)
  );
  let daysSince = Math.floor(
    (new Date().getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)
  );
  let percentage = Math.round((daysSince / daysDifference) * 100);

  if (percentage > 100) {
    percentage = 100;
  }

  if (percentage < 0) {
    percentage = 0;
  }

  if (daysSince < 0) {
    daysSince = 0;
  }

  return {
    percentage,
    totalDays: daysDifference,
    dayNumber: daysSince
  };
};

// Hard coding the list of pills that don't do extra dosage. All the other pills do extra dosage.
// I'm too lazy to put this in a database column because it doesn't change often
export const nonExtraDosagePills = [
  'Harmony 1kg',
  'Harmony 2kg',
  'Harmony 4kg',
  'Rose 2.5mg',
  'Rose 5mg',
  'Rose 10mg',
  'Rose 20mg',
  'Rose 1kg',
  'Rose 2kg',
  'Rose 4kg'
];

export const requisitionMedicationBrands = [
  'Karma',
  'Capella',
  'Rainbow',
  'Fina'
];

export const disabledRequisitionStates = [
  'New York',
  'Maine',
  'Illinois',
  'Massachusetts',
  'Minnesota',
  'New Hampshire',
  'Oregon',
  'Vermont',
  'Wisconsin',
  'California',
  'Georgia'
];

export const isRequisitionMedicationBrand = (name: string | undefined) => {
  for (const requisitionBrandName of requisitionMedicationBrands) {
    if (name?.includes(requisitionBrandName)) {
      return true;
    }
  }
  return false;
};

export const isBrandAssigned = (
  activeBrands: (string | undefined)[],
  medicationBrandCompanies: string[]
) => {
  if (!activeBrands) {
    return false;
  }

  for (const company of medicationBrandCompanies) {
    if (activeBrands.includes(company)) {
      return true;
    }
  }
  return false;
};

export const generateRequisitionBrands = (
  medicationBrands: MedicationBrand[]
) => {
  const brands: MedicationBrand[] = [];
  if (medicationBrands) {
    medicationBrands.filter((medicationBrand) => {
      if (isRequisitionMedicationBrand(medicationBrand.name)) {
        brands.push(medicationBrand);
      }
    });
  }
  return brands;
};

export const findStateByAbbreviation = (
  abbreviation: string,
  states: State[]
) => {
  if (abbreviation.length === 2) {
    return (
      states.find((state) => state.abbreviation === abbreviation.toUpperCase())
        ?.name ?? ''
    );
  }
  return '';
};

export const isEidd = (medicationBrand: MedicationBrand | undefined | null) => {
  return medicationBrand && medicationBrand.name.includes('EIDD');
};
