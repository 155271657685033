import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Requisition } from '../../../model/models';

@Component({
  selector: 'app-requisition-modal',
  templateUrl: './requisition-modal.component.html',
  styleUrls: ['./requisition-modal.component.scss']
})
export class RequisitionModalComponent {
  viewType = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialogRef<RequisitionModalComponent>
  ) {
    this.viewType = this.data?.requisition !== undefined ? 'Edit' : 'New';
  }

  onSave($event: Requisition) {
    this.dialog.close($event);
  }

  onClose() {
    this.dialog.close();
  }
}
