import { Component } from '@angular/core';
import { LoadingService } from '../../service/loading/loading.service';
import { ApplicationDataService } from '../../service/data/application-data.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AnalyticsService } from '../../service/analytics/analytics.service';
import { ApplicationData } from '../../model/models';
import { AnalyticsEventEnum } from '../../model/enums';
import { Router } from '@angular/router';
import { isRequisitionMedicationBrand } from '../../service/utils/ui-utils';

@Component({
  selector: 'app-supplies',
  templateUrl: './supplies.component.html',
  styleUrls: ['./supplies.component.scss']
})
export class SuppliesComponent {
  applicationData: ApplicationData | undefined;
  isTexas: boolean | undefined;
  requisitionMedicationBrands: string[] = [];
  showRequisitions = false;

  constructor(
    private loadingService: LoadingService,
    private analyticsService: AnalyticsService
  ) {
    this.loadingService.setLoading(false);
  }

  goToLink(url: string, analyticsEventEnum: AnalyticsEventEnum) {
    this.analyticsService.sendAnalyticsEvent(
      this.applicationData,
      undefined,
      analyticsEventEnum
    );
    window.location.href = url;
  }

  protected readonly AnalyticsEventEnum = AnalyticsEventEnum;
}
