import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { ApplicationData, Organization, Profile } from '../../../model/models';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AnalyticsService } from '../../../service/analytics/analytics.service';
import { AnalyticsEventEnum } from '../../../model/enums';

@Component({
  selector: 'app-profile-table',
  templateUrl: './profile-table.component.html',
  styleUrls: ['./profile-table.component.scss']
})
export class ProfileTableComponent implements AfterViewInit {
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  dataSource!: MatTableDataSource<Profile>;

  @Input()
  set profiles(profiles: Profile[]) {
    this.dataSource = new MatTableDataSource<Profile>(profiles);
  }

  @Input()
  applicationData: ApplicationData | undefined;

  @Output()
  profileTableClick = new EventEmitter<void>();

  mobileColumns: string[] = ['name', 'city', 'state'];
  desktopColumns: string[] = ['name', 'organizations', 'city', 'state'];
  isMobileSizeScreen = false;

  displayedColumns = this.desktopColumns;

  constructor(
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private analyticsService: AnalyticsService
  ) {
    this.breakpointObserver
      .observe([Breakpoints.XSmall])
      .pipe(takeUntilDestroyed())
      .subscribe((result) => {
        this.isMobileSizeScreen = result.matches;
        if (this.isMobileSizeScreen) {
          this.displayedColumns = this.mobileColumns;
        } else {
          this.displayedColumns = this.desktopColumns;
        }
      });
  }

  goToProfile(profile: Profile) {
    this.profileTableClick.emit();
    this.analyticsService.sendAnalyticsEvent(
      this.applicationData,
      undefined,
      AnalyticsEventEnum.SEARCH_PROFILE_VIEW,
      'Viewing ' + profile.firstName + ', ' + profile.lastName
    );
    void this.router.navigate(['profile', profile.id]);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getOrganizations(organizations: Organization[]) {
    return organizations.map((org) => org.name).join(', ');
  }
}
