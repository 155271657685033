import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MedicalCarePlan, MedicationBrand } from 'src/app/model/models';
import { FipAdminApiService } from 'src/app/service/api/fip-admin-api.service';
import { MatSelectChange } from '@angular/material/select';
import { SnackbarService } from 'src/app/service/snackbar/snackbar.service';
import { treatmentTypes } from '../../../model/constants';

@Component({
  selector: 'app-medical-care-plan-form',
  templateUrl: './medical-care-plan-form.component.html',
  styleUrls: ['./medical-care-plan-form.component.scss']
})
export class MedicalCarePlanFormComponent implements OnInit {
  @Input()
  catId: string | undefined;

  @Input()
  medicalCarePlan: MedicalCarePlan | undefined;

  @Input()
  medicationBrands: MedicationBrand[] | undefined;

  @Input()
  isEnrollment = false;

  @Output()
  medicalCarePlanSave = new EventEmitter<MedicalCarePlan>();

  @Output()
  medicalCarePlanClose = new EventEmitter<void>();

  medicalCarePlanForm = this.formBuilder.group({
    dosage: [
      0,
      [
        Validators.required,
        Validators.pattern('^[0-9]*$'),
        Validators.min(1),
        Validators.maxLength(2)
      ]
    ],
    treatmentType: new FormControl<string | undefined>(
      undefined,
      Validators.required
    ),
    medicationBrand: new FormControl<MedicationBrand | undefined>(undefined),
    secondTreatmentType: new FormControl<string | undefined>(undefined),
    secondMedicationBrand: new FormControl<MedicationBrand | undefined>(
      undefined
    ),
    secondDosage: new FormControl<number | undefined>(undefined, [
      Validators.pattern('^[0-9]*$'),
      Validators.min(1),
      Validators.maxLength(2)
    ])
  });

  filteredSelectionBrands: MedicationBrand[] | undefined;
  secondMedicationBrands: MedicationBrand[] | undefined;

  constructor(
    private formBuilder: FormBuilder,
    private fipAdminApiService: FipAdminApiService,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit() {
    this.medicalCarePlanForm.controls.medicationBrand.disable();
    if (this.medicalCarePlan) {
      this.setFormValues(this.medicalCarePlan);
    }
  }

  private setFormValues(medicalCarePlan: MedicalCarePlan) {
    if (medicalCarePlan.medicationBrand) {
      this.filterBrands(medicalCarePlan.medicationBrand.type);
      const selectedMedicationBrand = this.filteredSelectionBrands?.find(
        (brand) => brand.name == medicalCarePlan.medicationBrand?.name
      );

      this.medicalCarePlanForm.controls.medicationBrand.setValue(
        selectedMedicationBrand
      );
      this.medicalCarePlanForm.controls.treatmentType.setValue(
        selectedMedicationBrand?.type
      );
    }

    if (medicalCarePlan.secondMedicationBrand) {
      this.filterSecondMedicationBrands(
        medicalCarePlan.secondMedicationBrand.type
      );
      const secondMedicationBrand = this.secondMedicationBrands?.find(
        (brand) => brand.name == medicalCarePlan.secondMedicationBrand?.name
      );
      this.medicalCarePlanForm.controls.secondMedicationBrand.setValue(
        secondMedicationBrand
      );
      this.medicalCarePlanForm.controls.secondTreatmentType.setValue(
        secondMedicationBrand?.type
      );
    }
    this.medicalCarePlanForm.controls.dosage.setValue(medicalCarePlan.dosage);
    this.medicalCarePlanForm.controls.secondDosage.setValue(
      medicalCarePlan.secondDosage
    );
    this.medicalCarePlanForm.controls.medicationBrand.enable();
  }

  close() {
    this.medicalCarePlanClose.emit();
  }

  save() {
    const medicalCarePlan: any = {
      ...this.medicalCarePlan,
      ...this.medicalCarePlanForm.getRawValue(),
      catId: this.catId
    };
    if (this.catId) {
      this.fipAdminApiService
        .saveMedicalCarePlan(medicalCarePlan)
        .subscribe((medicalCarePlan: MedicalCarePlan) => {
          this.snackbarService.openSuccessSnackBar('Medical Care Plan Saved!');
          this.medicalCarePlan = medicalCarePlan;
          this.medicalCarePlanSave.emit(medicalCarePlan);
        });
    } else {
      // Just emit this when there is no cat for the registration stuff
      this.medicalCarePlanSave.emit(medicalCarePlan);
    }
  }

  treatmentTypeChange(treatmentType: MatSelectChange) {
    this.filterBrands(treatmentType.value);
    this.medicalCarePlanForm.controls.medicationBrand.reset();
    this.medicalCarePlanForm.controls.medicationBrand.enable();
  }

  secondTreatmentTypeChange(treatmentType: MatSelectChange) {
    this.filterSecondMedicationBrands(treatmentType.value);
    this.medicalCarePlanForm.controls.secondMedicationBrand.reset();
    this.medicalCarePlanForm.controls.secondMedicationBrand.enable();
  }

  filterBrands(treatmentType: string) {
    this.filteredSelectionBrands = this.medicationBrands?.filter(
      (brand) => brand.type === treatmentType
    );
  }

  filterSecondMedicationBrands(secondTreatmentType: string) {
    this.secondMedicationBrands = this.medicationBrands?.filter(
      (brand) => brand.type === secondTreatmentType
    );
  }

  protected readonly treatmentTypes = treatmentTypes;
}
