import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { LoadingService } from 'src/app/service/loading/loading.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent {
  message: string | undefined;
  errorType!: string;

  constructor(
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private loadingService: LoadingService
  ) {
    this.activatedRoute.params.subscribe((params: unknown) => {
      if (typeof params === 'object') {
        this.errorType = (params as { error: string }).error;

        switch (this.errorType) {
          case 'forbidden':
            this.message = 'Forbidden';
            break;
          case 'notFound':
            this.message = 'Page Not Found';
            break;
          default:
            this.message = 'Error';
        }
        this.loadingService.setLoading(false);
      }
    });
  }
}
