import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  AnalyticsEvent,
  Cat,
  ApplicationData,
  CatDate,
  CatTableRow,
  DashboardData,
  Enrollment,
  MedicalCarePlan,
  MedicalRecord,
  Profile,
  SearchResult,
  Task,
  CatRegistration,
  ProfileRegistration,
  Requisition,
  MedicationBrand
} from '../../model/models';
import { Comment } from '../../model/models';
import { CatRegistrationStatus, FileType } from '../../model/enums';
import { BloodWork, BloodWorkMedicalTest } from '../../model/blood-work-models';

@Injectable({
  providedIn: 'root'
})
export class FipAdminApiService {
  constructor(private httpClient: HttpClient) {}

  getApplicationData(): Observable<ApplicationData> {
    return this.httpClient.get<ApplicationData>(
      environment.fipAdminApiUrl + '/dashboard/application'
    );
  }

  getDashboardData() {
    return this.httpClient.get<DashboardData>(
      environment.fipAdminApiUrl + '/dashboard/data'
    );
  }

  getProfile(profileId: string) {
    return this.httpClient.get<Profile>(
      environment.fipAdminApiUrl + '/profile/' + profileId
    );
  }

  getProfileByEmail(email: string) {
    return this.httpClient.get<Profile>(
      environment.fipAdminApiUrl + '/profile/email/' + email
    );
  }

  saveProfile(profile: Profile): Observable<Profile> {
    return this.httpClient.post<Profile>(
      environment.fipAdminApiUrl + '/profile/save',
      profile
    );
  }

  saveCat(cat: Cat): Observable<Cat> {
    return this.httpClient.post<Cat>(
      environment.fipAdminApiUrl + '/cat/save',
      cat
    );
  }

  // Get all cats assigned to a profile
  getCats() {
    return this.httpClient.get<CatTableRow[]>(
      environment.fipAdminApiUrl + `/cat/profile`
    );
  }

  getAdminAssignedCats() {
    return this.httpClient.get<CatTableRow[]>(
      environment.fipAdminApiUrl + `/cat/assigned/admin`
    );
  }

  getCat(catId: string) {
    return this.httpClient.get<Cat>(
      environment.fipAdminApiUrl + '/cat/' + catId
    );
  }

  getMedicalRecords(catId: string) {
    return this.httpClient.get<MedicalRecord[]>(
      environment.fipAdminApiUrl + '/cat/' + catId + '/medicalRecords'
    );
  }

  saveMedicalRecord(medicalRecord: MedicalRecord) {
    return this.httpClient.post<MedicalRecord>(
      environment.fipAdminApiUrl + '/cat/save/medicalRecord',
      medicalRecord
    );
  }

  deleteMedicalRecord(medicalRecordId: string) {
    return this.httpClient.delete(`
      ${environment.fipAdminApiUrl}/cat/delete/medicalRecord/${medicalRecordId}`);
  }

  saveMedicalCarePlan(medicalCarePlan: MedicalCarePlan) {
    return this.httpClient.post<MedicalCarePlan>(
      environment.fipAdminApiUrl + '/cat/save/medicalCarePlan',
      medicalCarePlan
    );
  }

  getMedicalCarePlan(catId: string | undefined) {
    return this.httpClient.get<MedicalCarePlan>(
      environment.fipAdminApiUrl + '/cat/' + catId + '/medicalCarePlan'
    );
  }

  getComments(catId: string | undefined) {
    if (catId) {
      return this.httpClient.get<Comment[]>(
        environment.fipAdminApiUrl + '/cat/' + catId + '/comments'
      );
    } else {
      return of([]);
    }
  }

  getCatDates(catId: string) {
    return this.httpClient.get<CatDate[]>(
      environment.fipAdminApiUrl + '/cat/' + catId + '/dates'
    );
  }

  saveCatDate(catDate: CatDate) {
    return this.httpClient.post<CatDate>(
      environment.fipAdminApiUrl + '/cat/save/date',
      catDate
    );
  }

  saveComment(comment: Comment) {
    return this.httpClient.post<Comment>(
      environment.fipAdminApiUrl + '/cat/save/comment',
      comment
    );
  }

  getAssignableAdmins() {
    return this.httpClient.get<Profile[]>(
      environment.fipAdminApiUrl + '/profile/assignableAdmins'
    );
  }

  saveEnrollment(enrollment: Enrollment) {
    return this.httpClient.post<Enrollment>(
      environment.fipAdminApiUrl + '/enrollment/save',
      enrollment
    );
  }

  getEnrollment(enrollmentId: string) {
    return this.httpClient.get<Enrollment>(
      environment.fipAdminApiUrl + '/enrollment/' + enrollmentId
    );
  }

  getOpenEnrollments() {
    return this.httpClient.get<Enrollment[]>(
      environment.fipAdminApiUrl + '/enrollment/open'
    );
  }

  getSearchResult(searchTerm: string) {
    return this.httpClient.get<SearchResult>(
      environment.fipAdminApiUrl + '/search/' + searchTerm
    );
  }

  checkout(checkoutProduct: any) {
    return this.httpClient.post<any>(
      environment.fipAdminApiUrl + '/checkout/session',
      checkoutProduct
    );
  }

  hasCatPurchased(catId: string, productId: string) {
    return this.httpClient.get<any>(
      environment.fipAdminApiUrl + '/cat/' + catId + '/purchased/' + productId
    );
  }

  patchCatStatus(catId: string, catStatus: string) {
    return this.httpClient.patch<Cat>(
      environment.fipAdminApiUrl + '/cat/' + catId + '/patch/' + catStatus,
      null
    );
  }

  getTasks(catId: string) {
    return this.httpClient.get<Task[]>(
      environment.fipAdminApiUrl + '/task/cat/' + catId
    );
  }

  generateTasks(catId: string, taskType: string) {
    return this.httpClient.get<Task[]>(
      environment.fipAdminApiUrl +
        '/task/cat/' +
        catId +
        '/generate/' +
        taskType
    );
  }

  saveTask(task: any) {
    return this.httpClient.post<Task>(
      environment.fipAdminApiUrl + '/task/cat/save',
      task
    );
  }

  deleteTask(taskId: any) {
    return this.httpClient.delete(`
      ${environment.fipAdminApiUrl}/task/delete/${taskId}`);
  }

  searchAssignProfiles(searchTerm: string) {
    return this.httpClient.get<Profile[]>(
      environment.fipAdminApiUrl + '/search/profile/assign/' + searchTerm
    );
  }

  downloadCatFile(fileType: FileType, fileName: string, id: string) {
    return this.httpClient.get<Blob>(
      environment.fipAdminApiUrl +
        `/file/${fileType}/${id}/download/${fileName}`,
      { observe: 'response', responseType: 'blob' as 'json' }
    );
  }

  uploadFile(fileType: FileType, id: string, uploadData: FormData) {
    return this.httpClient.post<Blob>(
      environment.fipAdminApiUrl + `/file/${fileType}/${id}/upload`,
      uploadData,
      { observe: 'response', responseType: 'blob' as 'json' }
    );
  }

  getFiles(fileType: FileType, id: string) {
    return this.httpClient.get<string[]>(
      environment.fipAdminApiUrl + `/file/${fileType}/${id}/files`
    );
  }

  deleteCatFile(fileType: FileType, fileName: string, id: string) {
    return this.httpClient.delete<void>(
      environment.fipAdminApiUrl +
        `/file/${fileType}/${id}/fileName/${fileName}`
    );
  }

  renameFile(
    fileType: FileType,
    id: string | undefined,
    originalFileName: string,
    newFileName: string | undefined | null
  ) {
    const renameFileDTO = {
      originalFileName,
      newFileName
    };
    return this.httpClient.post<string[]>(
      environment.fipAdminApiUrl + `/file/${fileType}/${id}/files/rename`,
      renameFileDTO
    );
  }

  getPromotionCode(promotionCode: string) {
    return this.httpClient.get<any>(
      environment.fipAdminApiUrl + '/checkout/promotionCode/' + promotionCode
    );
  }

  getProduct(productId: string | undefined) {
    return this.httpClient.get<any>(
      environment.fipAdminApiUrl + '/checkout/product/' + productId
    );
  }

  uploadProfilePicture(profileId: string, uploadData: FormData) {
    return this.httpClient.post<Blob>(
      environment.fipAdminApiUrl + '/profile/' + profileId + '/upload',
      uploadData,
      { observe: 'response', responseType: 'blob' as 'json' }
    );
  }

  getProfileAvatar(profileId: string) {
    return this.httpClient.get<Blob>(
      environment.fipAdminApiUrl + '/profile/' + profileId + '/avatar',
      { observe: 'response', responseType: 'blob' as 'json' }
    );
  }

  uploadCatPicture(catId: string, uploadData: FormData) {
    return this.httpClient.post<Blob>(
      environment.fipAdminApiUrl + '/cat/' + catId + '/upload',
      uploadData,
      { observe: 'response', responseType: 'blob' as 'json' }
    );
  }

  getCatAvatar(catId: string) {
    return this.httpClient.get<Blob>(
      environment.fipAdminApiUrl + '/cat/' + catId + '/avatar',
      { observe: 'response', responseType: 'blob' as 'json' }
    );
  }

  sendAnalyticsEvent(analyticsEvent: AnalyticsEvent) {
    return this.httpClient.post<Task>(
      environment.fipAdminApiUrl + '/analytics/log/event',
      analyticsEvent
    );
  }

  saveCatRegistration(catRegistration: CatRegistration) {
    return this.httpClient.post<CatRegistration>(
      environment.fipAdminApiUrl + '/registration/cat/save',
      catRegistration
    );
  }

  getCatRegistration(catRegistrationId: string) {
    return this.httpClient.get<CatRegistration>(
      environment.fipAdminApiUrl + '/registration/cat/' + catRegistrationId
    );
  }

  getCatRegistrations() {
    return this.httpClient.get<CatRegistration[]>(
      environment.fipAdminApiUrl + '/registration/cat/list'
    );
  }

  getCatRegistrationByStatus(catRegistrationStatus: CatRegistrationStatus) {
    return this.httpClient.get<CatRegistration[]>(
      environment.fipAdminApiUrl +
        '/registration/cat/status/' +
        catRegistrationStatus
    );
  }

  getCatRegistrationsByProfile() {
    return this.httpClient.get<CatRegistration[]>(
      environment.fipAdminApiUrl + '/registration/cat'
    );
  }

  startCatRegistrationReview(catRegistrationId: string) {
    return this.httpClient.post<CatRegistration>(
      environment.fipAdminApiUrl +
        '/registration/cat/review/start/' +
        catRegistrationId,
      undefined
    );
  }

  saveProfileRegistration(profileRegistration: any) {
    return this.httpClient.post<ProfileRegistration>(
      environment.fipAdminApiUrl + '/registration/profile/save',
      profileRegistration
    );
  }

  getProfileRegistration(profileRegistrationId: string) {
    return this.httpClient.get<ProfileRegistration>(
      environment.fipAdminApiUrl +
        '/registration/profile/' +
        profileRegistrationId
    );
  }

  getProfileRegistrationStatus(checkRegistration: {
    id: undefined | null;
    email: undefined | null;
  }) {
    return this.httpClient.post<any>(
      environment.fipAdminApiUrl + '/registration/profile/status',
      checkRegistration
    );
  }

  getProfileRegistrations() {
    return this.httpClient.get<ProfileRegistration[]>(
      environment.fipAdminApiUrl + '/registration/profile/all'
    );
  }

  submitProfileRegistrationReview(profileRegistration: {
    id: string | undefined;
    status: string;
  }) {
    return this.httpClient.post<ProfileRegistration>(
      environment.fipAdminApiUrl + '/registration/profile/review',
      profileRegistration
    );
  }

  saveRequisition(requisition: Requisition) {
    return this.httpClient.post<Requisition>(
      environment.fipAdminApiUrl + '/requisition/save',
      requisition
    );
  }

  getRequisitionCats() {
    return this.httpClient.get<CatTableRow[]>(
      environment.fipAdminApiUrl + '/requisition/cats'
    );
  }

  getRequisitions() {
    return this.httpClient.get<Requisition[]>(
      environment.fipAdminApiUrl + '/requisition'
    );
  }

  getRequisitionPromoCodes(catId: string) {
    return this.httpClient.get<string[]>(
      environment.fipAdminApiUrl + '/requisition/promo/' + catId
    );
  }

  getBloodWorkMedicalTests() {
    return this.httpClient.get<BloodWorkMedicalTest[]>(
      environment.fipAdminApiUrl + '/bloodWork/medical/tests'
    );
  }

  getBloodWork(catId: string) {
    return this.httpClient.get<BloodWork[]>(
      environment.fipAdminApiUrl + `/bloodWork/${catId}`
    );
  }

  saveBloodWork(bloodWork: BloodWork) {
    return this.httpClient.post<BloodWork>(
      environment.fipAdminApiUrl + `/bloodWork/save`,
      bloodWork
    );
  }

  deleteBloodWork(id: string | undefined) {
    return this.httpClient.delete(`
      ${environment.fipAdminApiUrl}/bloodWork/${id}`);
  }

  deleteCat(id: string) {
    return this.httpClient.delete(`
      ${environment.fipAdminApiUrl}/cat/${id}`);
  }

  getProfileAnalytics() {
    return this.httpClient.get<Profile[]>(
      environment.fipAdminApiUrl + `/profile/analytics`
    );
  }

  getCatIdByRegistration(id: string) {
    return this.httpClient.get<Cat>(
      environment.fipAdminApiUrl + `/cat/registration/${id}`
    );
  }

  getMedicationBrands() {
    return this.httpClient.get<MedicationBrand[]>(
      environment.fipAdminApiUrl + '/brands/all'
    );
  }

  getStatusCounts() {
    return this.httpClient.get<
      { status: string; statusCount: number; statusPercentage: number }[]
    >(environment.fipAdminApiUrl + '/analytics/statistics/status');
  }

  getCompleteCats() {
    return this.httpClient.get<any>(
      environment.fipAdminApiUrl + '/analytics/statistics/complete'
    );
  }
}
