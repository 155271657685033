<app-banner *ngIf="isEnrollment" spacing="compact" [icon]="'info_outline'"
  >Medication Brand can be selected later by the owner or you, but you are
  required to save a dosage.</app-banner
>
<form [formGroup]="medicalCarePlanForm" class="container">
  <mat-form-field class="treatment-type">
    <mat-label>Type</mat-label>
    <mat-select
      id="treatmentType"
      formControlName="treatmentType"
      (selectionChange)="treatmentTypeChange($event)">
      <mat-option *ngFor="let type of treatmentTypes" value="{{ type }}">{{
        type
      }}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="medication-brand">
    <mat-label>Medication Brand</mat-label>
    <mat-select id="medicationBrand" formControlName="medicationBrand">
      <mat-option
        *ngFor="let medicationBrand of filteredSelectionBrands"
        [value]="medicationBrand">
        {{ medicationBrand.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="dosage">
    <mat-label>Dosage</mat-label>
    <input
      id="dosage"
      matInput
      placeholder="10"
      appRequiredRoles="Admin"
      formControlName="dosage" />
  </mat-form-field>

  <div class="message">Second Medication if applicable</div>

  <mat-form-field class="second-treatment-type">
    <mat-label>Type</mat-label>
    <mat-select
      id="secondTreatmentType"
      formControlName="secondTreatmentType"
      (selectionChange)="secondTreatmentTypeChange($event)">
      <mat-option *ngFor="let type of treatmentTypes" value="{{ type }}">{{
        type
      }}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="second-medication-brand">
    <mat-label>Medication Brand</mat-label>
    <mat-select
      id="secondMedicationBrand"
      appRequiredRoles="Admin"
      formControlName="secondMedicationBrand">
      <mat-option
        *ngFor="let medicationBrand of secondMedicationBrands"
        [value]="medicationBrand">
        {{ medicationBrand.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="second-dosage">
    <mat-label>Dosage</mat-label>
    <input
      id="secondDosage"
      appRequiredRoles="Admin"
      matInput
      placeholder="10"
      formControlName="secondDosage" />
  </mat-form-field>
</form>
<div class="action-footer">
  <button mat-stroked-button (click)="close()">Close</button>
  <button
    mat-flat-button
    color="primary"
    (click)="save()"
    [disabled]="!medicalCarePlanForm.valid">
    Save
  </button>
</div>
