<div class="page-container">
  <h1>Check Profile Registration Status</h1>

  <p>
    If you want to expedite your registration process or need immediate help,
    please message us on the
    <a href="https://www.facebook.com/profile.php?id=100094351179009"
      >FIP Oasis Facebook page.</a
    >
    with your <strong>email</strong> or <strong>ID</strong>.
  </p>
  <h3>
    Enter your saved <strong>Profile Registration ID</strong> or your
    <strong>email</strong> to check your status.
  </h3>
  <form class="status-form">
    <mat-radio-group
      aria-label="rangeType"
      [formControl]="selection"
      (change)="changeSelection()">
      <mat-radio-button value="id">ID</mat-radio-button>
      <mat-radio-button value="email">Email</mat-radio-button>
    </mat-radio-group>

    <mat-form-field *ngIf="selection.value === 'id'" class="registration-id">
      <mat-label>Profile Registration ID</mat-label>
      <input
        id="registration-id"
        matInput
        placeholder=""
        [formControl]="profileRegistrationId"
        type="text" />
    </mat-form-field>
    <mat-form-field *ngIf="selection.value === 'email'" class="email">
      <mat-label>Email</mat-label>
      <input
        id="email"
        matInput
        placeholder=""
        [formControl]="profileRegistrationEmail"
        type="text" />
    </mat-form-field>
  </form>

  <div class="action-footer">
    <button type="button" mat-stroked-button (click)="goBack()">Back</button>
    <button
      type="submit"
      mat-flat-button
      color="primary"
      (click)="submit()"
      [disabled]="
        !profileRegistrationEmail.valid && !profileRegistrationId.valid
      ">
      Submit
    </button>
  </div>

  <div class="result-area" *ngIf="profileRegistration">
    <h3 *ngIf="profileRegistration.status === 'SUBMITTED'">
      Your Profile registration has been submitted and still awaiting review.
    </h3>
    <h3 *ngIf="profileRegistration.status === 'ACCEPTED'">
      🎉 Your account has been approved! 🎉
      <div>Please click Sign up in the navigation bar and
      <strong
        >create your account using the same email of the approved registration.</strong
      >
        </div>
    </h3>
    <div style="margin-bottom: 0.5rem">
      Profile Registration ID: <strong>{{ profileRegistration.id }}</strong>
    </div>
    <div style="margin-bottom: 0.5rem">
      Email: <strong>{{ profileRegistration.email }}</strong>
    </div>
    <div style="margin-bottom: 0.5rem">
      Submitted on:
      <strong>{{
        profileRegistration.submittedOn | date : "short" : timeZone
      }}</strong>
    </div>
  </div>
</div>
