<div class="page-container">
  <div class="title-row">
    <h1 *ngIf="catName.value && catRegistration?.id" class="page-title">
      {{ catName.value + "'s Registration" }}
    </h1>
    <h1 *ngIf="!catRegistration?.id" class="page-title">
      {{ "New Cat Registration" }}
    </h1>
    <button
      mat-flat-button
      color="warn"
      [disabled]="disableCancelButton()"
      (click)="confirmCancelRegistration($event)">
      Cancel
    </button>
  </div>
  <mat-stepper
    #stepper
    [linear]="true"
    [orientation]="(stepperOrientation | async)!">
    <mat-step
      [stepControl]="basicInfoFormGroup"
      [label]="'Basic Info'"
      state="cat">
      <form [formGroup]="basicInfoFormGroup">
        <div class="category-header">Cat's Info</div>
        <div class="cat-info">
          <mat-form-field class="cat-name">
            <mat-label>Cat Name</mat-label>
            <input id="cat-name" matInput formControlName="catName" />
          </mat-form-field>

          <mat-form-field class="weight">
            <mat-label>Weight</mat-label>
            <input
              id="cat-weight"
              type="number"
              matInput
              formControlName="weight" />
            <span matTextSuffix>lbs</span>
            <mat-hint>Ensure weight is in lbs!</mat-hint>
          </mat-form-field>

          <mat-form-field class="birth-date">
            <mat-label>Birth Date</mat-label>
            <input
              id="cat-birth"
              matInput
              [matDatepicker]="picker"
              formControlName="birthDate" />
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle
              matIconSuffix
              [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>

          <mat-form-field class="color">
            <mat-label>Color</mat-label>
            <input id="color" matInput formControlName="color" />
          </mat-form-field>

          <mat-form-field class="breed">
            <mat-label>Breed</mat-label>
            <mat-select id="cat-breed" formControlName="breed">
              <mat-option *ngFor="let option of catBreeds" [value]="option">
                {{ option }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="category-header">Cat's Location</div>
        <div class="cat-location">
          <mat-form-field class="city">
            <mat-label>City</mat-label>
            <input id="cat-city" matInput formControlName="city" />
          </mat-form-field>

          <mat-form-field class="state">
            <mat-label>State</mat-label>
            <input
              type="text"
              id="state"
              placeholder="State"
              (change)="checkState($event)"
              matInput
              formControlName="state"
              [matAutocomplete]="stateAuto" />
            <mat-autocomplete #stateAuto="matAutocomplete">
              <mat-option
                *ngFor="let option of filteredStates | async"
                [value]="option.name">
                {{ option.name }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <mat-form-field class="zip">
            <mat-label>Postal Code</mat-label>
            <input id="cat-zip" matInput formControlName="zipCode" />
          </mat-form-field>

          <mat-form-field class="country">
            <mat-label>Country</mat-label>
            <mat-select
              id="country"
              formControlName="country"
              (selectionChange)="countryChange($event.value, true)">
              <mat-option
                *ngFor="let country of countries"
                [value]="country.country"
                >{{ country.country }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>

        <div class="category-header">Cat's Household</div>
        <div class="cat-household">
          <mat-form-field class="parent-email">
            <mat-label>Email</mat-label>
            <input id="parent-email" matInput formControlName="email" />
          </mat-form-field>

          <mat-form-field class="first-name">
            <mat-label>Your First Name</mat-label>
            <input
              id="parent-first-name"
              matInput
              formControlName="firstName" />
          </mat-form-field>

          <mat-form-field class="last-name">
            <mat-label>Your Last Name</mat-label>
            <input id="parent-last-name" matInput formControlName="lastName" />
          </mat-form-field>

          <mat-form-field class="household-cats">
            <mat-label># of Household Cats</mat-label>
            <input
              id="household-cats"
              matInput
              type="number"
              min="0"
              formControlName="householdCats" />
          </mat-form-field>
        </div>
        <div class="action-footer">
          <button
            mat-flat-button
            color="primary"
            matStepperNext
            [disabled]="!basicInfoFormGroup.valid || formDisabled"
            (click)="saveRegistration(1)">
            Next
          </button>
        </div>
      </form>
    </mat-step>

    <mat-step
      [stepControl]="diagnosisFormGroup"
      [label]="'Diagnosis Status'"
      state="status">
      <form [formGroup]="diagnosisFormGroup">
        <div class="question">
          <span> Is your cat <strong>eating</strong>? </span>
          <mat-radio-group
            aria-label="Select an option"
            formControlName="eating">
            <mat-radio-button [value]="true">Yes</mat-radio-button>
            <mat-radio-button [value]="false">No</mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="question">
          <span> Is your cat <strong>drinking</strong>? </span>
          <mat-radio-group
            aria-label="Select an option"
            formControlName="drinking">
            <mat-radio-button [value]="true">Yes</mat-radio-button>
            <mat-radio-button [value]="false">No</mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="question">
          <span> Is your cat experiencing <strong>Diarrhea</strong>? </span>
          <mat-radio-group
            aria-label="Select an option"
            formControlName="diarrhea">
            <mat-radio-button [value]="true">Yes</mat-radio-button>
            <mat-radio-button [value]="false">No</mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="question">
          <span> Is your cat experiencing <strong>vomiting</strong>? </span>
          <mat-radio-group
            aria-label="Select an option"
            formControlName="vomiting">
            <mat-radio-button [value]="true">Yes</mat-radio-button>
            <mat-radio-button [value]="false">No</mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="question">
          <span> Is your cat experiencing <strong>fevers</strong>? </span>
          <mat-radio-group
            aria-label="Select an option"
            formControlName="fever">
            <mat-radio-button [value]="true">Yes</mat-radio-button>
            <mat-radio-button [value]="false">No</mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="question">
          <span>
            Is your cat experiencing <strong>trouble in breathing</strong>?
          </span>
          <mat-radio-group
            aria-label="Select an option"
            formControlName="troubleBreathing">
            <mat-radio-button [value]="true">Yes</mat-radio-button>
            <mat-radio-button [value]="false">No</mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="question">
          <span> Has your cat's <strong>energy level changed</strong>? </span>
          <mat-radio-group
            aria-label="Select an option"
            formControlName="energyChanged">
            <mat-radio-button [value]="true">Yes</mat-radio-button>
            <mat-radio-button [value]="false">No</mat-radio-button>
          </mat-radio-group>
        </div>
      </form>
      <div class="action-footer" style="margin-top: 1rem">
        <button mat-stroked-button matStepperPrevious>Go Back</button>
        <button
          mat-flat-button
          color="primary"
          matStepperNext
          [disabled]="!diagnosisFormGroup.valid || formDisabled"
          (click)="saveRegistration(2)">
          Next
        </button>
      </div>
    </mat-step>

    <mat-step
      [stepControl]="symptomsFormGroup"
      [label]="'FIP Symptoms'"
      state="symptoms">
      <form [formGroup]="symptomsFormGroup">
        <div class="symptoms">
          <span> Did your vet indicate a type of <strong>FIP</strong>? </span>
          <mat-form-field class="diseases">
            <mat-label>Diseases</mat-label>
            <mat-select id="diseases" formControlName="diseases" multiple>
              <mat-option
                *ngFor="let option of diseasesSelection"
                [value]="option">
                {{ option }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <span> Do they have <strong>fluid</strong>? </span>
          <mat-radio-group
            aria-label="Select an option"
            formControlName="hasFluid">
            <mat-radio-button [value]="true">Yes</mat-radio-button>
            <mat-radio-button [value]="false">No</mat-radio-button>
          </mat-radio-group>

          <span *ngIf="hasFluid.value"
            >Where is / was the <strong>fluid</strong>?</span
          >
          <mat-form-field *ngIf="hasFluid.value" class="fluids">
            <mat-label>Fluid</mat-label>
            <mat-select id="fluids" formControlName="fluids" multiple>
              <mat-option
                *ngFor="let option of fluidSelection"
                [value]="option">
                {{ option }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <span *ngIf="hasFluid.value">
            How much, if any, has been <strong>removed</strong>? (Liquid amount
            in ML or CC)</span
          >
          <mat-form-field *ngIf="hasFluid.value">
            <input matInput formControlName="fluidRemoved" />
          </mat-form-field>

          <span *ngIf="hasFluid.value">
            What <strong>color</strong> was the removed fluid?
          </span>
          <mat-form-field *ngIf="hasFluid.value">
            <input matInput formControlName="fluidColor" />
          </mat-form-field>

          <span>
            Have you noticed any of the following <strong>eye</strong> issues:
          </span>
          <mat-form-field class="ocularSymptoms">
            <mat-label>Eye issues</mat-label>
            <mat-select
              id="ocularSymptoms"
              formControlName="ocularSymptoms"
              multiple>
              <mat-option
                *ngFor="let option of ocularSelection"
                [value]="option">
                {{ option }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <span>
            Have you noticed any of the following
            <strong>movement</strong> issues:
          </span>
          <mat-form-field class="neuroSymptoms">
            <mat-label>Movement issues</mat-label>
            <mat-select
              id="neuroSymptoms"
              formControlName="neuroSymptoms"
              multiple>
              <mat-option
                *ngFor="let option of neuroSelection"
                [value]="option">
                {{ option }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <div class="category-header">Medications and Vaccines</div>

          <span> Has your cat been fully vaccinated? </span>
          <mat-radio-group
            aria-label="Select an option"
            formControlName="fullyVaccinated">
            <mat-radio-button [value]="true">Yes</mat-radio-button>
            <mat-radio-button [value]="false">No</mat-radio-button>
          </mat-radio-group>

          <span>
            Is your cat currently on any <strong>medications</strong>? If yes,
            please add name, dose and frequency for each.
          </span>
          <button
            *ngIf="!formDisabled"
            style="width: fit-content"
            mat-flat-button
            color="primary"
            (click)="addNewMedication()">
            Add Medication
          </button>

          <div formArrayName="medications">
            <div
              *ngFor="
                let medicationForm of medications.controls;
                let i = index
              ">
              <div class="medication-form" formGroupName="{{ i }}">
                <div class="medication-header">
                  <div>Medication #{{ i + 1 }}</div>
                  <button
                    *ngIf="!formDisabled"
                    mat-icon-button
                    (click)="deleteMedication(i)"
                    matTooltip="Delete Medication">
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>

                <mat-form-field style="margin-right: 0.5rem">
                  <mat-label>Medication Name</mat-label>
                  <input id="name-{{ i }}" matInput formControlName="name" />
                </mat-form-field>
                <mat-form-field style="margin-right: 0.5rem">
                  <mat-label>Dose</mat-label>
                  <input id="dose-{{ i }}" matInput formControlName="dose" />
                </mat-form-field>
                <mat-form-field style="margin-right: 0.5rem">
                  <mat-label>Frequency</mat-label>
                  <mat-select
                    id="frequency-{{ i }}"
                    formControlName="frequency">
                    <mat-option
                      *ngFor="let option of medicationFrequencies"
                      [value]="option">
                      {{ option }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>

          <span>
            Has your cat been <strong>tested</strong> for the following:
          </span>

          <div formArrayName="tests">
            <div *ngFor="let test of tests.controls; let i = index">
              <div [formGroupName]="i">
                <div>
                  <strong>{{ test.value.name }}:</strong>
                  <mat-radio-group
                    aria-label="Select an option"
                    formControlName="tested">
                    <mat-radio-button [value]="true">Yes</mat-radio-button>
                    <mat-radio-button [value]="false">No</mat-radio-button>
                  </mat-radio-group>
                </div>
                <div *ngIf="test.value.tested">
                  <span>Test Result:</span>
                  <mat-radio-group
                    aria-label="Select an option"
                    formControlName="result">
                    <mat-radio-button [value]="'positive'"
                      >Positive
                    </mat-radio-button>
                    <mat-radio-button [value]="'negative'"
                      >Negative
                    </mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>
              <hr />
            </div>
          </div>

          <span>
            Has any type of <strong>GS treatment</strong> been started yet?
          </span>
          <mat-radio-group
            aria-label="Select an option"
            formControlName="gsTreatmentStarted">
            <mat-radio-button [value]="true">Yes</mat-radio-button>
            <mat-radio-button [value]="false">No</mat-radio-button>
          </mat-radio-group>

          <span *ngIf="symptomsFormGroup.controls.gsTreatmentStarted.value">
            What <strong>brand</strong>? If you don't know the name, select
            Generic "GS 15" or "GS 20"
          </span>
          <mat-form-field
            *ngIf="symptomsFormGroup.controls.gsTreatmentStarted.value">
            <mat-label>GS Treatment Brand</mat-label>
            <mat-select
              id="gsTreatmentBrand"
              formControlName="gsTreatmentBrand"
              multiple>
              <mat-option
                *ngFor="let option of applicationData?.medicationBrands"
                [value]="option.name">
                {{ option.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <span>
            Finally, is there anything else in your cat's history you'd like to
            share?
          </span>
          <mat-form-field>
            <input matInput formControlName="comments" />
          </mat-form-field>
        </div>

        <div class="action-footer" style="margin-top: 1rem">
          <button mat-stroked-button matStepperPrevious>Go Back</button>
          <button
            mat-flat-button
            color="primary"
            matStepperNext
            [disabled]="!symptomsFormGroup.valid || formDisabled"
            (click)="saveRegistration(3)">
            Next
          </button>
        </div>
      </form>
    </mat-step>

    <mat-step [label]="'Files'" state="files" [formGroup]="filesFormGroup">
      <div class="files">
        <div style="margin-bottom: 0.5rem">
          Please <strong>upload files</strong> outlined below, they assist
          admins in quickly identifying any issues.
        </div>
        <div style="margin-bottom: 0.5rem">
          <strong>This step is optional and can be done later.</strong>
        </div>
        <small style="margin-bottom: 1.5rem">
          Rename file names to match descriptions to help admins when reviewing.
        </small>

        <div class="category-header">
          <!--<mat-checkbox
            style="margin-right: 0.5rem"
            formControlName="eyes"></mat-checkbox>-->
          1. Close-up photo of your cat's eyes
        </div>
        <small>
          Please capture both eyes straight from the front, facing toward the
          light, no flash.
        </small>
      </div>
      <div class="files">
        <div class="category-header">
          <!-- <mat-checkbox
            style="margin-right: 0.5rem"
            formControlName="walking"></mat-checkbox> -->
          2. 30 second long video of your cat walking.
        </div>
        <small>
          If able, have both perspectives of your cat walking towards you and
          away from you.
        </small>
      </div>
      <div class="files">
        <div class="category-header">
          <!--<mat-checkbox
            style="margin-right: 0.5rem"
            formControlName="miscellaneous"></mat-checkbox>-->
          3. Any Blood Work, lab reports, and Vet notes you may have.
        </div>
        <small>
          If you do not have copies, please contact your veterinarian ASAP and
          have them emailed to you.
        </small>
      </div>

      <app-files
        *ngIf="catRegistration && catRegistration.id"
        [id]="catRegistration.id"
        [readOnly]="formDisabled"
        [type]="FileType.CAT_REGISTRATION"
        [applicationData]="applicationData"></app-files>

      <div class="action-footer" style="margin-top: 1rem">
        <button mat-stroked-button matStepperPrevious>Go Back</button>
        <button
          matStepperNext
          mat-flat-button
          color="primary"
          [disabled]="!filesFormGroup.valid"
          (click)="saveRegistration(4)">
          Next
        </button>
      </div>
    </mat-step>

    <mat-step [label]="'Submit'" state="submitted">
      <div *ngIf="catRegistration?.submittedOn">
        Registration Completed on:
        {{ catRegistration?.submittedOn | date : "short" : timeZone }}
      </div>

      <div class="review-form">
        <div class="medical-care-plan-review-grid">
          <h2>Proposed Medical Care Plan</h2>
          <app-banner
            *ngIf="!felvTested || !fivTested"
            spacing="compact"
            [icon]="'warning'"
            >You have indicated no testing has been done for:
            {{ !felvTested ? "FeLV" : "" }} {{ !fivTested ? "FIV" : "" }}.
            Consider testing for these because if they are positive it will
            change the dosage guidelines.</app-banner
          >
          <h4>
            Brand: <strong>{{ medicalCarePlan?.medicationBrand?.name }}</strong>
          </h4>
          <h4>
            Dosage: <strong>{{ medicalCarePlan?.dosage }} MG/KG</strong>
          </h4>
          <div>This can be changed later on your cat details page.</div>
          <div>
            If you are unsure about the brand or dosage, submit a comment on the
            messages for your cat after submission and an admin will reach out
            to verify.
          </div>
        </div>
        <button
          *ngIf="catRegistration?.status !== CatRegistrationStatus.SUBMITTED"
          mat-flat-button
          color="primary"
          (click)="confirmSubmitRegistration($event)">
          Submit Registration
        </button>
        <button
          *ngIf="catId"
          mat-flat-button
          color="primary"
          [routerLink]="'/cat/' + catId">
          View Cat
        </button>
      </div>
    </mat-step>

    <ng-template matStepperIcon="cat">
      <mat-icon>pets</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="status">
      <mat-icon>monitor_heart</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="symptoms">
      <mat-icon>emergency</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="files">
      <mat-icon>create_new_folder</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="submitted">
      <mat-icon>check_circle</mat-icon>
    </ng-template>
  </mat-stepper>
</div>
