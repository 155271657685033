import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { map } from 'rxjs';
import { isUserAdmin } from '../../service/utils/ui-utils';

export const enrollmentGuard: CanActivateFn = () => {
  const router = inject(Router);
  return inject(AuthService).user$.pipe(
    map((user) => {
      if (user) {
        const canActivate = isUserAdmin(user);

        if (!canActivate) {
          void router.navigate(['dashboard']);
        }
        return canActivate;
      }
      return false;
    })
  );
};
