import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService, User } from '@auth0/auth0-angular';
import { Router } from '@angular/router';
import { FipAdminApiService } from './service/api/fip-admin-api.service';
import { ApplicationDataService } from './service/data/application-data.service';
import { LoadingService } from './service/loading/loading.service';
import { Subject, delay, takeUntil } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  isUserAdmin,
  isUserDeveloper,
  isBrandAssigned,
  requisitionMedicationBrands,
  disabledRequisitionStates
} from './service/utils/ui-utils';
import { ConfettiService } from './service/confetti/confetti.service';
import { ApplicationData } from './model/models';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'fip-admin-ui';
  email: undefined | string;
  loading = false;
  errorMessage: undefined | string;
  destroyed = new Subject<void>();
  user: User | null | undefined = null;

  showConfetti = false;

  // Inject the authentication service into your component through the constructor
  constructor(
    public authService: AuthService,
    private router: Router,
    private fipAdminApiService: FipAdminApiService,
    private applicationDataService: ApplicationDataService,
    public loadingService: LoadingService,
    private confettiService: ConfettiService
  ) {
    this.listenToLoading();
    this.confettiService
      .getShowConfetti()
      .pipe(takeUntilDestroyed())
      .subscribe((showConfetti) => {
        this.showConfetti = showConfetti;
      });
  }

  ngOnInit() {
    this.appSetup();
    this.loadingService.isLoading$
      .pipe(takeUntil(this.destroyed), delay(0))
      .subscribe((value) => {
        this.loading = value;
      });
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }
  /**
   * Listen to the loadingSub property in the LoadingService class. This drives the
   * display of the loading spinner.
   */
  listenToLoading(): void {
    this.loadingService.loadingSub
      .pipe(delay(0), takeUntilDestroyed()) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
      .subscribe((loading) => {
        this.loading = loading;
      });
  }

  appSetup() {
    this.authService.user$.subscribe((user) => {
      if (!user) {
        return;
      }
      this.user = user;
      this.email = user?.email;
      let isAdmin = false;
      let isDeveloper = false;

      if (user) {
        isAdmin = isUserAdmin(user);
        isDeveloper = isUserDeveloper(user);
      }

      // Nesting observables boo but hey It's what we got now, email check will be removed by simply access token
      // But it's not a huge deal
      if (this.email) {
        this.setupApplicationData(isAdmin, isDeveloper);
      } else {
        this.errorMessage = 'Error Email not found';
      }
    });
  }

  private setupApplicationData(isAdmin: boolean, isDeveloper: boolean) {
    this.fipAdminApiService
      .getApplicationData()
      .subscribe((applicationData) => {
        if (applicationData) {
          this.updateOasisState(applicationData, isAdmin, isDeveloper);

          if (!applicationData.profile) {
            void this.router.navigate(['profile', 'new']);
          }
          //this.loadingService.setLoading(false);
        }
      });
  }

  private updateOasisState(
    applicationData: ApplicationData,
    isAdmin: boolean,
    isDeveloper: boolean
  ) {
    const showRequisitions = this.getShowRequisitions(applicationData);

    const {
      activeMedicationBrands,
      profile,
      assignableAdmins,
      medicationBrands,
      assignableOrganizations
    } = applicationData;

    this.applicationDataService.setData({
      email: this.email, // Do we need this or we pull from profile?
      profile,
      activeMedicationBrands,
      assignableAdmins,
      medicationBrands,
      assignableOrganizations,
      isAdmin,
      isDeveloper,
      showRequisitions
    });
  }

  private getShowRequisitions(applicationData: ApplicationData | undefined) {
    if (applicationData) {
      return (
        applicationData?.profile?.country === 'United States' &&
        // They aren't part of the states where no reqs are allowed
        // !disabledRequisitionStates.includes(
        //  applicationData.profile?.state || ''
        //) &&
        isBrandAssigned(
          applicationData.activeMedicationBrands,
          requisitionMedicationBrands
        )
      );
    }
    return false;
  }
}
