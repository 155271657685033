<div>
  <button mat-flat-button color="primary" (click)="openTasksModal(undefined)">
    <mat-icon
      aria-hidden="false"
      aria-label="Create New Task"
      fontIcon="add_tasks"></mat-icon>
    New Task
  </button>

  <button
    style="margin-left: 0.4rem;"
    mat-flat-button
    color="primary"
    (click)="autoGenerateTasks($event)"
  *ngIf="showGenerate()">
    <mat-icon
      aria-hidden="false"
      aria-label="New Status Tasks"
      fontIcon="format_list_bulleted"></mat-icon>
    Auto Blood Work Tasks
  </button>


  <div *ngIf="treatmentTasks.length > 0">
    <div class="task-list-grid" *ngFor="let task of treatmentTasks">
      <ng-template
        *ngTemplateOutlet="taskListRow; context: { task: task }"></ng-template>
    </div>
  </div>

  <div *ngIf="observationTasks.length > 0">
    <div class="task-list-grid" *ngFor="let task of observationTasks">
      <ng-template
        *ngTemplateOutlet="taskListRow; context: { task: task }"></ng-template>
    </div>
  </div>

  <div *ngIf="generalTasks.length > 0">
    <div class="task-list-grid" *ngFor="let task of generalTasks">
      <ng-template
        *ngTemplateOutlet="taskListRow; context: { task: task }"></ng-template>
    </div>
  </div>

  <ng-template #taskListRow let-task="task">
    <mat-icon
      *ngIf="task.resolved"
      color="primary"
      style="font-size: 1rem; margin-top: 1rem">
      check_circle</mat-icon
    >
    <div class="description">
      <app-task-card
        [date]="(task.dueDate | date : 'dd' : timeZone) ?? ''"
        [month]="(task.dueDate | date : 'MMM' : timeZone) ?? ''"
        [description]="task.description"
        [notified]="task.notified"
        [notifiedOn]="task.notifiedOn"
        [isAdmin]="isAdmin"
        [type]="getTaskTitle(task.taskType)"
        (cardClicked)="openTasksModal(task)">
      </app-task-card>
    </div>
    <button
      *ngIf="!task.resolved"
      mat-icon-button
      (click)="deleteTask($event, task)"
      matTooltip="Delete Record">
      <mat-icon class="delete-icon">delete</mat-icon>
    </button>
  </ng-template>
</div>
