import { Component, OnDestroy } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Router } from '@angular/router';
import { ApplicationData, Notification } from '../../../model/models';
import { ApplicationDataService } from '../../../service/data/application-data.service';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { SearchModalComponent } from '../../modals/search-modal/search-modal.component';
import {
  AdminGlobalNotifications,
  NotificationService
} from '../../../service/notification/notification.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { routes } from './routes';
import { AnalyticsService } from '../../../service/analytics/analytics.service';
import { AnalyticsEventEnum } from '../../../model/enums';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnDestroy {
  applicationData: ApplicationData | undefined;
  applicationData$: Subscription;
  notificationSubscription$: Subscription | undefined;
  adminGlobalNotificationsSubscription: Subscription | undefined;

  navbarMobileToggle = false;
  routes = routes;
  isAdmin = false;
  notifications: Notification[] = [];
  adminGlobalNotifications: AdminGlobalNotifications | undefined;

  constructor(
    public authService: AuthService,
    private applicationDataService: ApplicationDataService,
    private router: Router,
    public dialog: MatDialog,
    private notificationService: NotificationService,
    private analyticsService: AnalyticsService
  ) {
    this.applicationData$ = this.applicationDataService
      .getApplicationData()
      .pipe(takeUntilDestroyed())
      .subscribe((applicationData) => {
        this.applicationData = applicationData;

        if (applicationData) {
          this.isAdmin = applicationData.isAdmin;
          this.notificationService.retrieveNotifications();
          this.notificationSubscription$ =
            this.notificationService.notificationSubject.subscribe(
              (notifications) => (this.notifications = notifications)
            );

          if (this.isAdmin) {
            this.adminGlobalNotificationsSubscription =
              this.notificationService.registrationNotificationSubject.subscribe(
                (adminGlobalNotifications) =>
                  (this.adminGlobalNotifications = adminGlobalNotifications)
              );
          }
        }
      });
  }

  ngOnDestroy() {
    this.applicationData$.unsubscribe();
    if (this.notificationSubscription$) {
      this.notificationSubscription$.unsubscribe();
    }
  }

  logOut(): void {
    this.analyticsService.sendAnalyticsEvent(
      this.applicationData,
      undefined,
      AnalyticsEventEnum.SIGN_OUT
    );
    localStorage.clear();
    this.authService.logout({
      logoutParams: { returnTo: window.location.origin + '/home' }
    });
  }

  toggleSideNav() {
    this.navbarMobileToggle = !this.navbarMobileToggle;
  }

  openSearchDialog() {
    const searchDialog = this.dialog.open(SearchModalComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      ariaModal: true,
      backdropClass: 'search-dialog',
      panelClass: 'search-dialog-container'
    });
  }

  redirectToAuth0() {
    // Doesn't work since the redirect is too fast
    /* this.analyticsService.sendAnalyticsEvent(
      this.applicationData,
      undefined,
      AnalyticsEventEnum.LOGIN
    ); */
    this.authService.loginWithRedirect();
  }

  getMenuTitle() {
    if (this.applicationData?.profile) {
      return (
        this.applicationData?.profile?.firstName +
        ' ' +
        this.applicationData?.profile?.lastName
      );
    } else {
      return this.applicationData?.email;
    }
  }
}
