import { BreakpointObserver } from '@angular/cdk/layout';
import { StepperOrientation } from '@angular/cdk/stepper';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { Observable, map, startWith } from 'rxjs';
import { LoadingService } from 'src/app/service/loading/loading.service';
import { FipAdminApiService } from '../../service/api/fip-admin-api.service';
import { CatRegistrationStatus, FileType } from '../../model/enums';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ApplicationData,
  CatRegistration,
  MedicalCarePlan
} from '../../model/models';
import {
  catBreeds,
  countries,
  diseasesSelection,
  State
} from '../../model/constants';
import { ApplicationDataService } from '../../service/data/application-data.service';
import { MatStepper } from '@angular/material/stepper';
import { MedicalCarePlanModalComponent } from '../../components/modals/medical-care-plan-modal/medical-care-plan-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from '../../components/modals/confirmation-modal/confirmation-modal.component';
import { SnackbarService } from '../../service/snackbar/snackbar.service';
import { findStateByAbbreviation } from '../../service/utils/ui-utils';
import { determineDosage } from '../../service/utils/dose-utils';

export interface MedicationControl {
  dose: string;
  name: string;
  frequency: string;
}

export interface TestGroupControls {
  name: FormControl<string | null>;
  tested: FormControl<boolean | null | undefined>;
  result: FormControl<string | null | undefined>;
}

@Component({
  selector: 'app-cat-registration',
  templateUrl: './cat-registration.component.html',
  styleUrls: ['./cat-registration.component.scss']
})
export class CatRegistrationComponent implements OnInit, AfterViewInit {
  protected readonly CatRegistrationStatus = CatRegistrationStatus;
  protected readonly countries = countries;
  protected readonly catBreeds = catBreeds;

  stepperOrientation: Observable<StepperOrientation>;
  @ViewChild('stepper') stepper: MatStepper | undefined;
  applicationData: ApplicationData | undefined;
  catRegistration: CatRegistration | undefined;
  viewType = 'New';
  selectedIndex = 1;

  formDisabled = false;

  // So much work to type these things... holy crap
  felvFormGroup: FormGroup<TestGroupControls> =
    new FormGroup<TestGroupControls>({
      name: new FormControl('Felv'),
      tested: new FormControl(undefined),
      result: new FormControl(undefined)
    });

  fivFormGroup: FormGroup<TestGroupControls> = new FormGroup<TestGroupControls>(
    {
      name: new FormControl('FIV'),
      tested: new FormControl(undefined),
      result: new FormControl(undefined)
    }
  );

  toxoFormGroup: FormGroup<TestGroupControls> =
    new FormGroup<TestGroupControls>({
      name: new FormControl('Toxoplasmoisis'),
      tested: new FormControl(undefined),
      result: new FormControl(undefined)
    });

  basicInfoFormGroup = this._formBuilder.group({
    catName: [undefined, Validators.required],
    weight: [
      undefined,
      [Validators.required, Validators.max(999), Validators.min(1)]
    ],
    birthDate: [undefined, Validators.required],
    breed: [undefined, Validators.required],
    color: [undefined, Validators.required],
    country: new FormControl<string | undefined>(
      'United States',
      Validators.required
    ),
    city: new FormControl<string | undefined>(undefined, Validators.required),
    state: new FormControl<string | undefined>(undefined, Validators.required),
    zipCode: new FormControl<string | undefined>(
      undefined,
      Validators.required
    ),
    email: new FormControl<string | undefined>(undefined, Validators.required),
    firstName: new FormControl<string | undefined>(
      undefined,
      Validators.required
    ),
    lastName: new FormControl<string | undefined>(
      undefined,
      Validators.required
    ),
    householdCats: [undefined, Validators.required]
  });
  diagnosisFormGroup = this._formBuilder.group({
    eating: [undefined, Validators.required],
    drinking: [undefined, Validators.required],
    diarrhea: [undefined, Validators.required],
    vomiting: [undefined, Validators.required],
    fever: [undefined, Validators.required],
    troubleBreathing: [undefined, Validators.required],
    energyChanged: [undefined, Validators.required]
  });
  symptomsFormGroup = this._formBuilder.group({
    diseases: [undefined, Validators.required],
    hasFluid: [undefined, Validators.required],
    fluids: [[]],
    fluidRemoved: [undefined],
    fluidColor: [undefined],
    ocularSymptoms: [undefined, Validators.required],
    neuroSymptoms: [undefined, Validators.required],
    medications: this._formBuilder.array<FormGroup>([]),
    tests: this._formBuilder.array<FormGroup<TestGroupControls>>([
      this.felvFormGroup,
      this.fivFormGroup,
      this.toxoFormGroup
    ]),
    fullyVaccinated: [undefined, Validators.required],
    gsTreatmentStarted: [undefined, Validators.required],
    gsTreatmentBrand: [undefined],
    comments: [undefined]
  });

  filesFormGroup = new FormGroup({
    eyes: new FormControl<boolean>(false),
    walking: new FormControl<boolean>(false),
    miscellaneous: new FormControl<boolean>(false)
  });

  protected readonly diseasesSelection = [...diseasesSelection, 'N/A'];
  fluidSelection = ['Chest', 'Abdomen', 'Both'];
  ocularSelection = [
    'Different size pupils',
    'Cloudy Eyes',
    'Eye color changed',
    'Third Eyelids',
    'Squinty Eyes',
    'Watery Eyes',
    'N/A'
  ];

  neuroSelection = [
    'Trouble walking',
    'Trouble jumping',
    'Wobbliness',
    'Tremors',
    'Seizures',
    'N/A'
  ];

  medicationFrequencies = [
    'As needed',
    'Once a day',
    'Twice a day',
    'Three times a day',
    'Four times a day',
    'Weekly',
    'Every other day'
  ];
  timeZone = 'UTC';

  filteredStates: Observable<State[]>;
  states: State[] = this.countries[0].states.map((state) => state);
  medicalCarePlan: MedicalCarePlan | undefined;

  catId: string | undefined;

  constructor(
    private fipAdminApiService: FipAdminApiService,
    private loadingService: LoadingService,
    private _formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private applicationDataService: ApplicationDataService,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    public dialog: MatDialog,
    private snackBarService: SnackbarService,
    breakpointObserver: BreakpointObserver
  ) {
    this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.stepperOrientation = breakpointObserver
      .observe('(min-width: 751px)')
      .pipe(map(({ matches }) => (matches ? 'horizontal' : 'vertical')));

    this.filteredStates =
      this.basicInfoFormGroup.controls.state.valueChanges.pipe(
        startWith(''),
        map((value) =>
          this.states.filter((state) => {
            value = value || '';
            return state.name.toLowerCase().includes(value.toLowerCase());
          })
        )
      );
  }

  ngOnInit() {
    this.applicationDataService
      .getApplicationData()
      .subscribe((applicationData) => {
        this.applicationData = applicationData;
        // My dumb way of waiting for application data because im too lazy to change the resolver
        if (this.applicationData) {
          this.loadActivatedRouteData();
        }
      });
  }

  loadActivatedRouteData() {
    this.activatedRoute.data.subscribe(({ registration }) => {
      if (registration) {
        //console.log(JSON.parse(registration.data));
        this.catRegistration = registration;
        this.setViewType(registration);

        if (
          this.applicationData?.profile?.id !== this.catRegistration?.createdBy
        ) {
          this.formDisabled = true;
        }
        this.populateViewCat();
        this.populateRegistrationForms();
      } else {
        // No registration saved, lets populate from profile
        this.populateFromAppProfile();
      }
      this.disableProfileInfoFormValues();
      this.loadingService.setLoading(false);
      this.stepThroughForm();
    });
  }

  saveRegistration(completedStep: number) {
    const data = {
      completedStep,
      basicInfo: {
        ...this.basicInfoFormGroup.getRawValue()
      },
      diagnosisInfo: {
        ...this.diagnosisFormGroup.getRawValue()
      },
      symptomsInfo: {
        ...this.symptomsFormGroup.getRawValue()
      },
      filesInfo: {
        ...this.filesFormGroup.getRawValue()
      },
      medicalCarePlan: {
        ...this.medicalCarePlan
      }
    };

    const status = this.catRegistration?.status
      ? this.catRegistration.status
      : CatRegistrationStatus.STARTED;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const registration: CatRegistration = {
      ...this.catRegistration,
      status,
      data: JSON.stringify(data)
    };

    //console.log('---Sending --- ');
    //console.log(JSON.parse(registration.data).diagnosisInfo);

    this.fipAdminApiService
      .saveCatRegistration(registration)
      .subscribe((registration) => {
        const oldId = this.catRegistration?.id;
        this.catRegistration = registration;
        if (!oldId && registration.id) {
          this.snackBarService.openSuccessSnackBar('New Registration saved.');
          void this.router.navigate(['registration', 'cat', registration.id]);
        } else if (registration.status === CatRegistrationStatus.SUBMITTED) {
          this.snackBarService.openSuccessSnackBar(
            'Cat Registration submitted.'
          );
        } else {
          this.snackBarService.openSuccessSnackBar('Cat Registration updated.');
        }
        //console.log('---Response --- ');
        //console.log(JSON.parse(registration.data).diagnosisInfo);
        this.populateRegistrationForms();
      });
  }

  ngAfterViewInit() {
    // This solution is slightly different from enrollment, but still both annoyingly
    // involve using change detection to fix the expression changed
    // //after it was checked error
    this.stepThroughForm();
  }

  stepThroughForm() {
    if (this.stepper && this.catRegistration) {
      for (let i = 0; i < this.selectedIndex; i++) {
        this.stepper.next();
      }
      this.changeDetectorRef.detectChanges();
    }
  }

  private populateRegistrationForms() {
    if (this.catRegistration?.status != CatRegistrationStatus.STARTED) {
      this.formDisabled = true;
    }

    if (this.catRegistration && this.catRegistration.data) {
      const data = JSON.parse(this.catRegistration.data);
      this.selectedIndex = data.completedStep;

      if (data.basicInfo) {
        this.populateBasicInfo(data.basicInfo);
      }
      if (data.diagnosisInfo) {
        this.populateDiagnosisInfo(data.diagnosisInfo);
      }
      if (data.symptomsInfo) {
        this.populateSymptomsInfo(data.symptomsInfo);
        this.setupMedicalCarePlan(data.symptomsInfo);
      }
      if (data.filesInfo) {
        this.populateFilesInfo(data.filesInfo);
      }

      if (
        data.medicalCarePlan &&
        data.medicalCarePlan.dose &&
        data.medicalCarePlan.medicationBrand
      ) {
        this.medicalCarePlan = { ...data.medicalCarePlan };
      }
    }
  }

  private populateBasicInfo(basicInfo: any) {
    const basicInfoControls = this.basicInfoFormGroup.controls;
    basicInfoControls.catName.setValue(basicInfo.catName);
    basicInfoControls.weight.setValue(basicInfo.weight);
    basicInfoControls.birthDate.setValue(basicInfo.birthDate);
    basicInfoControls.breed.setValue(basicInfo.breed);
    basicInfoControls.color.setValue(basicInfo.color);
    basicInfoControls.city.setValue(basicInfo.city);
    basicInfoControls.state.setValue(basicInfo.state);
    basicInfoControls.zipCode.setValue(basicInfo.zipCode);
    basicInfoControls.email.setValue(basicInfo.email);
    basicInfoControls.firstName.setValue(basicInfo.firstName);
    basicInfoControls.lastName.setValue(basicInfo.lastName);
    basicInfoControls.householdCats.setValue(basicInfo.householdCats);

    if (this.formDisabled) {
      this.basicInfoFormGroup.disable();
    }
  }

  private populateDiagnosisInfo(diagnosisInfo: any) {
    const diagnosisInfoControls = this.diagnosisFormGroup.controls;
    diagnosisInfoControls.eating.setValue(diagnosisInfo.eating);
    diagnosisInfoControls.drinking.setValue(diagnosisInfo.drinking);
    diagnosisInfoControls.vomiting.setValue(diagnosisInfo.vomiting);
    diagnosisInfoControls.diarrhea.setValue(diagnosisInfo.diarrhea);
    diagnosisInfoControls.fever.setValue(diagnosisInfo.fever);
    diagnosisInfoControls.troubleBreathing.setValue(
      diagnosisInfo.troubleBreathing
    );
    diagnosisInfoControls.energyChanged.setValue(diagnosisInfo.energyChanged);

    if (this.formDisabled) {
      this.diagnosisFormGroup.disable();
    }
  }

  private populateSymptomsInfo(symptomsInfo: any) {
    const symptomsControls = this.symptomsFormGroup.controls;
    symptomsControls.diseases.setValue(symptomsInfo.diseases);
    symptomsControls.hasFluid.setValue(symptomsInfo.hasFluid);
    symptomsControls.fluids.setValue(symptomsInfo.fluids);
    symptomsControls.fluidRemoved.setValue(symptomsInfo.fluidRemoved);
    symptomsControls.fluidColor.setValue(symptomsInfo.fluidColor);
    symptomsControls.ocularSymptoms.setValue(symptomsInfo.ocularSymptoms);
    symptomsControls.neuroSymptoms.setValue(symptomsInfo.neuroSymptoms);

    this.setupMedicationsFormArray(symptomsInfo.medications);
    this.setupTestsFormArray(symptomsInfo.tests);

    symptomsControls.fullyVaccinated.setValue(symptomsInfo.fullyVaccinated);
    symptomsControls.gsTreatmentStarted.setValue(
      symptomsInfo.gsTreatmentStarted
    );
    symptomsControls.gsTreatmentBrand.setValue(symptomsInfo.gsTreatmentBrand);
    symptomsControls.comments.setValue(symptomsInfo.comments);

    if (this.formDisabled) {
      this.symptomsFormGroup.disable();
    }
  }

  populateFilesInfo(filesInfo: any) {
    this.filesFormGroup.controls.eyes.setValue(filesInfo.eyes);
    this.filesFormGroup.controls.miscellaneous.setValue(
      filesInfo.miscellaneous
    );
    this.filesFormGroup.controls.walking.setValue(filesInfo.walking);

    if (this.formDisabled) {
      this.filesFormGroup.disable();
    }
  }

  setupTestsFormArray(testsData: any) {
    testsData.forEach((test: any) => {
      const existingFormGroup =
        this.symptomsFormGroup.controls.tests.controls.find(
          (testGroup) => testGroup.controls.name.value === test.name
        );
      if (existingFormGroup) {
        existingFormGroup.controls.name.setValue(test.name);
        existingFormGroup.controls.tested.setValue(test.tested);
        existingFormGroup.controls.result.setValue(test.result);
      }
    });
  }

  setupMedicationsFormArray(medicationsData: MedicationControl[]) {
    this.symptomsFormGroup.controls.medications.clear();
    medicationsData.forEach((medication: MedicationControl) => {
      const formGroup = this._formBuilder.group({
        dose: medication.dose,
        frequency: medication.frequency,
        name: medication.name
      });

      if (this.formDisabled) {
        formGroup.disable();
      }

      this.symptomsFormGroup.controls.medications.push(formGroup);
    });
  }

  private setViewType(registration: CatRegistration) {
    if (registration.status == 'STARTED') {
      this.viewType = 'Continue';
    } else if (registration.status === 'CANCELLED') {
      this.viewType = 'Cancelled';
    } else {
      this.viewType = 'View';
    }
  }

  addNewMedication() {
    const medicationForm = this._formBuilder.group({
      name: ['', Validators.required],
      dose: ['', Validators.required],
      frequency: ['', Validators.required]
    });

    this.medications.push(medicationForm);
  }

  get medications(): FormArray {
    return this.symptomsFormGroup.controls.medications;
  }

  get tests(): FormArray {
    return this.symptomsFormGroup.controls.tests;
  }

  get fluids() {
    return this.symptomsFormGroup.controls.fluids;
  }

  get hasFluid() {
    return this.symptomsFormGroup.controls.hasFluid;
  }

  get catName() {
    return this.basicInfoFormGroup.controls.catName;
  }

  get felvTested() {
    return this.symptomsFormGroup.controls.tests.at(0).controls.tested.value;
  }

  get fivTested() {
    return this.symptomsFormGroup.controls.tests.at(1).controls.tested.value;
  }

  deleteMedication(index: number) {
    this.medications.removeAt(index);
  }

  protected readonly FileType = FileType;

  submitRegistration() {
    if (this.catRegistration) {
      this.catRegistration.status = CatRegistrationStatus.SUBMITTED;
      this.saveRegistration(4);
      this.stepper?.next();
    }
  }

  // Duplicate code from profile-form may need to look into grouping these two in one component
  countryChange(country: string, reset: boolean) {
    const states = this.countries.find((c) => c.country === country)?.states;

    if (states) {
      this.states = states.map((state) => state);
    }

    if (reset) {
      this.basicInfoFormGroup.controls.state.reset();
      this.basicInfoFormGroup.controls.zipCode.reset();
    }
  }

  openMedicalCarePlanDialog() {
    const medicalCarePlanDialog = this.dialog.open(
      MedicalCarePlanModalComponent,
      {
        minWidth: 350,
        width: '75vw',
        maxWidth: 750,
        ariaModal: true,
        data: {
          catId: undefined,
          medicationBrands: this.applicationData?.medicationBrands,
          medicalCarePlan: this.medicalCarePlan
        }
      }
    );
    medicalCarePlanDialog
      .afterClosed()
      .subscribe((medicalCarePlan: MedicalCarePlan) => {
        if (medicalCarePlan) {
          this.medicalCarePlan = { ...medicalCarePlan };
        }
      });
  }

  confirmSubmitRegistration($event: MouseEvent) {
    $event.stopPropagation();
    const confirmationDialog = this.dialog.open(ConfirmationModalComponent, {
      minWidth: 350,
      width: '75vw',
      maxWidth: 750,
      ariaModal: true,
      autoFocus: false,
      data: {
        action: `submit the Cat Registration?`,
        subContent: `The form will become read only and a new cat will be created in your dashboard.`
      }
    });
    confirmationDialog.afterClosed().subscribe((decision: boolean) => {
      if (decision && this.catRegistration) {
        this.catRegistration.status = CatRegistrationStatus.SUBMITTED;
        this.saveRegistration(5);
        setTimeout(() => {
          this.populateViewCat();
        }, 800);
      }
    });
  }

  private populateFromAppProfile() {
    if (this.applicationData && this.applicationData.profile) {
      this.basicInfoFormGroup.controls.firstName.setValue(
        this.applicationData.profile.firstName
      );

      this.basicInfoFormGroup.controls.lastName.setValue(
        this.applicationData.profile.lastName
      );

      this.basicInfoFormGroup.controls.city.setValue(
        this.applicationData.profile.city
      );

      this.basicInfoFormGroup.controls.state.setValue(
        this.applicationData.profile.state
      );

      this.basicInfoFormGroup.controls.zipCode.setValue(
        this.applicationData.profile.zipCode
      );

      this.basicInfoFormGroup.controls.country.setValue(
        this.applicationData.profile.country
      );

      this.basicInfoFormGroup.controls.email.setValue(
        this.applicationData.profile.email
      );
    }
  }

  disableProfileInfoFormValues() {
    this.basicInfoFormGroup.controls.email.disable();
    this.basicInfoFormGroup.controls.country.disable();
    this.basicInfoFormGroup.controls.state.disable();
    this.basicInfoFormGroup.controls.city.disable();
    this.basicInfoFormGroup.controls.zipCode.disable();
    this.basicInfoFormGroup.controls.firstName.disable();
    this.basicInfoFormGroup.controls.lastName.disable();
  }

  confirmCancelRegistration($event: MouseEvent) {
    $event.stopPropagation();
    const confirmationDialog = this.dialog.open(ConfirmationModalComponent, {
      minWidth: 350,
      width: '75vw',
      maxWidth: 750,
      ariaModal: true,
      autoFocus: false,
      data: {
        action: `cancel`,
        subContent:
          'This registration will become read only and will not show up on your dashboard anymore.'
      }
    });
    confirmationDialog.afterClosed().subscribe((decision: boolean) => {
      if (decision) {
        this.cancelRegistration();
      }
    });
  }

  cancelRegistration() {
    if (this.catRegistration && this.catRegistration.id) {
      this.catRegistration.status = CatRegistrationStatus.CANCELLED;
      this.saveRegistration(1);
      void this.router.navigate(['dashboard']);
    } else {
      void this.router.navigate(['dashboard']);
    }
  }

  checkState($event: any) {
    this.basicInfoFormGroup.controls.state.setValue(
      findStateByAbbreviation($event.target.value, this.states)
    );
  }

  disableCancelButton() {
    if (this.applicationData?.isDeveloper) {
      return false;
    }
    return this.formDisabled;
  }

  private setupMedicalCarePlan(symptomsInfo: any) {
    let medicationBrandName = 'GS 15';
    if (
      symptomsInfo &&
      symptomsInfo.gsTreatmentStarted &&
      symptomsInfo.gsTreatmentBrand &&
      symptomsInfo.gsTreatmentBrand.length > 0
    ) {
      medicationBrandName = symptomsInfo.gsTreatmentBrand[0];
    }

    const felv = symptomsInfo.tests[0].result === 'positive';
    const fiv = symptomsInfo.tests[1].result === 'positive';
    //debugger;
    let dosage = 0;
    if (symptomsInfo.diseases) {
      dosage = determineDosage(symptomsInfo.diseases, felv, fiv);
    }

    this.medicalCarePlan = {
      dosage,
      medicationBrand: this.applicationData?.medicationBrands.find(
        (brand) => brand.name === medicationBrandName
      )
    };
  }

  private populateViewCat() {
    if (this.catRegistration?.status === CatRegistrationStatus.SUBMITTED) {
      this.fipAdminApiService
        .getCatIdByRegistration(this.catRegistration.id)
        .subscribe((cat) => {
          if (cat) {
            this.catId = cat.id;
          }
        });
    }
  }
}
