<div class="container">
  <div class="header">
    <img
      *ngIf="!avatarUrl"
      src="/assets/pets_black_24dp.svg"
      alt="placeholder image of cat" />
    <img *ngIf="avatarUrl" [src]="avatarUrl" />
    <div class="cat-title">
      <div class="cat-name">
        {{ name }}
        <span class="spacer"></span>
        <mat-icon
          style="font-size: 1rem; width: fit-content; height: fit-content"
          *ngIf="!catProMemberDTO?.status"
          >lock</mat-icon
        >
        <app-chips color="primary" *ngIf="catProMemberDTO?.status === 'Active'"
          >PRO</app-chips
        >
        <app-chips color="primary" *ngIf="catProMemberDTO?.status === 'Trial'"
          >TRIAL {{ catProMemberDTO?.daysRemaining || 0 }}d</app-chips
        >
      </div>
      <div class="cat-subName">{{ subName }}</div>
    </div>
  </div>
  <div class="cat-insights" *ngIf="false">
    <div class="treatment-progress">
      <mat-progress-spinner
        color="primary"
        mode="determinate"
        [value]="treatmentProgress"
        strokeWidth="3"
        diameter="25"></mat-progress-spinner>
      Treatment Progress
    </div>
    <div class="weight-trend">
      Weight Trend:
      <mat-icon color="primary" *ngIf="weightTrend">arrow_upward</mat-icon>
      <mat-icon color="warn" *ngIf="weightTrend">arrow_downward</mat-icon>
    </div>
    <div class="last-daily-dose">
      Last Daily Dose:
      <span class="insight">{{ lastDailyDose }} day(s) ago</span>
    </div>
    <div class="other">Other Trends will go here basically...</div>
  </div>
  <div class="cat-insights" *ngIf="false">
    Purchase PRO on this cat to unlock insights and other capabilities...
  </div>
  <div class="action">
    <div
      [ngStyle]="{
        'margin-bottom': catProMemberDTO?.status === 'Trial' ? '0.5rem' : '0rem'
      }">
      <app-purchase-pro-button
        *ngIf="!catProMemberDTO?.status || catProMemberDTO?.status === 'Trial'"
        [catId]="id"
        [productId]="CAT_FIP_OASIS_PRO_ID"
        [profileId]="profileId"
        [catName]="name"></app-purchase-pro-button>
    </div>
    <button
      mat-flat-button
      color="primary"
      *ngIf="catProMemberDTO?.status"
      (click)="viewCatInfo()">
      View Info
    </button>
  </div>
</div>
