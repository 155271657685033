<h2 style="margin-top: 1rem">{{title}}</h2>
<div style="margin-bottom: 0.5rem" *ngIf="subTitle">{{subTitle}}</div>
<div *ngFor="let registration of registrations">
  <div class="registration-value">
      <div>
        <div>
          <a (click)="goToRegistration(registration.id)">{{
            registration.catName
            }}</a>, {{ registration.dateCreated | date : "short" : timeZone }}, {{ registration.firstName }} {{ registration.lastName }}, {{registration.city}}, {{registration.state}}
        </div>
      </div>
  </div>
</div>
<div *ngIf="registrations.length === 0">Empty.</div>
