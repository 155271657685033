<div class="page-container">
  <div *ngIf="!loading">
    <h1 *ngIf="enrollment.open">{{ viewType }} Enrollment</h1>
    <div class="enrollment_complete" *ngIf="!enrollment.open">
      <mat-icon>check_circle</mat-icon>
      <h1>Enrollment <br />Complete.</h1>
      <button mat-flat-button color="primary" routerLink="/dashboard">
        Go to Dashboard
      </button>
      <button
        mat-flat-button
        color="primary"
        [routerLink]="'/cat/' + enrollment.cat?.id">
        Go to Cat
      </button>
    </div>

    <mat-stepper
      *ngIf="enrollment.open"
      #stepper
      [linear]="true"
      orientation="vertical">
      <mat-step
        [label]="enrollment.profile ? 'Profile Created' : 'Create Profile'"
        [completed]="false"
        state="profile">

        <mat-form-field style="margin-top: 0.5rem;" *ngIf="applicationData?.isAdmin">
          <mat-label>Find existing Profile</mat-label>
          <input
            type="text"
            id="profile"
            matInput
            [formControl]="prefilledProfile"
            [matAutocomplete]="profileAutoComplete" />
          <mat-autocomplete #profileAutoComplete="matAutocomplete" [displayWith]="getProfileDisplayText">
            <mat-option *ngFor="let option of eligibleProfiles" [value]="option" (click)="setPrefilledProfile(option)">
              {{ option.firstName }} {{ option.lastName }}, {{ option.state }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <app-profile-form
          [setProfile]="enrollment.profile"
          [enrollment]="true"
          (profileSave)="saveProfileEnrollment($event)">
        </app-profile-form>
      </mat-step>
      <mat-step
        [label]="enrollment.cat ? 'Cat Created' : 'Create Cat'"
        state="cat"
        [completed]="false">
        <app-cat-form
          *ngIf="!!enrollment.profile"
          [process]="'enrollment'"
          [cat]="enrollment.cat"
          [profiles]="eligibleProfiles"
          (catSave)="saveCatEnrollment($event)">
        </app-cat-form>
      </mat-step>
      <mat-step
        [label]="
          enrollment.medicalCarePlan
            ? 'Medical Care Plan Created'
            : 'Create Medical Care Plan'
        "
        state="carePlan">
        <app-medical-care-plan-form
          *ngIf="enrollment.cat; else finishCat"
          [isEnrollment]="true"
          [medicalCarePlan]="enrollment.medicalCarePlan"
          [medicationBrands]="applicationData?.medicationBrands"
          (medicalCarePlanSave)="saveMedicalCarePlanEnrollment($event)"
          [catId]="enrollment.cat.id">
        </app-medical-care-plan-form>
        <ng-template #finishCat> Please finish creating a cat.</ng-template>
      </mat-step>
      <mat-step *ngIf="!enrollment.open" label="Complete">
        Enrollment Complete!
      </mat-step>

      <ng-template matStepperIcon="profile">
        <mat-icon>person_add</mat-icon>
      </ng-template>
      <ng-template matStepperIcon="cat">
        <mat-icon>pets</mat-icon>
      </ng-template>
      <ng-template matStepperIcon="carePlan">
        <mat-icon fontIcon="health_and_safety">health_and_safety</mat-icon>
      </ng-template>
    </mat-stepper>
  </div>
</div>
