<div class="page-container">
  <div class="container mt-3" *ngIf="requireProfile()">
    Please save a profile before creating a cat.
  </div>

  <div class="mt-3" *ngIf="!requireProfile() && applicationData">
    <!-- Summary details area -->
    <div *ngIf="cat">
      <div class="title-area">
        <div class="edit-area" (click)="openCatFormDialog()">
          <img
            *ngIf="!avatarUrl"
            class="cat-image"
            src="/assets/pets_black_24dp.svg"
            alt="placeholder image of cat" />
          <img *ngIf="avatarUrl" class="cat-image" [src]="avatarUrl" />
        </div>
        <h1 class="name-area">
          {{ cat.name }}
        </h1>
        <div class="chip-area">
          <app-chips *ngIf="cat.catProMemberDTO?.status === 'Active'">
            PRO
          </app-chips>
          <app-chips *ngIf="cat.catProMemberDTO?.status === 'Trial'">
            TRIAL
          </app-chips>
          <app-chips [color]="cat.compliant ? 'primary' : 'warn'">{{
            cat.compliant ? "Compliant" : "Noncompliance"
          }}</app-chips>
          <app-chips>
            {{ getStatusTitle(cat.status) }}
          </app-chips>
          <div
            class="conditions-chips-grid"
            *ngIf="cat.conditions.length > 0">
            <app-chips
              [color]="'accent'"
              *ngFor="let condition of cat.conditions">
              {{ condition }}
            </app-chips>
          </div>
        </div>
      </div>
      <div style="margin-bottom: 1rem">{{ cat.bio }}</div>

      <!-- *********** always show these cards *********** -->
      <div class="always-on-container">
        <div class="card-container" (click)="openCatFormDialog()">
          <div style="display: flex; align-items: center">
            <mat-icon
              aria-hidden="false"
              aria-label="Account"
              fontIcon="person"
              style="margin-right: 0.25rem"
              matTooltip="Account"></mat-icon>
            {{ cat.profile.firstName + " " + cat.profile.lastName }}
          </div>
          <div class="birth-date">
            <mat-icon
              aria-hidden="false"
              aria-label="Age"
              fontIcon="cake"
              style="margin-right: 0.25rem"
              matTooltip="Age"></mat-icon>
            {{ calculateAge(cat.birthDate) }}
          </div>
          <div *ngIf="cat.profile" class="location">
            <mat-icon
              aria-hidden="false"
              aria-label="Location"
              fontIcon="location_on"
              style="margin-right: 0.25rem"
              matTooltip="Location"></mat-icon>
            {{ cat.profile.city }}, {{ cat.profile.state }}
          </div>
          <div class="admins">
            <mat-icon
              aria-hidden="false"
              aria-label="Admin"
              fontIcon="supervisor_account"
              style="margin-right: 0.5rem"
              matTooltip="Assigned Admin(s)"></mat-icon>
            <div style="display: flex; flex-direction: column">
              <div *ngFor="let admin of cat.assignedAdmins">
                {{ admin.firstName }} {{ admin.lastName }}
              </div>
            </div>
          </div>
          <div class="diseases">
            <mat-icon
              aria-hidden="false"
              aria-label="Diseases"
              fontIcon="coronavirus"
              style="margin-right: 0.25rem"
              matTooltip="Diseases"></mat-icon>
            {{ cat.diseases.join(", ") }}
          </div>
        </div>
        <div
          class="card-container"
          (click)="openMedicalCarePlanDialog()">
          <h4 style="margin-bottom: 0.5rem;">Medical Plan</h4>
          <div *ngIf="!medicalCarePlan">None found. Click to create new.</div>
          <div *ngIf="medicalCarePlan?.dosage" class="info">
            <mat-icon
              aria-hidden="false"
              aria-label="Dosage"
              fontIcon="medication_liquid"
              style="margin-right: 0.25rem"
              matTooltip="Dosage"></mat-icon>
            {{ medicalCarePlan?.dosage }} MG/KG
          </div>

          <div *ngIf="medicalCarePlan?.medicationBrand?.type" class=" info">
            <mat-icon
              aria-hidden="false"
              aria-label="Treatment Type"
              fontIcon="vaccines"
              style="margin-right: 0.25rem"
              matTooltip="Treatment Type"></mat-icon>
            {{ medicalCarePlan?.medicationBrand?.type }}
          </div>

          <div *ngIf="medicalCarePlan?.medicationBrand?.name" class=" info">
            <mat-icon
              aria-hidden="false"
              aria-label="Medication Brand"
              fontIcon="medication"
              style="margin-right: 0.25rem"
              matTooltip="Medication Brand"></mat-icon>
            {{ medicalCarePlan?.medicationBrand?.name }}
          </div>
        </div>

        <div
          class="card-container"
          (click)="openCatDatesModal(dateType.TREATMENT)">
          <h4 style="margin-bottom: 0.5rem;">
            <mat-icon>date_range</mat-icon>
            Treatment
          </h4>
          <div>
            <div *ngIf="treatmentDates; else noTreatmentDates">
              <div>
                {{ treatmentDates.startDate | date : "shortDate" }} -
                {{ treatmentDates.endDate | date : "shortDate" }}
              </div>
              <div>
                Day #{{ progress.treatment.dayNumber }},
                {{ progress.treatment.percentage }}%.
              </div>
            </div>
            <ng-template #noTreatmentDates>
              No Treatment dates found.
            </ng-template>
          </div>
        </div>
        <div
          class="card-container"
          *ngIf="observationDates"
          (click)="openCatDatesModal(dateType.OBSERVATION)">
          <h4>
            <mat-icon>date_range</mat-icon>
            Observation
          </h4>
          <div>
            <div>
              {{ observationDates.startDate | date : "shortDate" }} -
              {{ observationDates.endDate | date : "shortDate" }}
            </div>
            <div>
              Day #{{ progress.observation.dayNumber }},
              {{ progress.observation.percentage }}%.
            </div>
          </div>
        </div>
      </div>

      <!-- *********** always show these tabs *********** -->
      <mat-tab-group
        class="cat-tab-group"
        mat-stretch-tabs="false"
        fitInkBarToContent="true"
        animationDuration="0">
        <mat-tab label="Daily Dose">
          <app-banner
            spacing="compact"
            style="margin-bottom: 1rem; font-size: small">
            Calculate your daily dose by clicking New Record, input the brand,
            weight, then click Calculate Dose.
          </app-banner>

          <button
            type="submit"
            mat-flat-button
            color="primary"
            (click)="openMedicalRecord(undefined)">
            <mat-icon
              aria-hidden="false"
              aria-label="Create New Record"
              fontIcon="note_add"></mat-icon>
            New Record
          </button>

          <div
            style="margin-top: 1rem"
            *ngIf="medicalRecords && medicalRecords.length <= 0">
            No Medical Records found.
          </div>

          <app-medical-record-table
            *ngIf="medicalRecords.length > 0"
            [medicalRecords]="medicalRecords"
            [startDate]="treatmentDates?.startDate"
            (recordDeleted)="medicalRecordDeleted()"
            (recordSelected)="
              openMedicalRecord($event)
            "></app-medical-record-table>
        </mat-tab>
        <mat-tab label="Tasks">
          <app-task-list
            [tasks]="tasks"
            [catStatus]="cat.status"
            [catId]="catId"
            [isAdmin]="applicationData.isAdmin"
            [taskTitle]="'Treatment'"
            (treatmentTaskUpdate)="getCat(catId)">
          </app-task-list>
        </mat-tab>
        <mat-tab label="Files">
          <app-files
            [id]="catId"
            [type]="FileType.CAT"
            [applicationData]="applicationData"></app-files>
        </mat-tab>
        <mat-tab label="Blood Work">
        <app-blood-work [id]="catId"></app-blood-work>
        </mat-tab>
      </mat-tab-group>

      <div *ngIf="!cat">
        <app-cat-form
          [profiles]="eligibleProfiles"
          [cat]="cat"
          (catSave)="onCatSave($event)"
          (catFormClose)="onCatClose()">
        </app-cat-form>
      </div>
    </div>
  </div>
  <div class="chat">
    <button
      mat-raised-button
      color="primary"
      class="chat-button"
      (click)="toggleChatWindow()"
      cdkOverlayOrigin
      #trigger="cdkOverlayOrigin">
      <span *ngIf="!showChatWindow">
        <mat-icon>forum</mat-icon>
      </span>
      <span *ngIf="showChatWindow">
        <mat-icon>close</mat-icon>
      </span>
    </button>
  </div>
</div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="showChatWindow"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayFlexibleDimensions]="true"
  [cdkConnectedOverlayOffsetY]="-5"
  [cdkConnectedOverlayOffsetX]="8"
  [cdkConnectedOverlayScrollStrategy]="scrollStrategy"
  (backdropClick)="toggleChatWindow()">
  <div class="chat-window">
    <app-app-comments
      [comments]="comments"
      [catId]="catId"
      (updatedComments)="updateComments($event)"></app-app-comments>
  </div>
</ng-template>
