import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { FipAdminApiService } from '../../service/api/fip-admin-api.service';
import {
  CatModalData,
  ApplicationData,
  Enrollment,
  MedicalCarePlan,
  Profile
} from '../../model/models';
import { ActivatedRoute, Router } from '@angular/router';
import { MatStepper } from '@angular/material/stepper';
import {
  debounceTime,
  distinctUntilChanged,
  of,
  startWith,
  Subscription,
  switchMap
} from 'rxjs';
import { ApplicationDataService } from '../../service/data/application-data.service';
import { LoadingService } from '../../service/loading/loading.service';
import { FormControl } from '@angular/forms';
import { ConfettiService } from '../../service/confetti/confetti.service';

@Component({
  selector: 'app-enrollment',
  templateUrl: './enrollment.component.html',
  styleUrls: ['./enrollment.component.scss']
})
export class EnrollmentComponent implements OnInit, OnDestroy {
  applicationData: ApplicationData | undefined;
  applicationData$: Subscription | undefined;

  @ViewChild('stepper') stepper: MatStepper | undefined;
  enrollmentId: string | undefined;
  enrollment: Enrollment = {
    open: true
  }; // We can null everything out here and it could still save
  viewType = '';
  loading = true; // For loading the initial data
  saving = false; // For a loading the saves on each step
  eligibleProfiles: Profile[] = [];

  prefilledProfile = new FormControl<Profile | undefined>(undefined);

  constructor(
    private fipAdminApiService: FipAdminApiService,
    private activatedRoute: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    private applicationDataService: ApplicationDataService,
    private router: Router,
    private loadingService: LoadingService,
    private confettiService: ConfettiService
  ) {}

  ngOnInit() {
    this.applicationData$ = this.applicationDataService
      .getApplicationData()
      .subscribe((applicationData) => {
        if (applicationData) {
          this.applicationData = applicationData;
        }
      });

    this.activatedRoute.data.subscribe(({ enrollment }) => {
      if (enrollment) {
        this.enrollment = enrollment;
        if (this.enrollment && this.enrollment.id) {
          this.enrollmentId = this.enrollment.id;
          this.viewType = '';

          this.loading = false;
          if (this.enrollment.open) {
            this.setupEnrollment();
          } else {
            this.confettiService.celebrateWithConfetti(false);
          }
        }
      } else {
        this.viewType = '';
        this.loading = false;
      }

      this.loadingService.setLoading(false);
    });

    this.setupEligibleProfilesSearch();
  }

  ngOnDestroy() {
    if (this.applicationData$) {
      this.applicationData$.unsubscribe();
    }
    this.confettiService.endConfetti();
  }

  private setupEnrollment() {
    if (this.enrollment.profile) {
      this.eligibleProfiles = [this.enrollment.profile];
    }

    // This is so the stepper isn't undefined when setting loading to false
    this.changeDetectorRef.detectChanges();

    if (this.enrollment && this.stepper) {
      if (this.enrollment.profile) {
        this.moveForward();
      }
      // If they have a cat go last step
      if (this.enrollment.cat) {
        this.moveForward();
      }
      if (this.enrollment.medicalCarePlan) {
        this.moveForward();
      }
    }
  }

  saveProfileEnrollment(profile: Profile) {
    this.enrollment.profile = profile;
    this.eligibleProfiles = [profile];
    this.enrollment.open = true;
    this.saveAndMoveForward();
  }

  saveCatEnrollment(data: CatModalData) {
    if (data.cat) {
      this.enrollment.cat = data.cat;
      this.saveAndMoveForward();
    }
  }

  saveMedicalCarePlanEnrollment(medicalCarePlan: MedicalCarePlan) {
    this.enrollment.medicalCarePlan = medicalCarePlan;
    this.enrollment.open = false;
    this.saveAndMoveForward();
  }

  private saveAndMoveForward() {
    // TODO: Get this on the save buttons? Pass it in?
    this.saving = true;
    // Need to make sure the ID gets stored
    this.fipAdminApiService
      .saveEnrollment(this.enrollment)
      .subscribe((enrollment) => {
        // we created a new enrollment, lets update the url
        if (this.enrollment.id == null && enrollment.id != null) {
          void this.router.navigate(['enrollment/' + enrollment.id]);
        }
        this.enrollment = enrollment;
        this.saving = false;
        this.moveForward();
      });
  }

  private moveForward() {
    if (this.stepper && this.stepper.selected) {
      // This doesn't work?? Neither do the icons lol
      this.stepper.selected.completed = true;
      this.stepper.next();
    }

    if (!this.enrollment.open) {
      this.confettiService.celebrateWithConfetti(false);
    }
  }

  private setupEligibleProfilesSearch() {
    this.prefilledProfile.valueChanges
      .pipe(
        debounceTime(400),
        distinctUntilChanged(),
        startWith(''),
        switchMap((value) => {
          if (typeof value === 'string' && value.length > 0) {
            return this.fipAdminApiService.searchAssignProfiles(value);
          }
          return of(null);
        })
      )
      .subscribe((profiles) => {
        if (profiles) {
          this.eligibleProfiles = profiles;
        }
      });
  }

  getProfileDisplayText(option: Profile): string {
    if (option) {
      return option.firstName + ' ' + option.lastName + ', ' + option.state;
    }
    return '';
  }

  setPrefilledProfile(selectedProfile: Profile) {
    this.saveProfileEnrollment(selectedProfile);
  }
}
