import { Component, Input } from '@angular/core';
import { Notification } from '../../model/models';
import { NotificationService } from '../../service/notification/notification.service';
import { notificationStatus } from '../../model/constants';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notification-menu',
  templateUrl: './notification-menu.component.html',
  styleUrls: ['./notification-menu.component.scss']
})
export class NotificationMenuComponent {
  @Input()
  notifications: Notification[] = [];

  constructor(
    private notificationService: NotificationService,
    private router: Router
  ) {}

  getAction(type: string) {
    if (type === 'COMMENT') {
      return 'commented';
    }
    return type;
  }

  clearNotifications() {
    this.notificationService
      .clearAllNotifications()
      .subscribe((notifications) => {
        this.notifications = notifications;
        this.notificationService.notificationSubject.next(notifications);
      });
  }

  clearSingleNotification(notificationId: string) {
    this.notificationService
      .clearSingleNotification(notificationId)
      .subscribe(() => {
        this.notifications = this.notifications.filter(
          (notification) => notification.id !== notificationId
        );
        this.notificationService.notificationSubject.next(this.notifications);
      });
  }

  protected readonly notificationStatus = notificationStatus;

  goToRequisitions() {
    void this.router.navigate(['requisitions']);
  }

  goToCat(catId: string) {
    void this.router.navigate(['cat', catId]);
  }
}
