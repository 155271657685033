<form [formGroup]="medicalRecordForm" class="container">
  <mat-form-field class="treatment-type">
    <mat-label>Type</mat-label>
    <mat-select
      id="treatmentType"
      formControlName="treatmentType"
      (selectionChange)="treatmentTypeChange($event.value)">
      <mat-option *ngFor="let type of treatmentTypes" value="{{ type }}">{{
        type
      }}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field hintLabel="MM/DD/YYYY" class="treatment-date">
    <mat-label>Treatment Date</mat-label>
    <input
      id="treatmentDate"
      matInput
      [matDatepicker]="treatmentDate"
      formControlName="treatmentDate" />
    <mat-datepicker-toggle
      matIconSuffix
      [for]="treatmentDate"></mat-datepicker-toggle>
    <mat-datepicker #treatmentDate></mat-datepicker>
  </mat-form-field>
  <mat-form-field class="treatment-time">
    <mat-label>Time</mat-label>
    <input
      id="treatmentTime"
      type="time"
      matInput
      formControlName="treatmentTime" />
  </mat-form-field>

  <mat-form-field class="medication-brand">
    <mat-label>Medication Brand</mat-label>
    <mat-select
      id="medicationBrand"
      formControlName="medicationBrand"
      (selectionChange)="medicationBrandChange()">
      <mat-option
        *ngFor="let medicationBrand of filteredSelectionBrands"
        [value]="medicationBrand">
        {{ medicationBrand.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div *ngIf="medicalRecordForm.controls.medicationBrand.value" class="details">
    <div>
      <span>Concentration:</span>
      <span class="primary-color">
        {{ medicalRecordForm.controls.concentration.value }}
        {{ medicalRecordForm.controls.medicationBrand.value.unit }}
      </span>
    </div>
    <div>
      <span>Dosage:</span>
      <span class="primary-color">
        {{ medicalRecordForm.controls.dosage.value }} MG/KG
        {{ secondaryDosage ? "(Secondary)" : "" }}
      </span>
    </div>
  </div>

  <mat-radio-group
    *ngIf="treatmentType.value === 'Pill'"
    aria-label="Frequency"
    formControlName="frequency"
    (change)="calculateDose()"
    class="frequency">
    <mat-radio-button value="1">Once a Day</mat-radio-button>
    <mat-radio-button value="2">Twice a Day</mat-radio-button>
  </mat-radio-group>

  <div class="weight">
    <small>
      <mat-icon> warning </mat-icon>
      If your cat loses weight, do not lower the dose.
    </small>
    <mat-form-field>
      <mat-label>Weight</mat-label>
      <input
        id="weight"
        type="number"
        matInput
        placeholder="0"
        formControlName="weight"
        (change)="dose.reset()" />
      <mat-button-toggle-group
        name="weightUnit"
        aria-label="weightUnit"
        matSuffix
        (change)="onUnitChange()"
        formControlName="weightUnit"
        style="height: 44px">
        <mat-button-toggle value="kg">kg</mat-button-toggle>
        <mat-button-toggle value="lb">lb</mat-button-toggle>
      </mat-button-toggle-group>
    </mat-form-field>
  </div>

  <mat-form-field class="dose">
    <mat-label>Dose</mat-label>
    <div class="dose-input">
      <input id="dose" type="number" matInput formControlName="dose" />
      <span style="align-self: center; justify-self: start">{{
        doseUnit.value
      }}</span>
      <span
        style="align-self: center"
        *ngIf="
          treatmentType.value === 'Pill' &&
          !medicationBrand.value?.type?.includes('EIDD')
        "
        matTextSuffix>
        or {{ pillCount }} Pills
      </span>
    </div>
    <button
      mat-flat-button
      matSuffix
      color="primary"
      style="height: 45px"
      (click)="calculateDose()"
      [disabled]="!(weight.value && medicationBrand.value)">
      <mat-icon>calculate</mat-icon>
      Calculate
    </button>
  </mat-form-field>

  <mat-form-field class="comments">
    <mat-label>Comments</mat-label>
    <textarea
      matInput
      [maxlength]="maxCommentLength"
      cdkTextareaAutosize
      draggable="false"
      formControlName="comment"
      cdkAutosizeMinRows="3"
      cdkAutosizeMaxRows="30"></textarea>
  </mat-form-field>
</form>

<div class="action-footer">
  <button mat-stroked-button (click)="close()">Close</button>
  <button
    mat-flat-button
    color="primary"
    (click)="save()"
    [disabled]="!this.medicalRecordForm.valid">
    Save
  </button>
</div>
