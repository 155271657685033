import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { map } from 'rxjs';

export const dashboardGuard: CanActivateFn = (route, state) => {
  // This guard is only here to navigate the unauthenticated catch all back to home to log in
  const router = inject(Router);
  return inject(AuthService).isAuthenticated$.pipe(
    map((authenticated) => {
      if (authenticated) {
        return true;
      }
      void router.navigate(['home']);
      return false;
    })
  );
};
