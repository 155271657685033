<div class="page-container">
  <div style="display: flex; align-items: flex-start">
    <h1 style="margin-right: 1rem">Dashboard</h1>
    <app-chips *ngIf="applicationData?.profile">
      {{ applicationData?.isAdmin ? "Admin" : "Pet Parent" }}
    </app-chips>
  </div>
  <app-banner
    [icon]="'info'"
    spacing="compact"
    style="margin-bottom: 2rem"
    *ngIf="!applicationData?.profile">
    Please save a profile under My Profile to continue using FIP Oasis.
  </app-banner>

  <div *ngIf="applicationData?.profile">
    <div *ngIf="applicationData?.isAdmin">
      <button
        style="margin-bottom: 1rem"
        type="submit"
        mat-flat-button
        color="primary"
        (click)="startNewEnrollment()">
        <mat-icon
          aria-hidden="false"
          aria-label="Start Enrollment"
          fontIcon="note_add"></mat-icon>
        Start Enrollment
      </button>

      <div class="cards">
        <app-at-a-glance-card
          [number]="dashboardData?.openEnrollments?.length"
          [title]="'Enrollments'"
          [description]="'open enrollment(s) currently.'"
          (cardClicked)="openEnrollmentsGlanceCard()"></app-at-a-glance-card>

        <app-at-a-glance-card
          [number]="dashboardData?.totalUpcomingTasks"
          [title]="'Upcoming Tasks'"
          [description]="'task(s) due in the next few days.'"
          (cardClicked)="
            openTaskCard(dashboardData?.upcomingTasks, 'Tasks Due Soon')
          "></app-at-a-glance-card>

        <app-at-a-glance-card
          [number]="dashboardData?.requireAssistanceCats?.length"
          [title]="'Assistance'"
          [description]="'cat(s) requested assistance.'"
          (cardClicked)="
            openRequestHelpGlanceCard(dashboardData?.requireAssistanceCats)
          "></app-at-a-glance-card>

        <app-at-a-glance-card
          [number]="dashboardData?.pendingTreatmentCats?.length"
          [title]="'Not started'"
          [description]="'cat(s) haven\'t logged records.'"
          (cardClicked)="
            openCatCard(
              dashboardData?.pendingTreatmentCats,
              'Cats with no records logged'
            )
          "></app-at-a-glance-card>

        <app-at-a-glance-card
          [number]="dashboardData?.pendingObservationCats?.length"
          [title]="'Pending Observation'"
          [description]="'cat(s) ready to start observation.'"
          (cardClicked)="
            openCatCard(
              dashboardData?.pendingObservationCats,
              'Cats Ready for Observation'
            )
          "></app-at-a-glance-card>

        <app-at-a-glance-card
          [number]="dashboardData?.pendingCuredCats?.length"
          [title]="'Pending Cured'"
          [description]="'cat(s) finished observation.'"
          (cardClicked)="
            openCatCard(
              dashboardData?.pendingCuredCats,
              'Cats Ready to be Cured'
            )
          "></app-at-a-glance-card>

        <!-- This is not a good card.. need to revisit it later. -->
        <!--<app-at-a-glance-card
        [number]="dashboardData?.inactiveCats?.length"
        [title]="'Stopped'"
        [description]="'cat(s) without new user activity.'"
        (cardClicked)="
          openCatCard(dashboardData?.inactiveCats, 'Cats Stopped')
        "></app-at-a-glance-card> -->
      </div>
    </div>

    <div style="margin-top: 1rem" *ngIf="applicationData?.isAdmin">
      <h2>Cats</h2>
      <app-cat-table [cats]="cats" [applicationData]="applicationData">
      </app-cat-table>
    </div>

    <!-- Non-admin dashboard -->

    <div *ngIf="applicationData && !applicationData?.isAdmin">
      <div class="quick-info">
        <div>Information about Treatment Supplies and Products below.</div>
        <div class="quick-info-button-row">
          <button mat-stroked-button (click)="goToFacebook()">
            <mat-icon>support</mat-icon>
            Facebook Support Group
          </button>
          <button mat-stroked-button (click)="goToSupplies()">
            <mat-icon>inventory</mat-icon>
            Healthy Cat Store
          </button>
          <button mat-stroked-button (click)="goToProducts()">
            <mat-icon>medication_liquid</mat-icon>
            Treatment Products
          </button>
          <button mat-stroked-button (click)="goToHandbook()">
            <mat-icon>menu_book</mat-icon>
            FIP Oasis Treatment Handbook
          </button>
        </div>
      </div>
      <div *ngIf="cats?.length !== 0">
        <h2>Cats</h2>
        <div class="cat-cards-container">
          <app-cat-card
            *ngFor="let cat of cats"
            [avatarUrl]="catImageUrls.get(cat.id)"
            [name]="cat.name"
            [subName]="cat.city + ', ' + cat.state"
            [applicationData]="applicationData"
            [weightTrend]="true"
            [treatmentProgress]="72"
            [id]="cat.id"
            [profileId]="applicationData.profile?.id"
            [catProMemberDTO]="cat.catProMemberDTO"
            [lastDailyDose]="2"></app-cat-card>
        </div>
      </div>

      <!--<div class="support-us">
      <h2>Support us</h2>
      <div>Purchase a pro membership for your cat.</div>
      <div>
        <button mat-flat-button color="primary" (click)="supportUs()">
          <mat-icon>volunteer_activism</mat-icon>
          Support us
        </button>
      </div>
    </div> -->
      <app-banner *ngIf="cats?.length === 0" [icon]="'warning'" spacing="compact" style="margin-bottom: 1rem"
      >For quick assistance, please complete a cat registration to enroll your cat and start a chat with the admins.
      </app-banner>
      <app-banner *ngIf="cats?.length === 0" [icon]="'info'" spacing="compact" style="margin-bottom: 1rem"
      >Responses may be delayed outside regular business hours. Email support@fipoasis.com for faster response times.
      </app-banner>

      <div>
        <h2>Cat Registrations</h2>
        <button
          *ngIf="cats?.length === 0"
          style="margin-bottom: 1rem"
          mat-flat-button
          color="primary"
          (click)="confirmNewCatRegistration($event)">
          <mat-icon
            aria-hidden="false"
            aria-label="New Cat Registration"
            fontIcon="pets"></mat-icon>
          New Cat Registration
        </button>
        <button
          *ngIf="cats?.length !== 0"
          style="margin-bottom: 1rem"
          mat-stroked-button
          color="secondary"
          (click)="confirmNewCatRegistration($event)">
          <mat-icon
            aria-hidden="false"
            aria-label="New Cat Registration"
            fontIcon="pets"></mat-icon>
          New Cat Registration
        </button>
        <mat-card
          class="registration-card"
          *ngFor="let registration of openCatRegistrations">
          <mat-card-content>
            <h3>{{ registration.data.basicInfo.catName }}</h3>
            <div>Status: {{ registration.status }}</div>
            <div>
              Created:
              {{ registration.dateCreated | date : "short" : timeZone }}
            </div>
            <div *ngIf="registration.submittedOn">
              Submitted:
              {{ registration.submittedOn | date : "short" : timeZone }}
            </div>
            <div>
              <button
                mat-flat-button
                color="primary"
                (click)="continueCatRegistration(registration.id)">
                {{
                  registration.status === "STARTED" ? "Continue" : "View Info"
                }}
              </button>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</div>
