import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Enrollment } from 'src/app/model/models';
import { getTaskTitle } from '../../../service/utils/ui-utils';

@Component({
  selector: 'app-glance-modal',
  templateUrl: './glance-modal.component.html',
  styleUrls: ['./glance-modal.component.scss']
})
export class GlanceModalComponent {
  timeZone = 'UTC';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialogRef<GlanceModalComponent>
  ) {
    this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  close(item?: any) {
    this.dialog.close(item ? item : null);
  }

  protected readonly getTaskTitle = getTaskTitle;
}
