import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CatRegistrationListValue } from '../../model/models';
import { Router } from '@angular/router';
import { CatRegistrationStatus } from '../../model/enums';
import { FipAdminApiService } from '../../service/api/fip-admin-api.service';

@Component({
  selector: 'app-cat-registration-list',
  templateUrl: './cat-registration-list.component.html',
  styleUrls: ['./cat-registration-list.component.scss']
})
export class CatRegistrationListComponent {
  @Input()
  registrations: CatRegistrationListValue[] = [];
  timeZone = 'UTC';

  @Input()
  registrationType = '';

  @Input()
  title: string | undefined;

  @Input()
  subTitle: string | undefined;

  @Output()
  emitStartCatRegistrationReview = new EventEmitter<string>();

  constructor(
    private router: Router,
    private fipAdminApiService: FipAdminApiService
  ) {
    this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  goToRegistration(registrationId: string) {
    void this.router.navigate([
      'registration',
      this.registrationType,
      registrationId
    ]);
  }
}
