<!--<h4>Files</h4>-->

<button
  style="margin-bottom: 0.5rem"
  mat-flat-button
  color="primary"
  [disabled]="readOnly || fileUploading"
  (click)="fileInput.click()">
  <mat-spinner
    *ngIf="fileUploading"
    strokeWidth="2"
    diameter="20"
    color="primary"></mat-spinner>

  <mat-icon
    *ngIf="!fileUploading"
    aria-hidden="false"
    aria-label="Choose file"
    fontIcon="upload"></mat-icon>
  <span *ngIf="!fileUploading">Choose File</span>
</button>
<input hidden #fileInput type="file" (change)="onFileSelected($event)" />

<div *ngFor="let fileName of fileNames" class="file-list-grid">
  <a
    (click)="downloadCatFile(fileName)"
    *ngIf="
      !(editingFile?.originalName === fileName) && !loadingImages.has(fileName)
    "
    >{{ fileName }}</a
  >
  <span *ngIf="loadingImages.has(fileName)">
    <mat-spinner strokeWidth="2" diameter="20" color="primary"></mat-spinner>
  </span>

  <mat-form-field
    *ngIf="editingFile && editingFile.originalName === fileName"
    subscriptSizing="dynamic">
    <input id="fileName" matInput [formControl]="editingFileNameControl" />
    <span style="align-self: center" matTextSuffix>
      .{{ editingFile.extension }}
    </span>
    <button
      *ngIf="!readOnly"
      [disabled]="!editingFileNameControl.valid"
      mat-icon-button
      matSuffix
      matTooltip="Save"
      (click)="saveFileRename($event.target)">
      <mat-icon>check</mat-icon>
    </button>
    <mat-hint>Click the checkmark to save your changes</mat-hint>
  </mat-form-field>
  <div class="action-list">
    <span class="desktop">
      <button
        mat-icon-button
        *ngIf="fileUrls.has(fileName)"
        (click)="openFile(fileUrls.get(fileName), fileName)"
        matTooltip="View File">
        <mat-icon>open_in_new</mat-icon>
      </button>
      <button
        mat-icon-button
        (click)="setFileToEdit(fileName)"
        matTooltip="Rename File"
        *ngIf="!(editingFile?.originalName === fileName) && !readOnly">
        <mat-icon>edit</mat-icon>
      </button>
      <!--<button
        mat-icon-button
        (click)="downloadCatFile(fileName)"
        matTooltip="Download File">
        <mat-icon>download</mat-icon>
      </button>-->
      <button
        mat-icon-button
        *ngIf="!readOnly"
        (click)="deleteCatFileConfirm($event, fileName)"
        matTooltip="Delete File">
        <mat-icon>delete</mat-icon>
      </button>
    </span>
    <span class="mobile">
      <button
        mat-menu-item
        *ngIf="fileUrls.has(fileName)"
        (click)="openFile(fileUrls.get(fileName), fileName)">
        <mat-icon>open_in_new</mat-icon>
      </button>
      <button
        mat-icon-button
        *ngIf="!readOnly"
        [matMenuTriggerFor]="menu"
        aria-label="Example icon-button with a menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button
          mat-menu-item
          (click)="setFileToEdit(fileName)"
          *ngIf="!(editingFile?.originalName === fileName) && !readOnly">
          <mat-icon>edit</mat-icon>
          <span>Rename File</span>
        </button>
        <!--<button mat-menu-item (click)="downloadCatFile(fileName)">
          <mat-icon>download</mat-icon>
          <span>Download File</span>
        </button>-->
        <button
          mat-menu-item
          *ngIf="!readOnly"
          (click)="deleteCatFileConfirm($event, fileName)">
          <mat-icon>delete</mat-icon>
          <span>Delete File</span>
        </button>
      </mat-menu>
    </span>
  </div>
</div>
