<div class="page-container">
  <h1>{{ isMobile ? "FAQs" : "Frequently Asked Questions" }}</h1>
  <div>
    <h2>About Us</h2>
    <mat-accordion multi displayMode="flat">
      <mat-expansion-panel class="mat-elevation-z0 panel">
        <mat-expansion-panel-header>
          <mat-panel-title> What is our mission? </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="panel-content">
          {{ OUR_MISSION }}
        </div>
      </mat-expansion-panel>
    </mat-accordion>

    <h2>Treatment</h2>
    <mat-accordion multi displayMode="flat">
      <mat-expansion-panel class="mat-elevation-z0 panel">
        <mat-expansion-panel-header>
          <mat-panel-title>
            What are FIP treatment admins and how do they help me with my cat?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="panel-content">
          An FIP diagnosis is overwhelming, but FIP OASIS and your admin team
          are here to help! Your admin supports and assists you on your journey
          to treat your cat. Admins are not veterinarians or veterinary
          professionals, but they are experts on FIP and share experience from
          treatment of many cats. Admins DO have a veterinary team to consult
          with when necessary.
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel class="mat-elevation-z0 panel">
        <mat-expansion-panel-header>
          <mat-panel-title> How much does FIP treatment cost? </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="panel-content">
          The treatment, <strong>GS-441524</strong>, or <strong>GS</strong>, is
          <strong>NOT</strong> FDA-approved and is sourced primarily from
          manufacturers in China. The cost will vary by factors such as brand,
          concentration, and relapse guarantee. The average cost to treat a 5 lb
          cat with wet FIP is $900-$1200. A larger neurological cat could be
          $2000-$3000. The cost of treatment varies based on weight and type of
          FIP.
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel class="mat-elevation-z0 panel">
        <mat-expansion-panel-header>
          <mat-panel-title> How is FIP diagnosed? </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="panel-content">
          There is no singular test to confirm FIP. Specific markers in the
          blood can be considered along with your cat's symptoms to establish or
          confirm a FIP diagnosis. CBC and chemistry panel blood work results
          are valuable in the diagnosis of FIP, as is FIV and FeLV testing,
          ultrasound, x-rays, and other diagnostics recommended by your
          veterinarian.
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel class="mat-elevation-z0 panel">
        <mat-expansion-panel-header>
          <mat-panel-title>
            What are the steps in getting started with treatment?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="panel-content">
          The process begins with finding a GS vial from a local parent treating
          their own cat so you can begin treatment immediately. Any brand will
          do, and payment is settled between you and the individual you connect
          with. Once you have the vial(s) and supplies, the correct dose is
          determined based on your cat's weight, type of FIP and brand of
          medication. Depending on your cats condition, GS may be dosed twice
          daily for the first 3-5 days.
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel class="mat-elevation-z0 panel">
        <mat-expansion-panel-header>
          <mat-panel-title> What is the treatment process? </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="panel-content">
          FIP treatment is 84 days MINIMUM of injections or pills followed by
          another 84 days of monitoring. Your cat is "cured" if no relapse
          occurs. Most cats are treated for 84 days. Some may need adjustments
          to treatment or extensions, which is based on their individual
          response to therapy seen with blood work.
          <strong>
            DO NOT STOP TREATMENT BEFORE FINAL LABS ARE REVIEWED BY YOUR
            ADMIN(S).
          </strong>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
