import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  Renderer2,
  ViewChild
} from '@angular/core';
import { LoadingService } from '../../service/loading/loading.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements AfterViewInit, OnDestroy {
  scriptSrc =
    'https://app.enzuzo.com/__enzuzo-privacy-app.js?mode=privacy&apiHost=https://app.enzuzo.com&qt=1688311640991&referral=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJDdXN0b21lcklEIjoyMjk3MiwiQ3VzdG9tZXJOYW1lIjoiY3VzdC13NllaSFJMbCIsIkN1c3RvbWVyTG9nb1VSTCI6IiIsIlJvbGVzIjpbInJlZmVycmFsIl0sIlByb2R1Y3QiOiJlbnRlcnByaXNlIiwiaXNzIjoiRW56dXpvIEluYy4iLCJuYmYiOjE2ODgzMTE2Mjl9.3cXwDJpGEwZN_ZgujR6ILcZwxQC8Z0iFS_N94Zzu5hI';
  @ViewChild('privacyPolicy') privacyPolicyElement: ElementRef | undefined;

  constructor(
    private loadingService: LoadingService,
    private renderer: Renderer2,
    private parentElement: ElementRef
  ) {
    this.loadingService.setLoading(false);
  }

  ngAfterViewInit() {
    const script = this.renderer.createElement('script');
    script.src = this.scriptSrc; // Replace 'something' with the actual script URL or source code
    this.renderer.appendChild(this.privacyPolicyElement?.nativeElement, script);
  }

  ngOnDestroy() {
    this.renderer.removeChild(
      this.parentElement.nativeElement,
      this.privacyPolicyElement?.nativeElement
    );
  }
}
