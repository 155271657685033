<div class="page-container">
  <h1>Cat Registrations</h1>

  <mat-tab-group
    class="cat-tab-group"
    mat-stretch-tabs="false"
    fitInkBarToContent="true"
    animationDuration="0">
    <mat-tab label="Started" *ngIf="isDeveloper">
      <app-cat-registration-list
        *ngIf="isDeveloper"
        [registrationType]="'cat'"
        [registrations]="startedRegistrations"></app-cat-registration-list>
    </mat-tab>
    <mat-tab label="Submitted">
      <app-cat-registration-list
        [registrationType]="'cat'"
        (emitStartCatRegistrationReview)="startCatRegistrationReview($event)"
        [registrations]="submittedRegistrations"></app-cat-registration-list>
    </mat-tab>
  </mat-tab-group>
</div>
