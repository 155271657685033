import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { BloodWork, BloodWorkTest } from '../../../model/blood-work-models';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AnalyticsService } from '../../../service/analytics/analytics.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BloodWorkGroups } from '../../../model/enums';

@Component({
  selector: 'app-blood-work-test-table',
  templateUrl: './blood-work-test-table.component.html',
  styleUrls: ['./blood-work-test-table.component.scss']
})
export class BloodWorkTestTableComponent implements OnInit {
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  dataSource!: MatTableDataSource<BloodWorkTest>;
  bloodWork: BloodWork | undefined;

  @Input()
  set bloodWorkInput(bloodWork: BloodWork | undefined) {
    this.bloodWork = bloodWork;
    if (bloodWork) {
      this.dataSource = new MatTableDataSource<BloodWorkTest>(
        bloodWork.bloodWorkTests
      );
    }
  }

  @Input()
  previousBloodWorks: BloodWork[] = [];

  @Output()
  rowSelected = new EventEmitter<BloodWork>();

  mobileColumns: string[] = [
    'group',
    'test',
    'testName',
    'unit',
    'range',
    'value'
  ];
  desktopColumns: string[] = [
    'group',
    'test',
    'testName',
    'unit',
    'range',
    'value'
  ];
  isMobileSizeScreen = false;

  displayedColumns = this.desktopColumns;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private analyticsService: AnalyticsService
  ) {
    this.breakpointObserver
      .observe([Breakpoints.XSmall])
      .pipe(takeUntilDestroyed())
      .subscribe((result) => {
        this.isMobileSizeScreen = result.matches;
        if (this.isMobileSizeScreen) {
          this.displayedColumns = this.mobileColumns;
        } else {
          this.displayedColumns = this.desktopColumns;
        }
      });
  }

  ngOnInit() {
    this.previousBloodWorks.forEach((bloodWork) => {
      if (bloodWork.id) {
        this.displayedColumns.splice(
          this.displayedColumns.length - 1,
          0,
          bloodWork.id
        );
      }
    });
  }

  /* ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  } */
  onModelChange(event: any, index: number) {
    if (this.bloodWork?.bloodWorkTests?.[index]) {
      if (event.target.value < 0) {
        this.bloodWork.bloodWorkTests[index].value = undefined;
      }
      if (event.target.value > 999) {
        this.bloodWork.bloodWorkTests[index].value = undefined;
      }
    }
  }

  getValueColor(bloodWorkTest: BloodWorkTest | undefined) {
    if (bloodWorkTest && bloodWorkTest.value) {
      if (
        bloodWorkTest.value >= bloodWorkTest.bloodWorkMedicalTest.high ||
        bloodWorkTest.value <= bloodWorkTest.bloodWorkMedicalTest.low
      ) {
        return 'bad-value';
      }
      // return 'good-value';
    }
    return undefined;
  }

  getLowHigh(bloodWorkTest: BloodWorkTest | undefined) {
    if (bloodWorkTest && bloodWorkTest.value) {
      if (bloodWorkTest.value >= bloodWorkTest.bloodWorkMedicalTest.high) {
        return 'High';
      }

      if (bloodWorkTest.value <= bloodWorkTest.bloodWorkMedicalTest.low) {
        return 'Low';
      }
    }
    return undefined;
  }

  getRowColorCssClass(bloodWorkTest: BloodWorkTest) {
    switch (bloodWorkTest.bloodWorkMedicalTest.groupName) {
      case BloodWorkGroups.FIP_RED_CELLS:
        return 'light-red';
      case BloodWorkGroups.FIP_WHITE_CELLS:
        return 'light-purple';
      case BloodWorkGroups.FIP_CHEMISTRY:
        return 'light-blue';
      case BloodWorkGroups.KIDNEY:
        return 'light-orange';
      case BloodWorkGroups.LIVER:
        return 'light-yellow';
      default:
        return '';
    }
  }

  getGroupName(bloodWorkTest: BloodWorkTest, index: number) {
    if (index > 0 && this.bloodWork && this.bloodWork.bloodWorkTests) {
      const currentGroupName =
        this.bloodWork.bloodWorkTests[index].bloodWorkMedicalTest.groupName;
      const previousGroupName =
        this.bloodWork.bloodWorkTests[index - 1].bloodWorkMedicalTest.groupName;
      if (currentGroupName == previousGroupName) {
        return '';
      }
    }
    return bloodWorkTest.bloodWorkMedicalTest.groupName;
  }

  getPreviousBloodWorkValue(bloodWork: BloodWork, index: number) {
    if (
      bloodWork &&
      bloodWork.bloodWorkTests &&
      bloodWork.bloodWorkTests[index]
    ) {
      return bloodWork.bloodWorkTests[index].value;
    }
    return undefined;
  }
}
