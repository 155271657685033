import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BloodWork } from '../../../model/blood-work-models';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FipAdminApiService } from '../../../service/api/fip-admin-api.service';
import { SnackbarService } from '../../../service/snackbar/snackbar.service';

@Component({
  selector: 'app-blood-work-form',
  templateUrl: './blood-work-form.component.html',
  styleUrls: ['./blood-work-form.component.scss']
})
export class BloodWorkFormComponent implements OnInit {
  @Input()
  bloodWork: BloodWork | undefined;

  @Input()
  catId: string | undefined;

  @Output()
  bloodWorkSave = new EventEmitter<BloodWork>();

  @Output()
  bloodWorkClose = new EventEmitter<BloodWork>();

  bloodWorkForm = new FormGroup({
    description: new FormControl<string | undefined>(
      undefined,
      Validators.required
    ),
    type: new FormControl<string | undefined>(undefined, Validators.required),
    date: new FormControl<Date | undefined>(undefined, Validators.required)
  });

  bloodWorkTypes = [
    {
      label: 'Pre-Diagnosis',
      value: 'PRE_DIAGNOSIS'
    },
    {
      label: 'Diagnosis',
      value: 'DIAGNOSIS'
    },
    {
      label: '4th Week',
      value: 'FOUR_WEEK'
    },
    {
      label: '6th Week',
      value: 'SIX_WEEK'
    },
    {
      label: '8th Week',
      value: 'EIGHT_WEEK'
    },
    {
      label: '12th Week',
      value: 'TWELVE_WEEK'
    },
    {
      label: '16th Week',
      value: 'SIXTEEN_WEEK'
    },
    {
      label: '20th Week',
      value: 'TWENTY_WEEK'
    },
    {
      label: '24th Week',
      value: 'TWENTY_FOUR_WEEK'
    },
    {
      label: '4th Week Post',
      value: 'FOUR_WEEK_POST'
    },
    {
      label: '6th Week Post',
      value: 'SIX_WEEK_POST'
    },
    {
      label: '8th Week Post',
      value: 'EIGHT_WEEK_POST'
    },
    {
      label: '12th Week Post',
      value: 'TWELVE_WEEK_POST'
    },
    {
      label: '4th Week Relapse',
      value: 'FOUR_WEEK_RELAPSE'
    },
    {
      label: '6th Week Relapse',
      value: 'SIX_WEEK_RELAPSE'
    },
    {
      label: '8th Week Relapse',
      value: 'EIGHT_WEEK_RELAPSE'
    },
    {
      label: '12th Week Relapse',
      value: 'TWELVE_WEEK_RELAPSE'
    }
  ];

  constructor(
    private fipAdminApiService: FipAdminApiService,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit() {
    if (this.bloodWork) {
      this.populateBloodWorkForm();
    }
  }

  save() {
    if (this.catId) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const bloodWork: BloodWork = {
        ...this.bloodWork,
        ...this.bloodWorkForm.getRawValue(),
        catId: this.catId
      };

      this.fipAdminApiService
        .saveBloodWork(bloodWork)
        .subscribe((bloodWork) => {
          this.snackbarService.openSuccessSnackBar('Blood Work Saved!');
          this.bloodWorkSave.emit(bloodWork);
        });
    }
  }

  close() {
    this.bloodWorkClose.emit();
  }

  private populateBloodWorkForm() {
    this.bloodWorkForm.controls.date.setValue(this.bloodWork?.date);
    //this.bloodWorkForm.controls.type.setValue(this.bloodWork?.type);
    this.bloodWorkForm.controls.description.setValue(
      this.bloodWork?.description
    );

    this.bloodWorkForm.controls.type.setValue(this.bloodWork?.type);
  }
}
