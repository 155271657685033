import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { MedicalRecord } from 'src/app/model/models';
import { calculateDayNumber } from '../../../service/utils/ui-utils';
import { ConfirmationModalComponent } from '../../modals/confirmation-modal/confirmation-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { FipAdminApiService } from 'src/app/service/api/fip-admin-api.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-medical-record-table',
  templateUrl: './medical-record-table.component.html',
  styleUrls: ['./medical-record-table.component.scss']
})
export class MedicalRecordTableComponent implements AfterViewInit {
  dataSource!: MatTableDataSource<MedicalRecord>;
  treatmentStartDate: string | undefined;

  @Input()
  set medicalRecords(medicalRecords: MedicalRecord[]) {
    this.dataSource = new MatTableDataSource<MedicalRecord>(medicalRecords);
    this.dataSource.paginator = this.paginator;
    // When component loads on init, this doesn't execute because treatment date isn't finished setting up
    // But this is used when we delete a record, and fetch the new medical records, this will be calculated again
    // Super inefficient, but its  way to sync up the server medical records with the client on delete
    this.populateTreatmentDayNumbers();
  }

  @Input()
  set startDate(date: string | undefined) {
    this.treatmentStartDate = date;
    this.populateTreatmentDayNumbers();
  }

  @Output()
  recordSelected = new EventEmitter();

  @Output()
  recordDeleted = new EventEmitter();

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  defaultViewableColumns = [
    {
      name: 'Day',
      value: 'day'
    },
    {
      name: 'Dose',
      value: 'dose'
    },
    {
      name: 'Weight',
      value: 'weight'
    },
    {
      name: 'Date',
      value: 'treatmentDate'
    },
    {
      name: 'Brand',
      value: 'brand'
    },
    {
      name: 'Comment',
      value: 'comment'
    },
    {
      name: 'Delete',
      value: 'deleteRecord'
    }
  ];

  defaultDisplayedColumns = [
    'day',
    'dose',
    'weight',
    'treatmentDate',
    'brand',
    'comment',
    'deleteRecord'
  ];

  displayedColumns = [...this.defaultDisplayedColumns];
  viewableColumns = [...this.defaultViewableColumns];
  isMobileSizeScreen = false;

  viewableColumnsStorageKey = 'viewable_columns_medical_records_table';
  displayedColumnsStorageKey = 'displayed_columns_medical_records_table';

  initialSelection = [];
  allowMultiSelect = true;
  selection = new SelectionModel<MedicalRecord>(
    this.allowMultiSelect,
    this.initialSelection
  );

  constructor(
    public dialog: MatDialog,
    private fipAdminApiService: FipAdminApiService
  ) {
    this.displayedColumns = [...this.readDisplayedColumns()];
    this.viewableColumns = [...this.readViewableColumns()];
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  recordOnClick(record: MedicalRecord | undefined) {
    this.recordSelected.emit(record);
  }

  // Should this be done on backend? Seems hard to update it each time
  // Chat GPT again I'm starting to like it
  private populateTreatmentDayNumbers() {
    if (this.treatmentStartDate) {
      const startDate = new Date(this.treatmentStartDate);
      if (this.dataSource.data) {
        for (const medicalRecord of this.dataSource.data) {
          medicalRecord.treatmentDayNumber = calculateDayNumber(
            startDate,
            medicalRecord.treatmentDate
          );
        }
      }
    }
  }

  deleteRecord(e: Event, recordId: string) {
    e.stopPropagation();
    const confirmationDialog = this.dialog.open(ConfirmationModalComponent, {
      minWidth: 350,
      width: '75vw',
      maxWidth: 750,
      ariaModal: true,
      data: {
        action: 'Delete Medical Record'
      }
    });
    confirmationDialog.afterClosed().subscribe((decision: boolean) => {
      if (decision) {
        this.fipAdminApiService.deleteMedicalRecord(recordId).subscribe(() => {
          // This just fetches the medical records again
          this.recordDeleted.emit();
        });
      }
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  changeColumnRows(column: string) {
    if (this.displayedColumns.includes(column)) {
      this.displayedColumns = this.displayedColumns.filter(
        (col) => col !== column
      );
    } else {
      this.displayedColumns.push(column);
    }
    this.storeValue(this.displayedColumnsStorageKey, this.displayedColumns);
  }

  storeValue(storageKey: string, displayedColumns: any) {
    localStorage.setItem(storageKey, JSON.stringify(displayedColumns));
  }

  readDisplayedColumns() {
    const setColumns = localStorage.getItem(this.displayedColumnsStorageKey);
    if (setColumns && setColumns != 'undefined') {
      return JSON.parse(setColumns);
    }
    return this.defaultDisplayedColumns;
  }

  readViewableColumns() {
    const setColumns = localStorage.getItem(this.viewableColumnsStorageKey);
    if (setColumns && setColumns != 'undefined') {
      return JSON.parse(setColumns);
    }
    return this.defaultViewableColumns;
  }

  columnListDropped(event: CdkDragDrop<string, any>) {
    // Column moves are off the viewable columns list, which may or may not line up wtih the actual displayed columns indices
    // So we need to find the index it's at and move it

    const movedValue = this.viewableColumns[event.previousIndex];
    const displayedColumnIndex = this.displayedColumns.findIndex(
      (col) => col === movedValue.value
    );

    moveItemInArray(
      this.displayedColumns,
      displayedColumnIndex,
      event.currentIndex
    );

    moveItemInArray(
      this.viewableColumns,
      event.previousIndex,
      event.currentIndex
    );
    this.storeValue(this.viewableColumnsStorageKey, this.viewableColumns);
    this.storeValue(this.displayedColumnsStorageKey, this.displayedColumns);
  }

  resetTable() {
    this.viewableColumns = this.defaultViewableColumns;
    this.displayedColumns = this.defaultDisplayedColumns;
    this.storeValue(this.viewableColumnsStorageKey, this.viewableColumns);
    this.storeValue(this.displayedColumnsStorageKey, this.displayedColumns);
  }
}
