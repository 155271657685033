import { Component } from '@angular/core';
import { AnalyticsEventEnum } from '../../model/enums';
import { LoadingService } from '../../service/loading/loading.service';
import { ApplicationDataService } from '../../service/data/application-data.service';
import { AnalyticsService } from '../../service/analytics/analytics.service';
import { Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ApplicationData } from '../../model/models';
import { isBrandAssigned } from '../../service/utils/ui-utils';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent {
  protected readonly AnalyticsEventEnum = AnalyticsEventEnum;
  applicationData: ApplicationData | undefined;
  isTexas: boolean | undefined;

  panelOpenState = true;

  showRequisitions = false;
  showLucky = false;
  showSparkAuraValor = false;
  showHarmony = false;
  showAzul = false;

  constructor(
    private loadingService: LoadingService,
    private applicationDataService: ApplicationDataService,
    private analyticsService: AnalyticsService,
    private router: Router
  ) {
    this.applicationDataService
      .getApplicationData()
      .pipe(takeUntilDestroyed())
      .subscribe((applicationData) => {
        if (applicationData) {
          this.applicationData = applicationData;

          this.isTexas = this.applicationData?.profile?.state == 'Texas';
          if (this.applicationData?.showRequisitions) {
            this.showRequisitions = this.applicationData.showRequisitions;
          }

          this.showLucky = isBrandAssigned(
            applicationData.activeMedicationBrands,
            ['Lucky']
          );
          this.showSparkAuraValor = isBrandAssigned(
            applicationData.activeMedicationBrands,
            ['Spark', 'Aura', 'Valor', 'Aala']
          );
          this.showHarmony = isBrandAssigned(
            applicationData.activeMedicationBrands,
            ['Harmony']
          );
          this.showAzul =
            this.isTexas &&
            isBrandAssigned(applicationData.activeMedicationBrands, ['Azul']);

          this.loadingService.setLoading(false);
        }
      });
  }

  goToRequisitions() {
    this.analyticsService.sendAnalyticsEvent(
      this.applicationData,
      undefined,
      AnalyticsEventEnum.REQUISITIONS_BUTTON
    );

    void this.router.navigate(['requisitions']);
  }

  goToLink(url: string, analyticsEventEnum: AnalyticsEventEnum) {
    this.analyticsService.sendAnalyticsEvent(
      this.applicationData,
      undefined,
      analyticsEventEnum
    );
    window.location.href = url;
  }
}
