import { Component } from '@angular/core';
import { LoadingService } from '../../service/loading/loading.service';
import { FormControl, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { FipAdminApiService } from '../../service/api/fip-admin-api.service';
import { ProfileRegistration } from '../../model/models';

@Component({
  selector: 'app-profile-registration-status',
  templateUrl: './profile-registration-status.component.html',
  styleUrls: ['./profile-registration-status.component.scss']
})
export class ProfileRegistrationStatusComponent {
  selection = new FormControl('id', Validators.required);
  profileRegistrationId = new FormControl(undefined, Validators.required);
  profileRegistrationEmail = new FormControl(undefined, Validators.required);

  profileRegistration: ProfileRegistration | undefined;
  timeZone = 'UTC';
  constructor(
    private loadingService: LoadingService,
    private location: Location,
    private fipAdminApiService: FipAdminApiService
  ) {
    this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.loadingService.setLoading(false);
  }

  goBack() {
    this.location.back();
  }

  submit() {
    this.profileRegistration = undefined;
    const checkRegistration = {
      id: this.profileRegistrationId.value,
      email: this.profileRegistrationEmail.value
    };
    this.fipAdminApiService
      .getProfileRegistrationStatus(checkRegistration)
      .subscribe((result) => {
        this.profileRegistration = result;
      });
  }

  changeSelection() {
    this.profileRegistrationEmail.reset();
    this.profileRegistrationId.reset();
  }
}
