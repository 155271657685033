import { Component, OnInit } from '@angular/core';
import { LoadingService } from '../../service/loading/loading.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { countries, State } from '../../model/constants';
import { map, Observable, startWith } from 'rxjs';
import { FipAdminApiService } from '../../service/api/fip-admin-api.service';
import { Location } from '@angular/common';
import { SnackbarService } from '../../service/snackbar/snackbar.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfileRegistration } from '../../model/models';
import { ConfirmationModalComponent } from '../../components/modals/confirmation-modal/confirmation-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { findStateByAbbreviation } from '../../service/utils/ui-utils';

@Component({
  selector: 'app-profile-registration',
  templateUrl: './profile-registration.component.html',
  styleUrls: ['./profile-registration.component.scss']
})
export class ProfileRegistrationComponent implements OnInit {
  protected readonly countries = countries;
  maxCommentLength = 1000;
  remaining = this.maxCommentLength;
  viewType = 'New';

  profileRegistrationForm = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.maxLength(100),
      Validators.min(2),
      Validators.email
    ]),
    firstName: new FormControl('', [
      Validators.required,
      Validators.maxLength(60)
    ]),
    lastName: new FormControl('', [
      Validators.required,
      Validators.maxLength(60)
    ]),
    city: new FormControl('', [Validators.required, Validators.maxLength(60)]),
    state: new FormControl('', [Validators.required, Validators.maxLength(30)]),
    country: new FormControl('United States', [Validators.required]),
    zipCode: new FormControl('', [Validators.maxLength(11)]),
    comment: new FormControl<string>('', [
      Validators.required,
      Validators.minLength(1),
      Validators.maxLength(this.maxCommentLength)
    ])
  });

  filteredStates: Observable<State[]>;
  states: State[] = this.countries[0].states.map((state) => state);
  submitted = false;

  reviewActive = false;
  profileRegistration: ProfileRegistration | undefined;

  showForm = true;

  constructor(
    private loadingService: LoadingService,
    private fipAdminApiService: FipAdminApiService,
    private location: Location,
    private snackBarService: SnackbarService,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private router: Router
  ) {
    this.loadingService.setLoading(false);
    this.filteredStates =
      this.profileRegistrationForm.controls.state.valueChanges.pipe(
        startWith(''),
        map((value) =>
          this.states.filter((state) => {
            value = value || '';
            return state.name.toLowerCase().includes(value.toLowerCase());
          })
        )
      );

    this.profileRegistrationForm.controls.comment.valueChanges.subscribe(
      (value) => {
        this.remaining = this.maxCommentLength - (value ? value.length : 0);
      }
    );
  }

  ngOnInit() {
    this.activatedRoute.data.subscribe(({ registration }) => {
      if (registration) {
        this.viewType = 'Review';
        this.profileRegistration = registration;
        this.populateProfileRegistrationForm(registration);
        this.profileRegistrationForm.disable();
        this.setReviewActive();
      }
    });
  }

  get email() {
    return this.profileRegistrationForm.controls.email;
  }

  goBack() {
    this.location.back();
  }

  save() {
    // Lowercase emails for backend
    if (this.email.value) {
      this.email.setValue(this.email.value.toLowerCase());
    }

    const profileRegistration = {
      ...this.profileRegistrationForm.getRawValue()
    };
    this.fipAdminApiService
      .saveProfileRegistration(profileRegistration)
      .subscribe((profileRegistration) => {
        if (profileRegistration.status === 'SUBMITTED') {
          this.snackBarService.openSuccessSnackBar(
            'Profile Registration submitted.'
          );
          this.profileRegistration = profileRegistration;
          this.showForm = false;
          this.profileRegistrationForm.disable();
          this.submitted = true;
        }
      });
  }

  // Copied from profile-form.component.ts
  countryChange(country: string, reset: boolean) {
    const states = this.countries.find((c) => c.country === country)?.states;
    if (states) {
      this.states = states.map((state) => state);
    }

    if (reset) {
      this.profileRegistrationForm.controls.state.reset();
      this.profileRegistrationForm.controls.zipCode.reset();
    }
  }

  private populateProfileRegistrationForm(registration: ProfileRegistration) {
    this.profileRegistrationForm.controls.email.setValue(registration.email);
    this.profileRegistrationForm.controls.firstName.setValue(
      registration.firstName
    );
    this.profileRegistrationForm.controls.lastName.setValue(
      registration.lastName
    );
    this.profileRegistrationForm.controls.city.setValue(registration.city);
    this.profileRegistrationForm.controls.state.setValue(registration.state);
    this.profileRegistrationForm.controls.zipCode.setValue(
      registration.zipCode
    );
    this.profileRegistrationForm.controls.country.setValue(
      registration.country
    );
    this.profileRegistrationForm.controls.comment.setValue(
      registration.comment
    );
  }

  confirmSubmitReview(status: string, $event: MouseEvent) {
    const term = status === 'ACCEPTED' ? 'ACCEPT' : 'REJECT';
    const subContent =
      status === 'ACCEPTED'
        ? 'A profile will automatically be created with these details.'
        : undefined;
    $event.stopPropagation();
    const confirmationDialog = this.dialog.open(ConfirmationModalComponent, {
      minWidth: 350,
      width: '75vw',
      maxWidth: 750,
      ariaModal: true,
      autoFocus: false,
      data: {
        action: `${term} the Profile Registration`,
        subContent
      }
    });
    confirmationDialog.afterClosed().subscribe((decision: boolean) => {
      if (decision) {
        this.submitReview(status);
      }
    });
  }

  submitReview(status: string) {
    const profileRegistration = {
      id: this.profileRegistration?.id,
      status
    };
    this.fipAdminApiService
      .submitProfileRegistrationReview(profileRegistration)
      .subscribe((profileRegistration) => {
        this.profileRegistration = profileRegistration;
        this.setReviewActive();
        void this.router.navigate(['registration', 'profile', 'review']);
      });
  }

  private setReviewActive() {
    this.reviewActive = this.profileRegistration?.status === 'SUBMITTED';
  }

  checkState($event: any) {
    this.profileRegistrationForm.controls.state.setValue(
      findStateByAbbreviation($event.target.value, this.states)
    );
  }
}
