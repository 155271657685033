<div style="margin-top: 0.25rem">
  <form [formGroup]="catForm" class="container">
    <div class="image">
      <!-- We need to keep the image div for css grid -->
      <div class="cat-image-upload" *ngIf="cat">
        <img
          *ngIf="!avatarUrl"
          class="cat-image"
          src="/assets/pets_black_24dp.svg"
          alt="placeholder image of cat" />
        <img *ngIf="avatarUrl" class="cat-image" [src]="avatarUrl" />
        <button
          (click)="fileInput.click()"
          type="button"
          mat-button
          class="upload-button">
          Upload/Edit Photo
        </button>
        <input
          hidden
          accept="image/jpeg"
          (change)="onFileSelected($event)"
          #fileInput
          type="file"
          id="file" />
      </div>
    </div>

    <mat-form-field class="profile">
      <mat-label>Assigned Profile</mat-label>
      <input
        type="text"
        id="profile"
        matInput
        appRequiredRoles="Admin"
        formControlName="profile"
        [matAutocomplete]="profileAutoComplete" />
      <mat-autocomplete
        #profileAutoComplete="matAutocomplete"
        [displayWith]="getProfileDisplayText">
        <mat-option *ngFor="let option of eligibleProfiles" [value]="option">
          {{ option.firstName }} {{ option.lastName }}, {{ option.state }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <div class="admins">
      <span *ngIf="!cat" class="icon-text-grid">
        <mat-icon> warning </mat-icon>
        Assigned Admins cannot be changed after enrollment.
      </span>
      <mat-form-field style="width: 100%">
        <mat-label>Assigned Admin(s)</mat-label>
        <mat-select
          id="assignedAdmins"
          formControlName="assignedAdmins"
          multiple
          appRequiredRoles="Admin">
          <mat-option *ngFor="let option of assignableAdmins" [value]="option">
            {{ option.firstName }} {{ option.lastName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <mat-form-field class="status">
      <mat-label>Status</mat-label>
      <mat-select appRequiredRoles="Admin" id="status" formControlName="status">
        <mat-option *ngFor="let option of eligibleStatuses" [value]="option">
          {{ option.title }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="name">
      <mat-label>Cat Name</mat-label>
      <input id="name" matInput placeholder="" formControlName="name" />
    </mat-form-field>

    <mat-form-field hintLabel="MM/DD/YYYY" class="birth-date">
      <mat-label>Birth Date</mat-label>
      <input
        matInput
        [matDatepicker]="birthDate"
        [max]="today"
        formControlName="birthDate" />
      <mat-datepicker-toggle
        matIconSuffix
        [for]="birthDate"></mat-datepicker-toggle>
      <mat-datepicker #birthDate></mat-datepicker>
    </mat-form-field>

    <mat-form-field class="gender">
      <mat-label>Sex</mat-label>
      <mat-select id="cat-gender" formControlName="gender">
        <mat-option *ngFor="let option of catGenders" [value]="option">
          {{ option }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="color">
      <mat-label>Color</mat-label>
      <input id="color" matInput placeholder="" formControlName="color" />
    </mat-form-field>

    <mat-form-field class="breed">
      <mat-label>Breed</mat-label>
      <mat-select id="cat-breed" formControlName="breed">
        <mat-option *ngFor="let option of catBreeds" [value]="option">
          {{ option }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="diseases">
      <mat-label>Diseases</mat-label>
      <mat-select
        appRequiredRoles="Admin"
        id="diseases"
        formControlName="diseases"
        multiple>
        <mat-option *ngFor="let option of diseasesSelection" [value]="option">
          {{ option }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="conditions">
      <mat-label>Conditions</mat-label>
      <mat-select
        appRequiredRoles="Admin"
        id="conditions"
        formControlName="conditions"
        multiple>
        <mat-option
          *ngFor="let option of conditionsSelections"
          [value]="option">
          {{ option }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="sideEffects">
      <mat-label>Side Effects (of GS)</mat-label>
      <mat-select
        id="sideEffects"
        appRequiredRoles="Admin"
        formControlName="sideEffects"
        multiple>
        <mat-option
          *ngFor="let option of sideEffectSelections"
          [value]="option">
          {{ option }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="bio">
      <mat-label>Bio</mat-label>
      <textarea
        matInput
        draggable="false"
        formControlName="bio"
        rows="3"></textarea>
    </mat-form-field>

    <mat-checkbox
      *ngIf="applicationData?.isAdmin"
      class="complete"
      id="complete"
      formControlName="complete"
      >Complete
    </mat-checkbox>

    <button
      *ngIf="cat?.catRegistrationId"
      mat-flat-button
      color="primary"
      (click)="goToCatRegistration()">
      View Registration
    </button>
  </form>
</div>

<div
  *ngIf="applicationData?.isAdmin"
  style="margin-bottom: 1rem"
  class="action-footer">
  <button mat-flat-button color="warn" (click)="deleteCat($event)">
    Delete
  </button>
</div>
<div class="action-footer">
  <button mat-stroked-button (click)="close()">Close</button>
  <button
    mat-flat-button
    color="primary"
    (click)="save()"
    [disabled]="!catForm.valid">
    Save
  </button>
</div>
