import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MedicalCarePlan } from 'src/app/model/models';

@Component({
  selector: 'app-medical-care-plan-modal',
  templateUrl: './medical-care-plan-modal.component.html',
  styleUrls: ['./medical-care-plan-modal.component.scss']
})
export class MedicalCarePlanModalComponent {
  viewType = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialogRef<MedicalCarePlanModalComponent>
  ) {
    this.viewType =
      this.data?.medicalCarePlan !== undefined ? 'Edit' : 'Create';
  }

  onSave($event: MedicalCarePlan) {
    this.dialog.close($event);
  }

  onClose() {
    this.dialog.close();
  }
}
