import { Component, Input, ViewChild } from '@angular/core';
import { BloodWork, BloodWorkMetaData } from '../../../model/blood-work-models';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-blood-work-metadata-table',
  templateUrl: './blood-work-metadata-table.component.html',
  styleUrls: ['./blood-work-metadata-table.component.scss']
})
export class BloodWorkMetadataTableComponent {
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  dataSource!: MatTableDataSource<BloodWorkMetaData>;
  bloodWork: BloodWork | undefined;

  displayedColumns = ['type', 'value'];

  @Input()
  set bloodWorkInput(bloodWork: BloodWork | undefined) {
    this.bloodWork = bloodWork;
    if (bloodWork) {
      this.dataSource = new MatTableDataSource<BloodWorkMetaData>(
        bloodWork.bloodWorkMetaData
      );
    }
  }

  constructor() {}

  onModelChange(event: any, index: number) {
    if (this.bloodWork?.bloodWorkMetaData?.[index]) {
      if (event.target.value < 0) {
        this.bloodWork.bloodWorkMetaData[index].value = undefined;
      }
      if (event.target.value > 999) {
        this.bloodWork.bloodWorkMetaData[index].value = undefined;
      }
    }
  }
}
