<app-blood-work-test-table
  [previousBloodWorks]="previousBloodWorks"
  [bloodWorkInput]="bloodWork"></app-blood-work-test-table>

<div>
  <h3>Metadata</h3>
  <app-blood-work-metadata-table
    [bloodWorkInput]="bloodWork"></app-blood-work-metadata-table>
</div>

<div class="action-footer">
  <button mat-stroked-button (click)="close()">Close</button>
  <button mat-flat-button color="primary" (click)="save()">Save</button>
</div>
