import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Import the module from the SDK
import { AuthModule } from '@auth0/auth0-angular';
import { NavbarComponent } from './components/layout/navbar/navbar.component';
import {
  HTTP_INTERCEPTORS,
  HttpClientModule,
  HttpClientXsrfModule
} from '@angular/common/http';
import { environment } from '../environments/environment';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { CatTableComponent } from './components/tables/cat-table/cat-table.component';
import { CatComponent } from './pages/cat/cat.component';
import { AuthorizationInterceptor } from './interceptor/authorization/authorization.interceptor';
import { ErrorComponent } from './pages/error/error.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CatCommentsComponent } from './components/cat-comments/cat-comments.component';
import { CatFormComponent } from './components/forms/cat-form/cat-form.component';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatChipsModule } from '@angular/material/chips';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import {
  MAT_RIPPLE_GLOBAL_OPTIONS,
  MatNativeDateModule
} from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ErrorInterceptor } from './interceptor/error/error.interceptor';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MedicalRecordTableComponent } from './components/tables/medical-record-table/medical-record-table.component';
import { CatModalComponent } from './components/modals/cat-modal/cat-modal.component';
import { MedicalRecordModalComponent } from './components/modals/medical-record-modal/medical-record-modal.component';
import { MedicalRecordFormComponent } from './components/forms/medical-record-form/medical-record-form.component';
import { MedicalCarePlanModalComponent } from './components/modals/medical-care-plan-modal/medical-care-plan-modal.component';
import { MedicalCarePlanFormComponent } from './components/forms/medical-care-plan-form/medical-care-plan-form.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { EnrollmentComponent } from './pages/enrollment/enrollment.component';
import { MatStepperModule } from '@angular/material/stepper';
import { ConfettiComponent } from './components/layout/confetti/confetti.component';
import { ProfileFormComponent } from './components/forms/profile-form/profile-form.component';
import { MatRadioModule } from '@angular/material/radio';
import { SearchModalComponent } from './components/modals/search-modal/search-modal.component';
import { LoaderComponent } from './components/layout/loader/loader.component';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { RequiredRolesDirective } from './service/directive/required-roles.directive';
import { ProfileTableComponent } from './components/tables/profile-table/profile-table.component';
import { MatBadgeModule } from '@angular/material/badge';
import { NotificationMenuComponent } from './components/notification-menu/notification-menu.component';
import { TimeAgoPipe } from './service/pipe/time-ago.pipe';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AtAGlanceCardComponent } from './components/at-a-glance-card/at-a-glance-card.component';
import { ConfirmationModalComponent } from './components/modals/confirmation-modal/confirmation-modal.component';
import { MatSortModule } from '@angular/material/sort';
import { GlanceModalComponent } from './components/modals/glance-modal/glance-modal.component';
import { HomeComponent } from './pages/home/home.component';
import { CatDatesFormComponent } from './components/forms/cat-dates-form/cat-dates-form.component';
import { CatDatesModalComponent } from './components/modals/cat-dates-modal/cat-dates-modal.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ChipsComponent } from './components/chips/chips.component';
import { BannerComponent } from './components/banner/banner.component';
import { TaskFormComponent } from './components/forms/task-form/task-form.component';
import { TaskModalComponent } from './components/modals/task-modal/task-modal.component';
import { TaskListComponent } from './components/task-list/task-list.component';
import { FaqsComponent } from './pages/faqs/faqs.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { ContactComponent } from './pages/contact/contact.component';
import { FilesComponent } from './components/files/files.component';
import { TaskCardComponent } from './components/task-card/task-card.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { CatCardComponent } from './components/cat-card/cat-card.component';
import { PurchaseProButtonComponent } from './components/purchase-pro-button/purchase-pro-button.component';
import { PaymentBreakdownModalComponent } from './components/modals/payment-breakdown-modal/payment-breakdown-modal.component';
import { CatRegistrationComponent } from './pages/cat-registration/cat-registration.component';
import { ActivityComponent } from './pages/activity/activity.component';
import { TermsComponent } from './components/terms/terms.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CatRegistrationReviewComponent } from './pages/cat-registration-review/cat-registration-review.component';
import { CatRegistrationListComponent } from './components/cat-registration-list/cat-registration-list.component';
import { ProfileRegistrationComponent } from './pages/profile-registration/profile-registration.component';
import { ProfileRegistrationReviewComponent } from './pages/profile-registration-review/profile-registration-review.component';
import { ProfileRegistrationStatusComponent } from './pages/profile-registration-status/profile-registration-status.component';
import { ProfileRegistrationListComponent } from './components/profile-registration-list/profile-registration-list.component';
import { SuppliesComponent } from './pages/supplies/supplies.component';
import { RequisitionsComponent } from './pages/requisitions/requisitions.component';
import { RequisitionFormComponent } from './components/forms/requisition-form/requisition-form.component';
import { RequisitionModalComponent } from './components/modals/requisition-modal/requisition-modal.component';
import { RequisitionTableComponent } from './components/tables/requisition-table/requisition-table.component';
import { ProductsComponent } from './pages/products/products.component';
import { SupportUsModalComponent } from './components/modals/support-us-modal/support-us-modal.component';
import { BloodWorkComponent } from './components/blood-work/blood-work.component';
import { BloodWorkTableComponent } from './components/tables/blood-work-table/blood-work-table.component';
import { BloodWorkTestModalComponent } from './components/modals/blood-work-test-modal/blood-work-test-modal.component';
import { BloodWorkTestTableComponent } from './components/tables/blood-work-test-table/blood-work-test-table.component';
import { BloodWorkTestFormComponent } from './components/forms/blood-work-test-form/blood-work-test-form.component';
import { BloodWorkFormComponent } from './components/forms/blood-work-form/blood-work-form.component';
import { BloodWorkModalComponent } from './components/modals/blood-work-modal/blood-work-modal.component';
import { ProfileNewComponent } from './pages/profile-new/profile-new.component';
import { UserComponent } from './pages/user/user.component';
import { CostEstimatorComponent } from './pages/cost-estimator/cost-estimator.component';
import { AnalyticsComponent } from './pages/analytics/analytics.component';
import { BloodWorkMetadataTableComponent } from './components/tables/blood-work-metadata-table/blood-work-metadata-table.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    DashboardComponent,
    ProfileComponent,
    CatTableComponent,
    CatComponent,
    ErrorComponent,
    CatCommentsComponent,
    CatFormComponent,
    MedicalRecordTableComponent,
    CatModalComponent,
    MedicalRecordModalComponent,
    MedicalRecordFormComponent,
    MedicalCarePlanModalComponent,
    MedicalCarePlanFormComponent,
    EnrollmentComponent,
    ConfettiComponent,
    ProfileFormComponent,
    SearchModalComponent,
    LoaderComponent,
    RequiredRolesDirective,
    ProfileTableComponent,
    NotificationMenuComponent,
    TimeAgoPipe,
    AtAGlanceCardComponent,
    ConfirmationModalComponent,
    GlanceModalComponent,
    HomeComponent,
    CatDatesFormComponent,
    CatDatesModalComponent,
    ChipsComponent,
    BannerComponent,
    CatDatesModalComponent,
    TaskFormComponent,
    TaskModalComponent,
    TaskListComponent,
    FaqsComponent,
    FooterComponent,
    ContactComponent,
    FilesComponent,
    TaskCardComponent,
    CatCardComponent,
    PurchaseProButtonComponent,
    PaymentBreakdownModalComponent,
    CatRegistrationComponent,
    ActivityComponent,
    TermsComponent,
    PrivacyPolicyComponent,
    CatRegistrationReviewComponent,
    CatRegistrationListComponent,
    ProfileRegistrationComponent,
    ProfileRegistrationReviewComponent,
    ProfileRegistrationStatusComponent,
    ProfileRegistrationListComponent,
    SuppliesComponent,
    RequisitionsComponent,
    RequisitionFormComponent,
    RequisitionModalComponent,
    RequisitionTableComponent,
    ProductsComponent,
    SupportUsModalComponent,
    BloodWorkComponent,
    BloodWorkTableComponent,
    BloodWorkTestModalComponent,
    BloodWorkTestTableComponent,
    BloodWorkTestFormComponent,
    BloodWorkFormComponent,
    BloodWorkModalComponent,
    ProfileNewComponent,
    UserComponent,
    CostEstimatorComponent,
    AnalyticsComponent,
    BloodWorkMetadataTableComponent
  ],
  imports: [
    AuthModule.forRoot({
      ...environment.auth,
      cacheLocation: 'localstorage', //TODO: Maybe change this in future
      useRefreshTokens: true
      // Wrote my own since this uses silent and that wont' work in local
      /*httpInterceptor: {
        allowedList: [
          {
            // Match any request that starts 'https://{yourDomain}/api/v2/' (note the asterisk)
            uri: 'http://localhost:8080/*',
            tokenOptions: {
              authorizationParams: {
                // The attached token should target this audience
                audience: 'https://dev-fip-admin-api',

                // The attached token should have these scopes
                scope: 'admin:write'
              }
            }
          }
        ]
      } Can't use this because consent required... really annoying */
    }),
    HttpClientXsrfModule, // CSRF, may need to remove
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    BrowserAnimationsModule,
    /* ------------ Material Modules ------------ */
    MatButtonModule,
    MatTableModule,
    MatDatepickerModule,
    MatChipsModule,
    MatInputModule,
    MatSelectModule,
    MatTabsModule,
    MatToolbarModule,
    MatIconModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatNativeDateModule, // Why two for date picker?
    MatDialogModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatSnackBarModule,
    MatAutocompleteModule,
    MatProgressBarModule,
    MatStepperModule,
    MatMenuModule,
    MatRadioModule,
    MatBadgeModule,
    MatMenuModule,
    MatDividerModule,
    MatSlideToggleModule,
    MatPaginatorModule,
    MatSortModule,
    MatListModule,
    MatCheckboxModule,
    MatExpansionModule,
    OverlayModule,
    MatButtonToggleModule,
    NgOptimizedImage,
    DragDropModule
    /* ------------ Material Modules ------------ */
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationInterceptor,
      multi: true
    },
    /* {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true
    }, */
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' }
    },
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: { disabled: true } },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
