import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Profile } from '../../model/models';
import { SnackbarService } from '../../service/snackbar/snackbar.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  profile: Profile | undefined;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private snackBarService: SnackbarService
  ) {}

  ngOnInit() {
    this.activatedRoute.data.subscribe(({ profile }) => {
      if (profile) {
        this.profile = profile;
      } else {
        console.error('No profile found for id.');
        this.snackBarService.openFailureSnackBar('No Profile found for id.');
        void this.router.navigate(['dashboard']);
      }
    });
  }
}
