import { Component, Inject } from '@angular/core';
import { Cat } from '../../../model/models';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FipAdminApiService } from '../../../service/api/fip-admin-api.service';
import { FormControl, Validators } from '@angular/forms';
import { CAT_FIP_OASIS_PRO_ID } from '../../../model/constants';

@Component({
  selector: 'app-support-us-modal',
  templateUrl: './support-us-modal.component.html',
  styleUrls: ['./support-us-modal.component.scss']
})
export class SupportUsModalComponent {
  profileId: string;
  cats: Cat[] = [];
  selectedCatControl = new FormControl<Cat | undefined>(
    undefined,
    Validators.required
  );
  selectedMembership = new FormControl<
    { name: string; value: number } | undefined
  >(undefined, Validators.required);

  proMemberships = [
    {
      name: '$5',
      value: 500
    },
    {
      name: '$10',
      value: 1000
    },
    {
      name: '$25',
      value: 2500
    },
    {
      name: '$50',
      value: 5000
    },
    {
      name: '$100',
      value: 10000
    }
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialogRef<SupportUsModalComponent>,
    private fipAdminApiService: FipAdminApiService
  ) {
    //this.productId = data.productId;
    this.cats = data.cats;
    this.profileId = data.profileId;
    if (this.cats.length > 0) {
      this.selectedCatControl.setValue(this.cats[0]);
    }
  }

  confirm() {
    if (this.selectedCatControl.value && this.selectedMembership.value) {
      this.redirectToCheckout(
        this.selectedCatControl.value,
        this.selectedMembership.value.value
      );
    }
  }

  cancel() {
    this.dialog.close({ confirm: false });
  }

  redirectToCheckout(cat: Cat, amount: number) {
    const checkoutProduct: any = {
      // Don't pass price into here, it will be calculated on backend again
      description: `${cat.name} will become a Pro Member. You will be supporting the FIP Oasis team and upcoming new features for new users. Thanks again!`,
      currency: 'usd',
      amount,
      imageUrl: window.location.origin + '/assets/cat_island_oasis.png',
      successUrl: window.location.origin + '/dashboard',
      cancelUrl: window.location.origin + '/dashboard',
      quantity: 1,
      catId: cat.id,
      productId: CAT_FIP_OASIS_PRO_ID,
      purchaser: this.profileId
    };

    this.fipAdminApiService.checkout(checkoutProduct).subscribe((result) => {
      if (result && result.url) {
        window.location.href = result.url;
      }
    });
  }
}
