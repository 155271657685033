import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BloodWork } from '../../../model/blood-work-models';

@Component({
  selector: 'app-blood-work-modal',
  templateUrl: './blood-work-modal.component.html',
  styleUrls: ['./blood-work-modal.component.scss']
})
export class BloodWorkModalComponent {
  viewType = 'Edit';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialogRef<BloodWorkModalComponent>
  ) {
    this.viewType = this.data?.bloodWork !== undefined ? 'Edit' : 'Create';
  }

  onSave($event: BloodWork) {
    this.dialog.close($event);
  }

  onClose() {
    this.dialog.close();
  }
}
