import { Injectable } from '@angular/core';
import { CreateNotification, Notification } from '../../model/models';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

export interface AdminGlobalNotifications {
  catRegistrations: boolean;
  profileRegistrations: boolean;
  requisitions: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private notifications: BehaviorSubject<Notification[]> = new BehaviorSubject<
    Notification[]
  >([]);

  private adminGlobalNotifications: BehaviorSubject<
    AdminGlobalNotifications | undefined
  > = new BehaviorSubject<AdminGlobalNotifications | undefined>(undefined);
  constructor(private httpClient: HttpClient) {}

  retrieveNotifications() {
    this.getNotifications().subscribe((notifications) => {
      this.notifications.next(notifications);
    });

    this.getRegistrationNotifications().subscribe(
      (adminGlobalNotifications) => {
        this.adminGlobalNotifications.next(adminGlobalNotifications);
      }
    );
  }

  timerRefreshNotifications() {
    setTimeout(() => {
      this.retrieveNotifications();
    }, 3000);
  }

  get notificationSubject() {
    return this.notifications;
  }

  get registrationNotificationSubject() {
    return this.adminGlobalNotifications;
  }

  saveNotification(createNotification: CreateNotification) {
    return this.httpClient.post<Notification>(
      environment.fipAdminApiUrl + '/notifications/create',
      createNotification
    );
  }

  getNotifications() {
    return this.httpClient.get<Notification[]>(
      environment.fipAdminApiUrl + '/notifications'
    );
  }

  getRegistrationNotifications() {
    return this.httpClient.get<AdminGlobalNotifications>(
      environment.fipAdminApiUrl + '/notifications/admin/global'
    );
  }

  clearAllNotifications() {
    return this.httpClient.delete<Notification[]>(
      environment.fipAdminApiUrl + '/notifications/clear'
    );
  }

  clearSingleNotification(notificationId: string) {
    return this.httpClient.delete<Notification[]>(
      environment.fipAdminApiUrl + '/notifications/clear/' + notificationId
    );
  }
}
