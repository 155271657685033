<div class="page-container">
  <h1>
    {{ message }}
  </h1>
  <div [ngSwitch]="errorType">
    <div *ngSwitchCase="'forbidden'">
      <h4>Please <strong>Sign out</strong> and <strong>Log in</strong> to view your data.</h4>
      <h4>If you continuously see this error, please send an email to <strong>support@fipoasis.com</strong>.</h4>
    </div>
    <div *ngSwitchCase="'notFound'">
      <h3>This page is either under construction or does not exist.</h3>
    </div>
    <div *ngSwitchDefault></div>
  </div>
  <a routerLink="/dashboard">Click here</a> to return back to your dashboard.
</div>
