import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { map, of, switchMap } from 'rxjs';
import { FipAdminApiService } from '../../service/api/fip-admin-api.service';
import { AuthService } from '@auth0/auth0-angular';
import { CAT_FIP_OASIS_PRO_ID } from '../../model/constants';

export const catProPurchaseGuard: CanActivateFn = (route) => {
  const router = inject(Router);
  const authService = inject(AuthService);
  const fipAdminApiService = inject(FipAdminApiService);

  const catId = route.paramMap.get('id');

  if (catId == null) {
    void router.navigate(['dashboard']);
    return false;
  }

  // If they unauthenticated, take them to the home page. But once they're authenticated, if they purchased pro,
  // take them to the dashboard, else take them home again
  return authService.isAuthenticated$.pipe(
    switchMap((isAuthenticated) => {
      if (isAuthenticated) {
        return of(true);
      } else {
        void router.navigate(['dashboard']);
        return of(false);
      }
    }),
    switchMap(() => {
      return fipAdminApiService
        .hasCatPurchased(catId, CAT_FIP_OASIS_PRO_ID)
        .pipe(
          map((result) => {
            if (result && result.purchased) {
              return true;
            }
            void router.navigate(['dashboard']);
            return false;
          })
        );
    })
  );
};
