export enum AnalyticsEventEnum {
  CAT_TABLE_VIEW = 'CAT_TABLE_VIEW',
  SEARCH_CAT_TABLE_VIEW = 'SEARCH_CAT_TABLE_VIEW',
  SEARCH_PROFILE_VIEW = 'SEARCH_PROFILE_VIEW',
  VIEW_INFO = 'VIEW_INFO',
  CALCULATE_DOSE = 'CALCULATE_DOSE',
  NEW_MEDICAL_RECORD = 'NEW_MEDICAL_RECORD',
  SAVE_MEDICAL_RECORD = 'SAVE_MEDICAL_RECORD',
  CLOSE_MEDICAL_RECORD = 'CLOSE_MEDICAL_RECORD',
  COST_ESTIMATOR_BRAND_CHANGE = 'COST_ESTIMATOR_BRAND_CHANGE',

  FILE_TOO_LARGE = 'FILE_TOO_LARGE',

  SUPPLIES_BUTTON = 'SUPPLIES_BUTTON',
  PRODUCTS_BUTTON = 'PRODUCTS_BUTTON',
  HANDBOOK_BUTTON = 'HANDBOOK_BUTTON',
  FACEBOOK_BUTTON = 'FACEBOOK_BUTTON',
  REQUISITIONS_BUTTON = 'REQUISITIONS_BUTTON',

  VIEW_REQUISITION = 'VIEW_REQUISITION',
  NEW_REQUISITION = 'NEW_REQUISITION ',
  SAVE_REQUISITION = 'SAVE_REQUISITION',
  CANCEL_REQUISITION = 'CANCEL_REQUISITION',
  MARK_AS_PAID = 'MARK_AS_PAID',

  LOGIN = 'LOGIN',
  SIGN_OUT = 'SIGN_OUT',

  HEALTHY_CAT_STORE = 'HEALTHY_CAT_STORE',
  LUCKY_PRODUCT = 'LUCKY_PRODUCT',
  SPARK_AURA_VALOR_PRODUCT = 'SPARK_AURA_VALOR_PRODCUT',
  AZUL_PRODUCT = 'AZUL_PRODUCT',
  HARMONY_PRODUCT = 'HARMONY_PRODUCT'
}

export enum CatRegistrationStatus {
  STARTED = 'STARTED',
  CANCELLED = 'CANCELLED',
  SUBMITTED = 'SUBMITTED'
  // IN_REVIEW = 'IN_REVIEW',
  // ACCEPTED = 'ACCEPTED',
  // REJECTED = 'REJECTED'
}

export enum FileType {
  CAT = 'CAT',
  CAT_REGISTRATION = 'CAT_REGISTRATION'
}

export enum RequisitionStatus {
  SUBMITTED = 'SUBMITTED',
  //PROCESSING = 'PROCESSING',
  AWAITING_PAYMENT = 'AWAITING_PAYMENT',
  PAID = 'PAID',
  SHIPPED = 'SHIPPED',
  CANCELLED = 'CANCELLED'
}

export const requisitionStatuses = [
  RequisitionStatus.SUBMITTED,
  //RequisitionStatus.PROCESSING,
  RequisitionStatus.AWAITING_PAYMENT,
  RequisitionStatus.PAID,
  RequisitionStatus.SHIPPED,
  RequisitionStatus.CANCELLED
];

export const RequisitionPromos = {
  FREE_VIAL: 'FREE_VIAL',
  FREE_PILL_BAG: 'FREE_PILL_BAG'
};

export const BloodWorkGroups = {
  FIP_RED_CELLS: 'FIP Red Cells',
  FIP_WHITE_CELLS: 'FIP White Cells',
  FIP_CHEMISTRY: 'FIP Chemistry',
  KIDNEY: 'Kidney',
  LIVER: 'Liver'
};
