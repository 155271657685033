import { ResolveFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { FipAdminApiService } from '../../service/api/fip-admin-api.service';
import { catchError, throwError } from 'rxjs';
import { ProfileRegistration } from '../../model/models';

export const profileRegistrationResolver: ResolveFn<
  ProfileRegistration | null
> = (route, state) => {
  const fipAdminApiService = inject(FipAdminApiService);
  const router = inject(Router);
  const profileRegistrationId = route.paramMap.get('id');
  if (profileRegistrationId) {
    return fipAdminApiService
      .getProfileRegistration(profileRegistrationId)
      .pipe(
        catchError(() => {
          void router.navigate(['error/notFound']);
          return throwError(() => 'Not found');
        })
      );
  } else {
    void router.navigate(['dashboard']);
    return null;
  }
};
