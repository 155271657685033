<div>
  <div class="new-comment-container">
    <app-banner spacing="compact" style="margin-bottom: 1rem; font-size: small">
      Request assistance or ask a question and your admins will be notified. They
      will review the request and respond as soon as possible.
    </app-banner>
    <div style="margin-bottom: 1rem;">
      <mat-slide-toggle [formControl]="toggleRequestAssistance" color="primary">Request Assistance</mat-slide-toggle>
    </div>
    <mat-form-field style="margin-top: 0.5rem">
      <mat-label>Add a Comment</mat-label>
      <textarea
        matInput
        [maxlength]="maxLength"
        cdkTextareaAutosize
        [formControl]="textArea"
        cdkAutosizeMinRows="3"
        cdkAutosizeMaxRows="30"
      ></textarea>
      <mat-hint align="end">{{ remaining }} characters remaining</mat-hint>
    </mat-form-field>
    <div class="add-comment-btn">
      <button
        type="button"
        mat-flat-button
        color="primary"
        (click)="saveComment()"
        [disabled]="remaining === maxLength || !enableSubmit">
        <span *ngIf="!loading"> Submit </span>
        <span *ngIf="loading">
          <mat-spinner
            strokeWidth="2"
            diameter="20"
            color="primary"></mat-spinner>
        </span>
      </button>
    </div>
  </div>
  <div class="comments">
    <div *ngFor="let comment of comments">
      <mat-card style="width: 100%" appearance="outlined">
        <mat-card-header>
          <div
            mat-card-avatar
            [ngClass]="
              isAdminComment(comment)
                ? 'image-circle admin-circle'
                : 'image-circle pet-parent-circle'
            ">
            <img
              *ngIf="showProfileImage(comment)"
              [src]="profileIdImages.get(comment.createdBy?.id)"
              class="profile-image" />
            {{ comment.createdBy?.firstName?.at(0) }}
          </div>
          <mat-card-title class="card-title">
            {{ comment?.createdBy?.firstName }}
            {{ comment?.createdBy?.lastName }}
            <span *ngIf="isAdminComment(comment)">
              <mat-chip-listbox
                hideSingleSelectionIndicator="true"
                [selectable]="false">
                <mat-chip-option
                  color="primary"
                  selected
                  style="height: 20px; font-size: 0.75rem">
                  Admin
                </mat-chip-option>
              </mat-chip-listbox>
            </span>
          </mat-card-title>
          <mat-card-subtitle class="card-metadata">
            {{ comment?.dateCreated | date : "short" : timeZone }}
          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <p>
            {{ comment?.text }}
          </p>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
