import { ResolveFn, Router } from '@angular/router';
import { FipAdminApiService } from '../../service/api/fip-admin-api.service';
import { inject } from '@angular/core';
import { CatResolverData } from '../../model/models';
import { catchError, forkJoin, map, switchMap, throwError } from 'rxjs';
import { handleAvatarResponse } from '../../service/utils/ui-utils';

export const catResolver: ResolveFn<CatResolverData | null> = (
  route,
  state
) => {
  const fipAdminApiService = inject(FipAdminApiService);
  const router = inject(Router);
  const catId = route.paramMap.get('id');
  if (catId) {
    const comments$ = fipAdminApiService.getComments(catId);
    const medicalCarePlan$ = fipAdminApiService.getMedicalCarePlan(catId);
    const catDates$ = fipAdminApiService.getCatDates(catId);
    const tasks$ = fipAdminApiService.getTasks(catId);
    const medicalRecords$ = fipAdminApiService.getMedicalRecords(catId);
    const catAvatar$ = fipAdminApiService.getCatAvatar(catId);
    const cat$ = fipAdminApiService.getCat(catId);

    return cat$.pipe(
      catchError(() => {
        void router.navigate(['error/forbidden']);
        return throwError(() => 'Forbidden');
      }),
      switchMap((cat) =>
        forkJoin([
          comments$,
          medicalCarePlan$,
          catDates$,
          tasks$,
          medicalRecords$,
          catAvatar$
        ]).pipe(
          catchError(() => {
            void router.navigate(['error/forbidden']);
            return throwError(() => 'Forbidden');
          }),
          map(
            ([
              comments,
              medicalCarePlan,
              catDates,
              tasks,
              medicalRecords,
              catAvatar
            ]) => {
              if (catAvatar && catAvatar.body) {
                cat.avatar = handleAvatarResponse(catAvatar);
              }
              return <CatResolverData>{
                comments,
                medicalCarePlan,
                catDates,
                tasks,
                medicalRecords,
                cat
              };
            }
          )
        )
      )
    );
  } else {
    void router.navigate(['dashboard']);
    return null;
  }
};
