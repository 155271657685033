import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeAgo'
})
export class TimeAgoPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): unknown {
    const now = new Date();
    const past = new Date(value);
    const diff = now.getTime() - past.getTime();

    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((diff / (1000 * 60)) % 60);
    const seconds = Math.floor((diff / 1000) % 60);

    let timeAgo = '';

    // For days and hours, we dont' need to show minutes or seconds.
    if (days > 0) {
      timeAgo += days + 'd ';
      return timeAgo + 'ago';
    }
    if (hours > 0) {
      timeAgo += hours + 'h ';
      return timeAgo + 'ago';
    }

    if (minutes > 0) {
      timeAgo += minutes + 'm ';
      return timeAgo + 'ago';
    }

    if (seconds > 0) {
      timeAgo += seconds + 's ';
    }

    return timeAgo + 'ago';
  }
}
