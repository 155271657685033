<div class="page-container">
  <h1>Analytics</h1>
  <div>
    <h2>Status Counts</h2>
    <table>
      <tr>
        <th>Status</th>
        <th>Count</th>
        <th>Percentage</th>
      </tr>
      <tr *ngFor="let statusCount of statusCounts">
        <td>{{ statusCount.status }}</td>
        <td>{{ statusCount.statusCount }}</td>
        <td>{{ statusCount.statusPercentage }}% </td>
      </tr>
      <tr>
        <td>Total</td>
        <td>{{getCountTotal() }}</td>
        <td>100% </td>
      </tr>
    </table>
  </div>
  <div style="margin-top: 1rem;">
    <h2>Completed Cats</h2>
    <div>{{completeCats}}</div>
  </div>
</div>
