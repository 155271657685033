import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';
import { CatModalData } from 'src/app/model/models';

@Component({
  selector: 'app-cat-modal',
  templateUrl: './cat-modal.component.html',
  styleUrls: ['./cat-modal.component.scss']
})
export class CatModalComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialogRef<CatModalComponent>
  ) {}

  onSave($event: CatModalData) {
    this.dialog.close($event);
  }

  // This is to make sure the avatar updates if they just upload that
  onClose($event: CatModalData) {
    this.dialog.close($event);
  }
}
