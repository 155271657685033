import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

//https://medium.com/swlh/angular-loading-spinner-using-http-interceptor-63c1bb76517b

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  loadingSub: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private isLoading$$ = new BehaviorSubject<boolean>(true);
  isLoading$ = this.isLoading$$.asObservable();

  setLoading(isLoading: boolean) {
    this.isLoading$$.next(isLoading);
  }
}
