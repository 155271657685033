export const environment = {
  production: true,
  fipAdminApiUrl: 'https://fip-admin-api-prod.herokuapp.com/api',
  auth: {
    domain: 'fip-oasis.us.auth0.com',
    clientId: 'RMfO5kQuQ0MOumtC91Sh6fqkkHPevTeU',
    authorizationParams: {
      redirect_uri: window.location.origin,
      audience: 'https://prod-fip-admin-api'
    }
  },
  namespace: 'https://fipoasis.com',
  stripePublicKey:
    'pk_live_51N6zldGe9mTofgPH7UOXe7WfclfbSYNEEvYTwPCbiDxdAU3Jykd41UwckdICXJVq4O1gn3uxtmiErrO7VBAa16q2008xyIGpaj'
};
