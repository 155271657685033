<div class="page-container">
  <h1>Requisitions</h1>
  <p>
    This page is for requesting <strong>Karma</strong>,
    <strong>Cappella</strong>, or <strong>Rainbow</strong> products. When you
    submit a new requisition it will be sent to the manufacturer for processing.
  </p>
  <p>
    You will be requested to send payment to the manufacturer per their
    protocols for shipment of products. The tracking number will be stored
    inside the requisition within 24 hours of the manufacturer receiving payment
  </p>
  <p><strong>Payments are sent through PayPal only.</strong></p>
  <p>
    <strong
      >The third-party supplier fulfills orders M-F. The cut off time for same
      day fulfillment is 3PM CST.</strong
    >
  </p>
  <p>
    If you have a question or problem regarding any PayPal transaction with the
    supplier,
    <strong
      >please speak with your admin to resolve the issue with the
      supplier.</strong
    >
  </p>
  <h3><strong>Disclaimer</strong></h3>
  <p>
    FIP Oasis is not responsible for handling payment, transactions, delivery,
    or fulfillment of requisitions.
  </p>
  <hr>
  <button
    mat-flat-button
    color="primary"
    [disabled]="loading"
    (click)="openRequisition(undefined)">
    <mat-icon
      aria-hidden="false"
      aria-label="New Capella Requisition"
      fontIcon="vaccines"></mat-icon
    >New Requisition
  </button>
  <div style="margin-top: 1rem">
    <app-banner
      *ngIf="requirePayment && !applicationData?.isAdmin"
      spacing="compact"
      icon="information"
      >You have approved requisitions, please open the submission and review the
      guidelines to submit payment.</app-banner
    >
  </div>
  <app-requisition-table
    [requisitions]="requisitions"
    [applicationData]="applicationData"
    (openRequisitionModal)="openRequisition($event)"></app-requisition-table>
</div>
