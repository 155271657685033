<div class="filter-area">
  <mat-form-field *ngIf="false">
    <mat-label>Filter</mat-label>
    <input
      matInput
      (keyup)="applyFilter($event)"
      placeholder="Filter Cats..."
      #input />
  </mat-form-field>

  <button
    style="margin-top: 0.5rem"
    mat-stroked-button
    [matMenuTriggerFor]="modifyMenu"
    aria-label="Modify Table">
    Modify
    <mat-icon>filter_list</mat-icon>
  </button>
  <mat-menu #modifyMenu="matMenu">
    <div style="margin: 0.5rem">Columns</div>
    <div
      style="margin-bottom: 1rem;"
      cdkDropList
      (cdkDropListDropped)="columnListDropped($event)"
      cdkDropListOrientation="vertical">
      <div
        cdkDrag
        style="margin: 0.5rem"
        class="modify-menu-item"
        *ngFor="let column of viewableColumns">
        <mat-checkbox
          (change)="changeColumnRows(column.value)"
          [checked]="displayedColumns.includes(column.value)">
          {{ column.name }}
        </mat-checkbox>
        <mat-icon cdkDragHandle>drag_indicator</mat-icon>
      </div>
    </div>
    <div style="margin: 0.5rem">
      <button mat-stroked-button (click)="resetTable()">
        <mat-icon aria-label="Reset table">restart_alt</mat-icon>
        Reset
      </button>
    </div>
  </mat-menu>
</div>

<table mat-table [dataSource]="dataSource">
  <!-- Position Column -->
  <ng-container matColumnDef="day">
    <th mat-header-cell *matHeaderCellDef>Day</th>
    <td mat-cell *matCellDef="let element">{{ element.treatmentDayNumber }}</td>
  </ng-container>

  <!-- dose Column -->
  <ng-container matColumnDef="dose">
    <th mat-header-cell *matHeaderCellDef>Dose</th>
    <td mat-cell *matCellDef="let element">
      {{ element.dose }} {{ element.doseUnit }}
    </td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="weight">
    <th mat-header-cell *matHeaderCellDef>Weight</th>
    <td mat-cell *matCellDef="let element">
      {{ element.weight }} {{ element.weightUnit }}
    </td>
  </ng-container>

  <!-- Date Column -->
  <ng-container matColumnDef="treatmentDate">
    <th mat-header-cell *matHeaderCellDef>Date</th>
    <td mat-cell *matCellDef="let element">
      {{ element.treatmentDate | date : "shortDate" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="brand">
    <th mat-header-cell *matHeaderCellDef>Brand</th>
    <td mat-cell *matCellDef="let element">
      {{ element.medicationBrand.name }}
    </td>
  </ng-container>

  <ng-container matColumnDef="comment">
    <th mat-header-cell *matHeaderCellDef>Comment</th>
    <td mat-cell *matCellDef="let element">
      {{ element.comment }}
    </td>
  </ng-container>

  <ng-container matColumnDef="deleteRecord">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <button
        mat-icon-button
        (click)="deleteRecord($event, element?.id)"
        matTooltip="Delete Record">
        <mat-icon class="delete-icon">delete</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: displayedColumns"
    (click)="recordOnClick(row)"></tr>
</table>
<mat-paginator
  #paginator
  [pageSizeOptions]="[5, 10, 20]"
  showFirstLastButtons
  aria-label="Select page of medical records">
</mat-paginator>
