<table mat-table [dataSource]="dataSource">
  <!-- Name Column -->
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>Name</th>
    <td mat-cell *matCellDef="let element">
      {{ element.firstName + " " + element.lastName }}
    </td>
  </ng-container>

  <ng-container matColumnDef="organizations">
    <th mat-header-cell *matHeaderCellDef>Organizations</th>
    <td mat-cell *matCellDef="let element">
      {{getOrganizations(element.organizations)}}
    </td>
  </ng-container>

  <!-- City Column -->
  <ng-container matColumnDef="city">
    <th mat-header-cell *matHeaderCellDef>City</th>
    <td mat-cell *matCellDef="let element">{{ element.city }}</td>
  </ng-container>

  <!-- State Column -->
  <ng-container matColumnDef="state">
    <th mat-header-cell *matHeaderCellDef>State</th>
    <td mat-cell *matCellDef="let element">{{ element.state }}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: displayedColumns"
    (click)="goToProfile(row)"></tr>
</table>
<mat-paginator
  [pageSizeOptions]="[5, 10, 25]"
  showFirstLastButtons
  aria-label="Select page of profiles"></mat-paginator>
