<h2 mat-dialog-title>{{ viewType }} Medical Record</h2>
<div mat-dialog-content>
  <app-medical-record-form
    [catId]="data?.catId"
    [medicalCarePlan]="data?.medicalCarePlan"
    [medicalRecord]="data?.medicalRecord"
    [medicationBrands]="data?.medicationBrands"
    [recentMedicalRecord]="data?.recentMedicalRecord"
    [applicationData]="data?.applicationData"
    (medicalRecordSave)="onSave($event)"
    (medicalRecordClose)="onClose()"></app-medical-record-form>
</div>
