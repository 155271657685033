<div class="notification-menu-header">
  <h4>Notifications</h4>
  <button
    mat-icon-button
    (click)="clearNotifications()"
    aria-label="clear all notifications"
    matTooltip="Clear All"
    class="clear-all-btn"
    *ngIf="notifications?.length !== 0">
    <mat-icon>done_all</mat-icon>
  </button>
</div>
<mat-divider></mat-divider>
<div class="notification-menu-content">
  <div *ngIf="notifications?.length === 0" class="empty-list">
    You have no notifications...
  </div>
  <div *ngFor="let notification of notifications">
    <div [ngSwitch]="notification?.type">
      <div *ngSwitchCase="notificationStatus.COMMENT">
        <div class="notification" (click)="$event.stopPropagation()">
          <div class="notification-header">
            <span class="indicator"> &#x2022; </span>
            <span class="initiating-account">
              {{ notification.createdBy.firstName }}
              {{ notification.createdBy.lastName }} &nbsp;
            </span>
            {{ getAction(notification.type) }}:
            <span class="spacer"></span>
            <button
              class="dismiss-notification"
              (click)="
                $event.stopPropagation();
                $event.preventDefault();
                clearSingleNotification(notification.id)
              ">
              <mat-icon> close </mat-icon>
            </button>
          </div>
          <div class="notification-body">
            <p>
              {{ notification.text }}
            </p>
          </div>
          <div *ngIf="notification.dateCreated" class="notification-footer">
            <a
              [routerLink]="'/cat/' + notification.catId"
              [queryParams]="{ goTo: 'comment' }"
              >{{ notification.catName }}
            </a>
            - {{ notification.dateCreated | timeAgo }}
          </div>
        </div>
      </div>
      <div *ngSwitchCase="notificationStatus.REQUEST_HELP">
        <a
          [routerLink]="'/cat/' + notification.catId"
          [queryParams]="{ goTo: 'comment' }">
          <div class="notification">
            <div class="notification-header">
              <!-- <span class="indicator"> &#x2022; </span> -->
              <!-- {{ getAction(notification.type) }}: -->
              <span class="spacer"></span>
            </div>
            <div class="notification-body">
              <p>
                <span class="initiating-cat">
                  {{ notification.catName }}
                </span>
                has requested admin assistance
              </p>
            </div>
            <div *ngIf="notification.dateCreated" class="notification-footer">
              {{ notification.dateCreated | timeAgo }}
            </div>
          </div>
        </a>
      </div>
      <div *ngSwitchCase="notificationStatus.ADMIN_ALERT">
        <a [routerLink]="'/cat/' + notification.catId">
          <div class="notification">
            <div class="notification-header">
              <!-- <span class="indicator"> &#x2022; </span> -->
              <!-- {{ getAction(notification.type) }}: -->
              <span class="spacer"></span>
              <button
                class="dismiss-notification"
                (click)="
                  $event.stopPropagation();
                  $event.preventDefault();
                  clearSingleNotification(notification.id)
                ">
                <mat-icon> close </mat-icon>
              </button>
            </div>
            <div class="notification-body">
              <p>
                <span class="initiating-cat">
                  {{ notification.catName }}:
                </span>
                {{ notification.text }}
              </p>
            </div>
            <div *ngIf="notification.dateCreated" class="notification-footer">
              {{ notification.dateCreated | timeAgo }}
            </div>
          </div>
        </a>
      </div>
      <div *ngSwitchCase="notificationStatus.RESPOND_HELP">
        <div class="notification" (click)="$event.stopPropagation()">
          <div class="notification-header">
            <span class="spacer"></span>
          </div>
          <div class="notification-body">
            <div>
              <span class="initiating-cat">
                {{ notification.createdBy.firstName }}
                {{ notification.createdBy.lastName }}
              </span>
              has responded to your help on
              <span class="initiating-cat">
                {{ notification.catName }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div *ngSwitchCase="notificationStatus.REQUISITION">
        <div class="notification" (click)="goToRequisitions()">
          <div class="notification-header">
            <span class="indicator"> &#x2022; </span>
            <div class="initiating-account">Requisition Update</div>
            <span class="spacer"></span>
            <button
              class="dismiss-notification"
              (click)="
                $event.stopPropagation();
                $event.preventDefault();
                clearSingleNotification(notification.id)
              ">
              <mat-icon> close </mat-icon>
            </button>
          </div>
          <div class="notification-body">
            <p>
              {{ notification.text }}
            </p>
          </div>
          <div *ngIf="notification.dateCreated" class="notification-footer">
            By <a>{{ notification.createdBy.firstName }}
            {{ notification.createdBy.lastName }}</a> -
            {{ notification.dateCreated | timeAgo }}
          </div>
        </div>
      </div>
      <div *ngSwitchCase="notificationStatus.BLOOD_WORK_CREATE">
        <div class="notification" (click)="goToCat(notification.catId)">
          <div class="notification-header">
            <span class="indicator"> &#x2022; </span>
            <div class="initiating-account">New Blood Work</div>
            <span class="spacer"></span>
            <button
              class="dismiss-notification"
              (click)="
                $event.stopPropagation();
                $event.preventDefault();
                clearSingleNotification(notification.id)
              ">
              <mat-icon> close </mat-icon>
            </button>
          </div>
          <div class="notification-body">
            <p>
              {{ notification.text }}
            </p>
          </div>
          <div *ngIf="notification.dateCreated" class="notification-footer">
            By <a>{{ notification.createdBy.firstName }}
            {{ notification.createdBy.lastName }}</a> -
            {{ notification.dateCreated | timeAgo }}
          </div>
        </div>
      </div>
      <div *ngSwitchCase="notificationStatus.CAT_REGISTRATION">
        <div class="notification" (click)="goToCat(notification.catId)">
          <div class="notification-header">
            <span class="indicator"> &#x2022; </span>
            <div class="initiating-account">New Cat</div>
            <span class="spacer"></span>
            <button
              class="dismiss-notification"
              (click)="
                $event.stopPropagation();
                $event.preventDefault();
                clearSingleNotification(notification.id)
              ">
              <mat-icon> close </mat-icon>
            </button>
          </div>
          <div class="notification-body">
            <p>
              {{ notification.text }}
            </p>
          </div>
          <div *ngIf="notification.dateCreated" class="notification-footer">
            By
            <a
              >{{ notification.createdBy.firstName }}
              {{ notification.createdBy.lastName }},
              {{ notification.createdBy.city }},
              {{ notification.createdBy.state }}</a
            >
            - {{ notification.dateCreated | timeAgo }}
          </div>
        </div>
      </div>
    </div>

    <mat-divider></mat-divider>
  </div>
</div>
