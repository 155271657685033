<div class="filter-area" *ngIf="applicationData?.isAdmin">
  <button mat-stroked-button (click)="downloadTable()">
    <mat-icon>download</mat-icon>Download Paid
  </button>
  <mat-form-field>
    <mat-label>Filter</mat-label>
    <input
      matInput
      (keyup)="applyFilter($event)"
      placeholder="Filter Requisitions..."
      #input />
  </mat-form-field>
</div>
<table mat-table [dataSource]="dataSource">
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef>Id</th>
    <td mat-cell *matCellDef="let element">{{ element.id }}</td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef>Status</th>
    <td mat-cell *matCellDef="let element">{{ element.status }}</td>
  </ng-container>

  <ng-container matColumnDef="trackingNumber">
    <th mat-header-cell *matHeaderCellDef>Tracking #</th>
    <td mat-cell *matCellDef="let element">{{ element.trackingNumber }}</td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>Name</th>
    <td mat-cell *matCellDef="let element">
      {{ element.firstName + " " + element.lastName }}
    </td>
  </ng-container>

  <ng-container matColumnDef="cat">
    <th mat-header-cell *matHeaderCellDef>Cat</th>
    <td mat-cell *matCellDef="let element">
      {{ element.catName }}
    </td>
  </ng-container>

  <!-- City Column -->
  <ng-container matColumnDef="city">
    <th mat-header-cell *matHeaderCellDef>City</th>
    <td mat-cell *matCellDef="let element">{{ element.city }}</td>
  </ng-container>

  <!-- State Column -->
  <ng-container matColumnDef="state">
    <th mat-header-cell *matHeaderCellDef>State</th>
    <td mat-cell *matCellDef="let element">{{ element.state }}</td>
  </ng-container>

  <ng-container matColumnDef="dateCreated">
    <th mat-header-cell *matHeaderCellDef>Date Created</th>
    <td mat-cell *matCellDef="let element">
      {{ element.dateCreated | date : "short" : timeZone }}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: displayedColumns"
    (click)="openRequisition(row)"></tr>
</table>
<mat-paginator
  [pageSizeOptions]="[5, 10, 25]"
  showFirstLastButtons
  aria-label="Select page of requisitions"></mat-paginator>
