<app-loader *ngIf="loading"></app-loader>
<app-confetti *ngIf="showConfetti"></app-confetti>
<!-- We need to wait for auth0 to get access token, and application data is all setup
 before rendering anything -->
<div class="app">
  <app-navbar class="sticky-navbar"></app-navbar>
  <div class="content">
    <router-outlet></router-outlet>
  </div>
  <span class="spacer"></span>
  <app-footer></app-footer>
</div>
