<table mat-table [dataSource]="dataSource" style="margin-bottom: 1rem">
  <ng-container matColumnDef="group">
    <th mat-header-cell *matHeaderCellDef>Group</th>
    <td
      [class]="getRowColorCssClass(element)"
      mat-cell
      *matCellDef="let element; let i = index">
      {{ getGroupName(element, i) }}
    </td>
  </ng-container>
  <ng-container matColumnDef="test">
    <th mat-header-cell *matHeaderCellDef>Test</th>
    <td
      [class]="getRowColorCssClass(element)"
      mat-cell
      *matCellDef="let element">
      {{ element.bloodWorkMedicalTest.abbreviation ?? "N/A" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="testName">
    <th mat-header-cell *matHeaderCellDef>Test Name</th>
    <td
      [class]="getRowColorCssClass(element)"
      mat-cell
      *matCellDef="let element">
      {{ element.bloodWorkMedicalTest.name }}
    </td>
  </ng-container>

  <ng-container matColumnDef="unit">
    <th mat-header-cell *matHeaderCellDef>Unit</th>
    <td mat-cell *matCellDef="let element">
      {{ element.bloodWorkMedicalTest.unit }}
    </td>
  </ng-container>

  <ng-container matColumnDef="range">
    <th mat-header-cell *matHeaderCellDef>Range</th>
    <td mat-cell *matCellDef="let element">
      <div>H: {{ element.bloodWorkMedicalTest.high }}</div>
      <div>L: {{ element.bloodWorkMedicalTest.low }}</div>
    </td>
  </ng-container>

  <ng-container matColumnDef="value">
    <th mat-header-cell *matHeaderCellDef>Value</th>
    <td mat-cell *matCellDef="let element; let i = index" style="padding-block: .5rem;">
      <mat-form-field
        class="value-field"
        subscriptSizing="dynamic"
        *ngIf="
          bloodWork && bloodWork.bloodWorkTests && bloodWork.bloodWorkTests[i]
        ">
        <div class="blood-work-input">
          <input
            id="value"
            type="number"
            max="999"
            min="0"
            maxlength="3"
            matInput
            (change)="onModelChange($event, i)"
            placeholder=""
            [(ngModel)]="bloodWork.bloodWorkTests[i].value" />
          <div *ngIf="bloodWork.bloodWorkTests[i].value" class="chip">
            <!-- To Ponder About: if its just two possible values, can probably get away with using a ternary instead of switch case -->
            <div [ngSwitch]="getLowHigh(bloodWork.bloodWorkTests[i])">
              <div *ngSwitchCase="'High'">
                <app-chips [color]="'warn'">High</app-chips>
              </div>
              <div *ngSwitchCase="'Low'">
                <app-chips [color]="'warn'">Low</app-chips>
              </div>
              <div *ngSwitchDefault></div>
            </div>
          </div>
        </div>
      </mat-form-field>
    </td>
  </ng-container>

  <div *ngFor="let bloodWork of previousBloodWorks">
    <ng-container matColumnDef="{{ bloodWork.id }}">
      <th mat-header-cell *matHeaderCellDef>
        {{ bloodWork.date | date : "shortDate" }}
      </th>
      <td mat-cell *matCellDef="let element; let i = index">
        <div
          style="text-align: center"
          [class]="getValueColor(bloodWork.bloodWorkTests?.[i])">
          {{ getPreviousBloodWorkValue(bloodWork, i) }}
        </div>
      </td>
    </ng-container>
  </div>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
<!--<mat-paginator-->
<!--  [pageSizeOptions]="[5, 10, 25]"-->
<!--  showFirstLastButtons-->
<!--  aria-label="Select page of bloodworks"></mat-paginator>-->
