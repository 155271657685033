import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { LoadingService } from '../../service/loading/loading.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent {
  emailString!: string;
  formData = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.maxLength(100),
      Validators.email
    ]),
    firstName: new FormControl('', [
      Validators.required,
      Validators.maxLength(60)
    ]),
    lastName: new FormControl('', [
      Validators.required,
      Validators.maxLength(60)
    ]),
    subject: new FormControl('', [Validators.required]),
    message: new FormControl('', [Validators.required]),
    requestType: new FormControl('General', Validators.required)
  });

  requestTypes = ['General', 'Feature Request', 'Report an Issue', 'Support'];

  constructor(private loadingService: LoadingService) {
    this.loadingService.setLoading(false);
  }

  onSubmit(formData: FormGroup) {
    this.emailString = '';
    if (formData.valid) {
      this.emailString = `mailto:support@fipoasis.com?Subject=[${
        formData.get('requestType')?.value
      }] ${formData.get('subject')?.value}&body=Name: ${
        formData.get('firstName')?.value
      } ${formData.get('lastName')?.value} %0A Email: ${
        formData.get('email')?.value
      } %0A %0A ${formData.get('message')?.value}`;
      // Do we need to reset the form?
      // setTimeout(() => {
      //   formData.reset();
      // }, 1000);
    }
  }
}
