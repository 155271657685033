import { Component, OnInit } from '@angular/core';
import { FipAdminApiService } from '../../service/api/fip-admin-api.service';
import { CatRegistration, CatRegistrationListValue } from '../../model/models';
import { CatRegistrationStatus } from '../../model/enums';
import { LoadingService } from '../../service/loading/loading.service';
import { NotificationService } from '../../service/notification/notification.service';
import { ApplicationDataService } from '../../service/data/application-data.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-cat-registration-review',
  templateUrl: './cat-registration-review.component.html',
  styleUrls: ['./cat-registration-review.component.scss']
})
export class CatRegistrationReviewComponent implements OnInit {
  catRegistrations: CatRegistration[] = [];
  startedRegistrations: CatRegistrationListValue[] = [];
  submittedRegistrations: CatRegistrationListValue[] = [];

  isDeveloper: boolean | undefined;

  constructor(
    private fipAdminApiService: FipAdminApiService,
    private loadingService: LoadingService,
    private notificationService: NotificationService,
    private applicationDataService: ApplicationDataService
  ) {
    this.loadingService.setLoading(false);
    this.applicationDataService
      .getApplicationData()
      .pipe(takeUntilDestroyed())
      .subscribe(
        (applicationData) => (this.isDeveloper = applicationData?.isDeveloper)
      );
  }

  ngOnInit() {
    this.notificationService.retrieveNotifications();
    this.fipAdminApiService
      .getCatRegistrations()
      .subscribe((catRegistrations) => {
        this.catRegistrations = catRegistrations;
        this.setCatRegistrationLists();
      });
  }

  private setCatRegistrationLists() {
    this.startedRegistrations = this.mapRegistrations(
      this.catRegistrations,
      CatRegistrationStatus.STARTED
    );

    this.submittedRegistrations = this.mapRegistrations(
      this.catRegistrations,
      CatRegistrationStatus.SUBMITTED
    );
  }

  private mapRegistrations(
    registrations: CatRegistration[],
    catRegistrationStatus: CatRegistrationStatus
  ) {
    return registrations
      .filter((registration) => registration.status === catRegistrationStatus)
      .map((registration) => this.mapRegistrationListValue(registration));
  }

  private mapRegistrationListValue(
    registration: CatRegistration
  ): CatRegistrationListValue {
    const basicInfo = JSON.parse(registration.data).basicInfo;
    const { catName, firstName, lastName, email, state, city } = basicInfo;
    return {
      id: registration.id,
      status: registration.status,
      catName,
      firstName,
      lastName,
      email,
      state,
      city,
      dateCreated: registration.dateCreated
    };
  }

  startCatRegistrationReview(id: string) {
    this.fipAdminApiService
      .startCatRegistrationReview(id)
      .subscribe((catRegistration) => {
        const existingIndex = this.catRegistrations.findIndex(
          (registration) => registration.id === catRegistration.id
        );
        if (existingIndex != -1) {
          this.catRegistrations[existingIndex] = catRegistration;
          this.setCatRegistrationLists();
        }
      });
  }
}
