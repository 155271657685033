<table mat-table [dataSource]="dataSource">
  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef>Date</th>
    <td mat-cell *matCellDef="let element">
      {{ element.date | date : "shortDate" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="description">
    <th mat-header-cell *matHeaderCellDef>Description</th>
    <td mat-cell *matCellDef="let element">{{ element.description }}</td>
  </ng-container>

  <ng-container matColumnDef="view">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <button
        (click)="onEditBloodWork($event, element)"
        mat-icon-button
        matTooltip="Edit BloodWork">
        <mat-icon class="">edit</mat-icon>
      </button>
    </td>
  </ng-container>

  <ng-container matColumnDef="delete">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <button
        mat-icon-button
        (click)="onDeleteBloodWork($event, element)"
        matTooltip="Delete File">
        <mat-icon>delete</mat-icon>
      </button>
    </td>
  </ng-container>


  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: displayedColumns"
    (click)="onRowSelected(row)"></tr>
</table>
<!--<mat-paginator-->
<!--  [pageSizeOptions]="[5, 10, 25]"-->
<!--  showFirstLastButtons-->
<!--  aria-label="Select page of bloodworks"></mat-paginator>-->
