import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FipAdminApiService } from '../../../service/api/fip-admin-api.service';
import { catchError, of } from 'rxjs';
import { HttpStatusCode } from '@angular/common/http';
import { Product } from '../../../model/models';

@Component({
  selector: 'app-payment-breakdown-modal',
  templateUrl: './payment-breakdown-modal.component.html',
  styleUrls: ['./payment-breakdown-modal.component.scss']
})
export class PaymentBreakdownModalComponent implements OnInit {
  productId: string | undefined;
  discount = 0;
  proPrice: number | undefined;
  subTotal: number | undefined;
  discountCode = '';
  appliedCode = '';
  isCodeValid = true;
  invalidCode = '';

  product: Product | undefined;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialogRef<PaymentBreakdownModalComponent>,
    private fipAdminApiService: FipAdminApiService
  ) {
    this.productId = data.productId;
  }

  ngOnInit(): void {
    this.fipAdminApiService.getProduct(this.productId).subscribe((product) => {
      this.product = product;
      if (this.product) {
        this.proPrice = this.product?.price / 100;
        this.subTotal = this.proPrice;
      }
    });
  }

  applyPromoCode() {
    if (this.discountCode) {
      this.fipAdminApiService
        .getPromotionCode(this.discountCode)
        .pipe(
          catchError((error) => {
            if (error.status === HttpStatusCode.NotFound) {
              this.isCodeValid = false;
              this.invalidCode = this.discountCode;
            }
            return of(undefined);
          })
        )
        .subscribe((promotionCode) => {
          if (
            promotionCode &&
            promotionCode.discountPercentage &&
            this.proPrice
          ) {
            this.isCodeValid = true;
            this.discount =
              this.proPrice * (promotionCode.discountPercentage / 100);
            this.subTotal = this.proPrice - this.discount;
            this.appliedCode = this.discountCode;
            this.discountCode = '';
          }
        });
    }
  }

  confirm() {
    this.dialog.close({ confirm: true, promotionCode: this.appliedCode });
  }

  cancel() {
    this.dialog.close({ confirm: false });
  }
}
