import { ApplicationData, Organization, Profile } from '../../../model/models';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { map, Observable, startWith, Subscription } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { countries, State } from 'src/app/model/constants';
import { ApplicationDataService } from 'src/app/service/data/application-data.service';
import { Router } from '@angular/router';
import { FipAdminApiService } from 'src/app/service/api/fip-admin-api.service';
import { SnackbarService } from 'src/app/service/snackbar/snackbar.service';
import { LoadingService } from '../../../service/loading/loading.service';
import {
  findStateByAbbreviation,
  handleAvatarResponse
} from '../../../service/utils/ui-utils';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-profile-form',
  templateUrl: './profile-form.component.html',
  styleUrls: ['./profile-form.component.scss']
})
export class ProfileFormComponent implements OnInit, OnDestroy {
  protected readonly countries = countries;
  applicationData: ApplicationData | undefined;
  applicationData$: Subscription | undefined;

  profile: Profile | undefined;

  @Input()
  set setProfile(profile: Profile | undefined) {
    if (profile) {
      this.profile = profile;
      this.populateProfile(profile);
    }
  }

  assignableOrganizations: Organization[] = [];

  @Input()
  enrollment = false;

  @Output()
  profileSave = new EventEmitter<Profile>();

  profileForm = new FormGroup({
    email: new FormControl<string | undefined>(undefined, [
      Validators.required,
      Validators.maxLength(100),
      Validators.min(2),
      Validators.email
    ]),
    firstName: new FormControl<string | undefined>(undefined, [
      Validators.required,
      Validators.maxLength(60)
    ]),
    lastName: new FormControl<string | undefined>(undefined, [
      Validators.required,
      Validators.maxLength(60)
    ]),
    city: new FormControl<string | undefined>(undefined, [
      Validators.required,
      Validators.maxLength(60)
    ]),
    state: new FormControl<string | undefined>(undefined, [
      Validators.required,
      Validators.maxLength(30)
    ]),
    country: new FormControl('United States', [Validators.required]),
    zipCode: new FormControl<string | undefined>(undefined, [
      Validators.maxLength(11)
    ]),
    organizations: new FormControl<Organization[]>(
      [],
      [Validators.required, Validators.minLength(1)]
    ),
    image: new FormControl(''),
    heardAboutUs: new FormControl<string[]>([])
  });

  filteredStates: Observable<State[]>;
  states: State[] = this.countries[0].states.map((state) => state);
  selectedAvatar: File | undefined;

  avatarUrl: string | undefined;
  heardAboutUsValues: string[] = [
    'FIP Warriors',
    'Friend',
    'Veterinarian',
    'Google',
    'Facebook',
    'Tiktok',
    'Instagram',
    'Other'
  ];

  constructor(
    private applicationDataService: ApplicationDataService,
    private router: Router,
    private loadingService: LoadingService,
    private fipAdminApiService: FipAdminApiService,
    private snackBarService: SnackbarService
  ) {
    this.loadingService.setLoading(false);
    this.filteredStates = this.profileForm.controls.state.valueChanges.pipe(
      startWith(''),
      map((value) =>
        this.states.filter((state) => {
          value = value || '';
          return state.name.toLowerCase().includes(value.toLowerCase());
        })
      )
    );

    this.applicationDataService
      .getApplicationData()
      .pipe(takeUntilDestroyed())
      .subscribe((applicationData) => {
        this.applicationData = applicationData;
        this.assignableOrganizations =
          applicationData?.assignableOrganizations || [];
      });
  }

  ngOnInit() {
    if (this.enrollment && this.profile) {
      this.profileForm.disable();
    }
  }

  ngOnDestroy() {
    if (this.applicationData$) {
      this.applicationData$.unsubscribe();
    }
  }

  get heardAboutUs() {
    return this.profileForm.controls.heardAboutUs;
  }

  goBack() {
    void this.router.navigate(['dashboard']);
  }

  save() {
    if (
      this.profile &&
      this.profile.email != this.profileForm.controls.email.value
    ) {
      alert(
        'WARNING: Emails are tied to the login account. Changing emails could lock the user out of the account.'
      );
    }

    const newProfile: any = {
      ...this.profile,
      ...this.profileForm.getRawValue(),
      email: this.profileForm.controls.email.value?.toLowerCase()
    };

    this.fipAdminApiService.saveProfile(newProfile).subscribe((profile) => {
      this.populateProfile(profile);
      this.profileSave.emit(profile);
      this.snackBarService.openSuccessSnackBar('Profile Saved');
    });
  }

  private populateProfile(profile: Profile) {
    this.profileForm.controls.firstName.setValue(profile.firstName);
    this.profileForm.controls.lastName.setValue(profile.lastName);
    this.profileForm.controls.city.setValue(profile.city);
    this.profileForm.controls.state.setValue(profile.state);
    this.profileForm.controls.zipCode.setValue(profile.zipCode);

    if (profile.country) {
      this.profileForm.controls.country.setValue(profile.country);
      this.countryChange(profile.country, false);
    }

    this.setOrganization(profile.organizations);

    if (profile.email) {
      this.profileForm.controls.email.setValue(profile.email);
    }

    if (profile.id) {
      this.loadAvatar(profile.id);
    }
  }

  setOrganization(organizations: Organization[] | undefined) {
    if (organizations && organizations.length > 0) {
      const orgs = this.assignableOrganizations.filter((assignableOrg) => {
        const matchedOrg = organizations.find(
          (profileOrgs) => profileOrgs.id === assignableOrg.id
        );
        return matchedOrg !== undefined;
      });
      this.profileForm.controls.organizations.setValue(orgs);
    } else if (this.assignableOrganizations.length > 0) {
      // Default org to fip oasis
      this.profileForm.controls.organizations.setValue([
        this.assignableOrganizations[0]
      ]);
    }
  }

  onFileSelected(event: any) {
    this.selectedAvatar = event.target.files[0];
    if (this.selectedAvatar && this.profile?.id) {
      const uploadData = new FormData();
      uploadData.append('file', this.selectedAvatar, this.selectedAvatar.name);
      this.fipAdminApiService
        .uploadProfilePicture(this.profile.id, uploadData)
        .subscribe((avatar) => (this.avatarUrl = handleAvatarResponse(avatar)));
    }
  }

  private loadAvatar(id: string) {
    this.fipAdminApiService
      .getProfileAvatar(id)
      .subscribe((avatar) => (this.avatarUrl = handleAvatarResponse(avatar)));
  }

  countryChange(country: string, reset: boolean) {
    const states = this.countries.find((c) => c.country === country)?.states;
    if (states) {
      this.states = states.map((state) => state);
    }

    if (reset) {
      this.profileForm.controls.state.reset();
      this.profileForm.controls.zipCode.reset();
    }
  }

  checkState($event: any) {
    this.profileForm.controls.state.setValue(
      findStateByAbbreviation($event.target.value, this.states)
    );
  }

  updateValue(checked: boolean, value: string) {
    let heardAboutUs = this.heardAboutUs.value;
    if (checked) {
      heardAboutUs?.push(value);
    } else if (heardAboutUs) {
      heardAboutUs = heardAboutUs?.filter((option) => option != value);
    }

    console.log(heardAboutUs);
    this.heardAboutUs.setValue(heardAboutUs);
  }
}
