<div>
  <app-banner  *ngIf="bloodWorks.length > 0" spacing="compact"
    >Click each row to modify the test values.</app-banner
  >
  <button
    type="submit"
    mat-flat-button
    color="primary"
    (click)="openBloodWork(undefined)">
    <mat-icon
      aria-hidden="false"
      aria-label="Create New Blood Work"
      fontIcon="bloodtype"></mat-icon>
    New Blood Work
  </button>
  <app-blood-work-table
    *ngIf="bloodWorks.length > 0"
    [bloodWorks]="bloodWorks"
    (rowSelected)="openBloodWorkTests($event)"
    (deleteBloodWork)="deleteBloodWorkConfirm($event)"
    (editBloodWork)="openBloodWork($event)"></app-blood-work-table>
</div>
