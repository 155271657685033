import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApplicationData, MedicationBrand } from '../../model/models';
import { ApplicationDataService } from '../../service/data/application-data.service';
import { LoadingService } from '../../service/loading/loading.service';
import {
  determineDosage,
  dosePills,
  doseShot
} from '../../service/utils/dose-utils';
import { FipAdminApiService } from '../../service/api/fip-admin-api.service';
import { SnackbarService } from '../../service/snackbar/snackbar.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { AnalyticsService } from '../../service/analytics/analytics.service';
import { diseasesSelection, treatmentTypes } from '../../model/constants';

@Component({
  selector: 'app-cost-estimator',
  templateUrl: './cost-estimator.component.html',
  styleUrls: ['./cost-estimator.component.scss']
})
export class CostEstimatorComponent implements OnInit {
  costEstimatorForm = new FormGroup({
    diseases: new FormControl<[]>([], [Validators.required]),
    prescription: new FormControl<boolean>(false, [Validators.required]),
    weight: new FormControl<number | undefined>(undefined, [
      Validators.required,
      Validators.max(100),
      Validators.maxLength(3)
    ]),
    weightUnit: new FormControl<string | undefined>('lb', [
      Validators.required
    ]),
    dosage: new FormControl<number | undefined>(undefined, [
      Validators.required
    ]),
    treatmentType: new FormControl<string | undefined>(undefined, [
      Validators.required
    ]),
    medicationBrand: new FormControl<MedicationBrand | undefined>(undefined, [
      Validators.required
    ])
  });

  applicationData: ApplicationData | undefined;
  filteredSelectionBrands: MedicationBrand[] | undefined;
  medicationBrands: MedicationBrand[] | undefined;
  items = '';

  discounts = [
    {
      name: 'Lucky',
      percent: 20
    },
    {
      name: 'Aura',
      percent: 20
    },
    {
      name: 'Valor',
      percent: 20
    }
  ];

  shareLink = '';

  constructor(
    private applicationDataService: ApplicationDataService,
    private loadingService: LoadingService,
    private fipAdminApiService: FipAdminApiService,
    private snackBarService: SnackbarService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private location: Location,
    private analyticsService: AnalyticsService
  ) {
    this.loadingService.setLoading(false);
  }

  ngOnInit() {
    this.applicationDataService
      .getApplicationData()
      .subscribe((applicationData) => {
        this.applicationData = applicationData;
        if (applicationData && applicationData.medicationBrands) {
          this.setMedicationBrands(applicationData.medicationBrands);
        } else {
          this.getMedicationBrands();
        }
      });

    this.costEstimatorForm.valueChanges.subscribe(() => {
      this.generateShareLink();
    });
  }

  setValuesFromQueryParams() {
    this.activatedRoute.queryParams.subscribe((params: any) => {
      debugger;
      if (params.weight) {
        this.weight.setValue(Number(params.weight));
      }

      if (params.weightUnit) {
        this.weightUnit.setValue(params.weightUnit);
      }

      if (params.diseases) {
        this.diseases.setValue(params.diseases.split(','));
      }

      if (params.dosage) {
        this.dosage.setValue(Number(params.dosage));
      }

      if (params.prescription) {
        this.prescription.setValue(params.prescription === 'true');
      }

      if (params.treatmentType) {
        this.treatmentType.setValue(params.treatmentType);
        this.treatmentTypeChange(params.treatmentType);
      }

      if (params.medicationBrand) {
        const paramBrand = this.filteredSelectionBrands?.find(
          (brand) => brand.name === params.medicationBrand
        );

        this.medicationBrand.setValue(paramBrand);
        this.onMedicationBrandChange();
      }

      // Clear the query params
      // void this.router.navigate(['.'], {
      //   relativeTo: this.activatedRoute,
      //   queryParams: {}
      // });
    });
  }

  treatmentTypeChange(treatmentType: string) {
    this.filterBrands(treatmentType);
    if (treatmentType === 'Shot') {
      this.items = 'vials';
    } else {
      this.items = 'pills';
    }
    this.costEstimatorForm.controls.medicationBrand.reset();
    this.costEstimatorForm.controls.medicationBrand.enable();
  }

  filterBrands(treatmentType: string) {
    this.filteredSelectionBrands = this.medicationBrands?.filter(
      (brand) =>
        brand.type === treatmentType &&
        this.prescription.value === brand.prescription
    );
  }

  onUnitChange() {
    if (this.weightUnit.value === 'lb' && this.weight.value) {
      this.weight.setValue(Number((this.weight.value * 2.2).toFixed(2)));
    }

    if (this.weightUnit.value === 'kg' && this.weight.value) {
      this.weight.setValue(Number((this.weight.value / 2.2).toFixed(2)));
    }
  }

  private getWeightInKg(): number {
    if (this.weightUnit.value === 'lb' && this.weight.value) {
      return Number((this.weight.value / 2.2).toFixed(2));
    }
    return this.weight.value || 0;
  }

  get medicationBrand() {
    return this.costEstimatorForm.controls.medicationBrand;
  }

  get weight() {
    return this.costEstimatorForm.controls.weight;
  }

  get weightUnit() {
    return this.costEstimatorForm.controls.weightUnit;
  }

  get dosage() {
    return this.costEstimatorForm.controls.dosage;
  }

  get treatmentType() {
    return this.costEstimatorForm.controls.treatmentType;
  }

  get diseases() {
    return this.costEstimatorForm.controls.diseases;
  }

  get prescription() {
    return this.costEstimatorForm.controls.prescription;
  }

  getTotalCost(medicationBrand: MedicationBrand) {
    const price = this.getPrice(medicationBrand);
    return Math.ceil(price * this.getTotalItems(medicationBrand));
  }

  getDailyDose(medicationBrand: MedicationBrand) {
    const weight = this.weight.value;
    const dosage = this.dosage.value;
    const concentration = medicationBrand.concentration;
    if (weight && dosage && concentration) {
      if (this.treatmentType.value === 'Shot') {
        return doseShot(this.weightUnit.value, weight, dosage, concentration);
      } else if (this.treatmentType.value === 'Pill') {
        const mgPerKg = dosePills(
          this.weightUnit.value,
          weight,
          dosage,
          medicationBrand
        );

        // TODO: Remove when I start storing the scores for pills
        if (medicationBrand.name.includes('Compounded')) {
          return Number(
            (
              mgPerKg / (this.medicationBrand.value?.concentration || 1)
            ).toFixed(2)
          );
        }

        return Math.ceil(
          mgPerKg / (this.medicationBrand.value?.concentration || 1)
        );
      }
    }

    return 0;
  }

  getTotalItems(medicationBrand: MedicationBrand) {
    const dailyDose = this.getDailyDose(medicationBrand);
    let totalItems = dailyDose * 84;

    if (this.treatmentType.value === 'Shot') {
      totalItems = totalItems / medicationBrand.size;
    }

    return Math.ceil(totalItems);
  }

  getWeeklyItems(medicationBrand: any) {
    const totalItems = this.getTotalItems(medicationBrand);
    return Math.ceil(totalItems / 12);
  }

  public getPrice(medicationBrand: MedicationBrand) {
    const discount = this.discounts.find((discount) =>
      medicationBrand.name.includes(discount.name)
    );
    if (discount) {
      return (
        medicationBrand.usd - medicationBrand.usd * (discount.percent / 100)
      );
    }
    return medicationBrand.usd;
  }

  private setMedicationBrands(medicationBrands: MedicationBrand[]) {
    this.medicationBrands = medicationBrands.filter(
      (medicationBrand) =>
        !medicationBrand.name.includes('GS') &&
        !medicationBrand.name.includes('Rose 20mg') &&
        !medicationBrand.name.includes('Rose 2.5')
    );
    this.setValuesFromQueryParams();
  }

  private getMedicationBrands() {
    this.fipAdminApiService
      .getMedicationBrands()
      .subscribe((medicationBrands) => {
        this.setMedicationBrands(medicationBrands);
      });
  }

  copyLink() {
    //this.shareLink = this.generateShareLink();
    navigator.clipboard
      .writeText(this.shareLink)
      .then(() =>
        this.snackBarService.openSuccessSnackBar('Link copied to clipboard.')
      )
      .catch(() =>
        this.snackBarService.openFailureSnackBar('Failed to copy to clipboard.')
      );
  }

  private generateShareLink() {
    const queryParams = new URLSearchParams();
    if (this.weight.value) {
      queryParams.set('weight', this.weight.value.toString());
    }
    if (this.weightUnit.value) {
      queryParams.set('weightUnit', this.weightUnit.value);
    }
    if (this.dosage.value) {
      queryParams.set('dosage', this.dosage.value.toString());
    }
    if (this.treatmentType.value) {
      queryParams.set('treatmentType', this.treatmentType.value);
    }

    if (this.medicationBrand.value) {
      queryParams.set('medicationBrand', this.medicationBrand.value?.name);
    }

    if (this.diseases.value && this.diseases.value?.length > 0) {
      queryParams.set('diseases', this.diseases.value?.toString());
    }

    queryParams.set(
      'prescription',
      this.prescription.value?.toString() || 'false'
    );

    this.location.replaceState('cost-estimator', queryParams.toString());
    //return 'http://localhost:4200/cost-estimator?' + queryParams.toString();
  }

  onMedicationBrandChange() {
    /* this.analyticsService.sendAnalyticsEvent(
      this.applicationData,
      undefined,
      AnalyticsEventEnum.COST_ESTIMATOR_BRAND_CHANGE,
      this.medicationBrand.value?.name
    ); */
  }

  protected readonly treatmentTypes = treatmentTypes;
  protected readonly diseasesSelection = diseasesSelection;

  onDiseasesChange() {
    const dosage = determineDosage(this.diseases.value ?? [], false, false);
    this.dosage.setValue(dosage);
  }

  filterPrescriptionBrands() {
    this.filteredSelectionBrands = this.medicationBrands?.filter((brand) => {
      return brand.prescription === this.prescription.value;
    });
    //console.log(this.filteredSelectionBrands);
    this.costEstimatorForm.controls.treatmentType.reset();
    this.costEstimatorForm.controls.medicationBrand.reset();
    this.costEstimatorForm.controls.medicationBrand.disable();
  }
}
