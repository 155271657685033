export const routes = {
  // regular logged in user
  loggedIn: [{ routeURL: '/profile', text: 'My Profile', adminOnly: false }],
  // pro members who have paid
  pro: [
    { routeURL: '/dashboard', text: 'Dashboard', adminOnly: false },
    { routeURL: '/user', text: 'My Profile', adminOnly: false },
    { routeURL: '/analytics', text: 'Analytics', adminOnly: true },
    { routeURL: '/cost-estimator', text: 'Cost Estimator', adminOnly: false }
    /* {
      routeURL: '/registration/cat/review',
      text: 'Cat Registrations',
      adminOnly: true
    },
    {
      routeURL: '/registration/profile/review',
      text: 'Profile Registrations',
      adminOnly: true
    } */
  ]
};
