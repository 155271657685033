import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApplicationData } from '../../model/models';

@Injectable({
  providedIn: 'root'
})
export class ApplicationDataService {
  private _applicationData: ApplicationData | undefined;

  private applicationData: BehaviorSubject<ApplicationData | undefined> =
    new BehaviorSubject<ApplicationData | undefined>(undefined);

  public setData(object: any) {
    this._applicationData = { ...this._applicationData, ...object };
    this.applicationData.next(this._applicationData);
  }

  public getApplicationData(): BehaviorSubject<ApplicationData | undefined> {
    return this.applicationData;
  }
}
