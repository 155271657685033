<form [formGroup]="taskForm" class="task-grid">
  <mat-form-field>
    <mat-label>Task Type</mat-label>
    <mat-select
      id="taskType"
      formControlName="taskType">
      <mat-option
        *ngFor="let taskType of taskTypeData" [value]="taskType">
        {{ taskType.title }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="description">
    <mat-label>Description</mat-label>
    <input
      id="description"
      matInput
      placeholder=""
      formControlName="description" />
  </mat-form-field>

  <mat-form-field hintLabel="MM/DD/YYYY" class="due-date">
    <mat-label>Due Date</mat-label>
    <input matInput [matDatepicker]="dueDate" formControlName="dueDate" />
    <mat-datepicker-toggle
      matIconSuffix
      [for]="dueDate"></mat-datepicker-toggle>
    <mat-datepicker #dueDate></mat-datepicker>
  </mat-form-field>

  <mat-checkbox class="resolved" id="resolved" formControlName="resolved">Resolved</mat-checkbox>

  <div *ngIf="isAdmin" class="notified">
    <mat-checkbox id="notified" formControlName="notified" (change)="setNotifiedOn()">Notified</mat-checkbox>
    <mat-form-field *ngIf="notified.value" hintLabel="MM/DD/YYYY" class="notifiedOn">
      <mat-label>Notified On</mat-label>
      <input
        id="notifiedOn"
        matInput
        [matDatepicker]="notifiedOn"
        formControlName="notifiedOn" />
      <mat-datepicker-toggle
        matIconSuffix
        [for]="notifiedOn"></mat-datepicker-toggle>
      <mat-datepicker #notifiedOn></mat-datepicker>
    </mat-form-field>
  </div>

</form>

<div class="action-footer">
  <button mat-stroked-button (click)="close()">Close</button>
  <button mat-flat-button color="primary" [disabled]="!taskForm.valid" (click)="save()">Save</button>
</div>
