export interface Country {
  country: string;
  type: string;
  states: State[];
}
export interface State {
  name: string;
  abbreviation: string;
}

export const countries: Country[] = [
  {
    country: 'United States',
    type: 'State',
    states: [
      { name: 'Alabama', abbreviation: 'AL' },
      { name: 'Alaska', abbreviation: 'AK' },
      { name: 'Arizona', abbreviation: 'AZ' },
      { name: 'Arkansas', abbreviation: 'AR' },
      { name: 'California', abbreviation: 'CA' },
      { name: 'Colorado', abbreviation: 'CO' },
      { name: 'Connecticut', abbreviation: 'CT' },
      { name: 'Delaware', abbreviation: 'DE' },
      { name: 'Florida', abbreviation: 'FL' },
      { name: 'Georgia', abbreviation: 'GA' },
      { name: 'Hawaii', abbreviation: 'HI' },
      { name: 'Idaho', abbreviation: 'ID' },
      { name: 'Illinois', abbreviation: 'IL' },
      { name: 'Indiana', abbreviation: 'IN' },
      { name: 'Iowa', abbreviation: 'IA' },
      { name: 'Kansas', abbreviation: 'KS' },
      { name: 'Kentucky', abbreviation: 'KY' },
      { name: 'Louisiana', abbreviation: 'LA' },
      { name: 'Maine', abbreviation: 'ME' },
      { name: 'Maryland', abbreviation: 'MD' },
      { name: 'Massachusetts', abbreviation: 'MA' },
      { name: 'Michigan', abbreviation: 'MI' },
      { name: 'Minnesota', abbreviation: 'MN' },
      { name: 'Mississippi', abbreviation: 'MS' },
      { name: 'Missouri', abbreviation: 'MO' },
      { name: 'Montana', abbreviation: 'MT' },
      { name: 'Nebraska', abbreviation: 'NE' },
      { name: 'Nevada', abbreviation: 'NV' },
      { name: 'New Hampshire', abbreviation: 'NH' },
      { name: 'New Jersey', abbreviation: 'NJ' },
      { name: 'New Mexico', abbreviation: 'NM' },
      { name: 'New York', abbreviation: 'NY' },
      { name: 'North Carolina', abbreviation: 'NC' },
      { name: 'North Dakota', abbreviation: 'ND' },
      { name: 'Ohio', abbreviation: 'OH' },
      { name: 'Oklahoma', abbreviation: 'OK' },
      { name: 'Oregon', abbreviation: 'OR' },
      { name: 'Pennsylvania', abbreviation: 'PA' },
      { name: 'Rhode Island', abbreviation: 'RI' },
      { name: 'South Carolina', abbreviation: 'SC' },
      { name: 'South Dakota', abbreviation: 'SD' },
      { name: 'Tennessee', abbreviation: 'TN' },
      { name: 'Texas', abbreviation: 'TX' },
      { name: 'Utah', abbreviation: 'UT' },
      { name: 'Vermont', abbreviation: 'VT' },
      { name: 'Virginia', abbreviation: 'VA' },
      { name: 'Washington', abbreviation: 'WA' },
      { name: 'West Virginia', abbreviation: 'WV' },
      { name: 'Wisconsin', abbreviation: 'WI' },
      { name: 'Wyoming', abbreviation: 'WY' }
    ]
  },
  {
    country: 'Canada',
    type: 'Providence',
    states: [
      { name: 'Alberta', abbreviation: 'AB' },
      { name: 'British Columbia', abbreviation: 'BC' },
      { name: 'Manitoba', abbreviation: 'MB' },
      { name: 'New Brunswick', abbreviation: 'NB' },
      { name: 'Newfoundland and Labrador', abbreviation: 'NL' },
      { name: 'Northwest Territories', abbreviation: 'NT' },
      { name: 'Nova Scotia', abbreviation: 'NS' },
      { name: 'Nunavut', abbreviation: 'NU' },
      { name: 'Ontario', abbreviation: 'ON' },
      { name: 'Prince Edward Island', abbreviation: 'PE' },
      { name: 'Quebec', abbreviation: 'QC' },
      { name: 'Saskatchewan', abbreviation: 'SK' },
      { name: 'Yukon', abbreviation: 'YT' }
    ]
  },
  {
    country: 'UAE',
    type: 'State',
    states: [
      { name: 'Abu Dhabi', abbreviation: 'AD' },
      { name: 'Dubai', abbreviation: 'DU' },
      { name: 'Sharjah', abbreviation: 'SH' },
      { name: 'Ajman', abbreviation: 'AJ' },
      { name: 'Umm Al-Quwain', abbreviation: 'UAQ' },
      { name: 'Ras Al Khaimah', abbreviation: 'RAK' },
      { name: 'Fujairah', abbreviation: 'FUJ' }
    ]
  },
  {
    country: 'France',
    type: 'Region', // France has regions instead of states
    states: [
      { name: 'Auvergne-Rhône-Alpes', abbreviation: 'ARA' },
      { name: 'Bourgogne-Franche-Comté', abbreviation: 'BFC' },
      { name: 'Brittany (Bretagne)', abbreviation: 'BRE' },
      { name: 'Centre-Val de Loire', abbreviation: 'CVL' },
      { name: 'Corsica (Corse)', abbreviation: 'COR' },
      { name: 'Grand Est', abbreviation: 'GES' },
      { name: 'Hauts-de-France', abbreviation: 'HDF' },
      { name: 'Île-de-France', abbreviation: 'IDF' },
      { name: 'Normandy (Normandie)', abbreviation: 'NOR' },
      { name: 'Nouvelle-Aquitaine', abbreviation: 'NAQ' },
      { name: 'Occitanie', abbreviation: 'OCC' },
      { name: 'Pays de la Loire', abbreviation: 'PDL' },
      { name: "Provence-Alpes-Côte d'Azur", abbreviation: 'PAC' }
    ]
  },
  {
    country: 'Belgium',
    type: 'Region',
    states: [
      { name: 'Flanders (Vlaams Gewest)', abbreviation: 'FLD' },
      { name: 'Wallonia (Région wallonne)', abbreviation: 'WAL' },
      {
        name: 'Brussels-Capital Region (Région de Bruxelles-Capitale)',
        abbreviation: 'BRU'
      },
      { name: 'Flemish Community (Vlaamse Gemeenschap)', abbreviation: 'FLC' },
      { name: 'French Community (Communauté française)', abbreviation: 'FRC' },
      {
        name: 'German-speaking Community (Deutschsprachige Gemeinschaft)',
        abbreviation: 'GER'
      }
    ]
  },
  {
    country: 'Netherlands',
    type: 'Providence',
    states: [
      { name: 'Drenthe', abbreviation: 'DR' },
      { name: 'Flevoland', abbreviation: 'FL' },
      { name: 'Friesland', abbreviation: 'FR' },
      { name: 'Gelderland', abbreviation: 'GE' },
      { name: 'Groningen', abbreviation: 'GR' },
      { name: 'Limburg', abbreviation: 'LI' },
      { name: 'North Brabant (Noord-Brabant)', abbreviation: 'NB' },
      { name: 'North Holland (Noord-Holland)', abbreviation: 'NH' },
      { name: 'Overijssel', abbreviation: 'OV' },
      { name: 'South Holland (Zuid-Holland)', abbreviation: 'ZH' },
      { name: 'Utrecht', abbreviation: 'UT' },
      { name: 'Zeeland', abbreviation: 'ZE' }
    ]
  },
  {
    country: 'Greece',
    type: 'Region',
    states: [
      { name: 'Attica (Αττική)', abbreviation: 'AT' },
      { name: 'Central Greece (Στερεά Ελλάδα)', abbreviation: 'ST' },
      { name: 'Central Macedonia (Κεντρική Μακεδονία)', abbreviation: 'CM' },
      { name: 'Crete (Κρήτη)', abbreviation: 'CR' },
      {
        name: 'Eastern Macedonia and Thrace (Ανατολική Μακεδονία και Θράκη)',
        abbreviation: 'EMT'
      },
      { name: 'Epirus (Ήπειρος)', abbreviation: 'EP' },
      { name: 'Ionian Islands (Ιόνια Νησιά)', abbreviation: 'ION' },
      { name: 'North Aegean (Βόρειο Αιγαίο)', abbreviation: 'NA' },
      { name: 'Peloponnese (Πελοπόννησος)', abbreviation: 'PEL' },
      { name: 'South Aegean (Νότιο Αιγαίο)', abbreviation: 'SA' },
      { name: 'Thessaly (Θεσσαλία)', abbreviation: 'THE' },
      { name: 'Western Greece (Δυτική Ελλάδα)', abbreviation: 'WG' },
      { name: 'Western Macedonia (Δυτική Μακεδονία)', abbreviation: 'WM' }
    ]
  },
  {
    country: 'Cyprus',
    type: 'District',
    states: [
      { name: 'Famagusta (Αμμόχωστος)', abbreviation: 'AM' },
      { name: 'Kyrenia (Κερύνεια)', abbreviation: 'KY' },
      { name: 'Larnaca (Λάρνακα)', abbreviation: 'LA' },
      { name: 'Limassol (Λεμεσός)', abbreviation: 'LE' },
      { name: 'Nicosia (Λευκωσία)', abbreviation: 'NI' },
      { name: 'Paphos (Πάφος)', abbreviation: 'PA' }
    ]
  },
  {
    country: 'Malta',
    type: 'Region',
    states: [
      { name: "Malta Island (Il-Gżira ta' Malta)", abbreviation: 'MI' },
      { name: "Gozo Island (Il-Gżira ta' Għawdex)", abbreviation: 'GI' }
    ]
  },
  {
    country: 'Poland',
    type: 'Region',
    states: [
      { name: 'Greater Poland (Wielkopolskie)', abbreviation: 'WP' },
      {
        name: 'Kuyavian-Pomeranian (Kujawsko-Pomorskie)',
        abbreviation: 'KP'
      },
      { name: 'Lesser Poland (Małopolskie)', abbreviation: 'MP' },
      { name: 'Lublin (Lubelskie)', abbreviation: 'LB' },
      { name: 'Lubusz (Lubuskie)', abbreviation: 'LS' },
      { name: 'Łódź (Łódzkie)', abbreviation: 'LD' },
      { name: 'Masovian (Mazowieckie)', abbreviation: 'MZ' },
      { name: 'Opole (Opolskie)', abbreviation: 'OP' },
      { name: 'Podkarpackie', abbreviation: 'PK' },
      { name: 'Podlaskie', abbreviation: 'PD' },
      { name: 'Pomeranian (Pomorskie)', abbreviation: 'PM' },
      { name: 'Silesian (Śląskie)', abbreviation: 'SL' },
      { name: 'Świętokrzyskie', abbreviation: 'SK' },
      {
        name: 'Warmian-Masurian (Warmińsko-Mazurskie)',
        abbreviation: 'WM'
      },
      {
        name: 'West Pomeranian (Zachodniopomorskie)',
        abbreviation: 'ZP'
      }
    ]
  },
  {
    country: 'North Macedonia',
    type: 'Municipality',
    states: [
      { name: 'Skopje (Скопје)', abbreviation: 'SK' },
      { name: 'Bitola (Битола)', abbreviation: 'BT' },
      { name: 'Kumanovo (Куманово)', abbreviation: 'KM' },
      { name: 'Prilep (Прилеп)', abbreviation: 'PR' },
      { name: 'Tetovo (Тетово)', abbreviation: 'TT' },
      { name: 'Ohrid (Охрид)', abbreviation: 'OH' },
      { name: 'Gostivar (Гостивар)', abbreviation: 'GS' },
      { name: 'Strumica (Струмица)', abbreviation: 'SM' },
      { name: 'Veles (Велес)', abbreviation: 'VL' },
      { name: 'Štip (Штип)', abbreviation: 'ST' },
      { name: 'Kavadarci (Кавадарци)', abbreviation: 'KV' },
      { name: 'Kočani (Кочани)', abbreviation: 'KC' },
      { name: 'Gevgelija (Гевгелија)', abbreviation: 'GV' },
      { name: 'Struga (Струга)', abbreviation: 'SG' },
      { name: 'Radoviš (Радовиш)', abbreviation: 'RD' },
      { name: 'Gjorce Petrov (Ѓорче Петров)', abbreviation: 'GP' },
      { name: 'Saraj (Сарај)', abbreviation: 'SR' },
      { name: 'Kratovo (Кратово)', abbreviation: 'KT' },
      { name: 'Probishtip (Пробиштип)', abbreviation: 'PB' },
      { name: 'Demir Kapija (Демир Капија)', abbreviation: 'DK' },
      { name: 'Negotino (Неготино)', abbreviation: 'NG' },
      { name: 'Valandovo (Валандово)', abbreviation: 'VD' },
      { name: 'Vinica (Виница)', abbreviation: 'VC' },
      { name: 'Delčevo (Делчево)', abbreviation: 'DL' },
      { name: 'Kriva Palanka (Крива Паланка)', abbreviation: 'KP' },
      {
        name: 'Makedonska Kamenica (Македонска Каменица)',
        abbreviation: 'MK'
      },
      { name: 'Resen (Ресен)', abbreviation: 'RS' },
      { name: 'Opština Ilinden (Општина Илинден)', abbreviation: 'IL' },
      {
        name: 'Opština Kisela Voda (Општина Кисела Вода)',
        abbreviation: 'KV'
      },
      { name: 'Opština Chair (Општина Чаир)', abbreviation: 'CH' },
      { name: 'Opština Saraj (Општина Сарај)', abbreviation: 'SR' }
    ]
  },
  {
    country: 'Italy',
    type: 'Region',
    states: [
      { name: 'Abruzzo', abbreviation: 'AB' },
      { name: 'Basilicata', abbreviation: 'BA' },
      { name: 'Calabria', abbreviation: 'CA' },
      { name: 'Campania', abbreviation: 'CM' },
      { name: 'Emilia-Romagna', abbreviation: 'ER' },
      { name: 'Friuli-Venezia Giulia', abbreviation: 'FVG' },
      { name: 'Lazio', abbreviation: 'LAZ' },
      { name: 'Liguria', abbreviation: 'LI' },
      { name: 'Lombardy (Lombardia)', abbreviation: 'LO' },
      { name: 'Marche', abbreviation: 'MAR' },
      { name: 'Molise', abbreviation: 'MO' },
      { name: 'Piedmont (Piemonte)', abbreviation: 'PIE' },
      { name: 'Sardinia (Sardegna)', abbreviation: 'SA' },
      { name: 'Sicily (Sicilia)', abbreviation: 'SI' },
      { name: 'Trentino-Alto Adige', abbreviation: 'TAA' },
      { name: 'Tuscany (Toscana)', abbreviation: 'TU' },
      { name: 'Umbria', abbreviation: 'UM' },
      { name: "Aosta Valley (Valle d'Aosta)", abbreviation: 'AO' },
      { name: 'Veneto', abbreviation: 'VE' }
    ]
  },
  {
    country: 'Albania',
    type: 'County',
    states: [
      { name: 'Berat', abbreviation: 'BR' },
      { name: 'Dibër', abbreviation: 'DI' },
      { name: 'Durrës', abbreviation: 'DR' },
      { name: 'Elbasan', abbreviation: 'EL' },
      { name: 'Fier', abbreviation: 'FR' },
      { name: 'Gjirokastër', abbreviation: 'GJ' },
      { name: 'Korçë', abbreviation: 'KO' },
      { name: 'Kukës', abbreviation: 'KU' },
      { name: 'Lezhë', abbreviation: 'LE' },
      { name: 'Shkodër', abbreviation: 'SH' },
      { name: 'Tiranë (Tirana)', abbreviation: 'TR' },
      { name: 'Vlorë', abbreviation: 'VL' }
    ]
  }
];

export const CAT_FIP_OASIS_PRO_DESCRIPTION = 'Cat FIP Oasis Pro';
export const CAT_FIP_OASIS_PRO_ID = 'ee89b8f7-f891-4f0a-9516-23533eb7e8b6';

export const diseasesSelection = [
  'Wet (Pleural)',
  'Wet (Abdominal)',
  'Wet (Bicompartment)',
  'Wet',
  'Dry',
  'Ocular',
  'Neuro'
];

export const conditionsSelections = [
  'FIV',
  'FeLV',
  'Extended',
  'Not FIP',
  'Raised',
  'Noncompliance',
  'Toxoplasmosis',
  'Depo',
  'Relapse',
  'Epileptic',
  'Diabetic',
  'Blind',
  'Deaf',
  'Incontinent',
  'Enucleation',
  'Tripod',
  'Kidney Disease',
  'Heart Disease',
  'Siblings with FIP',
  'Loading Doses',
  'Deceased',
  'Anemia',
  'Urinary Crystals',
  'Neoplasia',
  'Reinfection',
  'Stomatitis',
  'FISS',
  'Transfusion',
  'EIDD'
].sort();

export const sideEffectSelections = [
  'Lethargy',
  'Injection site sores',
  'Pain at the injection site',
  'Vomiting',
  'Diarrhea',
  'Skin lesions',
  'Itching',
  'Kidney elevations',
  'Liver elevations',
  'Gum inflammation',
  'Nausea',
  'Seizures',
  'Leukopenia',
  'Hypersalivation',
  'Hair loss',
  'Vasovagal Syncope'
].sort();

export const catBreeds = [
  'Domestic Shorthair',
  'Domestic Medium hair',
  'Domestic Longhair',
  //'Abyssinian',
  //'American Bobtail',
  //'American Curl',
  'American Shorthair',
  //'American Wirehair',
  //'Balinese',
  'Bengal',
  'Birman',
  'Bombay',
  'British Shorthair',
  'Burmese',
  'Chartreux',
  'Cornish Rex',
  'Devon Rex',
  'Selkirk Rex',
  //'Egyptian Mau',
  'Exotic Shorthair',
  //'Havana Brown',
  //'Himalayan',
  //'Japanese Bobtail',
  'Maine Coon',
  'Manx',
  'Munchkin',
  //'Norwegian Forest',
  //'Ocicat',
  'Oriental',
  'Persian',
  'Ragdoll',
  'Russian Blue',
  'Scottish Fold',
  'Siamese',
  'Siberian',
  //'Singapura',
  //'Somali',
  'Sphynx',
  //'Tonkinese',
  'Turkish Angora',
  //'Turkish Van',
  'Other'
];

export const catGenders: string[] = [
  'Male',
  'Female',
  'Neutered Male',
  'Spayed Female'
];

export const treatmentTypes: string[] = ['Shot', 'Pill', 'EIDD Pill'];

export const notificationStatus = {
  REQUEST_HELP: 'REQUEST_HELP',
  RESPOND_HELP: 'RESPOND_HELP',
  COMMENT: 'COMMENT',
  ADMIN_ALERT: 'ADMIN_ALERT',
  REQUISITION: 'REQUISITION',
  CAT_REGISTRATION: 'CAT_REGISTRATION',
  BLOOD_WORK_CREATE: 'BLOOD_WORK_CREATE'
};

export const catStatus = {
  PENDING_TREATMENT: 'PENDING_TREATMENT',
  TREATMENT: 'TREATMENT',
  PENDING_OBSERVATION: 'PENDING_OBSERVATION',
  OBSERVATION: 'OBSERVATION',
  PENDING_CURED: 'PENDING_CURED',
  CURED: 'CURED',
  RELAPSE: 'RELAPSE',
  DECEASED: 'DECEASED',
  STOPPED: 'STOPPED',
  NOT_TREATED: 'NOT_TREATED',
  UNKNOWN_OUTCOME: 'UNKNOWN_OUTCOME'
};

export const catStatusData = [
  {
    status: catStatus.PENDING_TREATMENT,
    title: 'Pending Treatment'
  },
  {
    status: catStatus.TREATMENT,
    title: 'Treatment'
  },
  {
    status: catStatus.PENDING_OBSERVATION,
    title: 'Pending Observation'
  },
  {
    status: catStatus.OBSERVATION,
    title: 'Observation'
  },
  {
    status: catStatus.PENDING_CURED,
    title: 'Pending Cured'
  },
  {
    status: catStatus.CURED,
    title: 'Cured'
  },
  {
    status: catStatus.RELAPSE,
    title: 'Relapse'
  },
  {
    status: catStatus.STOPPED,
    title: 'Stopped'
  },
  {
    status: catStatus.NOT_TREATED,
    title: 'Not Treated'
  },
  {
    status: catStatus.DECEASED,
    title: 'Deceased'
  },
  {
    status: catStatus.UNKNOWN_OUTCOME,
    title: 'Unknown Outcome'
  }
];

export const dateType = {
  TREATMENT: 'TREATMENT',
  OBSERVATION: 'OBSERVATION'
};

export const taskType = {
  BLOOD_WORK_TREATMENT: 'BLOOD_WORK_TREATMENT',
  BLOOD_WORK_OBSERVATION: 'BLOOD_WORK_OBSERVATION',
  GENERAL: 'GENERAL'
};

export interface TaskTypeData {
  order: number;
  type: string;
  title: string;
}

export const taskTypeData: TaskTypeData[] = [
  {
    order: 1,
    type: taskType.BLOOD_WORK_TREATMENT,
    title: 'Blood Work Treatment'
  },
  {
    order: 2,
    type: taskType.BLOOD_WORK_OBSERVATION,
    title: 'Blood Work Observation'
  },
  {
    order: 3,
    type: taskType.GENERAL,
    title: 'General'
  }
];

export const requisitionEmails = [
  'owencapella441@163.com',
  'owencapella@163.com',
  'songhuanqing2801@163.com'
];
