import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Task } from '../../../model/models';

@Component({
  selector: 'app-task-modal',
  templateUrl: './task-modal.component.html',
  styleUrls: ['./task-modal.component.scss']
})
export class TaskModalComponent {
  viewType = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialogRef<TaskModalComponent>
  ) {
    this.viewType = this.data?.task ? 'Edit' : 'Create';
  }

  onSave($event: Task) {
    this.dialog.close($event);
  }

  onClose() {
    this.dialog.close();
  }
}
