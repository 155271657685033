<form class="blood-work-form" [formGroup]="bloodWorkForm">
  <mat-form-field class="description">
    <mat-label>Description</mat-label>
    <input
      id="description"
      matInput
      maxlength="50"
      placeholder=""
      formControlName="description" />
  </mat-form-field>

  <mat-form-field class="type">
    <mat-label>Type</mat-label>
    <mat-select formControlName="type">
      <mat-option *ngFor="let option of bloodWorkTypes" [value]="option.value">
        {{ option.label }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field hintLabel="MM/DD/YYYY" class="date">
    <mat-label>Date</mat-label>
    <input id="date" matInput [matDatepicker]="date" formControlName="date" />
    <mat-datepicker-toggle matIconSuffix [for]="date"></mat-datepicker-toggle>
    <mat-datepicker #date></mat-datepicker>
  </mat-form-field>
</form>
<div class="action-footer">
  <button mat-stroked-button (click)="close()">Close</button>
  <button
    mat-flat-button
    color="primary"
    (click)="save()"
    [disabled]="!bloodWorkForm.valid">
    Save
  </button>
</div>
