<div class="page-container">
  <h1>{{ viewType }} Profile Registration</h1>
  <p *ngIf="showForm && !profileRegistration">
    Do you need help with treating FIP? Fill out the information below to
    request a profile for FIP Oasis. Our administrators will review and
    determine next steps.
  </p>

  <h3 *ngIf="profileRegistration">Status: {{ profileRegistration.status }}</h3>

  <form
    *ngIf="showForm"
    [formGroup]="profileRegistrationForm"
    class="form-container">
    <mat-form-field class="email" hintLabel="Emails will never be shared.">
      <mat-label>Email Address</mat-label>
      <input id="email" matInput formControlName="email" type="email" />
    </mat-form-field>

    <mat-form-field class="first-name">
      <mat-label>First Name</mat-label>
      <input
        id="firstName"
        matInput
        placeholder=""
        formControlName="firstName"
        type="text" />
    </mat-form-field>

    <mat-form-field class="last-name">
      <mat-label>Last Name</mat-label>
      <input
        id="lastName"
        matInput
        placeholder=""
        formControlName="lastName"
        type="text" />
    </mat-form-field>

    <mat-form-field class="city">
      <mat-label>City</mat-label>
      <input
        id="city"
        matInput
        placeholder=""
        formControlName="city"
        type="text" />
    </mat-form-field>

    <mat-form-field class="state">
      <mat-label>State</mat-label>
      <input
        type="text"
        id="state"
        placeholder="State"
        (change)="checkState($event)"
        matInput
        formControlName="state"
        [matAutocomplete]="stateAuto" />
      <mat-autocomplete #stateAuto="matAutocomplete">
        <mat-option
          *ngFor="let option of filteredStates | async"
          [value]="option.name">
          {{ option.name }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field class="zip-code">
      <mat-label>Postal Code</mat-label>
      <input
        id="zipCode"
        matInput
        placeholder=""
        formControlName="zipCode"
        type="text" />
    </mat-form-field>

    <mat-form-field class="country">
      <mat-label>Country</mat-label>
      <mat-select
        appRequiredRoles="Admin"
        id="country"
        formControlName="country"
        (selectionChange)="countryChange($event.value, true)">
        <mat-option *ngFor="let country of countries" [value]="country.country">{{
          country.country
        }}</mat-option>
      </mat-select>
    </mat-form-field>

    <div class="comment">
      <p *ngIf="!profileRegistrationForm.disabled">
        Add a comment for why you need access to FIP Oasis for our admins to
        review.
      </p>
      <mat-form-field style="width: 100%">
        <mat-label>Comments</mat-label>
        <textarea
          matInput
          [maxlength]="maxCommentLength"
          cdkTextareaAutosize
          draggable="false"
          formControlName="comment"
          cdkAutosizeMinRows="3"
          cdkAutosizeMaxRows="30"></textarea>
        <mat-hint align="end">{{ remaining }} characters remaining</mat-hint>
      </mat-form-field>
    </div>
  </form>

  <div *ngIf="showForm" class="action-footer">
    <button
      *ngIf="!profileRegistration"
      type="button"
      mat-stroked-button
      (click)="goBack()">
      Back
    </button>
    <button
      *ngIf="!profileRegistration"
      type="submit"
      mat-flat-button
      color="primary"
      (click)="save()"
      [disabled]="!profileRegistrationForm.valid">
      Save
    </button>

    <button
      *ngIf="reviewActive"
      type="submit"
      mat-flat-button
      color="primary"
      (click)="confirmSubmitReview('ACCEPTED', $event)">
      Accept
    </button>
    <button
      *ngIf="reviewActive"
      type="submit"
      mat-flat-button
      color="warn"
      (click)="confirmSubmitReview('REJECTED', $event)">
      Reject
    </button>
  </div>

  <div *ngIf="!showForm && profileRegistration">
    <h3>Your Registration has been submitted.</h3>
    <h3>Your Registration ID is:</h3>
    <h3>
      <strong>{{ profileRegistration.id }}</strong>
    </h3>
    <h3>
      Please copy this somewhere as you will not be able to retrieve it later.
      You can use the <strong>ID</strong> or your <strong>Email</strong> to check the status of your registration.
    </h3>
  </div>
</div>
