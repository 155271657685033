import { Component, Inject } from '@angular/core';
import { CatDate } from '../../../model/models';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { dateType } from '../../../model/constants';

@Component({
  selector: 'app-cat-dates-modal',
  templateUrl: './cat-dates-modal.component.html',
  styleUrls: ['./cat-dates-modal.component.scss']
})
export class CatDatesModalComponent {
  viewType = '';
  dateTypeTitle = 'Treatment';
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialogRef<CatDatesModalComponent>
  ) {
    this.viewType = this.data?.catDate ? 'Edit' : 'Create';
    if (this.data?.catDate?.dateType === dateType.OBSERVATION) {
      this.dateTypeTitle = 'Observation';
    }
  }

  onSave($event: CatDate) {
    this.dialog.close($event);
  }

  onClose() {
    this.dialog.close();
  }
}
