import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Task } from '../../../model/models';
import { FipAdminApiService } from '../../../service/api/fip-admin-api.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { SnackbarService } from '../../../service/snackbar/snackbar.service';
import { TaskTypeData, taskTypeData } from '../../../model/constants';

@Component({
  selector: 'app-task-form',
  templateUrl: './task-form.component.html',
  styleUrls: ['./task-form.component.scss']
})
export class TaskFormComponent implements OnInit {
  @Input()
  task: Task | undefined;

  @Input()
  catId: string | undefined;

  @Input()
  isAdmin: boolean | undefined;

  @Output()
  taskSave = new EventEmitter<Task>();

  @Output()
  taskClose = new EventEmitter<void>();

  timeZone = 'UTC';

  taskForm = this.formBuilder.group({
    taskType: new FormControl<TaskTypeData | undefined>(
      undefined,
      Validators.required
    ),
    resolved: [false],
    notified: [false],
    notifiedOn: new FormControl<Date | undefined>(undefined),
    description: ['', [Validators.required, Validators.maxLength(50)]],
    dueDate: new FormControl<Date | undefined>(undefined, Validators.required)
  });

  constructor(
    private fipAdminApiService: FipAdminApiService,
    private formBuilder: FormBuilder,
    private snackbarService: SnackbarService
  ) {
    this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  ngOnInit() {
    if (this.task) {
      this.setFormValues(this.task);
    }
  }

  close() {
    this.taskClose.emit();
  }

  save() {
    const task: any = {
      ...this.task,
      ...this.taskForm.getRawValue(),
      taskType: this.taskType.value?.type,
      catId: this.catId
    };

    this.fipAdminApiService.saveTask(task).subscribe((task: Task) => {
      this.snackbarService.openSuccessSnackBar('Task Saved!');
      this.taskSave.emit(task);
    });
  }

  private setFormValues(task: Task) {
    this.resolved.setValue(task.resolved);
    this.description.setValue(task.description);
    this.dueDate.setValue(task.dueDate);
    this.taskType.setValue(
      taskTypeData.find((data) => data.type === task.taskType)
    );
    this.taskType.disable();
    this.notified.setValue(task.notified);
    if (task.notifiedOn) {
      // Weird expression changed error from this line fixed with change detector
      this.notifiedOn.setValue(new Date(task.notifiedOn));
    }
  }

  get resolved() {
    return this.taskForm.controls.resolved;
  }

  get description() {
    return this.taskForm.controls.description;
  }

  get dueDate() {
    return this.taskForm.controls.dueDate;
  }

  get taskType() {
    return this.taskForm.controls.taskType;
  }

  get notified() {
    return this.taskForm.controls.notified;
  }

  get notifiedOn() {
    return this.taskForm.controls.notifiedOn;
  }

  protected readonly taskTypeData = taskTypeData;

  setNotifiedOn() {
    if (!this.notifiedOn.value) {
      this.notifiedOn.setValue(new Date());
    }
  }
}
