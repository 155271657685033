import { Component, OnDestroy } from '@angular/core';
import { ApplicationDataService } from '../../service/data/application-data.service';
import { Subscription } from 'rxjs';
import { ApplicationData } from '../../model/models';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AuthService } from '@auth0/auth0-angular';
import { LoadingService } from '../../service/loading/loading.service';
import { OUR_MISSION } from '../../model/faq-constants';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnDestroy {
  applicationData$: Subscription | undefined;
  applicationData: ApplicationData | undefined;
  authenticated = false;

  constructor(
    private applicationDataService: ApplicationDataService,
    public authService: AuthService,
    private loadingService: LoadingService
  ) {
    this.applicationData$ = this.applicationDataService
      .getApplicationData()
      .pipe(takeUntilDestroyed())
      .subscribe((applicationData) => {
        this.applicationData = applicationData;
      });

    this.authService.isAuthenticated$.subscribe((authenticated) => {
      if (authenticated) {
        this.authenticated = true;
      }
    });
    this.loadingService.setLoading(false);
  }

  ngOnDestroy() {
    if (this.applicationData$) {
      this.applicationData$.unsubscribe();
    }
  }

  protected readonly OUR_MISSION = OUR_MISSION;

  signUp() {
    this.authService.loginWithRedirect({
      authorizationParams: {
        screen_hint: 'signup'
      }
    });
  }
}
