import { Injectable } from '@angular/core';
import { ActivityRequest, Comment } from '../../model/models';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ActivityService {
  constructor(private httpClient: HttpClient) {}

  getComments(activityRequest: ActivityRequest) {
    return this.httpClient.post<Comment[]>(
      environment.fipAdminApiUrl + '/activity/comments',
      activityRequest
    );
  }
}
