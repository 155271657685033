<h2 mat-dialog-title>Please Confirm</h2>
<div mat-dialog-content>
  Are you sure you want to <strong>{{ data?.action || "continue" }}</strong>?
  <div style="margin-top: 1rem" *ngIf="data.subContent">
    {{ data.subContent }}
  </div>
</div>
<div mat-dialog-actions align="end">
  <button mat-stroked-button (click)="cancel()">Go Back</button>
  <button mat-flat-button color="primary" (click)="confirm()">Confirm</button>
</div>
