import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  constructor(private snackBar: MatSnackBar) {}

  // Why does it stay open across pages lol?
  // Snackbar that opens with success background
  openSuccessSnackBar(message: string) {
    this.snackBar.open(message, '', {
      duration: 2000,
      panelClass: ['notification-success'],
      horizontalPosition: 'right',
      verticalPosition: 'bottom'
    });
  }
  //Snackbar that opens with failure background
  openFailureSnackBar(message: string) {
    this.snackBar.open(message, '', {
      duration: 2000,
      panelClass: ['notification-error'],
      horizontalPosition: 'right',
      verticalPosition: 'bottom'
    });
  }
}
